import React, {useEffect, useState} from 'react';
import './VendorProducts.css';
import AdminAndVendorPageHeader from "../../common/AdminAndVendorPageHeader/AdminAndVendorPageHeader";
import {toggleSidebar} from "../../../utils/SidebarUtils";
import texts from "../../../Languages/bg";
import ButtonsSection from "../ButtonsSection/ButtonsSection";
import SelectSearch from "react-select";
import {createNestedOptions} from "../../../utils/SelectUtils";
import {getProductsForVendor} from "../../../services/VendorServices";
import ProductsTable from "./ProductsTable";
import {getCategories} from "../../../utils/CategoryUtils";
import ShowMoreButton from "../../customer/ShowMoreButton/ShowMoreButton";
import {DEFAULT_FETCH_ITEMS_COUNT} from "../../../config";
import AdminAndVendorFooter from "../../common/AdmivAndVendorFooter/AdminAndVendorFooter";
import {addIsEditableAttribute} from "../../../utils/OrderUtils";
import {Prompt} from "react-router-dom";
import {notify} from "../../../utils/Notifications";
import Spinner from "react-bootstrap/Spinner";
import {editVendorProduct} from "../../../utils/VendorProductUtils";

const VendorProducts = props => {

    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchedProduct, setSearchedProduct] = useState();
    const [selectedCategory, setSelectedCategory] = useState({});
    const [isShowMoreButtonHidden, setIsShowMoreButtonHidden] = useState(true);
    const [page, setPage] = useState(2);
    const searchRef = React.useRef();
    const [productsInEdit, setProductsInEdit] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        getCategories(setCategories)
    }, []);
    
    useEffect(() => {
        if (searchedProduct?.length > 0) {
            resetPageForShowMoreButton();
            getProducts(getProductsResponseHandler, selectedCategory ? selectedCategory.value : null, searchedProduct)
        };
    }, [searchedProduct]);

    useEffect(() =>  {
        if(searchRef.current) {
            searchRef.current.value = "";
        }
        resetPageForShowMoreButton();
        getProducts(getProductsResponseHandler, selectedCategory.value, null)
    },[selectedCategory]);

    useEffect(() => {
        if (productsInEdit > 0 ) {
            window.onbeforeunload = (e) => {
                e.returnValue = ""
            }
        } else {
            window.onbeforeunload = null
        }
    }, [productsInEdit]);


    const getProducts = async (onSuccess, selectedCategoryId, productName, page, size) => {
        try {
            let response = await getProductsForVendor(selectedCategoryId, productName, page, size);
            onSuccess(response.data);
        } catch (error) {
            
        }
    };

    const resetPageForShowMoreButton = () => {
        setPage(2)
    };

    const getProductsResponseHandler = (res) => {
        addIsEditableAttribute(res);
        setProducts([...res]);
        setIsShowMoreButtonHidden(res.length < DEFAULT_FETCH_ITEMS_COUNT)
        setIsLoading(false);
    };

    const handleSearch = (event) => {
        setSearchedProduct(event.target.value)
    };

    const handleClearSearch = () => {
        searchRef.current.value = "";
        setSearchedProduct();
    };

    const handleSelectCategory = (event) => {
        if (event.value) {
            setSelectedCategory(event)
        } else {
            setSelectedCategory({label: null, value: null})
        }
    };

    const showMoreResponseHandler = (res) => {
        setProducts([...products, ...res]);

        setIsShowMoreButtonHidden(res.length < DEFAULT_FETCH_ITEMS_COUNT)
    };

    const handleShowMoreClick = () => {
        setPage(page + 1);
        getProducts(showMoreResponseHandler, selectedCategory.value, searchedProduct, page);
    };

    const handlePriceChange = (event, packIndex, product, index) => {
        const productsArr = [...products];
        if (event.target.name === "product-status-change") {
            productsArr[index].productStatus = event.target.value;
            setProducts([...productsArr])
        }  else {
            productsArr[index].packagingList[packIndex].packagingUnitPriceVAT = event.target.value
            setProducts([...productsArr])
        }
    };

    const editProductResponseHandler = (res) => {
        setProductsInEdit(productsInEdit - 1);
        if (res.status === 200) {
            notify("success", texts.ADMIN_PANEL.SUCCESSFUL_CHANGE)
        }
    };

    const editProductErrorHandler = (err) => {
        setProductsInEdit(productsInEdit - 1);
    };

    const handleSaveClick = (product, index, isEditable) => {
        if (isEditable) {
            editVendorProduct(product, editProductResponseHandler, editProductErrorHandler);
            products[index].isEditable = false;
        } else {
            setProductsInEdit(productsInEdit + 1);
            products[index].isEditable = true;
            let element = document.getElementById(product.id)
            setTimeout(() => {
                if (element) {
                    element.focus();
                    element.select();
                }

            },100)
        }
        setProducts([...products])
    };

    return (
        <div>
            <AdminAndVendorPageHeader  onClick={toggleSidebar} text={texts.VENDOR_PANEL.PRODUCTS_PAGE_HEADING}/>

            <div className="vrp-action-buttons-wrapper">
                <ButtonsSection disableAll className="text-right" hideButtons showIcons />
            </div>

            <div className="vrp-products-wrapper m-1">
                <div className="card shadow">

                    <div className="card-header text-left h5">
                        {texts.VENDOR_PANEL.PRODUCTS_TABLE_HEADING}
                    </div>

                    <div className="card-body">

                        {((() => {
                            if (isLoading) {
                                return <Spinner animation={"border"} variant={"primary"} />
                            } else {
                                return (
                                    <>
                                        <div className="w-100 d-md-flex">
                                            <div className="input-group w-100 w-lg-55 mb-3 pr-md-5">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1">
                                                        <i className="fas fa-search"/>
                                                    </span>
                                                </div>
                                                <input type="text" ref={searchRef} className="form-control no-box-shadow"
                                                       onChange={handleSearch} placeholder={texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.SEARCH_PLACEHOLDER}
                                                       aria-describedby="basic-addon1"/>
                                                <div className="input-group-append" onClick={handleClearSearch}>
                                                    <span className="input-group-text"><i className="fas fa-times-circle"></i> </span>
                                                </div>
                                            </div>

                                            <div className="w-100 w-lg-35 text-left pl-md-5">
                                                <SelectSearch onChange={handleSelectCategory} options={createNestedOptions(categories)}
                                                              className="w-100" placeholder={texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.SEARCH_PLACEHOLDER}/>
                                            </div>

                                        </div>

                                        <div className="text-black pt-2 pb-2 w-100 d-flex">
                                            <div className="w-50 text-right">
                                                {selectedCategory.label && `${texts.VENDOR_PANEL.CATEGORY}: `}
                                            </div>
                                            <div className="pl-1"> {selectedCategory && selectedCategory.label} </div>
                                        </div>

                                        <div className="w-100">
                                            <ProductsTable products={products}
                                                           onSaveClick={handleSaveClick}
                                                           onChange={handlePriceChange}/>
                                        </div>
                                        <div className="text-left">
                                            {texts.GENERAL.PRODUCTS_SHOWN}: {products.length}
                                        </div>

                                    </>
                                )
                            }
                        })())}
                    </div>
                </div>

                <div hidden={isShowMoreButtonHidden} className="m-4">
                    <ShowMoreButton className={"m-0"} onClick={handleShowMoreClick}  text={texts.VENDOR_PAGE.SHOW_MORE_TEXT} />
                </div>
            </div>

            <AdminAndVendorFooter texts={texts.VENDOR_PAGE.FOOTER} />

            <Prompt when={productsInEdit > 0} message="Are you sure you want to leave?" />

        </div>
    );
};

export default VendorProducts;