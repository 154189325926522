import React from 'react';
import PropTypes from 'prop-types';
import './VendorProducstsViewTypeSelect.css';
import {ROUTES} from "../../../config";
import {Link} from 'react-router-dom'

const VendorProductsViewTypeSelect = ({texts, searchedPhrase, listViewIcon, gridViewIcon, changeView, breadcrumb, onClick, isDesktop, vendorName, resultsCount, clearSearchParams}) => {
    return (
        <div className="row">
            <div className="w-100 p-0 mt-1">
                <div className={`pt-2 pl-2 float-left vp-products-view-text ${breadcrumb.length > 0 && "bc-width"}`}>
                     <Link className="bc-item" to={ROUTES.HOME}> {texts.START} </Link>
                     <span onClick={clearSearchParams} className="bc-item">
                                  &gt; {vendorName}
                               </span>
                    {breadcrumb.length > 0 &&
                        <>

                            {breadcrumb.map((item, index) => {
                                return (
                                    <span onClick={() => onClick(item.id, item.name, isDesktop, breadcrumb.filter((e, i) => i <= index))} className="">
                                        <span> &gt; </span>
                                        <span id={item.name.split(' ').join('_')} className="bc-item"> {item.name} </span>
                                     </span>
                                )
                            })}

                        </>
                    }
                    {searchedPhrase && <span> &gt; {searchedPhrase}  ( {resultsCount} )</span>}
                </div>
                <div className="vp-products-view-buttons">
                    <span  className={`float-right p-2`}>
                        <img onClick={() => changeView(true)} className="icon-list c-pointer" src={listViewIcon} alt=""/>
                    </span>

                    <span className={`float-right p-2`}>
                        <img onClick={() => changeView(false)} className="icon-grid c-pointer" src={gridViewIcon} alt=""/>
                    </span>
                </div>
            </div>
        </div>
    );
};

VendorProductsViewTypeSelect.propTypes = {
    texts: PropTypes.object,
    searchedPhrase: PropTypes.string,
    listViewIcon: PropTypes.string,
    gridViewIcon: PropTypes.string,
    changeView: PropTypes.func,
    breadcrumb: PropTypes.array,
    onClick: PropTypes.func,
    isDesktop: PropTypes.bool,
    vendorName: PropTypes.string,
    resultsCount: PropTypes.number,
    clearSearchParams: PropTypes.func
};

export default VendorProductsViewTypeSelect;