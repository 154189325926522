import React from 'react';
import PropTypes from 'prop-types';
import './NotificationLink.css';

const NotificationLink = ({text, url}) => {
    return (
        <a className="np-link-btn" href={url}>
            <div className="np-link-btn-text">
                {text}
            </div>
        </a>
    );
};

NotificationLink.propTypes = {
    text: PropTypes.string,
    url: PropTypes.string
};

export default NotificationLink;