import React from 'react';
import PropTypes from 'prop-types';
import './AddToCartButton.css'
import ChangeQuantityButton from "../ChangeQuantityButton/ChangeQuantityButton";
import {isLargeScreen} from "../../../utils/CheckWindowWidth";

const AddToCartButton = ({onAddButtonClick, id, packagingId, itemInCart, updateOrder, selectedPackage, disabled, product}) => {

    const onAddClick = (event) => {
        event.target.disabled = true;
        onAddButtonClick(event, id, packagingId, 1, null, selectedPackage);
        setTimeout(() => {
            let element = document.getElementById("ifp" + id);
            if (element) {
               if (isLargeScreen(window.innerWidth)) {
                   element.focus()
               }
            }
        },200)
    };

    let button;

    if (itemInCart && itemInCart.quantity >= 0) {
        button = <ChangeQuantityButton id={id} onClick={(event) => updateOrder(event, id)} quantity={itemInCart.quantity} product={product} />
    } else {
        button =
           <div className="w-100">
               <button data-testid={"add_tid_" + id} disabled={disabled} onClick={onAddClick}
                       name="initial-add" className="vp-add-to-cart-btn ">
               </button>
           </div>
    }

    return button
};

AddToCartButton.propTypes = {
    onAddButtonClick: PropTypes.func,
    id: PropTypes.number,
    packagingId: PropTypes.number,
    itemInCart: PropTypes.object,
    updateOrder: PropTypes.func,
    selectedPackage: PropTypes.object,
    disabled: PropTypes.bool,
    product: PropTypes.object
};

export default AddToCartButton;