import React from 'react';
import PropTypes from 'prop-types';
import './ChangeQuantityButton.css';
import {notify} from "../../../utils/Notifications";
import texts from '../../../Languages/bg';
import {MEASURE_UNITS} from "../../../config";

const ChangeQuantityButton = ({onClick, quantity, id, refr, product}) => {
    if (quantity > 0) {
        sessionStorage.setItem(id, quantity);
    }

    const validateItemQuantity = (event, id, product) => {
        let value = event.target.value;
        if  (value >= 0) {
            if (value % 1 !== 0) {
                if (product.measureUnit === MEASURE_UNITS[0]) {
                    event.target.value = value;
                    return onClick(event, id)
                } else {
                    return  notify("error", texts.VENDOR_PAGE.MUST_BE_INTEGER_ERR)
                }
            } else {
                return onClick(event, id)
            }
        }

    };

    return (
        <div className="vp-up-down-btn d-flex justify-content-between align-items-center">
            <button onClick={onClick} disabled={quantity === 0 || quantity === ""}
                    name="remove-one" className="sign-wrapper" data-testid={"mtid_" + id}>
                -
            </button>
            <div className="vp-cart-list-input-num-wrapper text-center">
                <input className="cart-input-num text-center" type="number" name="set-quantity" id={"ifp" + id}
                       onChange={(e) => validateItemQuantity(e, id, product)} data-testid={"itid_" + id}
                       value={quantity} ref={refr} onBlur={(event) => {
                        if (event.target.value === '' || event.target.value === "0") {
                            event.target.value = sessionStorage.getItem(id);
                            return validateItemQuantity(event, id, product)
                        }
                }}/>
            </div>
            <button onClick={onClick} data-testid={"ptid_" + id}
                    name="add-one" className="sign-wrapper">
                +
            </button>   
        </div>
    );
};

ChangeQuantityButton.propTypes = {
    onClick: PropTypes.func,
    quantity: PropTypes.number, 
    id: PropTypes.number,
    refr: PropTypes.object,
    product: PropTypes.object
};

export default ChangeQuantityButton;