import React from 'react';
import PropTypes from 'prop-types';
import ProductImageTableCell from "../../ProductImageTableCell/ProductImageTableCell";
import {texts} from '../../../../Languages/Language';
import { getMatchedProp } from '../../../../utils/MatchUtils';

const MatchProductTable = ({csvRow, rowIndex, onRadioSelect}) => {
    let gp = csvRow.globalProductDTO;
    let vp = csvRow.vendorProductDTO;
    let csvData = csvRow.csvData;
    
    return (
        <div className="p-3 d-flex justify-content-end">
            <div className="w-75">
                <div className="h5 text-black-50 text-left">
                    {getMatchedProp(csvRow)}
                </div>
                <table className="table table-bordered table-striped">
                    <thead className="bg-primary text-white">
                    <tr>
                        {Object.values(texts.ADMIN_PANEL.MATCH_STEP_2.TABLE_HEADERS)
                            .map(th => (
                                <th>{th}</th>
                            ))}
                    </tr>
                    </thead>
                    <tbody className="font-weight-bold">
                    <tr>
                        <td>
                            <ProductImageTableCell product={gp} />
                        </td>
                        <td>{gp.productName}</td>
                        <td>{gp.netWeight}</td>
                        <td>{gp.measureUnit}</td>
                        <td>{gp.brand}</td>
                        <td>{gp.categories?.[0]?.name}</td>
                        <td>{gp.categories?.[1]?.name}</td>
                        <td>{gp.categories?.[2]?.name}</td>
                        <td>{gp.origin}</td>
                        <td>{gp.quality}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{vp.displayName}</td>
                        <td>{csvData.netWeight}</td>
                        <td>{csvData.measureUnit}</td>
                        <td>{csvData.brand}</td>
                        <td>{csvData.categories?.[0]?.name}</td>
                        <td>{csvData.categories?.[1]?.name}</td>
                        <td>{csvData.categories?.[2]?.name}</td>
                        <td>{csvData.origin}</td>
                        <td>{csvData.quality}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div style={{width: "15%"}} className="d-flex flex-column justify-content-between">
                <div className="text-left pl-3 font-weight-bold">
                    {texts.ADMIN_PANEL.MATCH_STEP_2.YES} / {texts.ADMIN_PANEL.MATCH_STEP_2.NO}
                </div>
                <div className="text-left pb-5">
                    <div className="d-flex pb-2">
                        <div className="form-check ml-3">
                            <input data-testid="matching" onChange={() => onRadioSelect("matching", csvRow, rowIndex)} type="radio"
                                   className="form-check-input match-checkbox" name={"tuple" + rowIndex}/>
                        </div>
                        <div className="form-check ml-3">
                            <input data-testid="not-matching" onChange={() => onRadioSelect("not_matching", csvRow, rowIndex)} type="radio"
                                   className="form-check-input match-checkbox" name={"tuple" + rowIndex}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

MatchProductTable.propTypes = {
    csvRow: PropTypes.object,
    rowIndex: PropTypes.number,
    onRadioSelect: PropTypes.func
}

export default MatchProductTable