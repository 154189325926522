import React from 'react';
import PropTypes from 'prop-types';
import './VendorInfoExpandable.css';
import { renderWorkingHours } from '../../../utils/VendorInfoUtils';

const VendorInfoExpandable = ({mainText, content, id, parentId, children}) => {

    return (
       <div>
           <div className="pt-2 pb-2 vp-expand-info-wrapper d-flex collapsed"  data-toggle="collapse"
                data-target={`#${id}`} aria-expanded="false">
               <div className="col-11 text-left vp-expandable-text">
                   {mainText}
               </div>
           </div>
           <div id={id} className="collapse vp-expandable-content text-left pl-2" data-parent={`#${parentId}`}>
               {(() => {
                   if (id === "deliveryTerms" || id === "moreInfo") {
                       return (
                           <div dangerouslySetInnerHTML={{__html: content}} />
                       )
                   } else if ( id === "workingHours") {
                       return (
                           content.split('\n').map((wh, index) => renderWorkingHours(wh, index, true))
                       )
                   } else {
                       return  content || children
                   }
               })()}
           </div>
       </div>
    );
};

VendorInfoExpandable.propTypes = {
    mainText: PropTypes.string,
    content: PropTypes.string,
    id: PropTypes.string,
    parentId: PropTypes.string,
    children: PropTypes.array
};

export default VendorInfoExpandable;