import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import SideNavigation from "../../common/SideNavigation/SideNavigation";
import SideNavigationLink from "../../common/SideNavigationLink/SideNavigationLink";
import {ROUTES, USER_ROLES} from "../../../config";
import texts from "../../../Languages/bg";
import {Route, Switch} from "react-router-dom";
import AdminOrders from "../AdminOrders/AdminOrders";
import AdminOrderView from "../AdminOrderView/AdminOrderView";
import AdminProducts from "../AdminProducts/AdminProducts";
import NewGlobalProductPage from "../NewGlobalProduct/NewGlobalProductPage";
import SideNavigationCollapseLink from "../../common/SideNavigationCollapseLink/SideNavigationCollapseLink";
import {PrivateRoute} from "../../../PrivateRoute";
import AdminImportPriceList from "../AdminImportPriceList/AdminImportPriceList";
import MatchByErpid from "../MatchByERPID/MatchByErpid";
import MatchByName from "../MatchByName/MatchByName";
import SuccessfulImportPage from "../SuccessfulImportPage/SuccessfulImportPage";
import UsersList from "../UsersList/UsersList";
import NewAndEditUser from "../NewAndEditUser/NewAndEditUser";
import AdminAndVendorProfile from "../../common/AdminAndVendorProfile/AdminAndVendorProfile";
import ListCustomers from "../ListCustomers/ListCustomers";
import ListVendors from "../ListVendors/ListVendors";
import NewAndEditVendor from "../NewAndEditVendor/NewAndEditVendor";
import NewAndEditCustomer from "../NewAndEditCustomer/NewAndEditCustomer";
import NewAndEditPointOfSale from "../NewAndEditPointOfSale/NewAndEditPointOfSale";
import {isLargeScreen, scrollToTop} from "../../../utils/CheckWindowWidth";

const AdminMainPage = props => {

    useEffect(() => {
        if (!isLargeScreen(window.innerWidth)) {
            scrollToTop();
        }
    },[props.location.pathname]);

    return (
        <div className="d-flex" id="wrapper">
            <SideNavigation path={ROUTES.ADMIN.ADMIN_HOME}>

                <SideNavigationCollapseLink target={"catalog"} text={texts.ADMIN_PANEL.SIDEBAR_LINKS.CATALOG} icon={<i className="fas fa-store"></i>}>
                    <SideNavigationLink isCollapsible className="collapse-item" text={texts.ADMIN_PANEL.SIDEBAR_LINKS.PRICE_LISTS} />
                    <SideNavigationLink isCollapsible className="collapse-item" text={texts.ADMIN_PANEL.SIDEBAR_LINKS.IMPORT} path={ROUTES.ADMIN.IMPORT} />
                    <SideNavigationLink isCollapsible className="collapse-item" path={ROUTES.ADMIN.GLOBAL_PRODUCTS} text={texts.ADMIN_PANEL.SIDEBAR_LINKS.PRODUCTS} />
                </SideNavigationCollapseLink>

                <SideNavigationCollapseLink target={"profile"} text={texts.ADMIN_PANEL.SIDEBAR_LINKS.PROFILES} icon={<i className=" fas fa-users-cog"> </i>}>
                    <SideNavigationLink isCollapsible className="collapse-item" text={texts.ADMIN_PANEL.SIDEBAR_LINKS.USERS}  path={ROUTES.ADMIN.USERS_LIST}/>
                    <SideNavigationLink isCollapsible className="collapse-item" text={texts.ADMIN_PANEL.SIDEBAR_LINKS.VENDORS} path={ROUTES.ADMIN.LIST_CLIENTS_VENDORS} />
                    <SideNavigationLink isCollapsible className="collapse-item" text={texts.ADMIN_PANEL.SIDEBAR_LINKS.CLIENTS} path={ROUTES.ADMIN.LIST_CLIENTS_CUSTOMERS} />
                </SideNavigationCollapseLink>
                <SideNavigationLink linkClasses={"nav-link"} className={"nav-item font-weight-bold"} path={ROUTES.ADMIN.ADMIN_ORDERS} icon={<i className="fas fa-clipboard-list"></i>} text={texts.ADMIN_PANEL.SIDEBAR_LINKS.ORDERS}/>
                <SideNavigationLink linkClasses={"nav-link"} className={"nav-item font-weight-bold"} text={texts.ADMIN_PANEL.SIDEBAR_LINKS.CATEGORIES} icon={<i className="fas fa-folder"></i>}/>

                <SideNavigationCollapseLink target={"marketing"} text={texts.ADMIN_PANEL.SIDEBAR_LINKS.MARKETING} icon={<i className="fas fa-percent"></i>}>
                    <SideNavigationLink isCollapsible className="collapse-item" text={texts.ADMIN_PANEL.SIDEBAR_LINKS.PROMOTIONS} />
                    <SideNavigationLink isCollapsible className="collapse-item" text={texts.ADMIN_PANEL.SIDEBAR_LINKS.NOTIFICATIONS} />
                    <SideNavigationLink isCollapsible className="collapse-item" text={texts.ADMIN_PANEL.SIDEBAR_LINKS.BANNERS} />
                </SideNavigationCollapseLink>

                <SideNavigationLink linkClasses={"nav-link"} className={"nav-item"} text={texts.ADMIN_PANEL.SIDEBAR_LINKS.PAGES} icon={<i className="fas fa-file-alt"></i>}/>
                <SideNavigationLink linkClasses={"nav-link"} className={"nav-item"} text={texts.ADMIN_PANEL.SIDEBAR_LINKS.SETTINGS} icon={<i className="fas fa-tools"></i>}/>

            </SideNavigation>
            <div className="w-100" id="content-wrapper">
                <Switch>
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.ADMIN_HOME} component={AdminOrders} />
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.ADMIN_ORDERS} component={AdminOrders} />
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.ADMIN_ORDER.ROUTE} component={AdminOrderView} />
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.GLOBAL_PRODUCTS} component={AdminProducts} />
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.NEW_GLOBAL_PRODUCT} component={NewGlobalProductPage}/>
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.IMPORT} component={AdminImportPriceList}/>
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.IMPORT_MATCH_2} component={MatchByErpid} />
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.MATCH_BY_NAME} component={MatchByName} />
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.FINISH_IMPORT} component={SuccessfulImportPage} />
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.USERS_LIST} component={UsersList} />
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.NEW_EDIT_USER_ROUTE} component={NewAndEditUser} />
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.PROFILE} component={AdminAndVendorProfile} />
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.LIST_CLIENTS_CUSTOMERS} component={ListCustomers} />
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.LIST_CLIENTS_VENDORS} component={ListVendors} />
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.NEW_EDIT_VENDOR_ROUTE} component={NewAndEditVendor} />
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.NEW_EDIT_CUSTOMER_ROUTE} component={NewAndEditCustomer} />
                    <PrivateRoute exact role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.NEW_EDIT_POS} component={NewAndEditPointOfSale} />
                </Switch>
            </div>
        </div>
    );
};

AdminMainPage.propTypes = {

};

export default AdminMainPage;