import React, {useCallback, useEffect, useState} from 'react';
import './VendorPage.css';
import ListIconBlue from "../../../assets/list_view_icon_blue.svg";
import ListIconGray from '../../../assets/list_view_icon_grey.svg';
import GridIconBlue from '../../../assets/grid_view_icon_blue.svg';
import GridIconGray from "../../../assets/grid_view_icon_grey.svg";
import CustomerPageHeader from "../CustomerPageHeader/CustomerPageHeader";
import VendorPageIntro from "../VendorPageIntro/VendorPageIntro";
import VendorInfoExpandable from "../VendorInfoExpandable/VendorInfoExpandable";
import VendorAssortimentSearch from "../VendorAssortimentSearch/VendorAssortimentSearch";
import VendorProductsViewTypeSelect from "../VendorProductsViewTypeSelect/VendorProductsViewTypeSelect";
import {getVendorImage, getVendorProductsAsync} from "../../../services/VendorServices";
import {getVendorCategoriesById} from "../../../services/CategoryServices";
import {texts as text} from '../../../Languages/Language';
import Footer from "../Footer/Footer";
import Drawer from "../Drawer/Drawer";
import Categories from "../Categories/Categories";
import CategoriesIcon from "../../../assets/bars-solid.svg";
import CloseIcon from '../../../assets/cancel.svg';
import ProductSection from ".././ProductSection/ProductSection";
import VendorInfoDesktop from "../VendorInfoDesktop/VendorInfoDesktop";
import {isLargeScreen} from "../../../utils/CheckWindowWidth";
import {
    deleteOrder,
    getInitialOrderToVendor,
    makeOrderAsync,
    deleteProductFromOrder
} from "../../../services/OrderServices";
import {
    createOrderBody,
    formatOrderDecimalQuantity,
    getItemsCount,
    getItemsPrice,
    handleOrderUpdateEvent, makeAndUpdateOrderErrorHandler, getInitialOrders
} from "../../../utils/OrderUtils";
import ShowMoreButton from "../ShowMoreButton/ShowMoreButton";
import {DEFAULT_FETCH_ITEMS_COUNT, ROUTES} from "../../../config";
import ShoppingCartButtonMobile from "../ShoppingCartButtonMobile/ShoppingCartButtonMobile";
import GoBackSection from "../GoBackSection/GoBackSection";
import _ from 'lodash';
import Spinner from "react-bootstrap/Spinner";
import BackToTopButton from "../BackToTopButton/BackToTopButton";
import {renderPhoneLinks} from "../../../utils/VendorInfoUtils";
import {
    addClassToElementById,
    collapseCategories,
    imageFlyToCart, removeClassFromElementById,
    removeLastBreadcrumb,
    removeSelectedCategoriesStyle,
    scrollToElement, setAttrToElementById
} from "../../../utils/Utils";
import {attachActiveAttribute} from '../../../utils/VendorProductUtils';
import { getVendorByIdAsync } from '../../../utils/VendorUtils';

const VendorPage = props => {
    const texts = text.VENDOR_PAGE;
    const [vendorData, setVendorData] = useState();
    const [vendorProducts, setVendorProducts] = useState([]);
    const [isListView, setListView] = useState(false);
    const [categories, setCategories] = useState();
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedCategoryId, setSelectedCategoryId] = useState();
    const [cart, setCart] = useState();
    const [orderId, setOrderId] = useState();
    const [searchedItem, setSearchedItem] = useState();
    const [isShowMoreButtonHidden, setIsShowMoreButtonHidden] = useState(true);
    const [page, setPage] = useState(2);
    const [breadcrumb, setBreadcrumb] = useState([]);
    const [allCarts, setAllCarts] = useState();
    const [vendorImage, setVendorImage] = useState("");
    const [noProductsFound, setNoProductsFound] = useState(false);
    const [noProductsFoundInCategory, setNoProductsFoundInCategory] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [ordersToSend, setOrdersToSend] = useState([]);
    const [sendNext, setSendNext] = useState(true);
    const searchRef = React.useRef();
    const breadcrumbRef = React.useRef();

    useEffect(() => {
        let id;
        if (props.location.state) {
            id = props.location.state.id;
            getVendorByIdAsync(id, setVendorData);
            getProductCategories(id);
            getInitialOrderForVendor(id);
            updateAllCarts()
            getVendorImage(props.location.state.id, setVendorImage);

            if (props.location.state.search) {
                setSearchedItem(props.location.state.search);
                getProducts(id, getVendorProductsResponseHandler, selectedCategoryId, props.location.state.search)
            } else if (props.location.state.categoryId) {
                handleCategorySelect(props.location.state.categoryId, props.location.state.categoryName, props.location.state.isDesktop, props.location.state.categories)
            } else {
                getProducts(id, getVendorProductsResponseHandler);
            }
        } else {
            props.history.push(ROUTES.HOME)
        }
    }, []);

    useEffect(() => {
        if (vendorData) {
            resetPageForShowMoreButton();
            if (searchedItem) {
                searchedItem.trim()
            }
            setNoProductsFoundInCategory(false);
            setNoProductsFound(false);
            debouncedGetProducts(vendorData.id, searchProductsResponseHandler, selectedCategoryId, searchedItem)
        }
    }, [searchedItem]);

    useEffect(() => {
        if (ordersToSend.length > 0 && sendNext) {
            let request = ordersToSend.shift();
            request();
            setOrdersToSend([...ordersToSend]);
            setSendNext(false);
        }
    }, [ordersToSend, sendNext]);

    const getProductCategories = async (vendorId) => {
        try {
            let response = await getVendorCategoriesById(vendorId);
            setCategories(response.data)
        } catch(error) {
            console.log("Error in getting categories");
        }
    };

    const getProducts = async (vendorId, onSuccess, categoryId, productName, page, size) => {
        try {
            let response = await getVendorProductsAsync(vendorId, categoryId, productName, page, size);
            attachActiveAttribute(response.data);
            onSuccess(response.data)
        } catch(error) {
            console.log("Error in getting products");
        }
    };

    const getInitialOrderForVendor = async (vendorId) => {
        try {
            let response = await getInitialOrderToVendor(vendorId);
            if (response.data[0]) {
                setCart(response.data[0].orderItems);
                setOrderId(response.data[0].id);
            } else {
                setCart([]);
            }
        } catch (error) {
            console.log(error);
            console.log("Error in getting initial order");
        }
    };

    const sendOrder = async (vendorId, order, onSuccess = makeOrderResponseHandler, onError = makeAndUpdateOrderErrorHandler) => {
          try {
              let res = await makeOrderAsync(vendorId, order);
              onSuccess(res, order.vendorProductId)
          } catch (error) {
              onError(error)
          }
        setSendNext(true);
    };

    const deleteProduct = async (orderId ,vendorProductId, orders) => {
        try {
            let response = await deleteProductFromOrder(orderId, vendorProductId);
            deleteItemFromOrderResponseHandler(response, orders)
        } catch (error) {
            console.log("Error with deleting the product fron the order")
        }
    };

    const resetPageForShowMoreButton = () => {
        setPage(2)
    };

    const handleClearClick = () => {
        searchRef.current.value = "";
        setSearchedItem();
        setSelectedCategoryId();
        setSelectedCategory("");
        setNoProductsFoundInCategory(false);
        setNoProductsFound(false);
        setIsLoading(true);

        removeLastBreadcrumb(breadcrumb);
        breadcrumb.map(bc => {
            removeClassFromElementById(bc.name.split(' ').join('_') + bc.id, "show");
            setAttrToElementById(bc.id + bc.name.split(' ').join('_'), "aria-expanded", "false");
        });
        setBreadcrumb([]);
        removeSelectedCategoriesStyle();
        getProducts(vendorData.id, searchProductsResponseHandler)
    };

    const handleCartUpdate = (event, vendorProductId) => {
        if (cart) {
            let orders = [...cart];
            let order = orders.find((el) => el.vendorProductId === vendorProductId);
            let index = orders.indexOf(order);
            handleOrderUpdateEvent(event, order);
            if (order.quantity.toString() === "0" && event.target.name !== "set-quantity") {
                orders.splice(index, 1);
                enqueueRequest( () => deleteProduct(orderId, order.vendorProductId, orders) );
            } else if (order.quantity > 0) {
                formatOrderDecimalQuantity(order);
                enqueueRequest( () => sendOrder(vendorData.id, order, updateOrderResponseHandler, () => setSendNext(true)) )  
            }
            setCart(orders);
        }
    };
    
    const enqueueRequest = (request) => {
        ordersToSend.push(request);
        setOrdersToSend([...ordersToSend]);
    };

    const updateOrderResponseHandler = (response) => {
        updateAllCarts()
        setSendNext(true)
    };

    const deleteItemFromOrderResponseHandler = async (res, orders) => {
        if (orders.length === 0) {
            try {
                await deleteOrder(orderId);
            } catch (e) {
                console.log(e);
            }
        }
        updateAllCarts()
        setSendNext(true)
    };

    const updateAllCarts =  () => {
        getInitialOrders(setAllCarts);
    };

    const makeOrderResponseHandler = (response, vendorProductId) => {
        imageFlyToCart('vp_' + vendorProductId);
        setOrderId(response.data);
        updateAllCarts();
        getInitialOrderForVendor(vendorData.id);
    };

    const makeOrderHandler = (event, vendorProductId, packagingId, quantity, comment, selectedPackage) => {
        let order = createOrderBody(comment, packagingId, quantity, vendorProductId, selectedPackage);
        enqueueRequest( () => sendOrder(vendorData.id, order) )
    };

    const categorySelectResponseHandler = (data) => {
        setVendorProducts(data);
        setIsLoading(false);
        setIsShowMoreButtonHidden(data.length < DEFAULT_FETCH_ITEMS_COUNT)
    };

    const handleCategorySelect = (categoryId, name, isDesktop, category) => {
        if (categoryId) {
            getProducts(props.location.state.id, categorySelectResponseHandler, categoryId);
            setSelectedCategoryId(categoryId);
            setSelectedCategory(name);
            setBreadcrumb(category);
            setSearchedItem();
            resetPageForShowMoreButton();
            if (searchRef.current) {
                searchRef.current.value = ""
            }
            let splitName = name.split(' ').join('_');
            removeSelectedCategoriesStyle();
            setTimeout(() => {
                if (document.getElementById(categoryId + splitName)) {
                   category.map((cat, index) => {
                      if (index < category.length -1) {
                          addClassToElementById(cat.name.split(' ').join('_') + cat.id, "show")
                      }
                   });
                    addClassToElementById(categoryId + splitName, "selected-category")
                }
                addClassToElementById(splitName, "selected-category")
            }, 200);

            if (isDesktop) {
                scrollToElement(breadcrumbRef)
            } else {
                closeAndCollapseCategories()
            }
        } else {
            getProducts(vendorData.id, setVendorProducts);
            removeSelectedCategoriesStyle();
            removeLastBreadcrumb(breadcrumb);
            breadcrumb.map(bc => removeClassFromElementById(bc.name + bc.id, "show"));
            setBreadcrumb([]);
            setSelectedCategory("")
        }
        setIsLoading(true);
        setNoProductsFoundInCategory(false);
    };

    const handlePackageSelect = (dataIndex, packageIndex) => {
        let data = [...vendorProducts];
        if (cart && cart.length > 0) {
            let orders = [...cart];
            let order = orders.find(el => el.vendorProductId === data[dataIndex].id);

            if (order) {
                let index = orders.indexOf(order);
                order.selectedPackage = data[dataIndex].packagingList[packageIndex];
                orders.splice(index, 1);
                orders.push(order);
                setCart(orders);
                sendOrder(vendorData.id, order, updateAllCarts)
            }
        }
        data[dataIndex].active = packageIndex;
        setVendorProducts(data);
    };

    const getVendorProductsResponseHandler = (data) => {
        let newProductsArray = vendorProducts.concat(data);
        setVendorProducts(newProductsArray);
        setIsShowMoreButtonHidden(data.length < DEFAULT_FETCH_ITEMS_COUNT);
        setIsLoading(false);
    };

    const closeAndCollapseCategories = () => {
        document.body.classList.remove("hide-scroll");
        collapseCategories();
        setDrawerVisible(false)
    };

    const removedCategorySearchResponseHandler = (responseData) => {
        if (responseData.length === 0) {
            setNoProductsFound(true);
            setNoProductsFoundInCategory(false);
        }
        setVendorProducts([...responseData]);
    };

    const searchProductsResponseHandler = (data) => {
        if (selectedCategoryId) {
            if (data.length === 0) {
                setBreadcrumb([]);
                setSelectedCategoryId(null);
                setNoProductsFound(false);
                setNoProductsFoundInCategory(true);
                getProducts(vendorData.id, removedCategorySearchResponseHandler, null, searchedItem)
            } else {
                setVendorProducts([...data]);
            }
            closeAndCollapseCategories();
            removeSelectedCategoriesStyle();
        } else {
            setVendorProducts([...data]);
        }
        setIsShowMoreButtonHidden(data.length < DEFAULT_FETCH_ITEMS_COUNT);
        setIsLoading(false)
    };
    
    const debouncedGetProducts = useCallback( _.debounce(getProducts, 1000), []);

    const handleAssortimentSearch = (event) => {
        setIsLoading(true);
        setSearchedItem(event.target.value);
        if (event.target.value.length > 0) {
            setIsShowMoreButtonHidden(true);
        } else {
            setIsShowMoreButtonHidden(false);
            setNoProductsFoundInCategory(false);
            setNoProductsFound(false);
        }
    };

    const handleShowMoreClick = () => {
        setPage(page + 1);
        getProducts(vendorData.id, getVendorProductsResponseHandler, selectedCategoryId, searchedItem, page)
    };

    return (
        <div>
            <div>
                <CustomerPageHeader ordersSum={allCarts && getItemsPrice(allCarts)} />

                <GoBackSection text={texts.SHOPPING_CART.GO_BACK} />
                {vendorData && cart &&
                <div className="vp-container">


                    <VendorPageIntro vendorLogo={vendorImage}
                                     vendorData={vendorData}
                    />

                    <div className="row d-lg-none">
                        <div className="col-12 p-0 accordion" id="vendorExpandableInfo">
                            <VendorInfoExpandable id="deliveryTerms"
                                                  parentId="vendorExpandableInfo"
                                                  mainText={texts.DELIVERY_CONDITIONS}
                                                  content={vendorData.deliveryRules}/>

                            <VendorInfoExpandable id="workingHours"
                                                  parentId="vendorExpandableInfo"
                                                  mainText={texts.WORKING_TIME}
                                                  content={vendorData.workingHours}/>

                            <VendorInfoExpandable id="contacts"
                                                  parentId="vendorExpandableInfo"
                                                  mainText={texts.CONTACTS}>
                                <div>
                                    <b>Email:</b> <i> {vendorData.email}</i>
                                </div>
                                <div>
                                    <b>{texts.CONTACTS_PHONE_LABEL}:</b> <i> {renderPhoneLinks(vendorData.phone)}</i>
                                </div>
                            </VendorInfoExpandable>

                            <VendorInfoExpandable id="moreInfo"
                                                  parentId="vendorExpandableInfo"
                                                  mainText={texts.MORE_INFO}
                                                  content={vendorData.description}/>

                        </div>
                    </div>

                    <VendorInfoDesktop texts={texts} vendorData={vendorData}/>

                    <div className="col-lg-12 mt-lg-4 p-0 d-lg-flex pb-lg-2 pr-lg-5">
                        <div className="search-input-container">
                            <VendorAssortimentSearch onChange={handleAssortimentSearch}
                                                     placeholder={selectedCategory.length > 0 ? `${texts.SEARCH_IN} ${selectedCategory }` : texts.SEARCH_PLACEHOLDER}
                                                     value={searchedItem}
                                                     reference={searchRef}
                                                     onClearClick={handleClearClick}
                            >
                                <div className="col-2 categories-toggler d-lg-none"
                                     onClick={() => {
                                         document.body.classList.add("hide-scroll");
                                         setDrawerVisible(!drawerVisible)
                                     }}>
                                    <img style={{width: "1.6em"}} src={CategoriesIcon} alt=""/>
                                </div>
                            </VendorAssortimentSearch>
                        </div>

                        <div ref={breadcrumbRef} id="bcd" className="vp-view-select-container pl-lg-5">
                            <VendorProductsViewTypeSelect
                                texts={texts}
                                searchedPhrase={searchedItem}
                                resultsCount={vendorProducts && vendorProducts.length}
                                selectedCategoryId={selectedCategoryId}
                                breadcrumb={breadcrumb}
                                listViewIcon={isListView ? ListIconBlue : ListIconGray}
                                gridViewIcon={isListView ? GridIconGray : GridIconBlue}
                                changeView={setListView}
                                isDesktop={isLargeScreen(window.innerWidth)}
                                onClick={handleCategorySelect}
                                clearSearchParams={handleClearClick}
                                vendorName={vendorData && vendorData.vendorName}
                            />
                        </div>
                    </div>

                    <div className="d-flex body-contain">
                        <div className="d-none d-lg-block categories-web bg-white">
                            <div id="catWeb" className="accordion  text-left">
                                {categories &&
                                <Categories onCategorySelect={handleCategorySelect}
                                            parentId="catWeb"
                                            categories={categories}
                                            isDesktop
                                />
                                }
                            </div>
                        </div>

                        <div className="products-section">
                            {(() => {
                                if (isLoading) {
                                    return  <Spinner animation={"border"} variant={"success"} />
                                } else {
                                    if (vendorProducts.length > 0) {
                                        return <>
                                   
                                            {noProductsFoundInCategory &&
                                            <div className="h5">
                                                <div>{texts.SHOWING_RESULTS_FROM} <b><i>{vendorData.vendorName}</i></b></div>
                                                <div>{texts.NO_RESULTS_FOUND_FOR} <b><i>{searchedItem}</i></b> {texts.IN} <b><i>{selectedCategory}</i></b></div>
                                            </div>
                                            }

                                            {
                                                !noProductsFound && !noProductsFoundInCategory && searchedItem &&
                                                <div className="h5">
                                                    <div> {texts.PRODUCTS_FOUND.toLowerCase()} <b><i>{searchedItem}</i></b></div>
                                                </div>
                                            }

                                            <ProductSection texts={texts}
                                                            isListView={isListView}
                                                            vendorProducts={vendorProducts}
                                                            selectPackageHandler={handlePackageSelect}
                                                            onAddButtonClick={makeOrderHandler}
                                                            cart={cart && cart}
                                                            updateOrder={handleCartUpdate}
                                            />
                                        </>
                                    } else if (vendorProducts.length === 0) {
                                        if (searchedItem) {
                                            return (
                                                <div className="h5">
                                                    {texts.NO_RESULTS_FOUND_FOR} <b><i>{searchedItem}</i></b> {texts.IN_ASSORTIMENT_OF} <b><i>{vendorData.vendorName}</i></b>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className="row bg-white">
                                                    <div className="col-12">
                                                        <h4>{texts.NO_PRODUCTS_FOUND}</h4>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                }
                            })()}


                            <p hidden={isShowMoreButtonHidden || isLoading}
                               className="row show-more-btn-wrapper p-2">

                                <ShowMoreButton text={texts.SHOW_MORE_PRODUCTS}
                                                onClick={handleShowMoreClick}/>
                            </p>
                        </div>
                    </div>

                    {allCarts && <ShoppingCartButtonMobile cartPrice={getItemsPrice(allCarts)} cartItems={getItemsCount(allCarts)} texts={texts}/>}
                </div>}
                <BackToTopButton texts={texts} />

                <Footer texts={texts.FOOTER}/>

            </div>

            <div className="d-lg-none">
                {!isLargeScreen(window.innerWidth) &&
                <Drawer isOpen={drawerVisible} onHide={closeAndCollapseCategories}>
                    <div className="row with-background">
                        <div className="col-12 p-0 d-flex">
                            <div className="col-2 p-0 close-categories float-right">
                                <img style={{width: "1.2em"}} src={CloseIcon} alt=""
                                     onClick={() => closeAndCollapseCategories()}/>
                            </div>
                            <div className="col-10 p-0 categories-header pr-5 text-center">
                                {texts.DRAWER_HEADING}
                            </div>
                        </div>
                        <div className="accordion mt-4 col-12 p-0" id="categories">
                            {categories &&
                            <Categories onCategorySelect={handleCategorySelect}
                                        parentId="categories"
                                        categories={categories}

                            />
                            }
                        </div>
                    </div>
                </Drawer>}
            </div>

        </div>
    );
};

export default VendorPage;