import React from 'react';
import PropTypes from 'prop-types';

const ThirdLevelCategories = ({thirdCategory, onCategorySelect, isDesktop, category, secondCategory}) => {
    return (
        <p id={thirdCategory.id + thirdCategory.name.replace(/\s+/gm, '_')}
             onClick={() => {
                 onCategorySelect(thirdCategory.id, thirdCategory.name, isDesktop,[ category, secondCategory, thirdCategory])
             }}
             className="category-name-colored  pl-5 pr-4">

            {isDesktop && "--"} <span className="focus-underline">{thirdCategory.name}</span> <span className="category-count">({thirdCategory.productsCount})</span>

        </p>
    );
};

ThirdLevelCategories.propTypes = {
    thirdCategory: PropTypes.object,
    onCategorySelect: PropTypes.func,
    isDesktop: PropTypes.bool,
    category: PropTypes.object,
    secondCategory: PropTypes.object
};

export default ThirdLevelCategories;