import {backendRequest} from "../utils/backend";
import TruckImage from '../assets/kamion.jpg';
import {DEFAULT_FETCH_ITEMS_COUNT} from "../config";
import { getAuthToken } from "./LocalStorageServices";

export const createGlobalProductRequest = (data) => {
    return backendRequest.post('/globalproduct', data)
};

export const getGlobalProductImage = async (globalProductId, imageNumber) => {

    let res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/globalproduct/${globalProductId}/image/${imageNumber}`, {
        method: "GET",
        headers: {
            Authorization: getAuthToken()
        }
    });

    if (res.status === 200) {
        let binaryResponse = await res.arrayBuffer()
        let binaryPhoto = `data:image/png;base64,${new Buffer(binaryResponse).toString("base64")}`
        return binaryPhoto
    } else {
        return TruckImage
    }
};

export const getGlobalProductById = (id) => {
    return backendRequest.get(`/globalproduct/${id}`)
};

export const getGlobalProductsAsync = (selectedCategory, searchedProduct, vendorId, page = 1, size = DEFAULT_FETCH_ITEMS_COUNT) => {
    let endpoint = `/globalproduct?page=${page}&size=${size}`;

    if (selectedCategory) {
        endpoint += `&categoryId=${selectedCategory}`
    }
    if (searchedProduct) {
        let searchQuery = encodeURIComponent(searchedProduct);
        endpoint += `&name=${searchQuery}`
    }
    if (vendorId) {
        endpoint += `&vendorId=${vendorId}`
    }

    return backendRequest.get(endpoint);
};

export const uploadGlobalProductImageAsync = (productId, imageNumber = 1, event) => {
    const formData = new FormData();

    if (event.files) {
        formData.append("uploadFile", event.files[0])
        formData.append("fileName", event.files[0].name)
    } else {
        formData.append("uploadFile", event)
        formData.append("fileName", "image1")
    }

    return backendRequest.put(`/globalproduct/${productId}/image/${imageNumber}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
};

export const editGlobalProductAsync = (body) => {
    return backendRequest.patch('/globalproduct', body, {
        headers: {
            "Content-Type": "application/json"
        }
    })
};

export const matchVendorProductToGlobalProductAsync = (data) => {
    let body = [];
    data.map(el => {
        body.push({
            globalProductId: el.globalProductDTO.id,
            vendorProductId: el.vendorProductDTO.id
        })
    });
    
    return backendRequest.put(`/globalproduct/match`, body);
};