import React from 'react';
import PropTypes from 'prop-types';
import './DeleteModalBody.css'

const DeleteModalBody = ({texts, heading, name, onConfirm, onCancel}) => {
    return (
        <div className="delete-modal-body">
            <div className="sov-delete-heading">{heading}:</div>
            <div className="sov-delete-vendor-name">{name}</div>

            <div className="sov-btns-wrapper">
                <button onClick={onCancel} className="btn btn-dark btns">{texts.DELETE_CANCEL}</button>
                <button onClick={onConfirm} className="btn btn-danger btns float-right">{texts.DELETE_CONFIRM}</button>
            </div>
        </div>
    );
};

DeleteModalBody.propTypes = {
    texts: PropTypes.object,
    heading: PropTypes.string,
    name: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func
};

export default DeleteModalBody;