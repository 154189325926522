import {isLargeScreen} from "./CheckWindowWidth";

export const goToPreviousPage = (props) => {
    setTimeout(() => props.history.goBack(), 1000)
};

export const sortData = (data, isAscending, key) => {
    data.sort((a, b) => {
        if (!a.hasOwnProperty(key)) {
            a[key] = ""
        }
        if (!b.hasOwnProperty(key)) {
            b[key] = ""
        }

        if (typeof a[key] === 'string') {
            if (isAscending) {
                return a[key].localeCompare(b[key])
            } else {
                return b[key].localeCompare(a[key])
            }
        } else {
            if (isAscending) {
                return a[key] - b[key]
            } else {
                return b[key] - a[key]
            }
        }
    });
};

export const checkAndAddRedBorder = (eventOrName, data, requiredFields) => {
    if (requiredFields) {
        if (requiredFields.includes(eventOrName)) {
            let element = document.getElementById(eventOrName);
            addOrRemoveRedBorderOnBlur(element, data[eventOrName] && data[eventOrName].length > 0)
        }
    } else {
        let element = document.getElementById(eventOrName.target.name);
        addOrRemoveRedBorderOnBlur(element,  element.value.length > 0)
    }
};

export const addOrRemoveRedBorderOnBlur = (element, condition) => {
    if (element) {
        if (condition) {
            element.classList.remove("border-red")
        } else {
            element.classList.add("border-red")
        }
    }
};


export const checkRequiredFields = (object, requiredFields) => {
    for (let field of requiredFields) {
        if (!object.hasOwnProperty(field)) {
            return false
        }
        if (object[field] === "" || object[field] === null || object[field] === undefined ) {
            return false
        }
    }
    return true
};

export const getCoordinates = (elem) => {
    let box = elem.getBoundingClientRect();

    let body = document.body;
    let docEl = document.documentElement;

    let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;

    let clientTop = docEl.clientTop || body.clientTop || 0;

    let top  = box.top +  scrollTop - clientTop;

    return {top: Math.round(top)};
};

export const roundTo = (input, digits) => {
    return Number.parseFloat(input).toFixed(digits)
};

export const imageFlyToCart = (elementId) => {
    let imgToDrag = document.getElementById(elementId);
    let cartView = isLargeScreen(window.innerWidth) ? document.getElementsByClassName('header-delivery')[0] : document.getElementsByClassName('cart-mobile-image')[0];
    let displayLeft= imgToDrag.getBoundingClientRect().left;
    let displayTop= imgToDrag.getBoundingClientRect().top;
    let cartLeft= cartView.getBoundingClientRect().left + (cartView.getBoundingClientRect().width / 2);
    let cartTop= cartView.getBoundingClientRect().top;
    let image = imgToDrag.cloneNode(true);

    image.style ='z-index: 1111; width: 100px;opacity:0.8; position:fixed; top:'+ displayTop+'px;left:'+ displayLeft+'px;transition: left 2s, top 2s, width 2s, opacity 2s cubic-bezier(1, 1, 1, 1)';
    let bodyWithAppendedImage=document.body.appendChild(image);
    setTimeout(function() {
        image.style.left=cartLeft+'px';
        image.style.top=cartTop+'px';
        image.style.width='40px';
        image.style.opacity='0';
    }, 200);
    setTimeout(function() {
        bodyWithAppendedImage.parentNode.removeChild(bodyWithAppendedImage);
    }, 2000);
};

export const scrollToElement = (ref) => {
    ref.current.scrollIntoView()
};

export const removeSelectedCategoriesStyle = () => {
   document.querySelectorAll(".selected-category").forEach(el => el.classList.remove("selected-category"))
};

export const collapseCategories = () => {
    document.querySelectorAll("p.category-level-one").forEach(el => {
        el.setAttribute("aria-expanded", "false")
    });
    document.querySelectorAll("div.cat-collapse").forEach(el => {
        el.setAttribute("aria-expanded", "false");
        el.classList.remove("show")
    });
};

export const removeLastBreadcrumb = (breadcrumb) => {
    if (breadcrumb.length === 3) {
        breadcrumb.pop();
    }
};

export const addClassToElementById = (elementId, className) => {
    document.getElementById(elementId).classList.add(className)
};

export const removeClassFromElementById = (elementId, className) => {
    document.getElementById(elementId).classList.remove(className)
};

export const setAttrToElementById = (elementId, attrName, attrValue) => {
    document.getElementById(elementId).setAttribute(attrName, attrValue)
};

export const redirectWithState = (route, data, props) => {
    props.history.push({
        pathname: route,
        state: {
            data: data
        }
    })
};