import React from 'react';
import PropTypes from 'prop-types';
import {texts} from '../../../../Languages/Language';
import {Link} from 'react-router-dom';
import { ROUTES } from '../../../../config';

const UserRow = ({ user, userTypeProps }) => (
    <tr>
        <td data-testid="ul-id">{user.id}</td>
        <td data-testid="ul-email">{user.email}</td>
        <td data-testid="ul-f-name">{user.firstName}</td>
        <td data-testid="ul-l-name">{user.lastName}</td>
        <td data-testid="ul-phone">{user.phone}</td>
        <td data-testid="ul-user-role" className={`${userTypeProps.bg}`}>{userTypeProps.role}</td>
        <td data-testid="ul-assigned-client">{user.assignedClient}</td>
        <td data-testid="ul-isActive">{user.isActive ? texts.ADMIN_PANEL.USERS.ACTIVE : texts.ADMIN_PANEL.USERS.INACTIVE}</td>
        <td><Link to={`${ROUTES.ADMIN.NEW_EDIT_USER_LINK}/edit?user=${user.id}`} className="text-primary c-pointer">{texts.ADMIN_PANEL.USERS.EDIT}</Link></td>
    </tr>
);

UserRow.propTypes = {
    user: PropTypes.object,
    userTypeProps: PropTypes.object
};

export default UserRow;