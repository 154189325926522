import React from 'react';
import PropTypes from 'prop-types';
import {createShortDayName} from "../../../../utils/VendorUtils";

const WorkingTimeField = ({day, onChange, defaultValues, isDisabled, onFieldBlur}) => {
    let df = defaultValues[createShortDayName(day)];

    return (
        <div className="d-flex align-items-center">
            <div className="p-2 w-30">
                {day}
            </div>
            <div className="p-2">
                <input data-testid="day-checkbox" checked={!isDisabled} onChange={(e) => onChange(e, true, day)} name={day} type="checkbox" className="match-checkbox mt-1"/>
            </div>
            <div className="p-2">
                <input data-testid="hours-from" onBlur={onFieldBlur} disabled={isDisabled} onChange={(e) =>onChange(e, false, day)} name={`${day}_from`} id={`${day}_from`} value={df && df.from  ? `${df.from}` : isDisabled ? "00:00" : ""}  type="text" className="form-control width-100px"/>
            </div>
            <div className="p-2">
                <input data-testid="hours-to" onBlur={onFieldBlur} disabled={isDisabled} onChange={(e) =>onChange(e, false, day)} name={`${day}_to`} id={`${day}_to`} value={df && df.to ? `${df.to}` : isDisabled ? "24:00" : ""} type="text" className="form-control width-100px"/>
            </div>
        </div>
    );
};

WorkingTimeField.propTypes = {
    day: PropTypes.string,
    onChange: PropTypes.func,
    defaultValues: PropTypes.object,
    isDisabled: PropTypes.bool,
    onFieldBlur: PropTypes.func
};

export default WorkingTimeField;