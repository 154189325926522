import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTable, useRowSelect} from "react-table";
import {createColumns} from "../../../utils/TableUtils";
import {texts} from "../../../Languages/Language";
import IndeterminateCheckbox from "../AdminOrders/IndeterminateCheckbox";
import {
    getMinAndMaxPrice,
    getProductOrderedCount
} from "../../../utils/VendorProductUtils";
import moment from "moment";
import Checkbox from "../Checkbox/Checkbox";
import {Link} from "react-router-dom";
import {ROUTES} from "../../../config";
import ProductImageTableCell from "../ProductImageTableCell/ProductImageTableCell";
import VendorProductTableRow from './VendorProductTableRow';
import { showSubrow } from '../../../utils/GlobalProductUtils';

const AdminProductsTable = ({products, onEditOrSaveClick, onVendorProductChange, onVendorOpsChange, setSelectedRows, uploadImageResHandler, vendorProductOps}) => {

    const createRows = (data) => {
        let arr = [];

        data.map((product) => {

            let priceData = getMinAndMaxPrice(product.vendorProducts);

            let obj = {
                product: product,
                selection: "",
                sosku: product.sosku,
                image: <ProductImageTableCell uploadImageResHandler={uploadImageResHandler} product={product} />,
                netWeight: <>
                    {((()=> {
                        if  (!product.hasOwnProperty("netWeight") && !product.hasOwnProperty("netWeightUnit")) {
                            return <span> {texts.VENDOR_PANEL.NONE} </span>
                        } else {
                           return <span>{product.netWeight && product.netWeight} {product.netWeightUnit && product.netWeightUnit} </span>
                        }
                    })())}
                </>,
                itemName: product.productName,
                brand: product.brand,
                measureUnit: product.measureUnit || texts.VENDOR_PANEL.NONE,
                vatPrice: product.vendorProducts.length > 0 ?`${priceData.min} ${priceData.currency} ${priceData.max && "/"} ${priceData.max} ${priceData.max && priceData.currency}` : texts.VENDOR_PANEL.NONE,
                ean: product.ean,
                offeredBy: <span>{product.vendorProducts.length}{texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.OFFERED_BY}</span>,
                promotion: "promo name",
                status: <>
                    <div>{moment(new Date(product.createdOn)).format("MM.DD.YYYY")}</div>
                    {product.vendorProducts.length > 0  ? texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.ACTIVE : texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.NOT_ACTIVE}
                    </>,
                ordered: getProductOrderedCount(product.vendorProducts),
                action: <Link  data-testid={"gp-edit"} to={ROUTES.ADMIN.NEW_GLOBAL_PRODUCT_LINK + `/edit?id=${product.id}`}>{texts.VENDOR_PANEL.EDIT_BTN_TEXT}</Link>,
                expander: <span className="d-inline btn btn-primary border-radius-5 c-pointer table-expander"
                             id={"table-expand" + product.sosku}
                             onClick={() => showSubrow(product.productName.split(' ')[0], product.sosku)}
                             role="button"
                             aria-expanded="false"
                             data-testid={`ap-expander${product.id}`}
                             >
                </span>
            };

            arr.push(obj)
        });
        return arr
    };

    const columns = React.useMemo(() => createColumns(texts.ADMIN_PANEL.ADMIN_PRODUCTS_TABLE_COLUMNS), []);
    const data = React.useMemo(() => createRows(products), [products]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
    } = useTable(
        {
            columns,
            data,
        },
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({getToggleAllRowsSelectedProps}) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({row}) => (
                        <div>
                            <IndeterminateCheckbox data-testid="agp-checkbox" {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        }
    );

    useEffect(() => {
        setSelectedRows(selectedFlatRows.map(row => row.original))
    }, [selectedFlatRows]);

    const hideAndShowColumn = (columnId) => {
        document.querySelectorAll(`td#${columnId}`).forEach(e => e.hidden = !e.hidden)
    };

    return (
        <>
            <div className="d-md-flex">
                {Object.values(texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE).map((el, i) => {
                    return <Checkbox key={el.ID + i}
                                     text={el.NAME}
                                     isHidden={!el.isHidden}
                                     onChange={() => hideAndShowColumn(el.ID)}/>
                })}

            </div>
            <table className="table table-bordered table-striped table-sm"  {...getTableProps()}>
                <thead className="bg-primary text-white">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                            return <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        })}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                    prepareRow(row);
                    return (<React.Fragment key={`rtb${i}`}>
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td className={`${cell.column.id === "itemName" && "max-width-500"} ${cell.column.id === "expander" && "bg-white"} 
                                              d-table-cell vertical-align-center`}
                                            {...cell.getCellProps()}>{cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                            <tr hidden={true} id={row.values.itemName.split(' ')[0] + row.values.sosku}>
                                <td colSpan={14}>
                                    <table id="table1" className="table table-bordered table-striped table-sm">
                                        <thead className="bg-secondary">
                                        <tr className="text-white row-centered">
                                            {Object.values(texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE).map(el => {
                                                return <td key={el.ID} id={el.ID} hidden={el.isHidden}> {el.NAME} </td>
                                            })}
                                        </tr>
                                        </thead>
                                        <tbody className="">
                                        {row.original.product.vendorProducts.map((vendorProduct, vendorProductIndex) => {
                                            return (
                                                <VendorProductTableRow key={vendorProduct.erpid + vendorProduct.productName + vendorProductIndex}
                                                    vendorProduct={vendorProduct}
                                                    vendorProductIndex={vendorProductIndex}
                                                    row={row}
                                                    onVendorProductChange={onVendorProductChange}
                                                    vendorProductOps={vendorProductOps}
                                                    onEditOrSaveClick={onEditOrSaveClick}
                                                    onVendorOpsChange={onVendorOpsChange}
                                                />
                                            )
                                        })}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </React.Fragment>
                    )
                })}
                </tbody>
            </table>
            <br/>
        </>
    );
};

AdminProductsTable.propTypes = {
    products: PropTypes.array,
    onEditOrSaveClick: PropTypes.func,
    onChange: PropTypes.func,
    onVendorOpsChange: PropTypes.func,
    setSelectedRows: PropTypes.func,
    uploadImageResHandler: PropTypes.func,
    vendorProductOps: PropTypes.array
};

export default AdminProductsTable;