import React from 'react';
import PropTypes from 'prop-types';

const ChangeTagsPopupBody = ({inputRef, onClear}) => {
    return (
        <div className="input-group mb-3">
            <input data-testid="ap-tags-cahnge" ref={inputRef} type="text" className="form-control"/>
                <div className="input-group-append">
                  <span onClick={onClear} className="input-group-text">  <i className="fas fa-times"></i> </span>
                </div>
        </div>
    );
};

ChangeTagsPopupBody.propTypes = {

};

export default ChangeTagsPopupBody;