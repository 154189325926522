import React, {useEffect, useState} from 'react';
import './AdminOrders.css';
import AdminAndVendorPageHeader from "../../common/AdminAndVendorPageHeader/AdminAndVendorPageHeader";
import {toggleSidebar} from "../../../utils/SidebarUtils";
import texts from "../../../Languages/bg";
import moment from "moment";
import PeriodSection from "../../common/PeriodSection/PeriodSection";
import InfoBox from "../../common/InfoBox/InfoBox";
import {ORDER_STATUSES} from "../../../config";
import {changeOrderStatus} from "../../../utils/OrderUtils";
import SelectSearch from "react-select";
import DatePicker from "react-date-picker";
import {handleDateChange} from "../../../utils/TimeUtils";
import ShowMoreButton from "../../customer/ShowMoreButton/ShowMoreButton";
import AdminAndVendorFooter from "../../common/AdmivAndVendorFooter/AdminAndVendorFooter";
import AdminOrdersTable from "./AdminOrdersTable";
import {adminAndVendorGetOrderResponseHandler, adminAndVendorShowMoreOrdersResponseHandler, getOrdersByRole, getPointsOfSaleForOrders} from "../../../utils/OrderUtils";
import {createSelectOptions} from "../../../utils/SelectUtils";
import {createPointOfSaleOptions, handlePointOfSaleChange, handleStatusChange} from "../../../utils/PointOfSaleUtils";
import {notify} from "../../../utils/Notifications";
import Spinner from "react-bootstrap/Spinner";

const AdminOrders = props => {
    const [startDate, setStartDate] = useState(moment().subtract(1, "M").format("x"));
    const [endDate, setEndDate] = useState(moment().format("x"));
    const [page, setPage] = useState(2);
    const [isShowMoreButtonHidden, setIsShowMoreButtonHidden] = useState(true);
    const [ordersData, setOrdersData] = useState({
        cancelledOrdersCount: 0,
        orders: [],
        pendingOrdersCount: 0,
        totalOrdersCount: 0
    });
    const [selectedRows, setSelectedRows] = useState([]);
    const [action, setAction] = useState();
    const [pointsOfSale, setPointsOfSale] = useState([]);
    const [selectedPointsOfSale, setSelectedPointsOfSale] = useState(null);
    const [status, setStatus] = useState(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let getOrdersInterval;
        if (startDate < endDate) {
            getOrdersByRole(getOrdersResponseHandler, startDate, endDate, status, selectedPointsOfSale);

            getOrdersInterval = setInterval(() => {
                getOrdersByRole(getOrdersResponseHandler, startDate, moment().format("x"), status, selectedPointsOfSale)
                setPage(2);
             
            }, 10000);

            getPointsOfSaleForOrders(startDate, endDate, setPointsOfSale)
        } else {
            notify('error', texts.VENDOR_PANEL.INVALID_DATE)
        }
        return () => clearInterval(getOrdersInterval)
    }, [startDate, endDate, selectedPointsOfSale, status]);

    const showMoreResponseHandler = (responseData) => {
        adminAndVendorShowMoreOrdersResponseHandler(responseData, ordersData, setOrdersData, setIsShowMoreButtonHidden);
    };

    const getOrdersResponseHandler = (responseData) => {
        adminAndVendorGetOrderResponseHandler(responseData, setOrdersData, setIsShowMoreButtonHidden);
        setIsEmpty(responseData.orders.length === 0);
        setIsLoading(false);
    };

    const showMoreHandler = () => {
        setPage(page + 1);
        getOrdersByRole(showMoreResponseHandler, startDate, endDate, status, selectedPointsOfSale, page )
    };

    const onRunClick = () => {
        if (selectedRows && action)  {
            selectedRows.map(row => {
                changeOrderStatus(row.original.id, action.value, changeOrderStatusResponseHandler)
            });
        }
    };

    const changeOrderStatusResponseHandler = (res) => {
        if (res.status === 200) {
            getOrdersByRole(getOrdersResponseHandler, startDate, endDate, status, selectedPointsOfSale);
        }
    };

    return (
        <div>
            <AdminAndVendorPageHeader onClick={toggleSidebar} text={texts.VENDOR_PANEL.PAGE_TITLE}/>

            <PeriodSection text={texts.VENDOR_PANEL.ORDERS_FOR_PERIOD} from={startDate} to={endDate}/>

            <div className="w-100 d-md-flex">
                <InfoBox className="ml-4 text-dark" count={ordersData.totalOrdersCount}
                         status="total" />
                <InfoBox className="m-3 mt-lg-0" count={ordersData.pendingOrdersCount}
                         status={ORDER_STATUSES.PENDING}/>
                <InfoBox className="m-3 mt-lg-0" count={ordersData.cancelledOrdersCount}
                         status={ORDER_STATUSES.REJECTED}/>
            </div>

            <div className="w-100 mt-5 pl-2 pr-r ">
                <div className="card shadow ">
                    <a href="#data-table" className="card-header text-left" data-toggle="collapse">
                        {texts.VENDOR_PANEL.PAGE_TITLE}
                    </a>
                    <div className="card-body p-2 collapse show" id="data-table">
                        <div className="d-block d-md-flex text-left mt-2 mb-2">
                            <div className="actions-wrapper pt-2 pb-2 text-left">
                                <SelectSearch onChange={(e) => setAction(e)} options={[...Object.values(texts.ADMIN_PANEL.SELECT_STATUSES)]}
                                              placeholder={texts.ADMIN_PANEL.ACTIONS_SELECT_PLACEHOLDER} className="w-75"/>
                                <button onClick={onRunClick} className="btn btn-outline-dark ml-2">
                                    {texts.ADMIN_PANEL.RUN}
                                </button>
                            </div>
                            <div className="pos-search-wrapper pt-2 pb-2 text-left">
                                <div className="pt-md-2 ">{texts.VENDOR_PANEL.FOR_POINTOFSALE}: </div>
                                <SelectSearch onChange={(e) => handlePointOfSaleChange(e, setSelectedPointsOfSale, setPage)} options={createPointOfSaleOptions(pointsOfSale, "name", "id")} className="w-75"/>
                            </div>
                            <div className="date-picker-wrapper  pt-2 pb-2">
                                <div className="pl-2 pr-2 pt-md-2"> {texts.VENDOR_PANEL.DATE_FROM}: </div>
                                <DatePicker clearIcon={null}
                                            value={new Date(moment.unix(startDate / 1000))}
                                            className="date-picker"
                                            onChange={(e) => handleDateChange(e, "start", setStartDate, setEndDate)}
                                            calendarIcon={<i className="far fa-calendar-alt"></i>}
                                            format="dd-MM-yyyy" />

                            </div>
                            <div className="date-picker-wrapper pt-2 pb-2">
                                <div className="pl-2 pr-2 pt-md-2"> {texts.VENDOR_PANEL.DATE_TO}: </div>
                                <DatePicker clearIcon={null}
                                            value={new Date(moment.unix(endDate / 1000))}
                                            className="date-picker"
                                            onChange={(e) => handleDateChange(e, "end", setStartDate, setEndDate)}
                                            calendarIcon={<i className="far fa-calendar-alt"></i>}
                                            format="dd-MM-yyyy"/>

                            </div>
                            <div className=" pt-2 pb-2  status-select-wrapper">
                                <div className="pl-2 pt-md-2">{texts.VENDOR_PANEL.STATUS}:</div>
                                <SelectSearch onChange={(e) => handleStatusChange(e, setStatus, setPage)} options={createSelectOptions([...Object.values(texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES_PLURAL)], "name", "value")} className="status-select"/>
                            </div>
                        </div>
                        {((()=> {
                            if (isLoading) {
                                return (
                                    <Spinner animation={"border"} variant={"primary"} />
                                )
                            } else {
                                return (
                                    <AdminOrdersTable setSelectedRows={setSelectedRows} texts={texts.VENDOR_PANEL} orders={ordersData.orders}/>
                                )
                            }
                        })())}


                        {isEmpty && <div className="text-center"> {texts.ADMIN_PANEL.NO_ORDERS_FOUND} </div>}
                        <div className="m-4" hidden={isShowMoreButtonHidden}>
                            <ShowMoreButton className="border m-0" text={texts.VENDOR_PAGE.SHOW_MORE_TEXT} onClick={showMoreHandler}/>
                        </div>
                    </div>
                </div>
            </div>

            <AdminAndVendorFooter texts={texts.VENDOR_PAGE.FOOTER}/>

        </div>
    );
};

export default AdminOrders;