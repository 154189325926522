import React from 'react';
import './VendorAssortimentSearch.css'
import PropTypes from 'prop-types';
import SearchIcon from "../../../assets/search_icon.svg";
import {hideMobileKeyboardHandler} from "../../../utils/SearchUtils";
import {getCoordinates} from "../../../utils/Utils";

const VendorAssortimentSearch = ({onChange, placeholder, value, children, reference, onClearClick}) => {
    return (
        <div className="row">
            <div className="col-12 p-0 pl-2 d-flex">
                <div className="col-10 col-lg-12 p-0 vp-search-wrapper mt-1  mb-1">
                    <label htmlFor="vp-search-input" className="vp-search float-left pl-1 pt-2"><img src={SearchIcon} alt=""/>
                        <input data-testid="vp-search" onFocus={e => {
                            let coordinates = getCoordinates(e.target);
                            let headerHeight = document.getElementById("customer-header").clientHeight;
                            window.scrollTo(0, coordinates.top - headerHeight - 20)
                        }} ref={reference} id="vp-search-input" type="text" onChange={onChange} value={value} onKeyPress={hideMobileKeyboardHandler} placeholder={placeholder} name="vp-search-input" className="vp-search-input"/>
                        <i onClick={onClearClick} hidden={reference.current && reference.current.value === ""} className="fas fa-times c-pointer m-auto pr-2"></i>
                 </label>
                </div>
                {children}
            </div>
        </div>
    );
};

VendorAssortimentSearch.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    children: PropTypes.object,
    reference: PropTypes.object,
    onClearClick: PropTypes.func
};

export default VendorAssortimentSearch;