import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select'
import {createNestedOptions} from "../../../../utils/SelectUtils";
import GlobalProductContext from "../GlobalProductContext";

const ProductCategoriesSection = ({texts, categories, onAddClick, onCategoryChange, onRemoveCategoryClick, addButtonDisabled}) => {
    
    const product = useContext(GlobalProductContext).product;
    let categoriesArray = createNestedOptions(categories);
    categoriesArray.shift();
    const filterCategories = (categoriesArr) => {
       if (product.categories) {
           return categoriesArr.filter(cat => !product.categories.some(({ id }) => cat.value === id))
       }  else {
           return categoriesArr
       }
    };

    return (
        <div>
            <div className="h4 font-weight-bold text-left">
                {texts.HEADING}:
            </div>
            <div className="">
                <div className="col-12 d-flex mt-2">
                    <div className="label pt-1">
                        {texts.CATEGORIES}:
                    </div>
                    <div className="select-wrapper">
                        <Select onChange={onCategoryChange} className="input-fld category-select" options={filterCategories(categoriesArray)} placeholder={texts.CATEGORIES_PLACEHOLDER} classNamePrefix={"select_search"} />
                    </div>
                    <div className="ml-3">
                        <button disabled={addButtonDisabled} onClick={onAddClick} className="btn btn-primary">{texts.ADD_TO_BUTTON}:</button>
                    </div>
                    <div className="ml-5 pl-4 pr-4">
                        <div className=" pl-2 pr-2 ">
                            {texts.LIST_OF_ASSIGNED}:
                        </div>
                        <div className="text-left border border-dark h5 p-2">
                            <ol>
                                {product.categories && product.categories.map((c, i) => {
                                    return (
                                        <li key={c.name + i}>
                                            {c.name} <i data-testid={c.name} onClick={() => onRemoveCategoryClick(c, i)} className="far fa-times-circle c-pointer float-right pl-2"></i>
                                        </li>
                                    )
                                })}
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ProductCategoriesSection.propTypes = {
    texts: PropTypes.object,
    categories: PropTypes.array,
    onAddClick: PropTypes.func,
    onCategoryChange: PropTypes.func,
    onRemoveCategoryClick: PropTypes.func,
    addButtonDisabled: PropTypes.bool
};

export default ProductCategoriesSection;