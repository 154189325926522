import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import VendorsModal from "./VendorsModal/VendorsModal";
import {notify} from "../../../utils/Notifications";
import {assignVendorErrorHandler, assignVendorToCustomer, unassignVendorFromCustomer} from "../../../utils/CustomerUtils";
import {FETCH_ALL_ITEMS} from "../../../config";
import {texts} from '../../../Languages/Language'; 
import {getVendors} from '../../../utils/VendorUtils';

const VendorsAssignedCard = ({data, customerId, setVendorsToAssign}) => {
    const assignTexts = texts.ADMIN_PANEL.ASSIGN_VENDORS;
    const [assignedVendors, setAssignedVendors] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [assignModalVisible, setAssignModalVisible] = useState(false);
    const [selectedVendors, setSelectedVendors] = useState({});

    useEffect(() => {
        if (data.assignedVendors) {
            setAssignedVendors(data.assignedVendors)
        }
    }, [data]);

    const isEdit = () => {
        return customerId > 0;
    };

    const handleAddVendorClick = () => {
        getVendors(getVendorsResponseHandler, undefined, 1, FETCH_ALL_ITEMS);
    };

    const getVendorsResponseHandler = (res) => {
        let data = res.data.filter(v => !assignedVendors.find(f => v.id === f.id));
        setVendors(data);
        setAssignModalVisible(true);
    };

    const handleVendorSelect = (vendor) => {
        if (!selectedVendors.hasOwnProperty(vendor.id)) {
            selectedVendors[vendor.id] = vendor
        } else {
            delete selectedVendors[vendor.id]
        }
        setSelectedVendors({...selectedVendors})
    };

    const handleAddClick = async () => {
        if (isEdit()) {
         for (let v of Object.values(selectedVendors)) {
            await assignVendorToCustomer(customerId, v.id, (response) => assignVendorResponseHandler(response, v), (error) => assignVendorErrorHandler(error, v, texts))
         }
            setAssignModalVisible(false);
        } else {
            setVendorsToAssign(selectedVendors);
            setAssignModalVisible(false);
        }
    };

    const assignVendorResponseHandler = (response, vendor) => {
        assignedVendors.push(vendor);
        delete selectedVendors[vendor.id];
        setAssignedVendors([...assignedVendors]);
        notify("success", `${vendor.vendorName} ${texts.ADMIN_PANEL.ASSIGN_USERS.USER_ASSIGNED}`);
    };

    const handleRemoveClick = (vendor) => {
        if (isEdit()) {
            unassignVendorFromCustomer(customerId, vendor.id, (response) => unassignVendorResponseHandler(response, vendor))
        } else {
            delete selectedVendors[vendor.id];
            setVendorsToAssign(selectedVendors);
            setSelectedVendors({...selectedVendors})
        }
    };

    const unassignVendorResponseHandler = (response, vendor) => {
        assignedVendors.splice(assignedVendors.indexOf(vendor), 1);
        setAssignedVendors([...assignedVendors]);
        notify("success", `${vendor.vendorName} ${texts.ADMIN_PANEL.ASSIGN_USERS.USER_UNASSIGNED}`);
    };

    return (
        <div className="card shadow">
            <div className="card-header text-primary h5 text-left">
                {assignTexts.VENDORS_ASSIGNED}
            </div>
            <div className="card-body">
                <div className="text-right">
                    <button onClick={handleAddVendorClick} className="btn btn-primary">
                        {assignTexts.ASSIGN_NEW_VENDOR}
                    </button>
                </div>
                <div className="pt-3">
                    <table className="table table-sm table-bordered table-striped">
                        <thead className="bg-primary text-white">
                        <tr>
                            {assignTexts.TABLE_HEADERS.map(th => <th key={th}>{th}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {assignedVendors.map(vendor => {
                            return (
                                <tr key={vendor.vendorName}>
                                    <td className="vertical-align-center">{vendor.id}</td>
                                    <td className="vertical-align-center">{vendor.vendorName}</td>
                                    <td className="vertical-align-center">{vendor.isEnabled ? texts.ADMIN_PANEL.USERS.ACTIVE : texts.ADMIN_PANEL.USERS.INACTIVE}</td>
                                    <td className="vertical-align-center">
                                        <span onClick={() => handleRemoveClick(vendor)} className="btn btn-link c-pointer">
                                            {assignTexts.REMOVE}
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>

            <VendorsModal onAddClick={handleAddClick} selectedVendors={selectedVendors} show={assignModalVisible}
                          onHide={() => setAssignModalVisible(false)} data={vendors}
                          onVendorSelect={handleVendorSelect}/>
        </div>
    );
};

VendorsAssignedCard.propTypes = {
    data: PropTypes.object,
    customerId: PropTypes.number,
    setVendorsToAssign: PropTypes.func
};

export default VendorsAssignedCard;