import React from 'react';
import PropTypes from 'prop-types';
import './Categories.css'
import SecondLevelCategories from "./SecondLevelCategories";

const Categories = ({parentId, onCategorySelect, categories, isDesktop}) => {
    return (
        <div className={`col-12 category-container p-0 ${isDesktop && "mt-4"}`}>
            {categories.map((category, index) => (
                <div key={category.name + index} className={`${isDesktop && "pl-4"}`}>
                    <div className={`col-12 p-0 text-left  d-flex category-level-one-wrapper`}>
                        <p id={category.id + category.name.replace(/\s+/gm, '_')}
                             className={`col-12 p-0 ${!isDesktop && category.children.length > 0 && "category-level-one"} ${isDesktop && "web-category-name"} category-name`}
                             data-toggle="collapse"
                             data-target={`#${category.name.replace(/\s+/gm, '_') + category.id}`} aria-expanded="false">

                                <span onClick={() => {
                                    onCategorySelect(category.id, category.name, isDesktop, [category])
                                }} className={`spanSelect`}>
                                   <span className="focus-underline pl-2">{category.name}</span> <span className="category-count">({category.productsCount})</span>
                                </span>
                        </p>
                    </div>

                    <div id={category.name.replace(/\s+/gm, '_') + category.id} className="collapse cat-collapse category-level-one-content"
                         data-parent={`#${parentId}`} aria-expanded="false">
                        {category.children.map((sc, index) => {
                            return (
                              <SecondLevelCategories key={sc.name + index} secondCategory={sc} isDesktop={isDesktop} onCategorySelect={onCategorySelect} category={category}/>
                            )
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};

Categories.propTypes = {
    parentId: PropTypes.string,
    categories: PropTypes.array,
    onCategorySelect: PropTypes.func,
    isDesktop: PropTypes.bool
};

export default Categories;