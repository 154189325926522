import React, {useEffect, useState} from 'react';
import ListCustomersAndVendors from "../ListCustomersAndVendors/ListCustomersAndVendors";
import {getVendors} from '../../../utils/VendorUtils';
import { FETCH_ALL_ITEMS } from '../../../config';

const ListVendors = props => {
    const [vendors, setVendors] = useState([]);

    useEffect(() => {
        getVendors((response) => setVendors(response.data), null, 1, FETCH_ALL_ITEMS);
    }, []);

    return (
        <ListCustomersAndVendors partners={vendors} isVendors={true}/>
    );
};

export default ListVendors;