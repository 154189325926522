import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

const SideNavigationLink = ({text, path = "", icon, className, linkClasses = "", isCollapsible}) => {
    if (isCollapsible) {
        return (
            <Link to={path} className={`${className}`} >
                {icon} <span>{text.toUpperCase()}</span>
            </Link>
        )
    } else {
        return (
            <li className={`${className}`}>
                <Link to={path} className={`${linkClasses}`} >
                    {icon} <span>{text.toUpperCase()}</span>
                </Link>
            </li>
        );
    }

};

SideNavigationLink.propTypes = {
    text: PropTypes.string,
    path: PropTypes.string,
    icon: PropTypes.object,
    className: PropTypes.string,
    linkClasses: PropTypes.string,
    isCollapsible: PropTypes.bool
};

export default SideNavigationLink;