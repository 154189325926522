import React from 'react';
import PropTypes from 'prop-types';
import './StatusDropdown.css';
import CheckIcon from "../../../../assets/check.svg";
import {getStatusInfo} from "../../../../utils/OrderUtils";

const StatusDropdown = ({texts, onStatusSelect, selectedStatus}) => {
    return (
        <div className="op-status-wrapper">
            <div className="op-status-dropdown dropdown">
                <div className="orders-dd" id="order-status" data-toggle="dropdown" aria-expanded="true" data-testid="ao_status_dd">
                    {(() => {
                        switch (selectedStatus) {
                            case "ALL" :
                               return texts.ORDERS_PAGE.DROPDOWN_TEXT;
                            case undefined:
                                return  texts.ORDERS_PAGE.DROPDOWN_TEXT;
                            default:
                              return  getStatusInfo(selectedStatus, true).name
                        }
                    }) ()}

                </div>
                <ul className="dropdown-menu order-status-menu" aria-labelledby="order-status">
                    {Object.values(texts.ORDERS_PAGE.STATUSES_PLURAL).map(status => (
                        <div key={status.value} className="dropdown-item op-orders-statuses" onClick={onStatusSelect}
                             id={status.value}>
                            <div className="op-orders-statuses-icon">
                                {selectedStatus === status.value &&
                                <img className="" src={CheckIcon} alt=""/>}
                            </div>
                            <div className="op-orders-statuses-name">
                                {status.name.toLowerCase()}
                            </div>

                        </div>
                    ))}

                </ul>
            </div>
        </div>
    );
};

StatusDropdown.propTypes = {
    texts: PropTypes.object,
    onStatusSelect: PropTypes.func,
    selectedStatus: PropTypes.func
};

export default StatusDropdown;