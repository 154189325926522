import React from 'react';
import PropTypes from 'prop-types';
import './OrderProductsInfo.css'

const OrderProductsInfo = ({texts, countOfItems, totalPriceVAT, className}) => {
    return (
        <div className={`order-card-products-info ${className}`}>
            <div className="order-card-products-count"><span>{countOfItems}</span> {texts.SHOPPING_CART.CART.ITEMS}</div>
            <div className="order-card-products-price"><span>{totalPriceVAT} {texts.SHOPPING_CART.CART.CURRENCY}</span> {texts.PRODUCT_CARD.VAT_INCLUDED}</div>
        </div>
    );
};

OrderProductsInfo.propTypes = {
    texts: PropTypes.object,
    countOfItems: PropTypes.number,
    totalPriceVAT: PropTypes.string,
    className: PropTypes.string
};

export default OrderProductsInfo;