import React from 'react';
import PropTypes from 'prop-types';
import './OrderStatus.css'

const OrderStatus = ({status, statusColor}) => {
    return (
        <div className={`order-card-status `}>
            <span className={`${statusColor}`}>  {status}</span>
        </div>
    );
};

OrderStatus.propTypes = {
    status: PropTypes.string,
    statusColor: PropTypes.string
};

export default OrderStatus;