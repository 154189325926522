import React from 'react';
import PropTypes from 'prop-types';

const SectionTextarea = ({label, rows, cols, onChange, name, defaultValue, testId}) => {
    return (
        <div className="mt-5 d-flex">
            <label className="label">{label}:</label>
            <textarea data-testid={testId} defaultValue={defaultValue} onChange={onChange} name={name} id="ngp-description" cols={cols}
                      rows={rows}>
            </textarea>
        </div>
    );
};

SectionTextarea.propTypes = {
    label: PropTypes.string,
    rows: PropTypes.number,
    cols: PropTypes.number,
    onChange: PropTypes.func,
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    testId: PropTypes.string
};

export default SectionTextarea;