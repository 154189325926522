import React from 'react';
import PropTypes from 'prop-types';
import './Footer.css'

const Footer = ({texts}) => {
    return (
        <div className="vp-footer-wrapper">
            <div className="d-flex justify-content-center">
                <div className="w-50 text-right pr-3">
                    <div>
                        <a className="text-white" href="https://shiftorders.com/terms">
                            {texts.TERMS}
                        </a></div>
                    <div>
                        <a className="text-white" href="https://shiftorders.com/privacy-policy">
                            {texts.PRIVACY}
                        </a>
                    </div>
                </div>
                <div className="border"></div>
                <div className="w-50 text-left pl-3">
                    <div className="text-white">
                        {texts.CONTACTS}:
                    </div>
                    <div>{texts.PHONE}</div>
                    <div> {texts.EMAIL}</div>
                </div>

            </div>
            <div className="vp-footer-copyright mt-2">
                <p>{texts.COPYRIGHT}</p>
            </div>
            <div className="cleardiv"></div>
        </div>
    );
};

Footer.propTypes = {
    texts: PropTypes.object
};

export default Footer;