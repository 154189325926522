import React from 'react';
import PropTypes from 'prop-types';
import './OpenCartButton.css';
import ChevRight from '../../../../assets/chevron-right-green.svg'

const OpenCartButton = ({texts}) => {

    return (
        <div className="sc-show-items-button-caret">
            <span> {texts.VIEW_CART}</span>
            <img src={ChevRight} alt=""/>
        </div>
    )
};

OpenCartButton.propTypes = {
    texts: PropTypes.object
};

export default OpenCartButton;