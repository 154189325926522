import React from 'react';
import PropTypes from 'prop-types';
import './OrderCard.css';
import OrderNumberAndDate from "../OrderNumberAndDate/OrderNumberAndDate";
import {Link} from "react-router-dom";
import OrderProductsInfo from "../OrderProductsInfo/OrderProductsInfo";
import OrderStatus from "../OrderStatus/OrderStatus";
import AddButton from "../../OrderView/AddButton/AddButton";
import {ROUTES} from "../../../../config";
import {showOrderTotalPrice} from '../../../../utils/OrderUtils';

const OrderCard = ({texts, order, status, statusColor, onClick}) => {

    return (
        <div>
            <div className="order-card-wrapper">
                <OrderNumberAndDate texts={texts} order={order} />

                <div className="order-card-row-2">
                    <div className="order-card-name">
                        <Link className="text-grey" to={{pathname: ROUTES.CUSTOMER.VENDOR_PAGE, state: {id: order.vendorId}}} > {order.vendorName} </Link>
                    </div>
                    <OrderProductsInfo texts={texts} countOfItems={order.countOfItems} totalPriceVAT={showOrderTotalPrice(order)} className="d-lg-none" />
                    <div className="order-card-status-wrapper d-none d-lg-block">
                        <OrderStatus statusColor={statusColor} status={status} />
                    </div>
                </div>

                <div className="order-card-row-3">
                    <div className="order-card-add-btn-wrapper">
                        <AddButton text={texts.ORDERS_PAGE.ADD_TO_CART} onClick={() => onClick(order)} isLoading={order.isLoading} />
                    </div>
                    <div className="order-card-status-wrapper d-lg-none">
                        <OrderStatus statusColor={statusColor} status={status} />
                    </div>
                </div>

                <div className={`order-card-view-order-btn ${statusColor}`}>
                    <Link to={{
                        pathname: `${ROUTES.CUSTOMER.SENT_ORDER_VIEW.LINK}/${order.id}`,
                    }}> {texts.ORDERS_PAGE.VIEW_ORDER} &gt;</Link>
                </div>
                <OrderProductsInfo className="d-none d-lg-block" texts={texts} countOfItems={order.countOfItems} totalPriceVAT={showOrderTotalPrice(order)} />
               
            </div>
            <div className={`order-card-view-order-btn ${statusColor} d-none d-lg-block`}>
                <Link to={{
                    pathname: `${ROUTES.CUSTOMER.SENT_ORDER_VIEW.LINK}/${order.id}`,
                }}> {texts.ORDERS_PAGE.VIEW_ORDER} &gt;</Link>
            </div>
        </div>

    );
};

OrderCard.propTypes = {
    texts: PropTypes.object,
    order: PropTypes.object,
    status: PropTypes.string,
    statusColor: PropTypes.string,
    onClick: PropTypes.func
};

export default OrderCard;