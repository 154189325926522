import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ text, isHidden, onChange }) => (
    <div className="pl-3 pr-3">
        <input type="checkbox"
            id={text + "cb"}
            defaultChecked={isHidden}
            onChange={onChange}
        />

        <label className="pl-1" htmlFor={text + "cb"}>{text}</label>
    </div>
);

Checkbox.propTypes = {
    text: PropTypes.string,
    isHidden: PropTypes.bool,
    onChange: PropTypes.func
};

export default Checkbox;