import React from 'react';
import './GoBackSection.css';
import PropTypes from 'prop-types'
import {Link, withRouter} from "react-router-dom";

const GoBackSection = props => {
    return (
        <div className="sc-go-back">
            {props.linkTo ?
            <Link to={props.linkTo}>  &lt; {props.text} </Link>
            :
            <div className="c-pointer" onClick={() => props.history.goBack()}>  &lt; {props.text} </div>
            }
        </div>
    );
};
GoBackSection.propTypes = {
    text: PropTypes.string,
    linkTo: PropTypes.string
};

export default withRouter(GoBackSection);