import React from 'react';
import PropTypes from 'prop-types';
import ThirdLevelCategories from "./ThirdLevelCategories";

const SecondLevelCategories = ({secondCategory, onCategorySelect, isDesktop, category}) => {
    return (
        <div>
            <p id={secondCategory.id + secondCategory.name.replace(/\s+/gm, '_')}
                 className={`text-left pl-3 ${!isDesktop && secondCategory.children.length > 0 && "category-level-one"} w-100 category-name-colored`}
                 data-toggle="collapse"
                 data-target={`#${secondCategory.name.replace(/\s+/gm, '_') + secondCategory.id}`}
                 aria-expanded="false">

                <span onClick={() => onCategorySelect(secondCategory.id, secondCategory.name, isDesktop, [category, secondCategory])}
                      className=" pl-3">

                    {isDesktop && "-"} <span className="focus-underline">{secondCategory.name}</span> <span className="category-count">({secondCategory.productsCount})</span>

                </span>
            </p>

            <div id={secondCategory.name.replace(/\s+/gm, '_') + secondCategory.id} className="collapse  text-left"
               >
                {secondCategory.children.map((tc, index) => {
                    return (
                        <ThirdLevelCategories key={tc.name + index}
                                              thirdCategory={tc}
                                              isDesktop={isDesktop}
                                              onCategorySelect={onCategorySelect}
                                              category={category}
                                              secondCategory={secondCategory}
                        />
                    )
                })}
            </div>
        </div>
    );
};

SecondLevelCategories.propTypes = {
    secondCategory: PropTypes.object,
    onCategorySelect: PropTypes.func,
    isDesktop: PropTypes.bool,
    category: PropTypes.object
};

export default SecondLevelCategories;