import React from 'react';
import PropTypes from 'prop-types';
import './ShowMoreButton.css';

const ShowMoreButton = ({text, onClick, className}) => {
    return (
        <button onClick={(e) => {
            let btn = e.target;
            btn.blur();
            btn.disabled = true;
            setTimeout(() => btn.disabled = false, 500);
            onClick()

        }} className={`show-more-btn ${className}`}>
            {text}
        </button>
    );
};

ShowMoreButton.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string
};

export default ShowMoreButton;