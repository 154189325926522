import React from 'react';
import PropTypes from 'prop-types';
import AddItemField from "../AddItemField/AddItemField";
import SelectSearch from "react-select"

const SectionField = ({label, options, onSelectChange, onAddButtonClick, name, buttonText, placeholder, onAddFieldInputChange, value, defaultValue, testId}) => {

    const handleChange = (event) => {
        onSelectChange(event, name)
    };

    return (
        <div className="d-flex p-1">
            <div className="d-flex">
                <div className="label">{label}: </div>
                <SelectSearch  value={defaultValue}  className="searchable-select" classNamePrefix={"select_search"} onChange={handleChange} name={name} placeholder={placeholder} options={options}/>
            </div>
            <AddItemField testId={testId} isDisabled={!value || value.trim().length ===0}  name={name} onChange={onAddFieldInputChange} onClick={onAddButtonClick} buttonText={buttonText}/>
        </div>
    );
};

SectionField.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    onSelectChange: PropTypes.func,
    onAddButtonClick: PropTypes.func,
    buttonText: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    onAddFieldInputChange: PropTypes.func,
    value: PropTypes.string,
    defaultValue: PropTypes.object,
    testId: PropTypes.string
};

export default SectionField;