import React from 'react';
import PropTypes from 'prop-types';
import {isLargeScreen} from "../../../utils/CheckWindowWidth";

const InfoCard = ({id, title, labels, customBody, children}) => {
    const isMobile = !isLargeScreen(window.innerWidth);
    return (
        <div className="card shadow flex-grow-1 vov-card-container">
            <a href={isMobile ? "#" + id : undefined} data-toggle={isMobile && "collapse"}  className="card-header text-left h5 icon-colored-text">
                {title.toUpperCase()}
            </a>
            <div id={id} className={`card-body collapse show d-lg-flex`}>
                {(() => {
                    if (customBody) {
                        return (
                            <>
                                {children}
                            </>
                        )
                    } else {
                        return (
                            <>
                                <div className="text-left vov-labels ">
                                    {labels.map((l, i) => <div key={l}><span>{l}</span><span className="d-lg-none"> {children && children[i]} </span> </div>)}
                                </div>
                                <div className="d-none d-lg-block text-left vov-values">
                                    {children}
                                </div>
                            </>
                        )
                    }
                })()}
            </div>
        </div>
    );
};

InfoCard.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    labels: PropTypes.array,
    customBody: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array
    ])
};

export default InfoCard;