import React, {useEffect, useState} from 'react';
import texts from "../../../Languages/bg";
import {getCategories} from "../../../utils/CategoryUtils";
import {createNestedOptions} from "../../../utils/SelectUtils";
import SelectSearch from "react-select";
import {getGlobalProducts, matchProducts} from "../../../utils/GlobalProductUtils";
import {ROUTES} from "../../../config";
import TableRow from "./TableRow/TableRow";
import MergeIcon from '../../../assets/icons8-merge-26.png';
import {Link} from "react-router-dom";
import {notify} from "../../../utils/Notifications";
import { redirectWithState } from '../../../utils/Utils';
import { matchProductErrorHandler } from '../../../utils/MatchUtils';

const MatchByName = props => {
    const [data, setData] = useState(props.location.state.data);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [currentProduct, setCurrentProduct] = useState(data.noMatches[0]);
    const [globalProducts, setGlobalProducts] = useState([]);
    const [searchedProduct, setSearchedProduct] = useState(currentProduct.productName);
    const customMatch = props.location.state.data.customMatch;

    const searchRef = React.useRef();
    const radioRef = React.useRef();

    useEffect(() => {
        searchRef.current.value = searchedProduct;
        getCategories(setCategories)
    }, []);

    useEffect(() => {
        if (data.noMatches.length > 0) {
            setCurrentProduct(data.noMatches[0]);
            setSearchedProduct(data.noMatches[0].productName)
        }
    }, [data]);

    useEffect(() => {
        if (searchedProduct.length > 0) {
            searchedProduct.trim();
        }
        getGlobalProducts(getGlobalProductsResponseHandler, selectedCategory.value, searchedProduct)
    }, [searchedProduct, selectedCategory]);

    useEffect(() => {
        if (data.noMatches.length > 0 ) {
            window.onbeforeunload = (e) => {
                e.returnValue = ""
            }
        } else {
            window.onbeforeunload = null
        }

        return () => window.onbeforeunload = null
    }, [data]);

    useEffect(() => {
        setTimeout(() => {
            document.getElementsByName("match").forEach(e => e.checked = false)
        }, 200);
    }, [currentProduct, globalProducts]);

    const getGlobalProductsResponseHandler = (res) => {
        setGlobalProducts(res.data)
    };

    const handleConfirm = () => {

        if (currentProduct.vendorProductId) {
            currentProduct.id = currentProduct.vendorProductId
        }
        let data = [{
            globalProductDTO: selectedProduct,
            vendorProductDTO: currentProduct
        }];
       
        matchProducts(data, matchProductResponseHandler, () => matchProductErrorHandler(texts.ADMIN_PANEL.MATCH_STEP_2.PRODUCT_NOT_MATCHED));
    };

    const matchProductResponseHandler = (res) => {
          if (res.status === 200) {
              if (res.data.vendorProductIds.length > 0) {
                  let text = texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT;
                  notify("info", 
                    `${text.PRODUCT} ${currentProduct.productName} ${text.WAS_N0T_MATCHED_TO} ${selectedProduct.productName}.`,
                     text.VENDOR_HAS_PRODUCT,
                     6000);
              } else {
                  notify("success", null, texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.SUCCESSFUL_MATCH);
                  let arr = [...data.noMatches];
                  arr.shift();
                  data.noMatches = arr;

                  setSelectedProduct({});
                  data.totalMatched += 1;
                  setData({...data});
                  if (data.noMatches.length === 0) {
                      redirectWithState(ROUTES.ADMIN.FINISH_IMPORT, data, props);
                  } else {
                      searchRef.current.value = arr[0].productName;
                  }
              }
          }
    };

 
    const handleFilter = (event, filterType) => {
        switch (filterType) {
            case "category":
                setSelectedCategory(event);
                break;
            case "search":
                setSearchedProduct(event.target.value);
                break;
            case "clear":
                setSearchedProduct("");
                searchRef.current.value = "";
                break;
            default:
                break;
        }
        setSelectedProduct({});
    };

    return (
        <div>
            <div>
                <h1 className="p-4 border-bottom-secondary"> {customMatch ? texts.ADMIN_PANEL.MATCH_BY_NAME.MATCH_BY_NAME : `${texts.ADMIN_PANEL.MATCH_BY_NAME.HEADER} - ${texts.ADMIN_PANEL.MATCH_BY_NAME.MATCH_BY_NAME}`} </h1>
            </div>

            <div>
                <h4 className="font-weight-bold">{data.noMatches.length} {texts.ADMIN_PANEL.MATCH_BY_NAME.LEFT_TO_MATCH}</h4>
            </div>

            <div className="pt-5 pl-5 pr-5">
                <table className="table table-sm table-bordered table-striped">
                    <thead className="bg-primary text-white">
                    <tr>
                        {Object.values(texts.ADMIN_PANEL.MATCH_STEP_2.TABLE_HEADERS).slice(1)
                            .map(th => (
                                <th className="">{th}</th>
                            ))}
                    </tr>
                    </thead>
                    <tbody className="font-weight-bold">
                        <TableRow product={currentProduct} showImage={false}/>
                    </tbody>
                </table>
            </div>
            <div className="pt-5 pl-5 pr-5 d-flex">
                <SelectSearch className="w-25"
                              defaultValue={createNestedOptions(categories)[0]}
                              onChange={(e) => handleFilter(e, "category")}
                              options={createNestedOptions(categories)}/>
                <div className="input-group pl-5  w-lg-75 mb-3 pr-md-5">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-search"></i></span>
                    </div>
                    <input type="text" ref={searchRef} data-testid="mbn-search" className="form-control no-box-shadow"
                           onChange={(e) => handleFilter(e, "search")}
                           aria-describedby="basic-addon1"/>
                    <div className="input-group-append" onClick={() => handleFilter(null, "clear")}>
                        <span className="input-group-text"><i className="fas fa-times-circle"></i> </span>
                    </div>
                </div>
            </div>
            <div className="pl-5 pr-5">
                <div className="text-left">
                    <div style={{width:"30%"}} className="mb-0 p-2 text-white h5 bg-primary">
                        <img style={{width:"20px"}} src={MergeIcon} alt=""/>   {texts.ADMIN_PANEL.MATCH_BY_NAME.MATCH_TO_GLOBAL_PRODUCT}
                    </div>
                </div>
                {globalProducts.map(gp => {
                    return (
                        <div className="d-flex">
                            <div className="w-100">
                                <table className="table mb-0 table-striped table-sm table-bordered">
                                    <tbody className="font-weight-bold">
                                     <TableRow product={gp} showImage={true}/>
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex flex-column pt-1 pl-2">
                                <div className="form-check pt-1">
                                    <input data-testid="mbn-radio" ref={radioRef} onChange={() => setSelectedProduct(gp)}
                                           className="form-check-input match-checkbox" name={"match"} type="radio"/>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className="d-flex justify-content-between p-5">
                <Link to={{
                    pathname: `${ROUTES.ADMIN.NEW_GLOBAL_PRODUCT_LINK}/match`,
                    state: {
                        data: data
                    }
                }}
                      className="btn btn-primary"> {texts.ADMIN_PANEL.MATCH_BY_NAME.ADD_NEW_GLOBAL_PRODUCT}
                </Link>
                <button disabled={!selectedProduct.id} onClick={handleConfirm} className="btn btn-primary">
                    {texts.ADMIN_PANEL.MATCH_BY_NAME.CONFIRM_AND_GO}
                </button>
            </div>
        </div>
    );
};

export default MatchByName;