import React from 'react';
import PropTypes from 'prop-types';

const ChangeCategoryPopupBody = ({categories, categorySelectHandler}) => {
    return (
        <div className="change-category-list">
            {categories.map((c, i )=> {
                return (
                    <div className="h5 text-primary ">
                        <span aria-selected={"false"} id={`cat${c.id}`} onClick={() => categorySelectHandler(c.id)} className="change-category-text"> {i+1}.  {c.name}</span>
                        {c.children.map((sc, si) => {
                            return (
                                <div className="pl-4">
                                    <span aria-selected={"false"} id={`cat${sc.id}`} onClick={() => categorySelectHandler(sc.id)} className="change-category-text"> {i+1}.{si+1}. {sc.name}</span>
                                    {sc.children.map((tc, ti) => {
                                        return (
                                            <div aria-selected={"false"} id={`cat${tc.id}`} onClick={() => categorySelectHandler(tc.id)} className="pl-4 change-category-text">
                                                {i+1}.{si+1}.{ti + 1}. {tc.name}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
        </div>
    );
};

ChangeCategoryPopupBody.propTypes = {
    categories: PropTypes.array,
    categorySelectHandler: PropTypes.func
};

export default ChangeCategoryPopupBody;