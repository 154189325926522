import React from 'react';
import PropTypes from 'prop-types';
import './TitleSection.css';

const TitleSection = ({leftText, rightText, currency, icon}) => {
    return (
        <div className="sc-all-carts-wrapper">
            <div className="sc-cart-all-carts">
                <img className="sc-delivery-icon" src={icon} alt=""/>
                <span className="sc-all-carts-text">{leftText}</span>
            </div>
            <div className="sc-all-carts-price">
            {rightText} {currency}
            </div>
        </div>
    );
};

TitleSection.propTypes = {
    text: PropTypes.string,
    totalSum: PropTypes.number,
    currency: PropTypes.string,
    icon: PropTypes.string
};

export default TitleSection;