import {backendRequest, userAuthRequests} from "../utils/backend";

export const loginUserRequest = (user) => {
    return userAuthRequests.post(`/user/login`, user)
};

export const getAllUsers = () => {
    return backendRequest.get(`/user`);
};

export const getUserById = (id) => {
    return backendRequest.get(`/user/${id}`);
};

export const editUser = (userData) => {
    return backendRequest.put(`/user`, userData)
};

export const registerUserAsync = (userData) => {
    return  backendRequest.post(`/user`, userData)
}

export const updateUserPassword = (password, id) => {
    let body = {
        newPassword: password,
        userId: id
    };

    return backendRequest.put(`/user/password`, body);
};

export const getUsersByAssignmentStatus = (clientId, role, unassigned) => {
    let endpoint = '/user?';
    if (clientId) {
        endpoint += `clientId=${clientId}&`
    }
    if (role) {
        endpoint += `role=${role}&`
    }
    if (unassigned) {
        endpoint += "unassigned"
    }

    return backendRequest.get(endpoint)
};

export const unassignUserAsync = (userId, clientId, type) => {
    let endpoint = `/user/${userId}`;
    if (type === "vendor") {
        endpoint += `/vendor/${clientId}`
    } else {
        endpoint += `/pointofsale/${clientId}`
    }

    return backendRequest.delete(endpoint);
};

export const getAssignedUsersToPOSAsync = (id) => {
    return backendRequest.get(`/user/pointofsale/${id}`);
};

export const assignUserToPOSAsync =  (userId, posId) => {
    return backendRequest.put(`/user/pointofsale`, {
        posId: posId,
        userId: userId
    });
};

export const assignUserToVendorAsync = (userId, vendorId) => {
    return backendRequest.put(`/user/${userId}/vendor/${vendorId}`)
};

export const getPointsOfSaleForUserAsync = () => {
    return backendRequest.get(`/user/pointofsale`);
};