import React, {useEffect, useState} from 'react';
import texts from '../../../Languages/bg';
import {matchProducts} from "../../../utils/GlobalProductUtils";
import {notify} from "../../../utils/Notifications";
import {ROUTES} from "../../../config";
import MatchProductTable from './MatchProductTable/MatchProductTable';
import { matchProductErrorHandler, removeFromArray } from '../../../utils/MatchUtils';
import { redirectWithState } from '../../../utils/Utils';

const MatchByErpid = props => {

    let matchingByErpId = [...props.location.state.data.matchingErpId];

    const [data, setData] = useState(props.location.state.data);
    const [matched, setMatched] = useState([]);
    const [notMatched, setNotMatched] = useState([]);


    useEffect(() => {
        if (data.matchingErpId.length > 0 ) {
            document.onbeforeunload = (e) => {
                e.returnValue = ""
            }
        } else {
            document.onbeforeunload = null
        }
    }, [data]);

    const handleRadioSelect = (eventType, tuple, index) => {
        let vp = tuple.vendorProductDTO;
        let rawData = tuple.csvData;

        matchingByErpId.splice(index, 1);
        if (eventType === "matching") {
            matched.push(tuple);
            removeFromArray(notMatched, rawData);
        } else {
            notMatched.push(rawData);
            removeFromArray(matched, vp, true);
        }
        setMatched([...matched]);
        setNotMatched([...notMatched])
    };

    const handleSaveClick = (matched, notMatched) => {

        notMatched.map(el => {
            data.noMatches.push(el);
            setData({...data})
        });
        if (matched.length > 0) {
            matchProducts(matched, matchProductsResponseHandler, () => matchProductErrorHandler(texts.ADMIN_PANEL.MATCH_STEP_2.PRODUCT_NOT_MATCHED))
        } else {
            goToNextPage()
        }
    };

    const goToNextPage = () => {
        if (data.noMatches.length > 0) {
            redirectWithState(ROUTES.ADMIN.MATCH_BY_NAME, data, props)
        } else {
            redirectWithState(ROUTES.ADMIN.FINISH_IMPORT, data, props)
        }
    };

    const matchProductsResponseHandler = (res) => {
        
        if (res.status === 200) {
            notify("success", texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.SUCCESSFUL_MATCH);
            data.totalMatched += matched.length;
            if (res.data.vendorProductIds.length > 0) {
                res.data.vendorProductIds.map(id => {
                    let productDTOs = data.matchingErpId.find(el => el.vendorProductDTO.id === id);
                    notify("info", "",`${texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.PRODUCT} ${productDTOs.vendorProductDTO.productName} ${texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.WAS_N0T_MATCHED}.`, 0)
                });
                data.totalMatched -= res.data.vendorProductIds.length
            }
           goToNextPage()
        }
    };

    return (
        <div>
            <h1 className="p-4 border-bottom-secondary"> {texts.ADMIN_PANEL.MATCH_STEP_2.HEADER} </h1>
            <div>
                <h4 className="font-weight-bold">{data.matchingErpId.length} {texts.ADMIN_PANEL.MATCH_STEP_2.OF} {data.total} {texts.ADMIN_PANEL.MATCH_STEP_2.MATCHED_BY}</h4>
            </div>
            <div>
                {data.matchingErpId.map((row, index) => <MatchProductTable csvRow={row} rowIndex={index} onRadioSelect={handleRadioSelect} /> )}
            </div>
            <div className="w-75 m-auto pt-5">
                <button onClick={() => handleSaveClick(matched, notMatched)} disabled={matched.length + notMatched.length !== data.matchingErpId.length}
                        className="btn btn-primary float-right btn-lg">
                    {texts.ADMIN_PANEL.MATCH_STEP_2.NEXT}
                </button>
            </div>
        </div>
    );
};

export default MatchByErpid;