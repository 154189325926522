export const setUserTokens = (authorization, refresh) =>  {
    localStorage.setItem('authtoken', authorization);
    localStorage.setItem('refresh', refresh);
};

export const setUserData = (data) => {
  localStorage.setItem('user', JSON.stringify(data))
};

export const getAuthToken = () => {
    return localStorage.getItem('authtoken')
};

export const getUserData = () => {
  return JSON.parse(localStorage.getItem("user"))
};


