import React from 'react';
import PropTypes from 'prop-types';
import Rating from "react-rating";
import EmptyStar from "../../../assets/star-empty-grey.svg";
import YellowStar from "../../../assets/star-full.svg";

const VendorRating = ({rating}) => {
    return (
        <Rating  emptySymbol={<img style={{"width": "1.2em", "marginRight": "5px"}} src={EmptyStar} alt=""/>}
                 fullSymbol={<img style={{"width": "1.2em","marginRight": "5px"}} src={YellowStar} alt=""/>}
                 readonly
                 initialRating={rating} />
    );
};

VendorRating.propTypes = {
    rating: PropTypes.number
};

export default VendorRating;