import React from "react";

export const checkIsSorted = (column) => {
    if (column.isSorted) {
        if (column.isSortedDesc) {
            return <span className="float-right"> <i className="fas fa-sort-down"></i></span>
        } else {
            return <span className="float-right"><i className="fas fa-sort-up"></i></span>
        }
    } else {
        return <span className="float-right"><i className="fas fa-sort"></i></span>
    }
};


export const createColumns = (data) => {
    let arr = [];

    Object.values(data).map(e => {
        arr.push({
            Header: e.HEADER,
            accessor: e.ACCESSOR
        })
    });
    return arr
};

