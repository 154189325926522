import {backendRequest, pdfRequest} from "../utils/backend";
import {DEFAULT_FETCH_ITEMS_COUNT, ORDER_STATUSES,} from "../config";

export const getInitialOrderToVendor = (vendorId) => {
    return backendRequest.get(`/order/${vendorId}/vendor?status=${ORDER_STATUSES.INITIAL}`)
};

export const getOrderByIdAndRoleAsync = (id) => {
    return backendRequest.get(`/order/${id}`);
}

export const deleteOrder = (id) => {
    return backendRequest.delete(`/order/${id}`)
};

export const placeOrder = (id, pointOfSale, comment) => {
    return backendRequest.post(`/order/${id}`, {
        pointOfSaleId: pointOfSale,
        comment: comment
    })
};

export const deleteProductFromOrder = (orderId, productId) => {
    return backendRequest.delete(`/order/${orderId}/${productId}`)
};

export const addCustomProduct = (vendorId, customOrder) => {
    return backendRequest.patch(`/order/${vendorId}/custom`, customOrder)

};

export const deleteCustomProduct = (orderId, itemId) => {
    return backendRequest.delete(`/order/${orderId}/${itemId}/custom`)
};

export const changeOrderStatusAsync = (orderId, status) => {
    return  backendRequest.put(`/order/${orderId}/status`, status, {
        headers: {
            "Content-Type": "text/plain"
        }
    })
};

export const rateOrder = (orderId, data) => {
    return backendRequest.post(`order/${orderId}/rate`, data)
};

export const makeOrderAsync = (vendorId,  order) => {
    return backendRequest.patch(`/order/${vendorId}/item`, {
        "comment": order.comment,
        "packagingId": order.packagingId || order.selectedPackage.id,
        "quantity": order.quantity,
        "vendorProductId": order.vendorProductId
    })
};

export const  getOrdersByUserRoleAsync = (startDate, endDate, orderStatus, pointOfSaleId, page = 1, size = DEFAULT_FETCH_ITEMS_COUNT) => {
    let endpoint = `/order?page=${page}&size=${size}`;

    if (startDate) {
        endpoint += `&fromDate=${startDate}`
    }
    if (endDate) {
        endpoint += `&toDate=${endDate}`
    }
    if (orderStatus) {
        endpoint += `&status=${orderStatus}`
    }
    if (pointOfSaleId) {
        endpoint += `&pointOfSaleId=${pointOfSaleId}`
    }
    return backendRequest.get(endpoint)
};

export const getPointsOfSaleByUserRoleAsync = (startDate, endDate) => {
    return backendRequest.get(`/order/pointOfSale?fromDate=${startDate}&toDate=${endDate}`);
};

export const downloadOrderPDF = (order) => {
    return pdfRequest.get(`/order/${order.id}/pdf`)
}

export const printOrderPDF = (order) => {
    return pdfRequest.get(`/order/${order.id}/pdf`)
};

export const getOrderStatusInfo = (orderId) => {
    return backendRequest.get(`/order/${orderId}/status`);
};

export const changePointOfSaleByAdmin = (orderId, pointOfSale) => {
    return backendRequest.put(`/order/${orderId}/pointOfSale`, pointOfSale, {
        headers: {
            "Content-Type": "text/plain"
        }
    });
};

export const changeOrder = (id, data) => {
   return backendRequest.patch(`/order/${id}`, data);
}