import React, {useCallback, useEffect, useState} from 'react';
import './HomePage.css';
import CustomerPageHeader from "../CustomerPageHeader/CustomerPageHeader";
import Banner from "../../../assets/banner-wide.png";
import {texts} from '../../../Languages/Language';
import Footer from "../Footer/Footer";
import VendorCard from "./VendorCard/VendorCard";
import {Link} from "react-router-dom";
import {getVendorImage} from "../../../services/VendorServices";
import {getVendors} from "../../../utils/VendorUtils";
import ShowMoreButton from "../ShowMoreButton/ShowMoreButton";
import {DEFAULT_FETCH_ITEMS_COUNT, ROUTES} from "../../../config";
import {getInitialOrders, getItemsCount, getItemsPrice} from "../../../utils/OrderUtils";
import ShoppingCartButtonMobile from "../ShoppingCartButtonMobile/ShoppingCartButtonMobile";
import {hideMobileKeyboardHandler} from "../../../utils/SearchUtils";
import Spinner from "react-bootstrap/Spinner";
import _ from 'lodash';
import BackToTopButton from "../BackToTopButton/BackToTopButton";

const HomePage = props => {
    const searchInputRef = React.useRef(null);
    const [vendors, setVendors] = useState([]);
    const [initialOrders, setInitialOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [isShowMoreButtonHidden, setShowMoreButtonHidden] = useState(true);
    const [searchedText, setSearchedText] = useState("");
    const [vendorImages, setVendorImages] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getVendors(getVendorsResponseHandler);
        getInitialOrders(setInitialOrders);
    }, []);

    useEffect(() => {
        vendors.map(vendor => getVendorImage(vendor.id, setImage))
    }, [vendors]);

    const debouncedGetVendors = useCallback(_.debounce(getVendors, 300), []);

    const setImage = (image, id) => {
        vendorImages[id] = image;
        setVendorImages({...vendorImages})
    };

    const handleSearch = (event) => {
        setSearchedText(event.target.value);
        debouncedGetVendors(searchVendorsResponseHandler, event.target.value.trim());
        setIsLoading(true)
    };

    const handleClearClick = () => {
        getVendors(searchVendorsResponseHandler);
        setIsLoading(true);
        searchInputRef.current.value = "";
        setSearchedText("");
    };

    const getVendorsResponseHandler = (response) => {
        let vendorsArray = vendors.concat(response.data);
        setVendors(vendorsArray);
        setShowMoreButtonHidden(response.data.length < DEFAULT_FETCH_ITEMS_COUNT);
        setIsLoading(false)
    };

    const searchVendorsResponseHandler = (response) => {
        setVendors(response.data);
        setIsLoading(false)
    };

    const showMoreVendorsHandler = () => {
        let nextPage = page + 1;
        setPage(nextPage);
        getVendors(getVendorsResponseHandler, searchedText, nextPage);
    };

    return (
        <div className="hp-wrapper">
            <CustomerPageHeader showSearch onSearch={handleSearch} searchRef={searchInputRef} onClearClick={handleClearClick}
                                ordersSum={initialOrders && getItemsPrice(initialOrders)} handleKeyPress={hideMobileKeyboardHandler} clearIconHidden={searchedText === ""}
            />

            <div className="hp-banner-wrapper">
                <img src={Banner} alt=""/>
            </div>

            <div className="hp-all-vendors-label">
                {(() => {
                    if (searchedText.length > 0) {
                        return (
                            <>
                                <span onClick={handleClearClick} className="c-pointer">
                                    {texts.VENDOR_PAGE.ALL_VENDORS}
                                </span>
                                <span> &gt; {searchInputRef.current.value} ( {vendors.length} )</span>
                                <div className="text-center">
                                    {searchedText.length > 0 && vendors.length === 0 && texts.VENDOR_PAGE.NO_RESULTS_FOUND}
                                </div>
                            </>
                        )
                    } else {
                        return <>{texts.VENDOR_PAGE.ALL_VENDORS}</>
                    }
                })()}
            </div>

            <div className="hp-vendor-card-wrapper">
                {((() => {
                    if (isLoading) {
                        return (
                            <div className="">
                                <Spinner variant={"success"} animation={"border"} />
                            </div>
                        )
                    } else {
                        return vendors.map(vendor => {
                            return (
                                <Link className="link-to-vendorpage" to={{
                                    pathname: ROUTES.CUSTOMER.VENDOR_PAGE,
                                    state: {
                                        id: vendor.id
                                    }
                                }} key={vendor.id}>
                                    <VendorCard
                                        vendorData={vendor}
                                        vendorLogo={vendorImages[vendor.id]}/>
                                </Link>
                            )
                        })
                    }
                })())}
            </div>

            <div hidden={isShowMoreButtonHidden} className="w-100 pt-4 pb-4">
                <ShowMoreButton className="m-0" onClick={showMoreVendorsHandler} text={texts.VENDOR_PAGE.SHOW_MORE_TEXT}/>
            </div>

            <BackToTopButton texts={texts.VENDOR_PAGE}/>
            <ShoppingCartButtonMobile cartPrice={getItemsPrice(initialOrders)} cartItems={getItemsCount(initialOrders)} texts={texts.VENDOR_PAGE}/>
            <Footer texts={texts.VENDOR_PAGE.FOOTER}/>
        </div>
    );
};

export default HomePage;