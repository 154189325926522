import React from 'react';
import PropTypes from 'prop-types';
import './DeleteButton.css'
import TrashIcon from "../../../../assets/trash-alt-solid.svg";

const DeleteButton = ({texts, onClick}) => {
    return (
        <div className="sc-delete-wrapper">
            <div onClick={onClick} className="sc-delete-btn">
                <img className="trash-icon" src={TrashIcon} alt=""/>
                <div className="sc-delete-text">
                    {texts.DELETE_CART}
                </div>
            </div>
        </div>
    );
};

DeleteButton.propTypes = {
    texts: PropTypes.object,
    onClick: PropTypes.func
};

export default DeleteButton;