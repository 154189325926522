import React from 'react';
import PropTypes from 'prop-types';

const AddItemField = ({onChange, onClick, buttonText, name, isDisabled, testId}) => {

    return (
        <div className="d-flex">
            <div>
                <input data-testid={testId} onChange={onChange} name={name} className="form-control input-fld ml-2" type="text"/>
            </div>
            <div>
                <button disabled={isDisabled} onClick={onClick} className="btn btn-primary ngp-add-btn ml-3">{buttonText}</button>
            </div>
        </div>
    );
};

AddItemField.propTypes = {
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
    name: PropTypes.string,
    isDisabled: PropTypes.bool,
    testId: PropTypes.string
};

export default AddItemField;