import React from 'react';
import PropTypes from 'prop-types';
import texts from "../../../Languages/bg";

import {getStatusInfo, showDataAccordingToStatus} from "../../../utils/OrderUtils";
import InfoCard from "../InfoCard/InfoCard";

const InfoCardStatus = ({customerInfo, order, onStatusChange}) => {
    return (
        <InfoCard customBody id="status" title={texts.VENDOR_PANEL.INFO_STATUS}>
            {customerInfo.id && order.status &&
            <div className="w-100">
                <div className="d-flex border-bottom text-left">
                    <span className="">{texts.VENDOR_PANEL.STATUS_LABELS.STATUS}: </span>
                    <div className="vov-custom-padding">
                            <select data-testid="vov-status-select" className="font-weight-bold text-gray-700 bg-none c-pointer"
                                      onChange={(e) => onStatusChange(e.target.value)}
                                      value={order.status}>
                                <option value={order.status} hidden>{getStatusInfo(order.status).name}</option>
                                {order.status && showDataAccordingToStatus(order.status, true).options}
                            </select>

                    </div>
                </div>
                <div className="text-left">
                    <span>{texts.VENDOR_PANEL.STATUS_LABELS.COMMENT}:  </span>
                    <span className="pl-1 vov-comment">
                        {order.comment && order.comment !== "" ? order.comment : texts.VENDOR_PANEL.STATUS_LABELS.NO_COMMENT}
                    </span>
                </div>
            </div>
            }
        </InfoCard>
    );
};

InfoCardStatus.propTypes = {
    customerInfo: PropTypes.object,
    order: PropTypes.object,
    onStatusChange: PropTypes.func
};

export default InfoCardStatus;