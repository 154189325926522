import React from 'react';
import PropTypes from 'prop-types';
import './VendorCard.css';
import VendorRating from "../../VendorRating/VendorRating";

const VendorCard = ({vendorLogo, vendorData}) => {
    return (
        <div className="hp-vendor-card">
            <div className="v-card-image">
                <img src={vendorLogo} alt=""/>
            </div>

            <div className="hp-vendor-card-body">
                <div className="v-card-info-wrapper">
                    <div className="v-card-name">
                        {vendorData.vendorName}
                    </div>
                    <div className="v-card-rate">
                        <span className="v-card-rate-stars">
                         <VendorRating rating={vendorData.rating} />

                        </span>
                    </div>
                </div>
                <div className="v-card-description">
                    <div dangerouslySetInnerHTML={{__html: vendorData.description}} />
                </div>
            </div>
        </div>
    );
};

VendorCard.propTypes = {
    vendorLogo: PropTypes.string,
    vendorData: PropTypes.object,
};

export default VendorCard;