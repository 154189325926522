import React from 'react';
import PropTypes from 'prop-types';
import './NotificationSection.css';
import {getStatusInfo} from "../../../../utils/OrderUtils";
import {Link} from "react-router-dom";
import NotificationLink from "../NotificationLink/NotificationLink";
import {ROUTES} from "../../../../config";

const NotificationSection = ({texts, notification}) => {
    if (notification.newStatus) {
        return (
            <Link to={`${ROUTES.CUSTOMER.SENT_ORDER_VIEW.LINK}/${notification.orderId}`} className="np-link-order">
                <div className="np-status-notification-wrapper">
                    {texts.ORDER} #<span className="np-bolded-text">{notification.orderCode} </span>
                    {texts.FOR} <span className="np-bolded-text"> {notification.vendorName} </span>
                    {texts.IS} <span className="np-bolded-text"> {getStatusInfo(notification.newStatus).name}</span>
                </div>
            </Link>
        )
    } else {
        return (
            <div className="np-ad-image-text">
                <div className="np-notification-body">
                    <div className="np-message-wrapper">
                        {notification.image &&
                        <div className={`np-image ${notification.message ? "float-left w-25 mr-2" : "text-center"}`}>
                            <img src={notification.image} alt=""/>
                        </div>}
                        {notification.message && <div dangerouslySetInnerHTML={{__html: notification.message}}/>}
                    </div>
                </div>
                {notification.url && <NotificationLink text={texts.LINK_TEXT} url={notification.url}/>}
            </div>
        )
    }
};

NotificationSection.propTypes = {
    texts: PropTypes.object,
    notification: PropTypes.object
};

export default NotificationSection;