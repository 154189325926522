import {notify} from "./Notifications";
import {logout} from "./Logout";
import {setUserData, setUserTokens} from "../services/LocalStorageServices";
import {ROUTES, USER_ROLES} from "../config";
import texts from "../Languages/bg";

export const shouldResetPassword = (props) => {
    return props.location.state && props.location.state.resetPassword
};

export const showNotificationFrorPasswordReset = (texts) => {
    notify("info", texts.GENERAL.PASSWORD_CHANGE, texts.GENERAL.RESET_AT_LOGIN, 0)
};

export const logoutAfterDelay = (delay) => {
    setTimeout(() => {
        logout();
    }, delay)
};

export const redirect = (role, props) => {
    switch (role) {
        case USER_ROLES.ADMIN:
            props.history.push(ROUTES.ADMIN.ADMIN_HOME);
            break;
        case USER_ROLES.CUSTOMER:
            props.history.push(ROUTES.CUSTOMER.CUSTOMER_HOME);
            break;
        case USER_ROLES.VENDOR:
            props.history.push(ROUTES.VENDOR.VENDOR_HOME);
            break;
        default:
            break;
    }
};

export const redirectToProfilePage = (type, props) => {
    props.history.push({
        pathname: ROUTES[type].PROFILE,
        state: {
            resetPassword: true
        }
    })
};


export const loginUserResponseHandler = (response, setIsLoading, props) => {
    setIsLoading(false);
    if (response.status === 200) {
        let userData = response.data;
        setUserTokens(response.headers.authorization, response.headers.refresh);
        setUserData(userData);
        if (userData.resetPasswordOnLogin) {
            redirectToProfilePage(userData.type, props)
        } else {
            redirect(response.data.type, props);
        }
    }
};

export const loginUserErrorHandler = (error, setIsLoading, setError) => {
    setIsLoading(false);
    if (error.response.status === 401) {
        setError(texts.GENERAL.LOGIN_ERROR_MESSAGE)
    }
};