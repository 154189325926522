import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import jwt from 'jwt-decode'
import {getAuthToken} from "./services/LocalStorageServices";

export const PrivateRoute = ({component: Component, role, ...rest}) => (
    <Route {...rest} render={props => {
        let token = getAuthToken();
        if (token) {
            if (jwt(token).groups[0] === role) {
                return <Component {...props} />
            } else {
                return <Redirect to="/forbidden"/>
            }
        } else {
            return <Redirect to="/"/>
        }
    }
    }/>
);