import {notify} from "./Notifications";
import {getAllVendorsAsync, getVendorById} from '../services/VendorServices';

export const handleVendorErrors = (error, texts) => {
  switch (error.response.status) {
      case 400:
          notify("error", texts.ADMIN_PANEL.NEW_EDIT_VENDOR.ERROR_400);
          break;
      case 409:
          notify("error", texts.ADMIN_PANEL.NEW_EDIT_VENDOR.ERROR_MESSAGE);
          break;
      default:
          break;
  }
};

export const createShortDayName = (day) => {
    return day.substr(0,3);
};

export const getVendorByIdAsync = async (vendorId, onSuccess) => {
    try {
        let response = await getVendorById(vendorId);
        onSuccess(response.data);
    } catch (error) {
        console.log("Error in getting vendor info");
    }
};

export const getVendors = async (onSuccess, searchText, page, size) => {
    try {
        let response = await getAllVendorsAsync(searchText, page, size);
        onSuccess(response)
    } catch (error) {
    }
};

export const getWorkHoursDefaultValue = (arr, workingDays, setWorkingDays, restDayText) => {
    if(arr) {
      arr.map(el => {
         const split = el.split(' ');
          if (split[2] === "-") {
              workingDays[split[0]] = {
                  short: split[0],
                  from: split[1],
                  to: split[3]
              };
          } else {
              workingDays[split[0]] = restDayText;
          }
      });
        setWorkingDays({...workingDays})
    }
};

export const handleWorkDayChange = (event, isCheckbox, day, workingDays, setWorkingDays, restDayText) => {
    let name = event.target.name;
    let value;
    let dayShort = createShortDayName(day);
    if (isCheckbox) {
        value = event.target.checked;
        if (value) {
            workingDays[dayShort] = {
                short: dayShort,
                from: undefined,
                to: undefined
            };
            setTimeout(() => document.getElementById(day + "_from").focus(), 150)
        } else {
             workingDays[dayShort] = restDayText;
        }
    } else {
        value = event.target.value;
        let key = name.split('_')[1];
        workingDays[dayShort][key] = value
    }
    setWorkingDays({...workingDays})
};

export const handleWorkDayFieldBlur = (event, workingDays, setWorkingDays) => {
    let value = event.target.value;
    let name = event.target.name.split('_')[0];
    let type = event.target.name.split('_')[1];
    let dayShort = createShortDayName(name);
    if(value.length < 5) {
        if (value > 0 && value < 10) {
            workingDays[dayShort][type] = "0" + value + ":" + "00";
        } else if (value >= 10 && value <= 24) {
            workingDays[dayShort][type] = value + ":" + "00";
        } else if (Number(value.split(":")[0]) < 10 && Number(value.split(":")[0]) > 0) {
            workingDays[dayShort][type] = "0" + value.split(":")[0] + ":" + value.split(":")[1];
        }
    }

    setWorkingDays({...workingDays})
};

export const createPhonesString = (phones) => {
   return  Object.values(phones).filter(phone => phone !== undefined && phone.length > 1).join('\n');
};

export const createWorkHoursString = (workingDays) => {
    let daysArr = [];
    Object.values(workingDays).map((day, idx) => {
        if (day.hasOwnProperty("from")) {
            daysArr.push(`${day.short} ${day.from} ${day.to.includes(":") ? '- ' : ''}${day.to}`);
        } else {
            daysArr.push(`${Object.keys(workingDays)[idx]} ${day}`)
        }
    });
    return daysArr.join('\n');
};

export const checkCorrectWorkingDays = (days) => {
    let bool = true;
    Object.values(days).map(day => {
        if (day.hasOwnProperty("from")) {
            if (day.from == undefined || day.from === "" || day.to == undefined || day.to === "") {
                bool = false;
            }
        }
    })
    return bool
};
