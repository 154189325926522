import React from 'react';
import PropTypes from 'prop-types';

const ForbiddenPage = props => {
    return (
        <div className="jumbotron ">
            <h1 className="display-4 p-5">This page is forbidden !</h1>
            <hr className="my-4"/>
                <p className="lead p-5">
                    <button className="btn btn-primary btn-lg" onClick={() => props.history.goBack()} role="button">BACK</button>
                </p>
        </div>
    );
};

ForbiddenPage.propTypes = {
    
};

export default ForbiddenPage;