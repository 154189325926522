import React, {useEffect, useState} from 'react';
import AdminAndVendorPageHeader from "../../common/AdminAndVendorPageHeader/AdminAndVendorPageHeader";
import {toggleSidebar} from "../../../utils/SidebarUtils";
import SelectField from "../SelectField/SelectField";
import {
    editVendorAsync,
    getVendorImage, 
    uploadVendorImage
} from "../../../services/VendorServices";
import CompanyInfoCard from "../CompanyInfoCard/CompanyInfoCard";
import InputFieldRow from "../InputFieldRow/InputFieldRow";
import WorkingTimeField from "./WorkingTimeField/WorkingTimeField";
import AdminAndVendorFooter from "../../common/AdmivAndVendorFooter/AdminAndVendorFooter";
import RichTextEditor from "react-rte";
import {notify} from "../../../utils/Notifications";
import {createEmptyValue, createValueFromString} from "react-rte";
import {
    createPhonesString, createShortDayName, createWorkHoursString,
    getVendorByIdAsync, getWorkHoursDefaultValue, handleVendorErrors,
    handleWorkDayChange, handleWorkDayFieldBlur, checkCorrectWorkingDays
} from "../../../utils/VendorUtils";
import {handleProfileDataChange, assignUserToVendor} from "../../../utils/UserUtils";
import {ROUTES} from "../../../config";
import UsersAssignedCard from "../UsersAssignedCard/UsersAssignedCard";
import {texts} from '../../../Languages/Language';
import {checkRequiredFields} from "../../../utils/Utils";

const NewAndEditVendor = props => {
    const requiredFields = ["companyName", "companyAddress", "vatNumber", "manager"];
    const newAndEditVendorTexts = texts.ADMIN_PANEL.NEW_EDIT_VENDOR;
    const id = Number(props.location.search.split('=')[1]);
    const isEdit = id > 0;
    const [vendor, setVendor] = useState({minimalOrder: 0});
    const [rte, setRte] = useState(createEmptyValue());
    const [deliveryRules, setDeliveryRules] = useState(createEmptyValue());
    const [phones, setPhones] = useState([]);
    const [workingDays, setWorkingDays] = useState({});
    const [image, setImage] = useState([]);
    const [selectedImage, setSelectedImage] = useState();
    const [usersToAssign, setUsersToAssign] = useState([]);

    useEffect(() => {
        if (isEdit) {
            getVendorByIdAsync(id, getVendorResponseHandler);
            getVendorImage(id, setImage);
        } else {
            newAndEditVendorTexts.WORKING_DAYS_ARRAY.map(day => {
                let short = createShortDayName(day);
                workingDays[short] = newAndEditVendorTexts.REST_DAY;
                setWorkingDays({...workingDays})
            });
        }
    }, []);

    const editVendor = async (data, onSuccess, onError) => {
        try {
            let response = await editVendorAsync(data);
            onSuccess(response);
        } catch (error) {
            onError(error);
        }
    };

    const uploadVendorLogo = async (image, vendorId, onSuccess, onError) => {
        try {
            let response = await uploadVendorImage(image, vendorId);
            onSuccess(response);
        } catch (error) {
            onError(error);
        }
    }

    const getVendorResponseHandler = (res) => {
        setVendor(res);
        setRte(createValueFromString(res.description, 'html'));
        setDeliveryRules(createValueFromString(res.deliveryRules, 'html'));
        getWorkHoursDefaultValue(res.workingHours.split('\n'), workingDays, setWorkingDays, newAndEditVendorTexts.REST_DAY);
        let splittedPhones = res.phone.split('\n');
        setPhones({
            phone1: splittedPhones[0],
            phone2: splittedPhones[1],
            phone3: splittedPhones[2]
        })
    };

    const handleRichTextChange = (value, attr) => {
        if (attr === "description") {
            setRte(value);
            vendor.description = value.toString('html')
        } else {
            setDeliveryRules(value);
            vendor.deliveryRules = value.toString('html')
        }
        setVendor({...vendor});
    };

    const handleSaveClick = (data) => {
        if (checkCorrectWorkingDays(workingDays)) {
            data.phone = createPhonesString(phones);
            setVendor({...data});

            data.workingHours = createWorkHoursString(workingDays);

            if (typeof data.searchTags === "string") {
                data.searchTags = data.searchTags.split(/[ ,]/);
            }
            
            if (data.minimalOrder === "" || data.minimalOrder < 0) {
                data.minimalOrder = 0;
            }

            editVendor(data, editVendorResponseHandler, editVendorErrorHandler)
        } else {
            notify("error", newAndEditVendorTexts.ENTER_WORKING_TIME, newAndEditVendorTexts.WORKING_TIME_MESSAGE)
        }
    };

    const editVendorResponseHandler = async (response) => {
        let message = isEdit ? newAndEditVendorTexts.EDIT_RESPONSE_MASSAGE :  newAndEditVendorTexts.NEW_RESPONSE_MASSAGE;
        notify("success", message);
        if (!isEdit) {
            if (selectedImage) {
                const shouldRedirect = Object.values(usersToAssign).length === 0;
                await uploadVendorLogo(selectedImage, response.data.id, (response) => uploadImageResponseHandler(response, shouldRedirect), uploadImageErrorHandler)
            }
            if (Object.values(usersToAssign).length > 0) {
                for (let user of Object.values(usersToAssign)) {
                    await assignUserToVendor(user.id, response.data.id, (response) => assignUserResponseHandler(response, user), (error) =>  assignUserErrorHandler(error, user))
                }
            }
        }
        setTimeout(() => {
            props.history.push(ROUTES.ADMIN.LIST_CLIENTS_VENDORS)
        }, 500)
    };

    const assignUserResponseHandler = (response, user) => {
        notify("success", `${user.email} ${texts.ADMIN_PANEL.ASSIGN_USERS.USER_ASSIGNED}`);
    };

    const assignUserErrorHandler = (error, user) => {
        notify("error", `${user.email} ${texts.ADMIN_PANEL.ASSIGN_USERS.ASSIGN_ERROR}`);
    };

    const editVendorErrorHandler = (err) => {
      handleVendorErrors(err, texts);
    };

    const handlePhoneChange = (event) => {
        phones[event.target.name] = event.target.value;
        setPhones({...phones})
    };

    const handleImageUpload = (event) => {
        if (isEdit) {
            uploadVendorImage(event.target, vendor.id, uploadImageResponseHandler, uploadImageErrorHandler)
        } else {
            setSelectedImage(event.target);
            setImage(URL.createObjectURL(event.target.files[0]))
        }
    };

    const uploadImageResponseHandler = (response, shouldRedirect) => {
        getVendorImage(id, setImage);
        notify("success", newAndEditVendorTexts.UPLOAD_SUCCESSFUL);
        if (shouldRedirect) {
            setTimeout(() => {
                props.history.push(ROUTES.ADMIN.LIST_CLIENTS_VENDORS)
            }, 500)
        }
    };

    const uploadImageErrorHandler = (error) => {
        notify("error", newAndEditVendorTexts.UPLOAD_UNSUCCESSFUL)
    };

    return (
        <div>
            <AdminAndVendorPageHeader onClick={toggleSidebar}
                                      text={`${isEdit ? newAndEditVendorTexts.EDIT_HEADER : newAndEditVendorTexts.NEW_HEADER}`}/>

            <div className="text-right pr-3 d-flex justify-content-end">
                <div className="w-25">
                    <SelectField label={texts.ADMIN_PANEL.USERS.STATUS}
                                 nameAndId={"isEnabled"}
                                 options={texts.ADMIN_PANEL.USERS.STATUS_OPTIONS}
                                 defaultValue={vendor.isEnabled}
                                 onChange={(e) => handleProfileDataChange(e, vendor, setVendor)}
                                 testId="v-status"
                                 />
                </div>
            </div>

            <div className="p-3">
                <CompanyInfoCard partner={vendor} onChange={(e, shouldTrim) => handleProfileDataChange(e, vendor, setVendor, null, null, shouldTrim)}/>
            </div>

            <div className="p-3">
                <div className="card shadow">
                    <div className="card-header h5 text-primary text-left">
                        {newAndEditVendorTexts.VENDOR_SETTINGS}
                    </div>
                    <div className="card-body d-flex flex-column">
                        <div className="d-flex w-100">
                            <div className="w-70">
                                <div className="p-2">
                                    <div className="d-flex align-items-center">
                                        <div className="w-35 text-right pr-2">
                                            {newAndEditVendorTexts.VENDOR_NAME}:
                                        </div>
                                        <div className="w-60">
                                            <input data-testid="vendorName" type="text" onChange={(e) => handleProfileDataChange(e, vendor, setVendor)}
                                                   className="form-control" name="vendorName"
                                                   defaultValue={isEdit ? vendor.vendorName : ''}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div className="d-flex align-items-center">
                                        <div className="align-self-start w-35 text-right pr-2">
                                            {newAndEditVendorTexts.VENDOR_DESCRIPTION}:
                                        </div>
                                        <div className="w-60">
                                            <RichTextEditor data-testid="description" value={rte} onChange={(v) => handleRichTextChange(v, "description")}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-30">
                                <div>
                                    {newAndEditVendorTexts.VENDOR_LOGO}
                                </div>
                                <div className="w-100 d-flex pt-3">
                                    <div className="w-50">
                                        {image.length > 0 ? <img style={{width:"75px"}} src={image} alt=""/> : newAndEditVendorTexts.NO_IMAGE}
                                    </div>
                                    <div className="w-40">
                                        <label htmlFor="v-logo" className="btn btn-primary">{newAndEditVendorTexts.SELECT_IMAGE}</label>
                                        <input data-testid="vendorImage" onChange={handleImageUpload} hidden type="file" name="v-logo" id="v-logo"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 p-2">
                        <div className="d-flex">
                            <div className="align-self-start w-25 pr-2 text-right">
                                {newAndEditVendorTexts.VENDOR_DELIVERY_RULES}:
                            </div>
                            <div className="w-70">
                                <RichTextEditor data-testid="deliveryRules" value={deliveryRules}
                                                onChange={(v) => handleRichTextChange(v, "delivery")}/>
                            </div>
                        </div>
                    </div>
                    <div className="w-100 p-2">
                        <div className="d-flex">
                            <div className="align-self-start w-25 pr-2 text-right">
                                {newAndEditVendorTexts.VENDOR_SEARCH_TAGS}:
                            </div>
                            <div className="w-70">
                                <textarea data-testid="vendorTags" onChange={(e) => handleProfileDataChange(e, vendor, setVendor)} name="searchTags" type="text"
                                          defaultValue={isEdit ? vendor.searchTags : ''}
                                          className="form-control" rows="3"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="p-3">
                <div className="card shadow">
                    <div className="card-header text-primary text-left h5">
                        {newAndEditVendorTexts.ORDER_SETTINGS}
                    </div>
                    <div className="card-body">
                        <InputFieldRow testId="emailForOrders" onChange={(e) => handleProfileDataChange(e, vendor, setVendor, null, null, true)}
                                       label={newAndEditVendorTexts.ORDER_EMAIL}
                                       defaultValue={isEdit ? vendor.emailForOrders: ""}
                                       nameAndId={"emailForOrders"} type="email"/>
                        <InputFieldRow testId="minimalOrder" label={newAndEditVendorTexts.ORDER_MINIMAL_AMOUNT} onChange={(e) => handleProfileDataChange(e, vendor, setVendor)}
                                       defaultValue={isEdit ? vendor.minimalOrder : ''} nameAndId={"minimalOrder"}
                                       type="number"/>
                    </div>
                </div>
            </div>

            <div className="p-3">
                <div className="card shadow">
                    <div className="card-header text-primary text-left h5">
                        {newAndEditVendorTexts.CONTACTS}
                    </div>
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <div className="w-60">
                                <InputFieldRow testId="phone1" onChange={handlePhoneChange} nameAndId={"phone1"} label={newAndEditVendorTexts.CONTACTS_PHONE_1}
                                               defaultValue={phones.phone1}
                                               type="text"/>
                                <InputFieldRow testId="phone2" onChange={handlePhoneChange} nameAndId={"phone2"} label={newAndEditVendorTexts.CONTACTS_PHONE_2}
                                               defaultValue={phones.phone2}
                                               type="text"/>
                                <InputFieldRow testId="phone3" onChange={handlePhoneChange} nameAndId={"phone3"} label={newAndEditVendorTexts.CONTACTS_PHONE_3}
                                               defaultValue={phones.phone3}
                                               type="text"/>
                                <InputFieldRow testId="email" onChange={(e) => handleProfileDataChange(e, vendor, setVendor, null, null, true)} label={newAndEditVendorTexts.CONTACTS_EMAIL} nameAndId={"email"}
                                               defaultValue={vendor.email} type="text"/>
                                <InputFieldRow testId="address" onChange={(e) => handleProfileDataChange(e, vendor, setVendor)} label={newAndEditVendorTexts.CONTACTS_ADDRESS} nameAndId={"address"}
                                               defaultValue={vendor.address}
                                               type="textarea"/>

                            </div>
                            <div className="w-40">
                                <div className="">
                                    {newAndEditVendorTexts.WORKING_DAYS_LABEL}
                                </div>
                                {newAndEditVendorTexts.WORKING_DAYS_ARRAY.map(day => {
                                    return (
                                        <WorkingTimeField key={day} 
                                            isDisabled={workingDays[createShortDayName(day)] === newAndEditVendorTexts.REST_DAY}
                                            onChange={(event, isCheckbox, day) => handleWorkDayChange(event, isCheckbox, day, workingDays, setWorkingDays, newAndEditVendorTexts.REST_DAY)} 
                                            day={day} 
                                            defaultValues={workingDays}
                                            onFieldBlur={(event) => handleWorkDayFieldBlur(event, workingDays, setWorkingDays)}
                                            />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-3">
                <UsersAssignedCard type={"vendor"} customerId={id} assignTo={id} isEdit={isEdit} setToAssign={setUsersToAssign} />
            </div>
            <div className="text-right pr-5 pt-5">
                <button disabled={!checkRequiredFields(vendor, requiredFields)} onClick={() => handleSaveClick(vendor)} className="btn btn-primary pl-3 pr-3">{texts.ADMIN_PANEL.USERS.SAVE_BTN}</button>
            </div>
            <AdminAndVendorFooter texts={texts.VENDOR_PAGE.FOOTER}/>
        </div>
    );
};

export default NewAndEditVendor;