import React from 'react';
import {texts} from '../../../../Languages/Language';
import { ORDER_STATUSES } from '../../../../config';
import ConfirmDeliveryButton from '../ConfirmDeliveryButton/ConfirmDeliveryButton';
import RateOrderSection from '../RateOrderSection/RateOrderSection';
import PropTypes from 'prop-types';

const OrderRatingsCard = ({ isDelivered, order, handleOrderConfirm, handleRateOrder }) => (
    <div className="ov-delivery-confirm-wrapper">
        <div className="ov-confirm-button-wrapper"
            hidden={isDelivered || order.status === ORDER_STATUSES.DELIVERED}>
            <ConfirmDeliveryButton text={texts.VENDOR_PAGE.ORDERS_PAGE.CONFIRM_DELIVERY}
                onClick={handleOrderConfirm} />
        </div>

        {order.id && <RateOrderSection texts={texts.VENDOR_PAGE} order={order}
            rateHandler={handleRateOrder} />}
    </div>
);


OrderRatingsCard.propTypes = {
    isDelivered: PropTypes.bool,
    order: PropTypes.object,
    handleOrderConfirm: PropTypes.func,
    handleRateOrder: PropTypes.func
}

export default OrderRatingsCard;