import React from 'react';
import PropTypes from 'prop-types';
import {convertTimestampToDate} from "../../../utils/TimeUtils";
import texts from '../../../Languages/bg';

const PeriodSection = ({text, from, to}) => {
    return (
        <div className="w-75 text-left m-4 ml-5">
            <h5 className="">  {text}: {convertTimestampToDate(from)} {texts.VENDOR_PANEL.DATE_TO} {convertTimestampToDate(to)}</h5>
        </div>
    );
};

PeriodSection.propTypes = {
    text: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
};

export default PeriodSection;