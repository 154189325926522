import { createQuality, getQualities } from "../services/QualityServices";
import { createSelectOptions } from "./SelectUtils";

export const getAllQualities = async (onSuccess) => {
    try {
        let response = await getQualities();
        let options = createSelectOptions(response.data);
        onSuccess(options);
    } catch (error) { }
};

export const createNewQuality = async (quality, onSuccess, onError) => {
    try {
        let response = await createQuality(quality); 
        onSuccess(response)
    } catch (error) {
        onError(error)
    }
};