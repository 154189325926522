export const getLastNotificationsCheckTimestamp = () => {
       return  localStorage.getItem("timestamp")
};

export const setLastNotificationsCheckTimestamp = (timestamp) => {
    localStorage.setItem("timestamp", timestamp)
};

export const sortNotificationsByTimestamp = (data, arrayWithNewer, arrayWithOlder, lastTimestamp) => {
    data.map(notification => {
        if (notification.timestamp > lastTimestamp) {
            arrayWithNewer.push(notification)
        } else {
            arrayWithOlder.push(notification)
        }
    });
};