import React from 'react';
import PropTypes from 'prop-types';
import './VendorPageIntro.css';
import {texts} from "../../../Languages/Language";
import VendorRating from "../VendorRating/VendorRating";

const VendorPageIntro = ({vendorLogo, vendorData}) => {

    return (
        <div className="row">
            <div className="col-12 p-0 vp-intro-index">
                <div className="vp-intro d-flex">
                    <div className="col-3 col-lg-2 p-0 vp-logo-wrapper">
                           <img className="vp-logo" src={vendorLogo} alt=""/>
                    </div>
                    <div className="col-9 col-lg-4 p-0 vp-name-wrapper">
                        <div className="float-left vp-name text-left ">{vendorData.vendorName}</div>
                        <div className="col-12 p-0 d-flex">
                            <div className="col-8 p-0 vp-name-and-rate">

                                <div className="float-left vp-rate w-100 text-left">
                                    <VendorRating rating={vendorData.rating}/>
                                </div>
                                <div className="float-left vp-completed-orders w-100 text-left"><span>{vendorData.totalOrdersDelivered}</span> <span>{texts.VENDOR_PAGE.COMPLETED_ORDERS}</span></div>
                            </div>
                            <div className="col-2 p-0 vp-minimal-order d-lg-none">
                                <div className="pl-2">{texts.VENDOR_PAGE.MINIMAL_AMOUNT_LABEL}:
                                    <div>
                                        {vendorData.minimalOrder > 0 ? <div>{vendorData.minimalOrder} {texts.VENDOR_PAGE.PRODUCT_CARD.CURRENCY}</div> : texts.VENDOR_PAGE.NO_MINIMAL_ORDER_AMOUNT}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-none d-lg-inline-block col-lg-6 p-0 ml-lg-0  ml-5">
                        <div className=" text-left pt-1 vp-web-description mt-2">
                            <div dangerouslySetInnerHTML={{__html: vendorData.description}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

VendorPageIntro.propTypes = {
    vendorData: PropTypes.object,
    vendorLogo: PropTypes.string
};

export default VendorPageIntro;