import React from 'react';
import PropTypes from 'prop-types';
import './ProductCardList.css';
import {
    packageHasPrice,
    availabilityFormatHandler,
    checkCurrentActivePackage,
    checkCurrentActivePrice, checkIsProductAvailable, checkLastOrderedDate, getFormattedSingleItemPrice
} from "../../../utils/VendorProductUtils";
import AddToCartButton from "../AddToCartButton/AddToCartButton";
import PromotionPill from "../PromotionPill/PromotionPill";
import PackageSelectPill from "../PackageSelectPill/PackageSelectPill";
import ProductPrices from "../ProductPrices/ProductPrices";
import DeleteIcon from '../../../assets/times-solid.svg';
import EditIcon from '../../../assets/edit-3.svg';
import {PRODUCT_STATUS} from "../../../config";
import ProductImage from "../ProductImage/ProductImage";

const ProductCardList = ({   texts, changeActivePack,
                             packages, productIndex, onAddButtonClick, updateOrder, itemInCart,
                             inOrdersView, onDeleteClick, commentText, onAddCommentClick, product
                         }) => {
    return (
        <div id={"product" + product.id} className="vp-product-card-wrapper mt-2 pl-lg-1">
            <div className="vp-erp-promo-wrapper">
                <div className="pl-2 vp-product-number">{product.sosku}</div>
                <div className={`vp-vendor-promo-wrapper `}>
                    {checkLastOrderedDate(product.lastOrderedTimestamp) && <PromotionPill className="bg-green" text={texts.RECENT_ORDERED} />}
                </div>
                {inOrdersView && <div data-testid={"dlt_" + product.id} onClick={onDeleteClick} className="float-right pr-3">
                    <img className="vp-card-list-delete-icon" src={DeleteIcon} alt="X"/>
                </div>}
            </div>
            <div className="vp-card-list-body-container">
                <div className="vp-card-list-image">
                    <ProductImage globalProductId={product.globalProductId} vendorProductId={"vp_" + product.id} className={"vp-product-image"} />
                </div>

                <div className="vp-card-list-body">
                    <div className="vp-product-name">
                        {product.displayName}
                    </div>
                    <div className="d-lg-flex pt-lg-1">
                        {(() => {
                            if (packageHasPrice(packages[product.active])) {
                                return (
                                    <div className="vp-price-selected">
                                        {getFormattedSingleItemPrice(packages[product.active])} {texts.CURRENCY}/{product.measureUnit} {texts.VAT_INCLUDED}
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="vp-price-selected">
                                        {texts.NO_PRICE}
                                    </div>
                                )
                            }
                        })()
                        }

                        <div className={`text-right vp-pills-wrapper d-lg-inline-block`}>
                            {packages.map((pack, index) => (
                                <PackageSelectPill key={pack.id}
                                                   units={pack.packagingUnit}
                                                   quantity={product.measureUnit}
                                                   onClick={() => changeActivePack(productIndex, index, pack.id)}
                                                   isActive={checkCurrentActivePackage(itemInCart, packages, pack, product.active)}/>
                            ))}
                        </div>
                    </div>
                    <div className="vp-availability">
                        {availabilityFormatHandler(product.productStatus)}
                    </div>

                </div>
                <div className="d-none d-lg-block vp-card-price-and-button-desktop">
                    <div className="vp-price-container">
                        {packageHasPrice(packages[product.active]) &&
                        <ProductPrices vatPriceLabel={texts.PRODUCT_PRICE_VAT}
                                       noVatPriceLabel={texts.PRODUCT_PRICE}
                                       vatPrice={checkCurrentActivePrice(itemInCart, packages, product.active, true)}
                                       totalPrice={checkCurrentActivePrice(itemInCart, packages, product.active, true, true)}
                                       currency={texts.CURRENCY} productId={product.id}
                        />
                        }
                    </div>

                    {checkIsProductAvailable(product.productStatus) && packages.length > 0
                        ?
                            <div className="vp-card-list-btn-wrapper-desktop">
                                <AddToCartButton disabled={product.productStatus === PRODUCT_STATUS.NOT_AVAILABLE}
                                                 onAddButtonClick={onAddButtonClick}
                                                 packagingId={packages[product.active].id}
                                                 id={product.id}
                                                 updateOrder={updateOrder}
                                                 itemInCart={itemInCart}
                                                 selectedPackage={packages[product.active]}
                                                 product={product}
                                />
                             </div>
                        :
                            <div className="font-weight-bold text-red">
                                {texts.PRODUCT_AVAILABILITY.NOT_AVAILABLE}
                            </div>
                    }

                    {(() => {
                        if (inOrdersView) {
                            if (!commentText) {
                                return (
                                    <div className="cp-card-list-add-comment">
                                       <span className="c-pointer" onClick={onAddCommentClick}>
                                           <img src={EditIcon} alt=""/>
                                           {texts.ADD_COMMENT}
                                       </span>
                                    </div>
                                )
                            }
                        }
                    })()}

                </div>

            </div>
            {commentText &&
            <div className="d-none d-lg-block card-list-comment-text">
                <span className="c-pointer"  onClick={onAddCommentClick}>
                    <span>
                        <img src={EditIcon} alt=""/>
                    </span>
                    <span className="pl-2">
                        {commentText}
                    </span>
                </span>
            </div>}
            <div className="d-lg-none w-100">
                <div className="vp-price-container">
                    {packageHasPrice(packages[product.active]) &&
                    <ProductPrices vatPriceLabel={texts.PRODUCT_PRICE_VAT}
                                   noVatPriceLabel={texts.PRODUCT_PRICE}
                                   vatPrice={checkCurrentActivePrice(itemInCart, packages, product.active, true)}
                                   totalPrice={checkCurrentActivePrice(itemInCart, packages, product.active, true, true)}
                                   currency={texts.CURRENCY} productId={product.id}/>
                    }
                </div>
                <div className={`vp-card-list-add-btn-wrapper ${!packageHasPrice(packages[product.active]) && "pt-2"}`}>
                    {checkIsProductAvailable(product.productStatus) && packages.length > 0
                        ?
                        <div className="vp-card-list-btn-wrapper-desktop">
                            <AddToCartButton disabled={product.productStatus === PRODUCT_STATUS.NOT_AVAILABLE}
                                             onAddButtonClick={onAddButtonClick}
                                             packagingId={packages[product.active].id}
                                             id={product.id}
                                             updateOrder={updateOrder}
                                             itemInCart={itemInCart}
                                             selectedPackage={packages[product.active]}
                                             product={product}
                            />
                        </div>
                        :
                        <div className="font-weight-bold text-red">
                            {texts.PRODUCT_AVAILABILITY.NOT_AVAILABLE}
                        </div>
                    }
                </div>

                {(() => {
                    if (inOrdersView) {
                        if (!commentText) {
                            return (
                                <div className="cp-card-list-add-comment">
                                 <span className="c-pointer" onClick={onAddCommentClick}>
                                     <img src={EditIcon} alt=""/>
                                     {texts.ADD_COMMENT}
                                 </span>
                                </div>
                            )
                        } else {
                            return (
                                <div className="card-list-comment-text">
                                <span className="c-pointer" onClick={onAddCommentClick}>
                                  <span>
                                        <img src={EditIcon} alt=""/>
                                  </span>
                                   <span className="pl-2">
                                        {commentText}
                                   </span>
                                </span>
                                </div>
                            )
                        }
                    }
                })()}
            </div>


        </div>
    );
};

ProductCardList.propTypes = {
    texts: PropTypes.object,
    changeActivePack: PropTypes.func,
    packages: PropTypes.array,
    productIndex: PropTypes.number,
    onAddButtonClick: PropTypes.func,
    updateOrder: PropTypes.func,
    itemInCart: PropTypes.object,
    inOrdersView: PropTypes.bool,
    onDeleteClick: PropTypes.func,
    commentText: PropTypes.string,
    onAddCommentClick: PropTypes.func,
    product: PropTypes.object
};

export default ProductCardList;