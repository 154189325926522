import React from 'react';
import PropTypes from 'prop-types';
import Select from "react-select"
import GlobalProductContext from "../../GlobalProductContext";
import {checkAndAddRedBorder} from "../../../../../utils/Utils";

const MeasureUnitsRow = ({label, options, onChange, name, measurePlaceholder}) => {
    const context = React.useContext(GlobalProductContext);
    const product = context.product;
    const requiredFields = context.requiredFields;
    const handleUnitChange = (e) =>  {
        onChange(e, name)
    };

    return (
        <div className="col-11 d-flex mt-2">
            <div className="label measure-units">
                * {label}:
            </div>
            <div className="select-wrapper">

                <Select onBlur={() => checkAndAddRedBorder(name, product, requiredFields)}
                        className={`measure-select input-fld`}
                        id={name} name={name} onChange={handleUnitChange} 
                        placeholder={product ? product.measureUnit : measurePlaceholder} 
                        options={options} 
                        classNamePrefix={"select_search"} />
            </div>
        </div>
    );
};

MeasureUnitsRow.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    name: PropTypes.string,
    measurePlaceholder: PropTypes.string
};

export default MeasureUnitsRow;