import React from 'react';
import PropTypes from 'prop-types';
import './ProductCard.css';

const ProductCard = ({texts, item}) => {

    return (
        <div className="ov-product-card-wrapper">
            <div className="ov-code">
                {item.sosku}
            </div>
            <div className="ov-product-card-body">
                <div className="ov-product-name">
                    {item.displayName}
                </div>

                <div className="ov-product-info-wrapper">
                    <div className="ov-product-info d-flex">
                        <div className="ov-measure-unit-wrapper">
                            <div className="ov-product-measure-units">
                                x {item.selectedPackage.packagingUnit} {item.selectedPackage.packagingType}
                            </div>
                        </div>
                        <div className="ov-single-price-vat-wrapper">
                            <div className="ov-single-price-vat">
                                {item.unitPriceVAT} {texts.SHOPPING_CART.CART.CURRENCY}
                            </div>
                        </div>
                        <div className="ov-quantity-wrapper">
                            <div className="ov-product-quantity">
                                {item.quantity}
                            </div>
                        </div>
                        <div className="ov-total-vat-price-wrapper">
                            <div className="ov-total-vat-price">
                                {item.sum.toFixed(2)} {texts.SHOPPING_CART.CART.CURRENCY}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ov-order-comment-wrapper">
                <div className="ov-order-comment">
                    {item.comment}
                </div>
            </div>
        </div>
    );
};

ProductCard.propTypes = {
    texts: PropTypes.object,
    item: PropTypes.object
};

export default ProductCard;