import React from 'react';
import PropTypes from 'prop-types';

const CustomButton = ({text, icon, className, onClick, testid}) => {
    return (
        <button data-testid={testid} onClick={onClick} className={`btn ${className} btn-lg btn-icon-split`}>
            <span className="icon w-auto h-auto  text-white-50"> {icon} </span>
            <span className="text">{text}</span>
        </button>
    );
};

CustomButton.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.object,
    testid: PropTypes.string
};

export default CustomButton;