import React from 'react';
import PropTypes from 'prop-types';
import ProductCardList from "../ProducrCardList/ProductCardList";
import ProductCardGrid from "../ProductCardGrid/ProductCardGrid";
import './ProductSection.css';
import Spinner from "react-bootstrap/Spinner";

const ProductSection = ({texts, vendorProducts, isListView, selectPackageHandler, onAddButtonClick, updateOrder, cart, isLoading}) => {


    return (
        <div className="vp-products-container">
            {(() => {
                if (isLoading) {
                   return <Spinner animation={"border"} variant={"success"} />
                } else {
                    if (vendorProducts && vendorProducts.length > 0) {
                        if (isListView) {
                            return (
                                <div>
                                    {vendorProducts.map((p, index) => (

                                        <ProductCardList key={p.id}
                                                         texts={texts.PRODUCT_CARD}
                                                         packages={p.packagingList}
                                                         productIndex={index}
                                                         changeActivePack={selectPackageHandler}
                                                         onAddButtonClick={onAddButtonClick}
                                                         updateOrder={updateOrder}
                                                         itemInCart={cart.find(el => el.vendorProductId === p.id)}
                                                         product={p}

                                        />))
                                    }
                                </div>
                            )
                        } else {
                            return (

                                <div className="grid-card-container">
                                    {vendorProducts.map((p, index) => (
                                        <ProductCardGrid key={p.id}
                                                         texts={texts.PRODUCT_CARD}
                                                         packages={p.packagingList}
                                                         productIndex={index}
                                                         changeActivePack={selectPackageHandler}
                                                         onAddButtonClick={onAddButtonClick}
                                                         updateOrder={updateOrder}
                                                         itemInCart={cart.find(el => el.vendorProductId === p.id)}
                                                         product={p}

                                        />))
                                    }
                                </div>

                            )
                        }
                    }
                }

            })()}
        </div>
    );
};

ProductSection.propTypes = {
    texts: PropTypes.object,
    vendorProducts: PropTypes.array,
    isListView: PropTypes.bool,
    selectPackageHandler: PropTypes.func,
    onAddButtonClick: PropTypes.func,
    updateOrder: PropTypes.func,
    cart: PropTypes.array,
    isLoading: PropTypes.bool
};

export default ProductSection;