import React from 'react';
import PropTypes from 'prop-types';
import './ProductPrices.css'

const ProductPrices = ({vatPriceLabel, noVatPriceLabel, vatPrice, totalPrice, currency, productId}) => {
    return (
        <div className="vp-price-wrapper">
            <div className="vp-vat-price-label">{vatPriceLabel}: <span data-testid={"pcl_vptid" + productId}
                className="vp-vat-price">{vatPrice} {currency}</span></div>
            <div className="vp-no-vat-price-label">{noVatPriceLabel}: <span data-testid={"pcl_tptid" + productId}
                className="vp-no-vat-price">{totalPrice} {currency}</span></div>
        </div>
    );
};

ProductPrices.propTypes = {
    vatPriceLabel: PropTypes.string,
    noVatPriceLabel: PropTypes.string,
    vatPrice: PropTypes.string,
    totalPrice: PropTypes.string,
    currency: PropTypes.string,
    productId: PropTypes.number
};

export default ProductPrices;