import {notify} from "./Notifications";
import {checkPasswordMinimalLength, checkPasswordsEqual, checkPasswordsExist} from "./PasswordUtils";
import {PASSWORD_MINIMAL_LENGTH, ROUTES, USER_ROLES} from "../config";
import {
    assignUserToVendorAsync, editUser, getAllUsers,
    getUsersByAssignmentStatus, registerUserAsync, unassignUserAsync,
    updateUserPassword, assignUserToPOSAsync, getAssignedUsersToPOSAsync
} from "../services/UserServices";
import {setUserData} from "../services/LocalStorageServices";
import {goToPreviousPage} from "./Utils";
import {texts} from "../Languages/Language";

export const handleErrors = (error, texts) => {
    switch (error.response.status) {
        case 400:
            notify("error", error.response.data.parameterViolations[0].message);
            break;
        case 404:
            notify("error", texts.ADMIN_PANEL.USERS.USER_ID_NOT_FOUND);
            break;
        case 409:
            notify("error", texts.ADMIN_PANEL.USERS.EMAIL_ALREADY_EXISTS);
            break;
        default:
            break;
    }
};

export const checkPasswordsAndSendRequest = (texts, passwords, request) => {
    if (passwords.password.length > 0) {
        if ( checkPasswordMinimalLength(passwords.password)) {
            if (checkPasswordsEqual(passwords)) {
                request();
            } else {
                notify("error", texts.ADMIN_PANEL.USERS.PASSWORDS_DO_NOT_MATCH)
            }
        } else {
            notify("error", `${texts.ADMIN_PANEL.USERS.PASSWORD_SHOULD_BE} ${PASSWORD_MINIMAL_LENGTH} ${texts.ADMIN_PANEL.USERS.SYMBOLS}`)
        }
    }

};

export const redirectToProfilePage = (userType, history) => {
    switch(userType) {
        case USER_ROLES.ADMIN:
           return  history.push(ROUTES.ADMIN.PROFILE);

        case USER_ROLES.VENDOR:
            return history.push(ROUTES.VENDOR.PROFILE);

        case USER_ROLES.CUSTOMER:
            return history.push(ROUTES.CUSTOMER.PROFILE);
        default:
            break;
    }
};

export const handleProfileDataChange = (event, user, setUser, passwords, setPasswords, shouldTrim = false) => {
    let name = event.target.name;
    let value = shouldTrim ? event.target.value.trim() : event.target.value;

    if (name === "password" || name === "repeat_password") {
        passwords[name] = value;
        setPasswords({...passwords});
    } else {
        user[name] = value;
        setUser({...user})
    }
};

const editUserRequest = async (userData, onSuccess, onError) => {
    try {
        let response = await editUser(userData);
        onSuccess(response);
    } catch(error) {
        if (error.response) {
            onError(error)
        }
    }
};

export const handleProfileSaveClick = async (userData, passwords, editUserResponseHandler, editUserErrorHandler) => {
    if (checkPasswordsExist(passwords)) {
        checkPasswordsAndSendRequest(texts, passwords,  () => editUserRequest(userData, (res) => editUserResponseHandler(res, true), editUserErrorHandler))
    } else {
        editUserRequest(userData, (response) => editUserResponseHandler(response, false),  editUserErrorHandler);
    }
};

export const editUserProfileResponseHandler = (response, shouldUpdatePassword, setUserInStateAndStorage, passwords, editPasswordResponseHandler, editPasswordErrorHandler, props) => {
    if (setUserInStateAndStorage) {
        setUserInStateAndStorage(response.data);
        setUserData(response.data);
    }
    
    if (shouldUpdatePassword) {
        updatePassword(passwords.password, response.data.id, editPasswordResponseHandler, editPasswordErrorHandler);
    } else {
        notify("success", texts.ADMIN_PANEL.USERS.SUCCESSFUL_EDIT)
        goToPreviousPage(props)
    }
};

const updatePassword = async (password, userId, onSuccess, onError) => {
    try {
        let response = await updateUserPassword(password, userId);
        onSuccess(response)
    } catch (error) {
        if (error.response) {
            onError(error)
        }
    }
};

export const editUserProfileErrorHandler = (err, passwords, userId, editPasswordResponseHandler, editPasswordErrorHandler) => {
    handleErrors(err, texts);
    checkPasswordsAndSendRequest(texts, passwords, () => updatePassword(passwords.password, userId, editPasswordResponseHandler, editPasswordErrorHandler))
};

export const getUsers = async (onSuccess, onError) => {
    try {
        let response = await getAllUsers();
        onSuccess(response)
    } catch (error) {
        onError(error)
    }
};

export const registerUser = async (userData, onSuccess, onError) => {
    try {
        let response = await registerUserAsync(userData);
        onSuccess(response);
    } catch (error) {
        onError(error);
    }
};

export const getUsersByAssignment = async (clientId, role, unassigned, onSuccess, onError = () => {}) => {
    try {
        let response = await getUsersByAssignmentStatus(clientId, role, unassigned);
        onSuccess(response);
    } catch (error) {
        onError(error);
    }
};

export const assignUserToVendor = async (userId, vendorId, onSuccess, onError) => {
    try {
        let response = await assignUserToVendorAsync(userId, vendorId);
        onSuccess(response);
    } catch (error) {
        onError(error)
    }
};

export const unassignUser = async (userId, clientId, type, onSuccess, onError = () => {}) => {
    try {
        let response = await unassignUserAsync(userId, clientId, type);
        onSuccess(response)
    } catch (error) {
        onError(error)
    }
};

export const assignUserToPOS = async (userId, posId, onSuccess, onError = () => {}) => {
    try {
        let response = await assignUserToPOSAsync(userId, posId);
        onSuccess(response);
    } catch (error) {
        onError(error)
    }
};

export const getAssignedUsersToPOS = async (posId,  onSuccess, onError = () => {}) => {
    try {
        let response = await getAssignedUsersToPOSAsync(posId);
        onSuccess(response);
    } catch (error) {
        onError(error);
    }
}