import React from 'react';
import PropTypes from 'prop-types';
import CaretUp from "../../../assets/caret-up-solid.svg";

const BackToTopButton = ({texts}) => {
    return (
        <div onClick={() => document.documentElement.scrollTop = 0} className="sov-back-to-top">
            <div className=""><img src={CaretUp} alt=""/></div>
            {texts.SHOPPING_CART.BACK_TO_TOP}
        </div>
    );
};

BackToTopButton.propTypes = {
    texts: PropTypes.object
};

export default BackToTopButton;