import React from 'react';
import PropTypes from 'prop-types';
import './VendorInfoDesktop.css';
import {renderPhoneLinks, renderWorkingHours} from "../../../utils/VendorInfoUtils";

const VendorInfoDesktop = ({texts, vendorData}) => {
    return (
        <div className="d-none d-lg-flex col-lg-12 pl-0 pr-5 mt-2">
            <div className="vp-web-delivery">
                <p className="info-heading">{texts.DELIVERY_CONDITIONS}</p>
                <div className="align-items-start">
                    <div dangerouslySetInnerHTML={{__html: vendorData.deliveryRules}} />
                </div>
                <div className="h4 vp-web-min-price">{texts.MINIMAL_AMOUNT_LABEL}: {vendorData.minimalOrder} лв.</div>
            </div>
            <div className="vp-web-working-hours">
                <p className="info-heading">{texts.WORKING_TIME}</p>
                <div>{vendorData.workingHours.split('\n').map((wh, index) => renderWorkingHours(wh, index))}</div>
            </div>
            <div className="vp-web-contacts">
                <p className="info-heading">{texts.CONTACTS}</p>
                <div className="d-flex flex-column align-items-center">
                  <div>
                      {
                        renderPhoneLinks(vendorData.phone)
                      }
                  </div>
                </div>
                <p className="text-break">
                    {vendorData.email}
                </p>
                <p>{vendorData.address}</p>
            </div>
        </div>
    );
};

VendorInfoDesktop.propTypes = {
    texts: PropTypes.object,
    vendorData: PropTypes.object
};

export default VendorInfoDesktop;