import React from 'react';
import PropTypes from 'prop-types';
import './PromotionPill.css'

const PromotionPill = ({text, className}) => {
    return (
        <div className={`vp-product-promo ${className}`}>
            {text}
        </div>
    );
};

PromotionPill.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string
};

export default PromotionPill;