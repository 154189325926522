import React, {useEffect, useState} from 'react';
import AdminAndVendorPageHeader from "../../common/AdminAndVendorPageHeader/AdminAndVendorPageHeader";
import {toggleSidebar} from "../../../utils/SidebarUtils";
import InputFieldRow from "../InputFieldRow/InputFieldRow";
import AdminAndVendorFooter from "../../common/AdmivAndVendorFooter/AdminAndVendorFooter";
import {CATEGORY_TYPES} from "../../../config";
import {createNestedOptions} from "../../../utils/SelectUtils";
import Select from "react-select";
import {assignUserToPOS, handleProfileDataChange} from "../../../utils/UserUtils";
import {notify} from "../../../utils/Notifications";
import {addOrEditPintOfSale} from "../../../utils/CustomerUtils";
import UsersAssignedCard from "../UsersAssignedCard/UsersAssignedCard";
import {checkAndAddRedBorder, checkRequiredFields} from "../../../utils/Utils";
import { getCategories } from '../../../utils/CategoryUtils';
import {texts} from '../../../Languages/Language';

const NewAndEditPointOfSale = props => {
    const requiredFields = ["name", "country", "city", "shippingAddress", "phone", "email", "contactPersonFirstName", "contactPersonLastName"];
    const newAndEditPOS = texts.ADMIN_PANEL.NEW_EDIT_POS;
    const posId = props.location.state ? Number(props.location.state.posId) : props.history.goBack();
    const customerId = props.location.state.customerId;
    const isEdit = posId > 0;
    const [pointOfSale, setPointOfSale] = useState({});
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [clientsToAssign, setClientsToAssign] = useState([]);

    useEffect(() => {
        if (posId) {
            setPointOfSale(props.location.state.data)
        }
        getCategories(getCategoriesResponseHandler, CATEGORY_TYPES.COMPANY);
    }, []);

    const getCategoriesResponseHandler = (categories) => {
      let categoriesArr = createNestedOptions(categories);
      categoriesArr.shift();
      setCategories(categoriesArr)
    };

    const handleCategorySelect = (event) => {
        setSelectedCategory(event)
    };

    const handleAddToClick = () => {
        if (!pointOfSale.hasOwnProperty("categories")) {
            pointOfSale.categories = [];
        }
        let category = {
            id: selectedCategory.value,
            name: selectedCategory.label
        };
        pointOfSale.categories.push(category);
        setPointOfSale({...pointOfSale});
        setSelectedCategory({})
    };

    const handleCategoryRemove = (index) => {
        pointOfSale.categories.splice(index, 1);
        setPointOfSale({...pointOfSale})
    };

    const handleSaveClick = (data) => {
        addOrEditPintOfSale(customerId, data, isEdit, addOrEditPOSResponseHandler, addOrEditPOSErrorHandler)
    };

    const addOrEditPOSResponseHandler = async (response) => {
        let message = isEdit ? newAndEditPOS.EDIT_RESPONSE_MESSAGE : newAndEditPOS.NEW_RESPONSE_MESSAGE;
        notify("success", message);
        for (let user of Object.values(clientsToAssign)) {
            if (!isEdit) {
                const id = response.headers.location.split('/').pop();
                await assignUserToPOS(user.id, id, (response) => assignUserResponseHandler(response, user), (response) =>  assignUserErrorHandler(response, user));
            }
        }
        setTimeout(() => {
            props.history.goBack();
        }, 500)
    };

    const assignUserResponseHandler = (response, user) => {
        notify("success", `${user.email} ${texts.ADMIN_PANEL.ASSIGN_USERS.USER_ASSIGNED}`);
    };

    const assignUserErrorHandler = (err, user) => {
        notify("error", `${user.email} ${texts.ADMIN_PANEL.ASSIGN_USERS.ASSIGN_ERROR}`);
    };

    const addOrEditPOSErrorHandler = (err) => {
        switch (err.response.status) {
            case 400:
                notify("error", err.response.data.parameterViolations[0].message);
                break;
            case 409:
                notify("error", newAndEditPOS.ERROR_MESSAGE);
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <AdminAndVendorPageHeader onClick={toggleSidebar}
                                      text={`${isEdit ? newAndEditPOS.EDIT_HEADER : newAndEditPOS.NEW_HEADER}`}/>

            <div className="p-2">
                <div className="card shadow">
                    <div className="card-header h5 text-primary text-left">
                        {newAndEditPOS.POINT_ADDRESS}
                    </div>
                    <div className="card-body">
                        <InputFieldRow onBlur={(e) => checkAndAddRedBorder(e, pointOfSale)}
                                       label={newAndEditPOS.NAME}
                                       nameAndId={"name"}
                                       defaultValue={pointOfSale.name}
                                       onChange={(e) => handleProfileDataChange(e, pointOfSale, setPointOfSale)}
                                       testId="name"
                                       />

                        <InputFieldRow onBlur={(e) => checkAndAddRedBorder(e, pointOfSale)}
                                       label={newAndEditPOS.COUNTRY}
                                       nameAndId={"country"}
                                       defaultValue={pointOfSale.country}
                                       onChange={(e) => handleProfileDataChange(e, pointOfSale, setPointOfSale)}
                                       testId="country"
                                       />

                        <InputFieldRow onBlur={(e) => checkAndAddRedBorder(e, pointOfSale)}
                                       label={newAndEditPOS.CITY}
                                       nameAndId={"city"}
                                       defaultValue={pointOfSale.city}
                                       onChange={(e) => handleProfileDataChange(e, pointOfSale, setPointOfSale)}
                                       testId="city"
                                       />

                        <InputFieldRow onBlur={(e) => checkAndAddRedBorder(e, pointOfSale)}
                                       label={newAndEditPOS.ADDRESS}
                                       nameAndId={"shippingAddress"}
                                       defaultValue={pointOfSale.shippingAddress}
                                       onChange={(e) => handleProfileDataChange(e, pointOfSale, setPointOfSale)}
                                       testId="shippingAddress"
                                       />

                        <InputFieldRow onBlur={(e) => checkAndAddRedBorder(e, pointOfSale)}
                                       label={newAndEditPOS.PHONE}
                                       nameAndId={"phone"}
                                       defaultValue={pointOfSale.phone}
                                       onChange={(e) => handleProfileDataChange(e, pointOfSale, setPointOfSale)}
                                       testId="phone"
                                       />

                        <InputFieldRow onBlur={(e) => checkAndAddRedBorder(e, pointOfSale)}
                                       label={newAndEditPOS.EMAIL}
                                       nameAndId={"email"}
                                       defaultValue={pointOfSale.email}
                                       onChange={(e) => handleProfileDataChange(e, pointOfSale, setPointOfSale, null, null, true)}
                                       testId="email"
                                       />

                        <InputFieldRow label={newAndEditPOS.NOTES}
                                       type={"textarea"}
                                       nameAndId={"notes"}
                                       defaultValue={pointOfSale.notes}
                                       onChange={(e) => handleProfileDataChange(e, pointOfSale, setPointOfSale)}
                                       testId="notes"
                                       />
                        <div className="text-left w-75 m-auto h4">
                            {newAndEditPOS.CONTACT_PERSON}
                        </div>
                        <InputFieldRow testId="contactPersonFirstName" onBlur={(e) => checkAndAddRedBorder(e, pointOfSale)} label={newAndEditPOS.FIRST_NAME} nameAndId={"contactPersonFirstName"} defaultValue={pointOfSale.contactPersonFirstName}  onChange={(e) => handleProfileDataChange(e, pointOfSale, setPointOfSale)}/>
                        <InputFieldRow testId="contactPersonLastName" onBlur={(e) => checkAndAddRedBorder(e, pointOfSale)}  label={newAndEditPOS.LAST_NAME} nameAndId={"contactPersonLastName"} defaultValue={pointOfSale.contactPersonLastName} onChange={(e) => handleProfileDataChange(e, pointOfSale, setPointOfSale)} />
                    </div>
                </div>
            </div>
            <div className="p-2">
                <div className="card shadow">
                    <div className="card-header h5 text-primary text-left">
                        {newAndEditPOS.CATEGORIES}
                    </div>
                    <div className="card-body" >
                            <div className="d-flex">
                                <div className="w-30">
                                    <Select onChange={handleCategorySelect}
                                            options={categories.filter(category => pointOfSale.categories ? !pointOfSale.categories.some(({id}) => id === category.value) : category)}
                                            />
                                </div>
                                <div className="pl-3">
                                    <button disabled={!selectedCategory.value} onClick={handleAddToClick} className="btn btn-primary">
                                        {texts.NEW_GLOBAL_PRODUCT.PRODUCT_CATEGORIES.ADD_TO_BUTTON}
                                    </button>
                                </div>
                                <div className="ml-5 pl-4 pr-4">
                                    <div className=" pl-2 pr-2 ">
                                        {texts.NEW_GLOBAL_PRODUCT.PRODUCT_CATEGORIES.LIST_OF_ASSIGNED}:
                                    </div>
                                    <div className="text-left border border-dark h5 p-3">
                                        <ol>
                                            {pointOfSale.categories && pointOfSale.categories.map((c, i) => {
                                                return (
                                                    <li>
                                                        {c.name} <i onClick={() => handleCategoryRemove(i)} className="far fa-times-circle c-pointer float-right pt-1 pl-2 "></i>
                                                    </li>
                                                )
                                            })}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div className="p-2">
                <UsersAssignedCard isEdit={isEdit} assignTo={posId} customerId={customerId} type={"pointofsale"} setToAssign={setClientsToAssign} />
            </div>

            <div className="p-5 text-right">
                <button disabled={!checkRequiredFields(pointOfSale, requiredFields)} onClick={() => handleSaveClick(pointOfSale)} className="btn btn-primary">
                    {texts.ADMIN_PANEL.USERS.SAVE_BTN}
                </button>
            </div>
            <AdminAndVendorFooter texts={texts.VENDOR_PAGE.FOOTER}/>
        </div>
    );
};

export default NewAndEditPointOfSale;