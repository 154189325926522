import React from 'react';
import PropTypes from 'prop-types';
import InfoCard from "../InfoCard/InfoCard";
import texts from "../../../Languages/bg";

const InfoCardClient = ({customer, customTitle, isVendor}) => {
    return (
        <InfoCard id={customTitle ? customTitle : "client"} title={customTitle ? customTitle : texts.VENDOR_PANEL.INFO_CLIENT}
                  labels={texts.VENDOR_PANEL.CLIENT_LABELS}>
            <div>
                <span className="text-black"> {customer.companyName} </span>
            </div>
            <div>
                <span className="text-black h-100"> {customer.vatNumber || "..."}  </span>
            </div>
            <div>
                <span className="text-black"> {isVendor ? customer.address : customer.companyAddress} </span>
            </div>
            <div>
                <span className="text-black"> {customer.manager || "..."} </span>
            </div>
            <div>
                <span className="text-black"> {isVendor ? customer.phone :customer.companyPhone} </span>
            </div>
        </InfoCard>
    );
};

InfoCardClient.propTypes = {
    customer: PropTypes.object,
    customTitle: PropTypes.string,
    isVendor: PropTypes.bool
};

export default InfoCardClient;