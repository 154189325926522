import React from 'react';
import PropTypes from 'prop-types';
import './InputFieldRow.css';

const InputFieldRow = ({label, onChange, defaultValue, type, nameAndId, wrapperClasses ,inputClass, onBlur, testId}) => {
    if (type === "textarea") {
        return (
            <div className={`d-flex align-items-center p-2 ${wrapperClasses}`}>
                <label className="w-25 text-right pr-2" htmlFor={nameAndId}> {label}: </label>
                <textarea data-testid={testId} id={nameAndId} name={nameAndId} onChange={onChange}
                       defaultValue={defaultValue} className="form-control ifr-input"/>
            </div>
        )
    } else {
        return (
            <div className={`d-flex align-items-center p-2 ${wrapperClasses}`}>
                <label className="w-25 text-right pr-2" htmlFor={nameAndId}> {label}: </label>
                {
                    type === "number"
                        ?
                        <input data-testid={testId} onBlur={onBlur} autoComplete={"new-password"} min={0} type={type} id={nameAndId} name={nameAndId} onChange={onChange}
                               value={defaultValue} className={`form-control ifr-input ${inputClass}`}/>
                        :
                        <input data-testid={testId} onBlur={onBlur} autoComplete={"new-password"} type={type} id={nameAndId} name={nameAndId} onChange={onChange}
                               value={defaultValue} className={`form-control ifr-input ${inputClass}`}/>
                }
            </div>
        );
    }

};

InputFieldRow.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    type: PropTypes.string,
    nameAndId: PropTypes.string,
    wrapperClasses: PropTypes.string,
    inputClass: PropTypes.string,
    onBlur: PropTypes.func,
    testId: PropTypes.string
};

export default InputFieldRow;