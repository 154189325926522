import React, {useEffect, useState} from 'react';
import AdminAndVendorPageHeader from "../AdminAndVendorPageHeader/AdminAndVendorPageHeader";
import {toggleSidebar} from "../../../utils/SidebarUtils";
import UserProfileForm from "../UserProfileForm/UserProfileForm";
import {getUserData} from "../../../services/LocalStorageServices";
import UsernameAndLogoutSection from "../UsernameAndLogoutSection/UsernameAndLogoutSection";
import {
    editUserProfileErrorHandler, editUserProfileResponseHandler,
    handleErrors,
    handleProfileDataChange, handleProfileSaveClick
} from "../../../utils/UserUtils";
import AdminAndVendorFooter from "../AdmivAndVendorFooter/AdminAndVendorFooter";
import {shouldResetPassword, showNotificationFrorPasswordReset} from "../../../utils/LoginUtils";
import {editUserPasswordResponseHandler} from "../../../utils/PasswordUtils";
import {texts} from '../../../Languages/Language'

const AdminAndVendorProfile = props => {
    const [passwords, setPasswords] = useState({password: "", repeat_password: ""});
    const [user, setUser] = useState(getUserData());
    const resetPassword = shouldResetPassword(props);

    const handleSaveClick = (userData) => {
      handleProfileSaveClick(userData, passwords, editUserResponseHandler, editUserErrorHandler)
    };

    useEffect(() => {
        if (resetPassword) {
            showNotificationFrorPasswordReset(texts)
        }
    }, []);

    const editUserResponseHandler = (response, updatePassword) => {
        editUserProfileResponseHandler(response, updatePassword, setUser, passwords, editPasswordResponseHandler, editPasswordErrorHandler, props)
    };

    const editUserErrorHandler = (error) => {
        editUserProfileErrorHandler(error, passwords, user.id, editPasswordResponseHandler, editPasswordErrorHandler)
    };

    const editPasswordResponseHandler = (response) => {
        editUserPasswordResponseHandler(response, resetPassword, props)
    };

    const editPasswordErrorHandler = (err) => {
        handleErrors(err, texts)
    };

    return (
        <div>
            <AdminAndVendorPageHeader onClick={toggleSidebar}
                                      text={`${texts.ADMIN_PANEL.USERS.PROFILES} - профил на потребител`}/>

            <UsernameAndLogoutSection user={user} />
            <div className="p-1 p-md-4">
                <div className="card shadow">
                    <div className="card-header h4 text-primary text-left">
                        {texts.ADMIN_PANEL.USERS.PROFILE_INFO}
                    </div>
                    <div className="card-body p-0">
                        <UserProfileForm user={user} onChange={(e, shouldTrim) => handleProfileDataChange(e, user, setUser, passwords, setPasswords, shouldTrim)} />
                        <div className="p-5 text-right">
                            <button onClick={() => handleSaveClick(user)} className="btn btn-primary">
                                {texts.ADMIN_PANEL.USERS.SAVE_BTN}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <AdminAndVendorFooter texts={texts.VENDOR_PAGE.FOOTER} />
        </div>
    );
};

export default AdminAndVendorProfile;