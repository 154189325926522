import React from 'react';
import PropTypes from 'prop-types';
import './SearchSection.css';
import SearchIcon from "../../../../assets/search_icon.svg";

const SearchSection = ({onKeyPress, onSearchClick, reference, onClearClick}) => {
    return (
        <div className="sc-search">
            <label htmlFor="sov-search-input" className="float-left w-100 d-flex">
                <img onClick={onSearchClick} data-testid="sov-search-icon" src={SearchIcon} alt="" className="pl-2 c-pointer"/>
                <input ref={reference} onKeyDown={onKeyPress} data-testid="sov-search" type="text" name="sov-search-input" className=""/>
                <i onClick={onClearClick} data-testid="sov-search-clear" className="fas fa-times text-gray-700 m-auto c-pointer pr-2"></i>
            </label>
        </div>
    );
};

SearchSection.propTypes = {
    onEnterPress: PropTypes.func,
    onSearchClick: PropTypes.func,
    reference: PropTypes.object,
    onCLearClick: PropTypes.func
};

export default SearchSection;