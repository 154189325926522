import React from "react";
import PropTypes from "prop-types";
import texts from '../../../Languages/bg'
import {Link} from "react-router-dom";

const PopupButtons = ({isExport, isDownload, isPrint, handleDownload, handlePrint, handleExport, order}) => {

    if (order !== null) {
        return (
            <>
                {!isExport && <div className="text-center">
                    <div className="p-4">
                        <Link data-testid={"popup-link-ov"} to={`/vendor/order/${order.id}`}>
                            <button type="button" className="btn btn-warning">
                                <i className="icon text-white-50 pr-1 fas fa-eye"/>
                                <span className="text">{texts.VENDOR_PANEL.POPUP_INFO.VIEW_ORDER_BTN}</span>
                            </button>
                        </Link>
                    </div>

                    <div>
                        {isDownload && <button data-testid={"popup-btn-download"} onClick={handleDownload} className="btn btn-success">
                            <i className="icon text-white-50 pr-1 fas fa-check"/>
                            <span className="text">
                        {texts.VENDOR_PANEL.POPUP_INFO.CONFIRM_BTN}
                                <i className="icon text-white-50 pl-2 pr-1 fas fa-file-pdf"/>
                                {texts.VENDOR_PANEL.POPUP_INFO.CONFIRM_BTN_DOWNLOAD}
                    </span>
                        </button>}
                        {isPrint && <button data-testid={"popup-btn-print"} onClick={handlePrint} className="btn btn-success">
                            <i className="icon text-white-50 pr-1">
                                <i className="fas fa-check"></i>
                            </i>
                            <span className="text">
                        {texts.VENDOR_PANEL.POPUP_INFO.CONFIRM_BTN}
                                <i className="icon text-white-50 pl-2 pr-1 fas fa-print"/>
                                {texts.VENDOR_PANEL.POPUP_INFO.CONFIRM_BTN_PRINT}
                    </span>
                        </button>}
                    </div>
                </div>}
        </>);
    }

    return (
        <>
            {isExport && <div className="text-center">
                <button onClick={handleExport} className="btn btn-success">
                    <span className="text">{texts.VENDOR_PANEL.POPUP_INFO.SEND_INQUIRY_BTN}</span>
                </button>
            </div>}
        </>
    );

};

PopupButtons.propTypes = {
    isExport: PropTypes.bool,
    isDownload: PropTypes.bool,
    isPrint: PropTypes.bool,
    handleDownload: PropTypes.func,
    handlePrint: PropTypes.func,
    handleExport: PropTypes.func,
    order: PropTypes.object
};

export default PopupButtons;