import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AdminAndVendorPageHeader from "../../common/AdminAndVendorPageHeader/AdminAndVendorPageHeader";
import {toggleSidebar} from "../../../utils/SidebarUtils";
import {texts} from "../../../Languages/Language";
import {Link} from 'react-router-dom';
import AdminAndVendorFooter from "../../common/AdmivAndVendorFooter/AdminAndVendorFooter";
import {ROUTES} from "../../../config";
import {sortData} from "../../../utils/Utils";
import Spinner from "react-bootstrap/Spinner";

const ListCustomersAndVendors = ({isVendors, partners}) => {

    const adminClientsTexts = texts.ADMIN_PANEL.CLIENTS;
    const tableHeaders = isVendors ? adminClientsTexts.TABLE_HEADERS_VENDOR : adminClientsTexts.TABLE_HEADERS_CUSTOMER;

    const [data, setData] = useState([]);
    const [isAscending, setIsAscending] = useState(true);
    const [isLoading, setIsLoading] = useState(partners.length > 0);

    useEffect(() => {
        setData(partners);
        setIsLoading(partners.length > 0)
    }, [partners]);

    const sortPartners = (keyIndex) => {
        setIsAscending(!isAscending);
        sortData(data, isAscending, tableHeaders[keyIndex].id);
        setData([...data]);
    };

    return (
        <div>
           <AdminAndVendorPageHeader onClick={toggleSidebar} text={isVendors ? adminClientsTexts.VENDOR_LIST : adminClientsTexts.CUSTOMER_LIST}/>

           <div className="p-5 text-right">
               <Link to={isVendors ? ROUTES.ADMIN.NEW_EDIT_VENDOR_ROUTE : ROUTES.ADMIN.NEW_EDIT_CUSTOMER_ROUTE} className="btn btn-primary">
                   {isVendors ? adminClientsTexts.NEW_VENDOR : adminClientsTexts.NEW_CUSTOMER}
               </Link>
           </div>

            <div className="p-5">
                <div className="card shadow">
                    <div className="card-header text-primary h5 text-left">
                        {isVendors ? adminClientsTexts.VENDOR_LIST : adminClientsTexts.CUSTOMER_LIST}
                    </div>
                    <div className="card-body">
                        {((() => {
                            if  (!isLoading) {
                                return <Spinner animation={"border"} variant={"primary"} />
                            } else {
                                return (
                                    <table className="table table-sm table-striped table-bordered">
                                        <thead className="bg-primary text-white">
                                        <tr>
                                            {tableHeaders.map((header, headerIndex) => (
                                                <th>
                                                    {header.label}
                                                    {headerIndex < tableHeaders.length - 1 &&
                                                        <i data-testid="lcv-sort" onClick={() => sortPartners(headerIndex)} className="fas fa-sort float-right pt-1 pl-2"/>
                                                    }
                                                </th>
                                            ))}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {data.map(partner => {
                                            return <tr>
                                                <td data-testid="lcv-id">{partner.id}</td>
                                                <td data-testid="lcv-name">{isVendors ? partner.vendorName : partner.companyName}</td>
                                                <td data-testid="lcv-status">{(()=> {
                                                    if (isVendors) {
                                                        return partner.isEnabled ? adminClientsTexts.ACTIVE : adminClientsTexts.INACTIVE;
                                                    } else {
                                                        return adminClientsTexts.ACTIVE
                                                    }
                                                })()}</td>
                                                <td> <Link to={isVendors ? ROUTES.ADMIN.NEW_EDIT_VENDOR_ROUTE + `?id=${partner.id}` : ROUTES.ADMIN.NEW_EDIT_CUSTOMER_ROUTE  + `?id=${partner.id}`}>{texts.ADMIN_PANEL.NEW_EDIT_CUSTOMER.EDIT}</Link> </td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </table>
                                )
                            }
                        })())}
                    </div>
                </div>
            </div>

            <AdminAndVendorFooter texts={texts.VENDOR_PAGE.FOOTER} />
        </div>
    );
};

ListCustomersAndVendors.propTypes = {
    isVendors: PropTypes.bool,
    users: PropTypes.object
};

export default ListCustomersAndVendors;