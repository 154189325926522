import React from 'react';
import PropTypes from 'prop-types';
import InfoCard from "../InfoCard/InfoCard";
import texts from "../../../Languages/bg";

const InfoCardTotals = ({order, orderTotals}) => {
    return (
        <InfoCard customBody id="total" title={texts.VENDOR_PANEL.INFO_TOTAL}>
            <div className="w-lg-35 text-left">
                <div>
                    <span className=" font-weight-bold"> {order.orderItems && order.orderItems.length + order.customOrderItems.length} </span>
                    {texts.VENDOR_PAGE.SHOPPING_CART.CART.ITEMS}
                </div>
                <div>
                    <span className=" font-weight-bold"> {orderTotals.totalItemsQuantity + orderTotals.customItemsQuantity} </span> {texts.VENDOR_PAGE.SHOPPING_CART.UNITS}
                </div>
            </div>
            <div className="w-lg-65 text-left h5">
                <div className="d-flex justify-content-between">
                    <div> {texts.VENDOR_PAGE.SHOPPING_CART.TOTAL_PRICE} </div>
                    <div className=""> {orderTotals.totalPrice} {texts.VENDOR_PAGE.PRODUCT_CARD.CURRENCY}</div>
                </div>

                <div className="d-flex justify-content-between">
                    <div> {texts.VENDOR_PAGE.SHOPPING_CART.VAT} </div>
                    <div> {orderTotals.vat} {texts.VENDOR_PAGE.PRODUCT_CARD.CURRENCY}</div>
                </div>
                <div className="d-flex justify-content-between border-top mt-2 pt-1 font-weight-bold">
                    <div> {texts.VENDOR_PAGE.SHOPPING_CART.TOTAL_PRICE_VAT} </div>
                    <div> {orderTotals.totalPriceWithVat} {texts.VENDOR_PAGE.SHOPPING_CART.CART.CURRENCY} </div>
                </div>
            </div>
        </InfoCard>
    );
};

InfoCardTotals.propTypes = {
    order: PropTypes.object,
    orderTotals: PropTypes.object
};

export default InfoCardTotals;