import React from 'react';
import PropTypes from 'prop-types';

const UploadImageButton = ({buttonText, onChange, name, id, testId}) => {
    return (
        <div className="ml-4">
            <label htmlFor={id} className="btn btn-primary c-pointer">{buttonText}</label>
            <input data-testid={testId} type="file" name={name} onChange={onChange} id={id} className="btn btn-primary input-image"/>
        </div>
    );
};

UploadImageButton.propTypes = {
    buttonText: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    id: PropTypes.string,
    testId: PropTypes.string
};

export default UploadImageButton;