import PhoneIcon from "../assets/phone-square-alt-solid.svg";
import React from "react";

export const renderPhoneLinks = (phones) => {
  if (phones) {
      return phones.split('\n').map(phone => (
          <p key={phone} className="text-left">
              <a className="clear-link-style" href={"tel:" + phone}> <img className={"phone-icon"} src={PhoneIcon} alt=""/> {phone} </a>
          </p>
      ))
  }
};

export const renderWorkingHours = (workingHoursString, index, isBold = false) => {
    let workingHoursSubstring = workingHoursString.substr(3, workingHoursString.length - 1);
    let workingHours = isBold ? <span className="font-weight-bold">{workingHoursSubstring}</span> : workingHoursSubstring;

    return <div key={"wh" + index}>{workingHoursString.substr(0, 3)}: {workingHours}</div>
}