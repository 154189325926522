import React from 'react';
import PropTypes from 'prop-types';
import './RateOrder.css'
import Rating from "react-rating";
import EmptyStar from "../../../../assets/star-empty.svg";
import YellowStar from "../../../../assets/star-full.svg";

const RateOrder = ({onClick, initialRating}) => {
    return (
        <Rating readonly={initialRating}
                initialRating={initialRating}
                onClick={onClick}
                emptySymbol={<img style={{"width": "1.2em", "marginRight": "5px"}} src={EmptyStar} alt="rate-order" />}
                fullSymbol={<img style={{"width": "1.2em","marginRight": "5px"}} src={YellowStar} alt="rate-order" />}  />
    );
};

RateOrder.propTypes = {
    onClick: PropTypes.func,
    initialRating: PropTypes.number
};

export default RateOrder;