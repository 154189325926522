import React from 'react';
import PropTypes from 'prop-types';
import { texts } from '../../../Languages/Language'
import { displayVendorProductPackages } from '../../../utils/GlobalProductUtils';
import { optionsCreate, productDoesntHavePackageWithUnits } from '../../../utils/VendorProductUtils';

const VendorProductTableRow = ({
    vendorProduct, vendorProductIndex, row, vendorProductOps,
    onVendorProductChange, onEditOrSaveClick, onVendorOpsChange
}) => (
    <tr className={`row-centered ${vendorProductOps.find(e => e.id === vendorProduct.id) && "bg-warning"}`}>
        <td hidden={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.ERP_ID.isHidden}
            id={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.ERP_ID.ID}>
            {vendorProduct.erpid}
        </td>
        <td hidden={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.VENDOR_NAME.isHidden}
            id={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.VENDOR_NAME.ID}>
            {vendorProduct.vendorName}
        </td>
        <td style={{ width: "400px" }}
            hidden={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.ITEM_NAME.isHidden}
            id={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.ITEM_NAME.ID}>
            {vendorProduct.productName}
        </td>
        <td hidden={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.SINGLE_PRICE.isHidden}
            id={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.SINGLE_PRICE.ID}>
            {vendorProduct.packagingList[0] && vendorProduct.packagingList[0].singleItemPriceWithVAT}
        </td>
        <td hidden={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.DU.isHidden}
            id={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.DU.ID}>

            {displayVendorProductPackages(vendorProduct.packagingList[0], vendorProduct, vendorProductIndex, "units", 0, onVendorProductChange)}
        </td>
        <td hidden={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.DU_PRICE.isHidden}
            id={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.DU_PRICE.ID}>
            {displayVendorProductPackages(vendorProduct.packagingList[0], vendorProduct, vendorProductIndex, "price", 0, onVendorProductChange)}
        </td>
        <td hidden={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.DU2.isHidden}
            id={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.DU2.ID}>
            {displayVendorProductPackages(vendorProduct.packagingList[1], vendorProduct, vendorProductIndex, "units", 1, onVendorProductChange)}
        </td>
        <td hidden={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.DU2_PRICE.isHidden}
            id={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.DU2_PRICE.ID}>
            {displayVendorProductPackages(vendorProduct.packagingList[1], vendorProduct, vendorProductIndex, "price", 1, onVendorProductChange)}
        </td>
        <td hidden={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.DU3.isHidden}
            id={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.DU3.ID}>
            {displayVendorProductPackages(vendorProduct.packagingList[2], vendorProduct, vendorProductIndex, "units", 2, onVendorProductChange)}
        </td>
        <td hidden={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.DU3_PRICE.isHidden}
            id={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.DU3_PRICE.ID}>
            {displayVendorProductPackages(vendorProduct.packagingList[2], vendorProduct, vendorProductIndex, "price", 2, onVendorProductChange)}
        </td>
        <td hidden={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.AVAILABILITY.isHidden}
            id={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.AVAILABILITY.ID}>

            <select disabled={!vendorProduct.isEditable} id="product-status-change" data-testid="vproduct-status-change"
                className={`${!vendorProduct.isEditable && "bg-none border-0 hideArrow"}`}
                onChange={(e) => onVendorProductChange(e, null, vendorProduct, vendorProductIndex, row.original.product)}
                value={vendorProduct.productStatus}
                name="product-status-change">
                {optionsCreate(vendorProduct.productStatus)}
            </select>
            <div className="hidden-vp-text ">{!vendorProduct.isEnabled && texts.ADMIN_PANEL.HIDDEN_PRODUCT}</div>
        </td>

        <td hidden={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.ORDERED.isHidden}
            id={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.ORDERED.ID}>
            {vendorProduct.orderCount}
        </td>
        <td hidden={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.STATUS.isHidden}
            id={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.STATUS.ID}>
            <span>
                {texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.UPDATED}:
                                                        </span>
            <div>
                {vendorProduct.lastUpdated}
            </div>
        </td>
        <td hidden={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.PROMO.isHidden}
            id={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.PROMO.ID}>
            6+15
                                                    </td>
        <td hidden={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.ACTIONS.isHidden}
            id={texts.ADMIN_PANEL.ADMIN_PRODUCTS_SUB_TABLE.ACTIONS.ID}>
            <div className="text-primary">
                <button data-testid={"vp-save-edit"} disabled={vendorProduct.isEditable && productDoesntHavePackageWithUnits(vendorProduct)}
                    onClick={vendorProduct.isEditable ? () => onEditOrSaveClick(vendorProduct, vendorProductIndex, true) : () => onEditOrSaveClick(vendorProduct, vendorProductIndex, false, row.original.product)}
                    className="btn btn-sm btn-outline-primary  border-radius-5 mb-2">
                    {vendorProduct.isEditable ? texts.VENDOR_PANEL.SAVE_BTN_TEXT : texts.VENDOR_PANEL.EDIT_BTN_TEXT}
                </button>
            </div>
            <select onChange={(e) => onVendorOpsChange(e, vendorProduct, vendorProductIndex)} data-testid={"vendor-op-select" + vendorProduct.id}
                className="width-100px" name="" id="" defaultValue="actions">
                <option value="actions" hidden> {texts.ADMIN_PANEL.ACTIONS_SELECT_PLACEHOLDER} </option>
                <option disabled={vendorProduct.isEnabled} hidden={vendorProduct.isEnabled} value="enable">{texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.ENABLE}</option>
                <option disabled={!vendorProduct.isEnabled} hidden={!vendorProduct.isEnabled} value="disable">{texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.DISABLE}</option>
                <option value="match">{texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.MATCH_UNMATCH}</option>
            </select>
        </td>
    </tr>
);

VendorProductTableRow.propTypes = {
    vendorProduct: PropTypes.object,
    vendorProductIndex: PropTypes.number,
    row: PropTypes.object,
    vendorProductOps: PropTypes.array,
    onVendorProductChange: PropTypes.func,
    onEditOrSaveClick: PropTypes.func,
    onVendorOpsChange: PropTypes.func
};

export default VendorProductTableRow;