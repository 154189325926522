import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types'
import UploadSection from "./UploadSection/UploadSection";
import Resizer from "react-image-file-resizer";
import {CORS_PROXY} from "../../../../config";
import {notify} from '../../../../utils/Notifications';

const ProductImagesSection = ({texts, onChange, images, fromMatch}) => {
    const [image1, setImage1] = useState();
    const [image2, setImage2] = useState();

    const checkIsString = (element) => {
        return typeof element === "string"
    };

    useEffect(() => {
        if (checkIsString(images.image1)) {
            setImage1(images.image1);
            if (fromMatch) {
                getImageBlob(images.image1, "image1")
            }
        }
        if (checkIsString(images.image2)) {
            setImage2(images.image2);
            if (fromMatch) {
                getImageBlob(images.image2, "image2")
            }
        }

    }, [images]);

    const getImageBlob = async (link, imageNumber) => {
        if (link?.length > 0) {
            try {
                let response = await fetch(CORS_PROXY + link, { method: "GET"});
                if (response.status === 200) {
                    let blob = await response.blob();
                    onChange(blob, imageNumber);
                } else {
                    notify('warning', null, `${texts.IMAGE} ${imageNumber} ${texts.CANNOT_BE_DOWNLOADED}`, 6000);
                }
            } catch (error) { }
        }
    };

    const updateImage = (event) => {
        let name = event.target.name;
        Resizer.imageFileResizer(
            event.target.files[0],
            400,
            400,
            'PNG',
            100,
            0,
            uri => {
                if (name === "image1") {
                    setImage1(uri);
                } else {
                    setImage2(uri)
                }
            },
            'base64'
        );

        onChange(event, name)
    };

    return (
        <div>
            <UploadSection texts={texts}
                           number={1}
                           name="image1"
                           onChange={updateImage}
                           image={image1}
                           info={[texts.NO_IMAGE_MESSAGE]}
                           id="input-image1"
                           testId="gp-image-1"
            />

            <UploadSection texts={texts}
                           name="image2"
                           number={2}
                           onChange={updateImage}
                           image={image2}
                           id="input-image2"
                           info={[texts.NO_IMAGE_MESSAGE]}
                           testId="gp-image-2"
                           />
        </div>
    );
};

ProductImagesSection.propTypes = {
    texts: PropTypes.object,
    onChange: PropTypes.func,
    images: PropTypes.object,
    fromMatch: PropTypes.bool
};

export default ProductImagesSection;