import {backendRequest} from "../utils/backend";

export const getCustomerPointsOfSale = (customerId) => {
    return backendRequest.get(`/customer/${customerId}/pointofsale`);
};

export const getCustomerByIdAsync = (customerId) => {
    return backendRequest.get(`/customer/${customerId}`);
};

export const getAllCustomers = () => {
    return backendRequest.get(`/customer`); 
}; 

export const addOrEditCustomer = (data) => {
    return backendRequest.put(`/customer`, data) 
};

export const addOrEditPintOfSaleAsync = (id, data, isEdit) => {
    let method = isEdit ? 'put' : "post";
    return backendRequest[method](`/customer/${id}/pointofsale`, data)
};

export const assignVendorToCustomerAsync = (customerId, vendorId) => {
    return backendRequest.put(`/customer/${customerId}/vendor/${vendorId}`)
};

export const unassignVendorFromCustomerAsync = (customerId, vendorId) => {
    return backendRequest.delete(`/customer/${customerId}/vendor/${vendorId}`)
};