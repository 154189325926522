import React from 'react';
import PropTypes from 'prop-types';
import './RateOrderSection.css';
import RateOrder from "../RateOrder/RateOrder";
import {RATING_CRITERIA} from "../../../../config";

const RateOrderSection = ({texts, order, rateHandler}) => {

    const ratings = {
        delivery: order.orderRatings.find(e => e.ratingCriteria === RATING_CRITERIA.DELIVERY),
        quality: order.orderRatings.find(e => e.ratingCriteria === RATING_CRITERIA.QUALITY),
        quantity: order.orderRatings.find(e => e.ratingCriteria === RATING_CRITERIA.QUANTITY)
    };

    return (
        <div>
            <div className="ov-rating-title-wrapper">
                <div className="ov-rating-title">
                    {texts.ORDERS_PAGE.RATE_TITLE}
                </div>
            </div>
            <div className="rate-separator"></div>
            <div>
                <div className="ov-rate-criteria">
                    {texts.ORDERS_PAGE.RATE_DELIVERY}
                </div>
                <RateOrder initialRating={ratings.delivery && ratings.delivery.rating} onClick={(event) => rateHandler(event, RATING_CRITERIA.DELIVERY)}  />
            </div>
            <div className="rate-separator"></div>
            <div>
                <div className="ov-rate-criteria">
                    {texts.ORDERS_PAGE.RATE_QUALITY}
                </div>
                <RateOrder initialRating={ratings.quality && ratings.quality.rating} onClick={(event) => rateHandler(event, RATING_CRITERIA.QUALITY)} />
            </div>
            <div className="rate-separator"></div>
            <div>
                <div className="ov-rate-criteria">
                    {texts.ORDERS_PAGE.RATE_QUANTITY}
                </div>
                <RateOrder initialRating={ratings.quantity && ratings.quantity.rating} onClick={(event) => rateHandler(event, RATING_CRITERIA.QUANTITY)}/>
            </div>
            <div className="rate-separator"></div>
        </div>

    );
};

RateOrderSection.propTypes = {
    texts: PropTypes.object,
    order: PropTypes.object,
    rateHandler: PropTypes.func
};

export default RateOrderSection;