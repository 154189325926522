import React, {useEffect, useState, useRef, useCallback} from 'react';
import {
    deleteOrder,
    makeOrderAsync, placeOrder,
    deleteProductFromOrder, addCustomProduct, deleteCustomProduct, changeOrder, getOrderByIdAndRoleAsync
} from "../../../services/OrderServices";
import {attachActiveAttribute} from "../../../utils/VendorProductUtils";
import DeleteButton from "../ShoppingCart/DeleteButton/DeleteButton";
import OrderButton from "../ShoppingCart/OrderButton/OrderButton";
import AddProductsText from "../ShoppingCart/AddProductsText/AddProductsText";
import SearchSection from "../ShoppingCart/SearchSection/SearchSection";
import CategoriesToggler from "../ShoppingCart/CategoriesToggler/CategoriesToggler";
import ProductCardList from "../ProducrCardList/ProductCardList";
import DeliverySection from "../ShoppingCart/DeliverySection/DeliverySection";
import OrderTotalSection from "../OrderTotalSection/OrderTotalSection";
import VendorInfo from "../ShoppingCart/VendorInfo/VendorInfo";
import Cart from "../ShoppingCart/Cart/Cart";
import CustomerPageHeader from "../CustomerPageHeader/CustomerPageHeader";
import TitleSection from "../TitleSection/TitleSection";
import GoBackSection from "../GoBackSection/GoBackSection";
import './SingleOrderView.css'
import {Modal} from "react-bootstrap";
import DeleteModalBody from "../ShoppingCart/DeleteModalBody/DeleteModalBody";
import {getPointsOfSaleForUserAsync} from "../../../services/UserServices";
import Footer from "../Footer/Footer";
import CaretUp from '../../../assets/caret-up-solid.svg';
import {
    formatOrderDecimalQuantity,
    formatPrice,
    getOrderTotals,
    handleOrderUpdateEvent
} from "../../../utils/OrderUtils";
import ChangeQuantityButton from "../ChangeQuantityButton/ChangeQuantityButton";
import CustomProductCard from "./CustomProductCard/CustomProductCard";
import {MEASURE_UNITS, ORDER_STATUSES, PRODUCT_TYPE, ROUTES} from "../../../config";
import Drawer from "../Drawer/Drawer";
import CloseIcon from "../../../assets/cancel.svg";
import Categories from "../Categories/Categories";
import {getVendorCategoriesById} from "../../../services/CategoryServices";
import {isLargeScreen, scrollToTop} from "../../../utils/CheckWindowWidth";
import {notify} from "../../../utils/Notifications";
import {texts as language} from '../../../Languages/Language';
import _ from 'lodash';
import { backendRequest } from '../../../utils/backend';
import { getVendorByIdAsync } from '../../../utils/VendorUtils';

const SingleOrderView = props => {
    const initialCustomProduct = {quantity: 1, measureUnit: MEASURE_UNITS[0], comment: "", itemName: ""};
    const texts = language.VENDOR_PAGE;
    const [order, setOrder] = useState();
    const [cartData, setCartData] = useState({});
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [placeOrderModalVisible, setPlaceOrderModalVisible] = useState(false);
    const [addItemModalVisible, setAddItemModalVisible] = useState(false);
    const [pointsOfSale, setPointsOfSale] = useState([]);
    const [pointOfSaleSelectModalVisible, setPointOfSaleSelectModalVisible] = useState(false)
    const [selectedPointOfSale, setSelectedPointOfSale] = useState({});
    const [orderComment, setOrderComment] = useState("");
    const [deleteItemModalVisible, setDeleteItemModalVisible] = useState(false);
    const [itemToDelete, setItemToDelete] = useState({});
    const [addCommentModalVisible, setAddCommentModalVisible] = useState(false);
    const [orderToUpdate, setOrderToUpdate] = useState();
    const [vendorData, setVendorData] = useState();
    const [customProduct, setCustomProduct] = useState(initialCustomProduct);
    const [showNotAddedProducts, setShowNotAddedProducts] = useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [categories, setCategories] = useState();
    const [orderTotals, setOrderTotals] = useState({});
    const [waitBeforeSend, setWaitBeforeSend] = useState(false);

    const searchRef = useRef(null);

    useEffect(() => {
        scrollToTop();
        setCartData(props.location.state);
        getVendorByIdAsync(props.location.state.vendorId, setVendorData);
        getOrder(props.location.state.id);
        getPointsOfSale();
        getCategories(props.location.state.vendorId);
     
        backendRequest.interceptors.response.use(response => {
            if (response.config.method !== "get") {
                setWaitBeforeSend(false);
            }
            return response;
        }, error => {
            if (error.response.config.method !== "get") {
                setWaitBeforeSend(false);
            }
            return error
        });
        
        return () => {
            backendRequest.interceptors.response.use(response => response)  
        }
    }, []);

    useEffect(() => {
       if (order) {
           if (order.orderItems.length === 0 && order.customOrderItems.length === 0) {
               handleDeleteOrder(order.id)
           }
           setOrderTotals(getOrderTotals(order))
       }
    }, [order]);

    const getCategories = async (vendorId) => {
        try {
            let response = await getVendorCategoriesById(vendorId);
            setCategories(response.data)
        } catch (error) {
            console.log("Error in getting categories");
        }
    };

    const getPointsOfSale = async () => {
        try {
            let response = await getPointsOfSaleForUserAsync();
            setPointsOfSale(response.data);
            setSelectedPointOfSale(response.data[0])
        } catch (error) {
            console.log("Error in getting points of sale");
        }
    };

    const orderCustomProduct = async (vendorId, customProduct) => {
        try {
            await addCustomProduct(vendorId, customProduct);
            getOrder(order.id);
            setAddItemModalVisible(false);
            setCustomProduct(initialCustomProduct);
        } catch (error) {
            console.log("Error in ordering custom product");
        }
    };

    const updateOrder = async (vendorId, orderedProduct, onSuccess) => {
        try {
            let response = await makeOrderAsync(vendorId, orderedProduct);
            onSuccess(response)
            
        } catch (error) {
            console.log("Error with sending the order");
        }
    };

    const getOrder = async (orderId) => {
        try {
            let response = await getOrderByIdAndRoleAsync(orderId);
            getOrderResponseHandler(response.data);
        } catch (error) {
            getOrderErrorHandler(error);
        }
    };

    const deleteProduct = async (vendorProductId) => {
        try {
            await deleteProductFromOrder(order.id, vendorProductId);
        } catch (error) {
            console.log("Error with deleting the product fron the order")
        }
    };

    const deleteWholeOrder = async (orderId) => {
        try {
            await  deleteOrder(orderId);
            let data = {...cartData};
            let num = data.cartsCount;
            data.cartsCount = num - 1;
            setCartData(data);
            if (data.cartsCount <= 0) {
                props.history.push({pathname: ROUTES.CUSTOMER.VENDOR_PAGE, state: {id: cartData.vendorId}})
            } else {
                props.history.push({pathname: ROUTES.CUSTOMER.SHOPPING_CART})
            }
        } catch (error) {
            console.log("Error with deleting the order");
        }
    };

    const sendOrder = async () => {
        try {
            await placeOrder(order.id, selectedPointOfSale.id, orderComment);
            setPlaceOrderModalVisible(true)
        } catch (error) {
            placeOrderErrorHandler(error);
        }
    };

    const addCustomItemInputRef = React.useRef();

    const getOrderResponseHandler = (response) => {
        let totals = getOrderTotals(response);
        if (response.status === ORDER_STATUSES.INITIAL) {
            setOrder(response);
            setOrderComment(response.comment);
            setOrderTotals(totals);
        } else {
            props.history.go(-2)
        }
    };

    const openCategoriesDrawer = () => {
        setIsDrawerOpen(true);
        document.body.classList.add("hide-scroll")
    };

    const closeCategoriesDrawer = () => {
        setIsDrawerOpen(false);
        document.body.classList.remove("hide-scroll")
    };

    const getOrderErrorHandler = (err) => {
        if (err.response && err.response.status === 404) {
            props.history.goBack()
        }
    };

    const debouncedUpdateOrder = useCallback(_.debounce(updateOrder, 400), []);

    const handleUpdateCart = (event, vendorProductId) => {
        setWaitBeforeSend(true);
        let initialOrder = {...order};
        let productsInOrder = [...initialOrder.orderItems];
        let orderedProduct = productsInOrder.find((product) => product.id === vendorProductId);
        let index = productsInOrder.indexOf(orderedProduct);

        handleOrderUpdateEvent(event, orderedProduct);

        productsInOrder[index] = orderedProduct;
        initialOrder.orderItems = productsInOrder;
        setOrder(initialOrder);

        if (orderedProduct.quantity.toString() ===  "0" &&  event.target.name !== "set-quantity") {
            productsInOrder.splice(index, 1);
            deleteProduct(orderedProduct.vendorProductId);
            debouncedUpdateOrder.cancel()
        } else if (orderedProduct.quantity > 0) {
            formatOrderDecimalQuantity(orderedProduct);
            debouncedUpdateOrder(order.vendorId, orderedProduct, () => getOrder(order.id));
        }
    };

    const handlePackageChange = (dataIndex, packageIndex, packId) => {
        let initialOrder = {...order}
        let ordersArr = [...initialOrder.orderItems];
        let ord = ordersArr[dataIndex]
        let index = ordersArr.indexOf(ord);
        let prevPackage = {...ord.selectedPackage};
        let otherPackages = [...ord.otherPackages];
        let newPack = otherPackages.find(p => p.id === packId);
        let newPackIdx = otherPackages.indexOf(newPack);
        ord.selectedPackage = {...newPack};
        otherPackages.splice(newPackIdx, 1);
        otherPackages.push(prevPackage);
        ord.otherPackages = otherPackages;
        ordersArr[index] = ord;
        initialOrder.orderItems = ordersArr;
        setOrder(initialOrder);
        setWaitBeforeSend(true);
        updateOrder(order.vendorId, ord)
    };
    
    const handleDeleteOrder = (id) => {
        setWaitBeforeSend(true);
        deleteWholeOrder(id);
    };

    const handlePlaceOrder = async() => {
        let minimalOrder = vendorData.minimalOrder ?? 0;
        if (pointsOfSale.length > 0) {
            if (orderTotals.totalPriceWithVat >= minimalOrder) {
                if (order.orderItems.length > 0) {
                    setWaitBeforeSend(true);
                    sendOrder()
                } else {
                    notify("warning", texts.ADD_ITEM_WARN)
                }
            } else {
                notify("info", texts.INSUFFICIENT_AMOUNT, `${texts.MINIMAL_AMOUNT_LABEL} ${texts.NOTIFICATIONS_PAGE.IS} ${vendorData.minimalOrder} ${texts.PRODUCT_CARD.CURRENCY}.`, 10000)
            }
        } else {
            notify("warning", texts.UNSUCCESSFUL_ORDER, texts.CAN_NOT_PLACE_ORDER, 6000)
        }
    };

    const placeOrderErrorHandler = (error) => {
        if (error.response) {
            let status = error.response.status;
            switch (status) {
                case 400:
                    notify("error", texts.PLACE_ORDER_ERROR_400);
                    break;
                case 404:
                    notify("error", texts.PLACE_ORDER_ERROR_404);
                    break;
                case 409:
                    notify("error", texts.PLACE_ORDER_ERROR_409);
                    break;
                default:
                    break;
            }
        }
    };

    const closePlaceOrderModalAndRedirect = () => {
        setPlaceOrderModalVisible(false);
        props.history.push(ROUTES.CUSTOMER.SHOPPING_CART);
    };

    const handleSearchEnterPress = (event) => {
        if (event.key === "Enter") {
            props.history.push({
                pathname: ROUTES.CUSTOMER.VENDOR_PAGE,
                state: {
                    id: order.vendorId,
                    search: event.target.value
                }
            })
        }
    };

    const handleSearchPress = () => {
        props.history.push({
            pathname: ROUTES.CUSTOMER.VENDOR_PAGE,
            state: {
                id: order.vendorId,
                search: searchRef.current.value
            }
        })
    };

    const selectPointOfSale = (point) => {
        setSelectedPointOfSale(point);
        setPointOfSaleSelectModalVisible(false)
    };

    const handleOrderCommentChange = (event) => {
        setOrderComment(event.target.value)
    };

    const openConfirmProductDeleteModal = (id, name, productType) => {
        setItemToDelete({id: id, name: name, productType:productType});
        setDeleteItemModalVisible(true)
    };

    const handleDeleteProductFromOrder = async () => {
        let initialOrder = { ...order };
        let orderItems = [...initialOrder.orderItems];
        let ord = orderItems.find(o => o.vendorProductId === itemToDelete.id);
        let index = orderItems.indexOf(ord);
        orderItems.splice(index, 1);
        initialOrder.orderItems = orderItems;
        setOrder(initialOrder);
        setDeleteItemModalVisible(false);
        setWaitBeforeSend(true);
        if (itemToDelete.productType === PRODUCT_TYPE.CUSTOM) {
            await deleteCustomProduct(order.id, itemToDelete.id);
        } else {
            await deleteProduct(itemToDelete.id);
        }
        getOrder(order.id);
    };

    const openAddCommentModal = (orderIndex, id) => {
        let ord1 = order.orderItems.find(e => e.vendorProductId === id);
        setOrderToUpdate(ord1);
        setAddCommentModalVisible(true)
    };

    const handleUpdateOrderComment = (comment) => {
            let initialOrder = {...order};
            let orderItems = [...initialOrder.orderItems];
            let ord = orderItems.find(e => e.vendorProductId === orderToUpdate.vendorProductId);
            let index = orderItems.indexOf(ord);
            ord.comment = comment;
            orderItems[index] = ord;
            initialOrder.orderItems = orderItems;
            setOrder(initialOrder);
            setWaitBeforeSend(true);
            updateOrder(order.vendorId, order.orderItems[index])
            setAddCommentModalVisible(false)
    };

    const handleCustomItemChange = (event) => {
        let product = {...customProduct};

        switch (event.target.name) {
            case "custom-item-name":
                product.itemName = event.target.value;
                break;
            case "custom-item-measure-unit":
                product.measureUnit = event.target.value;
                break;
            default:
                handleOrderUpdateEvent(event, product);
                break;
        }
        setCustomProduct(product)
    };

    const handleAddCustomItem = () => {
        setWaitBeforeSend(true);
        orderCustomProduct(order.vendorId, customProduct);
    };

    const handleProductCommentChange = (event) => {
        let orderedProduct = {...orderToUpdate};
        orderedProduct.comment = event.target.value;
        setOrderToUpdate(orderedProduct)
    };

    const handleCategorySelect = (categoryId, categoryName, isDesktop, categories) => {
        props.history.push({
            pathname: ROUTES.CUSTOMER.VENDOR_PAGE,
            state: {
                id: order.vendorId,
                categoryName: categoryName,
                categoryId: categoryId,
                isDesktop: isDesktop,
                categories: categories
            }
        })
    };

    const handleClearClick = () => {
        searchRef.current.value = ""
    };

    const openAddCustomItemModal = () => {
        setAddItemModalVisible(true);

        setTimeout(() => {
            if (addCustomItemInputRef.current) {
                addCustomItemInputRef.current.focus()
            }
        }, 100)
    };

    return (
        <div className="single-order-wrapper">

            <CustomerPageHeader isCartHidden />

            <TitleSection leftText={texts.SHOPPING_CART.CART_TEXT}/>

            <GoBackSection linkTo={ROUTES.CUSTOMER.SHOPPING_CART} text={texts.SHOPPING_CART.BACK_TO_ALL_CARTS}/>

            <div id="stick">
                <Cart hideButton={true}
                      vendorName={vendorData && vendorData.vendorName}
                      itemsCount={order && order.orderItems.length + order.customOrderItems.length}
                      totalPriceVAT={order && formatPrice(order.orderItems)}
                      number={cartData.number}
                      texts={texts.SHOPPING_CART} vendorId={cartData.vendorId}
                />
            </div>

            <div className="sov-items-container ">
                {(() => {
                    if (order) {
                        let orderItems = order.orderItems;

                        attachActiveAttribute(orderItems);
                        return (
                            <div className="sc-cart-expanded-container">

                                <div className="sc-box d-lg-none">
                                    <div className="d-flex sc-actions-wrapper">
                                        <DeleteButton onClick={() => setDeleteModalVisible(true)} texts={texts.SHOPPING_CART}/>
                                        <OrderButton onClick={() => handlePlaceOrder()}
                                                     texts={texts.SHOPPING_CART}
                                                     shouldWait={waitBeforeSend} />

                                    </div>

                                    <div className="sc-search-wrapper ">
                                        <AddProductsText visibleInMobile texts={texts.SHOPPING_CART}/>

                                        <div className="d-flex">
                                            <SearchSection reference={searchRef}
                                                           onKeyPress={handleSearchEnterPress}
                                                           onSearchClick={handleSearchPress}
                                                           onClearClick={handleClearClick}/>
                                            <CategoriesToggler onClick={openCategoriesDrawer}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="sc-product-card-wrapper d-lg-inline-block">
                                    {
                                        orderItems.map((item, index) => {
                                            let packages = [...item.otherPackages, item.selectedPackage];
                                            let activePack = packages.find(el => {
                                                return el.id === item.selectedPackage.id
                                            });

                                            packages.sort((a, b) => a.packagingUnit - b.packagingUnit);
                                            let active = packages.indexOf(activePack);
                                            item.active = active;
                                            return <div key={index} className="sc-product-card">

                                                <ProductCardList
                                                    texts={texts.PRODUCT_CARD}
                                                    packages={packages}
                                                    activePackage={active}
                                                    productIndex={index}
                                                    updateOrder={handleUpdateCart}
                                                    changeActivePack={handlePackageChange}
                                                    itemInCart={item}
                                                    inOrdersView
                                                    onDeleteClick={() => openConfirmProductDeleteModal(item.vendorProductId, item.name)}
                                                    commentText={item.comment}
                                                    onAddCommentClick={() => openAddCommentModal(index, item.vendorProductId)}
                                                    product={item}
                                                />
                                            </div>
                                        })}


                                    {order.customOrderItems.map((item,index) => {
                                        return (
                                            <CustomProductCard onClick={() => openConfirmProductDeleteModal(item.itemId, item.itemName, PRODUCT_TYPE.CUSTOM)}
                                                               item={item}
                                                               texts={texts.PRODUCT_CARD} key={item.itemName + index} />
                                        )
                                    })}
                                </div>

                                <div id="sticky-side" className="sc-content-after-products">
                                    <div className="sc-search-wrapper">
                                        <div
                                            className="sc-actions-wrapper d-none d-lg-flex">
                                            <DeleteButton onClick={() => setDeleteModalVisible(true)} texts={texts.SHOPPING_CART}/>
                                            <OrderButton onClick={() => handlePlaceOrder()}
                                                         texts={texts.SHOPPING_CART} 
                                                         shouldWait={waitBeforeSend}/>
                                        </div>
                                        <AddProductsText visibleInMobile texts={texts.SHOPPING_CART}/>

                                        <div className="d-flex">
                                            <SearchSection reference={searchRef}
                                                           onKeyPress={handleSearchEnterPress}
                                                           onSearchClick={handleSearchPress}
                                                           onClearClick={handleClearClick}/>
                                            <CategoriesToggler onClick={openCategoriesDrawer}/>
                                        </div>
                                        <div onClick={() => openAddCustomItemModal()} className="sc-cannot-find">
                                            <span>{texts.SHOPPING_CART.CANNOT_FIND}</span> &gt;
                                        </div>
                                    </div>

                                    <div className="sc-delivery-wrapper">
                                        {pointsOfSale.length > 0 &&
                                        <DeliverySection pointName={selectedPointOfSale.name}
                                                         pointAddress={selectedPointOfSale.shippingAddress}
                                                         onChangePointClick={() => setPointOfSaleSelectModalVisible(true)}
                                                         onTextareaChange={handleOrderCommentChange}
                                                         texts={texts.SHOPPING_CART}
                                                         comment={orderComment}
                                                         onTextareaBlur={() => changeOrder(order.id, {comment:orderComment})}
                                        />}
                                    </div>

                                    <div className="sc-order-total-wrapper">
                                        <OrderTotalSection totalItems={order.orderItems.length + order.customOrderItems.length}
                                                           totalPrice={orderTotals.totalPrice}
                                                           totalPriceWithVat={orderTotals.totalPriceWithVat}
                                                           vat={orderTotals.vat}
                                                           texts={texts.SHOPPING_CART}/>
                                    </div>

                                    <div
                                        className="d-flex sc-actions-wrapper actions-wrapper-with-margin d-lg-none">
                                        <DeleteButton onClick={() => setDeleteModalVisible(true)} texts={texts.SHOPPING_CART}/>
                                        <OrderButton onClick={() => handlePlaceOrder()}
                                                     texts={texts.SHOPPING_CART}
                                                     shouldWait={waitBeforeSend}/>
                                    </div>

                                    <div className="sc-vendor-info-wrapper">
                                        {vendorData && <VendorInfo vendorName={vendorData.vendorName}
                                                                   deliveryRules={vendorData.deliveryRules}
                                                                   vendorPhones={vendorData.phone}
                                                                   workingHours={vendorData.workingHours}
                                                                   texts={texts}/>
                                        }

                                    </div>
                                </div>
                            </div>
                        )
                    }
                })()}
                <div className="d-none d-lg-block sov-last-btn-desktop">
                    {order &&  <OrderButton isDesktopLast
                                            onClick={() => handlePlaceOrder()}
                                            texts={texts.SHOPPING_CART} 
                                            shouldWait={waitBeforeSend}/>}
                </div>
            </div>

            <Modal centered
                   show={deleteModalVisible}
                   onHide={() => setDeleteModalVisible(false)}

            >
                <Modal.Header className="sov-modal-header" closeButton/>
                <DeleteModalBody heading={texts.SHOPPING_CART.DELETE_CART_TEXT}
                                 name={cartData.vendorName}
                                 onCancel={() => setDeleteModalVisible(false)}
                                 onConfirm={() => handleDeleteOrder(order.id)} texts={texts.SHOPPING_CART}/>
            </Modal>

            <Modal centered
                   show={placeOrderModalVisible}
                   backdrop="static"
                   onHide={() => closePlaceOrderModalAndRedirect()}

            >
                <Modal.Header className="sov-modal-header" closeButton />
                <div className="sov-completed-text">{texts.SHOPPING_CART.SUCCESSFUL_SEND}</div>
                <div className="sov-completed-text2">{texts.SHOPPING_CART.THANKING} !</div>
                <button onClick={() => closePlaceOrderModalAndRedirect()}
                        className="btn sov-complete-btn text-white">{texts.SHOPPING_CART.PLACE_ORDER_BUTTON_TEXT}
                </button>
            </Modal>

            <Modal
                centered
                show={addItemModalVisible}
                onHide={() => setAddItemModalVisible(false)}
            >
                <Modal.Header className="sov-modal-header" closeButton>
                    <div className="sov-add-item-header">{texts.SHOPPING_CART.ADD_CUSTOM_ITEM}</div>
                </Modal.Header>

                <div className="sov-add-item-name">{texts.SHOPPING_CART.ITEM_NAME}:</div>
                <textarea data-testid="sov_custom_name" className="sov-add-item-textarea" onChange={handleCustomItemChange} name="custom-item-name"
                          id="" cols="20" rows="2"/>
                <div className="sov-add-item">
                    <div className="sov-add-item-label">{texts.SHOPPING_CART.MEASURE_UNIT}</div>
                    <div className="sov-add-item-unit-select">
                        <select className="sov-select-unit" onChange={handleCustomItemChange}
                                name="custom-item-measure-unit" defaultValue={MEASURE_UNITS[0]}>
                            {MEASURE_UNITS.map(unit => (
                                <option key={unit} value={unit}>{unit}</option>
                            ))}

                        </select>
                    </div>
                </div>
                <div className="sov-add-item">
                    <div className="sov-add-item-label">{texts.SHOPPING_CART.QUANTITY}</div>
                    <div className="sov-add-item-quantity">
                        <ChangeQuantityButton product={customProduct} onClick={handleCustomItemChange} refr={addCustomItemInputRef} quantity={customProduct.quantity}/>
                    </div>
                </div>

                <div className="sov-add-item-btn-wrapper">
                    <button onClick={handleAddCustomItem} disabled={customProduct.itemName.trim() === "" || customProduct.quantity === 0}
                            className="sov-add-item-btn">{texts.SHOPPING_CART.ADD_CUSTOM_ITEM_BUTTON}</button>
                </div>
            </Modal>

            <Modal show={pointOfSaleSelectModalVisible}
                   onHide={() => setPointOfSaleSelectModalVisible(false)}
            >
                <Modal.Header className="sov-modal-header" closeButton>
                    <div className="sov-add-item-header">{texts.SHOPPING_CART.SELECT_POINT_OF_SALE}</div>
                </Modal.Header>
                {pointsOfSale.map((point, index) => {
                    return (
                        <div key={index} onClick={() => selectPointOfSale(point)}
                             className={`sov-point-select-wrapper ${point.id === selectedPointOfSale.id && "selected-point"}`}>
                            <div className="sov-point-name">{point.name}</div>
                            <div className="sov-point-address">{point.shippingAddress}</div>
                        </div>
                    )
                })}
            </Modal>
            <Modal show={deleteItemModalVisible}
                   onHide={() => setDeleteItemModalVisible(false)}>
                <Modal.Header className="sov-modal-header" closeButton/>
                <DeleteModalBody heading={texts.SHOPPING_CART.DELETE_ITEM_TEXT}
                                 name={itemToDelete.name}
                                 onCancel={() => setDeleteItemModalVisible(false)}
                                 onConfirm={() => handleDeleteProductFromOrder(order.id, itemToDelete.id)}
                                 texts={texts.SHOPPING_CART}/>
            </Modal>

            {orderToUpdate &&
                <Modal show={addCommentModalVisible}
                                          onHide={() => setAddCommentModalVisible(false)}>
                    <Modal.Header className="sov-modal-header" closeButton/>

                    <div className="sov-comment-textarea-wrapper">
                        <textarea onChange={handleProductCommentChange}
                                  value={orderToUpdate.comment} name=""
                                  onFocus={(e) => e.target.placeholder = ""}
                                  onBlur={(e) => e.target.value.length < 1 ? e.target.placeholder = texts.SHOPPING_CART.ADD_COMMENT: null}
                                  cols="35" rows="3"
                                  maxLength="120" placeholder={texts.SHOPPING_CART.ADD_COMMENT}/>
                        <div className="sov-comment-maxlength">{orderToUpdate.comment ? orderToUpdate.comment.length : 0}/120</div>
                    </div>
                    <div className="sov-comment-buttons-wrapper">
                        <div onClick={() => setAddCommentModalVisible(false)} className="sov-comment-cancel">
                            {texts.SHOPPING_CART.ADD_COMMENT_CANCEL}
                        </div>
                        <div onClick={() => handleUpdateOrderComment(orderToUpdate.comment)} className="sov-comment-save">
                            {texts.SHOPPING_CART.ADD_COMMENT_CONFIRM}
                        </div>
                    </div>
                </Modal>
            }

            {props.location.state.errors &&  props.location.state.errors.length > 0 &&
                <Modal show={showNotAddedProducts}
                        onHide={() => setShowNotAddedProducts(false)}
                >
                    <Modal.Header closeButton>
                        <h4>{texts.SHOPPING_CART.NOT_ADDED_PRODUCTS}</h4>
                    </Modal.Header>
                    {props.location.state.errors.map((e, i) => {
                       
                        return (
                            <div className="p-1">
                              {i + 1}. {e.name}
                            </div>
                        )
                    })}

                    <div className="p-3 text-center">
                        <button className="btn btn-success w-50" onClick={() => setShowNotAddedProducts(false)}>
                            {texts.SHOPPING_CART.CLOSE}
                        </button>
                    </div>
                </Modal>
            }

            <div className="sov-drawer-container">
                <Drawer isOpen={isDrawerOpen} onHide={closeCategoriesDrawer} isDesktop={isLargeScreen(window.innerWidth)} >
                    <div className="row with-background">
                        <div className="col-12 p-0 d-flex">
                            <div className="col-2 p-0 close-categories float-right">
                                <img style={{width: "1.2em"}} src={CloseIcon} alt=""
                                     onClick={() => setIsDrawerOpen(false)}/>
                            </div>
                            <div className="col-10 p-0 categories-header pr-5 text-center">
                                {texts.DRAWER_HEADING}
                            </div>
                        </div>
                        <div className="accordion mt-4 col-12 p-0" id="categories">
                            {categories &&
                            <Categories onCategorySelect={handleCategorySelect}
                                        parentId="categories"
                                        categories={categories}

                            />
                            }
                        </div>
                    </div>
                </Drawer>
            </div>


            <div onClick={() => document.documentElement.scrollTop = 0} className="sov-back-to-top">
                <div className=""><img src={CaretUp} alt=""/></div>
                {texts.SHOPPING_CART.BACK_TO_TOP}
            </div>
            <Footer texts={texts.FOOTER}/>
        </div>
    );
};

export default SingleOrderView;