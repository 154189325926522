import {
    makeOrderAsync, addCustomProduct,
    getOrdersByUserRoleAsync, getPointsOfSaleByUserRoleAsync,
    printOrderPDF, downloadOrderPDF, changeOrderStatusAsync,
    getOrderByIdAndRoleAsync, getOrderStatusInfo
} from "../services/OrderServices";
import {DEFAULT_FETCH_ITEMS_COUNT, ORDER_STATUSES, ROUTES} from "../config";
import React from "react";
import {notify} from "./Notifications";
import {texts} from "../Languages/Language";
import moment from 'moment';

export const formatPrice = (data) => {
    if (data) {
        let sum = data.reduce((total, item) => total + item.quantity * item.selectedPackage.packagingUnitPriceVAT, 0 )
        return sum > 0 ? sum.toFixed(2) : 0.00
    }
};

export const handleOrderUpdateEvent = (event, order) => {
    let isDecimal = order.quantity % 1 !== 0;

    switch (event.target.name) {
        case "add-one":
            isDecimal ? order.quantity = Math.ceil(order.quantity) : order.quantity ++;
            break;
        case "remove-one":
           isDecimal ? order.quantity = Math.floor(order.quantity) : order.quantity --;
            break;
        case "set-quantity":
            order.quantity = event.target.value;
            break;
        default:
            break;
    }
};

export const getItemsPrice = (items) => {
    return items.reduce((price, {totalPriceVAT}) => price + (totalPriceVAT || 0), 0).toFixed(2);
};

export const getItemsCount = (items) => {
    return items.reduce((totalCount, {countOfItems}) => totalCount + countOfItems, 0)
};

export const getStatusInfo = (status, isPlural) => {

           if (status === ORDER_STATUSES.PENDING || status ===  texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES.PENDING.name) {
               return {
                   color: "pending-color",
                   name: isPlural ? texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES_PLURAL.PENDING.name : texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES.PENDING.name,
                   bg: "bg-warning",
                   namePlural: texts.VENDOR_PANEL.PENDING
               };
           } else if (status ===  ORDER_STATUSES.ACCEPTED || status ===  texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES.ACCEPTED.name) {
               return {
                   color: "accepted-color",
                   name: isPlural ? texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES_PLURAL.ACCEPTED.name : texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES.ACCEPTED.name,
                   bg: "bg-success"
               };
           } else if (status === ORDER_STATUSES.REJECTED || status ===  texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES.REJECTED.name) {
               return {
                   color: "rejected-color",
                   name: isPlural ? texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES_PLURAL.REJECTED.name : texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES.REJECTED.name,
                   bg: "bg-danger",
                   namePlural: texts.VENDOR_PANEL.CANCELLED
               };
           } else if (status === ORDER_STATUSES.DELIVERED ||status ===   texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES.DELIVERED.name) {
               return {
                   color: "delivered-color",
                   name: isPlural ? texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES_PLURAL.DELIVERED.name : texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES.DELIVERED.name,
                   bg: "bg-info"
               };
           } else if(status === "total") {
               return {
                   namePlural: texts.VENDOR_PANEL.TOTAL_FOR_PERIOD,
                   bg:""
               }
           } else {
               return  {
                   color: "",
                   name: "",
                   bg: ""
               }
           }
};

export const duplicateHandler = async (order, props, stopSpinner) => {
    let payload = {};
    const getPayload = (data) => {
        return payload = data
    };

    await duplicateOrder(order, getPayload);

    if (Number(payload.newId)) {
        props.history.push({
            pathname: `${ROUTES.CUSTOMER.ORDER_WITH_STATUS_INITIAL_VIEW.LINK}/${payload.newId}`,
            state: {
                vendorId: order.vendorId,
                id: payload.newId,
                errors: payload.errors
            }
        })
    } else {
        notify("warning", texts.VENDOR_PAGE.CANNOT_DUPLICATE_ORDER);
        stopSpinner();
    }
};

const setNewOrderId = (id) => {
    if (!Number(getNewOrderId())) {
        sessionStorage.setItem("newOrderId", id);
    }
};

const getNewOrderId = () => {
    return sessionStorage.getItem("newOrderId");
}

const sendItemErrorHandler = (item, errors, status) => {
    errors.push(item);
    if (status === 403) {
        notify("error", texts.GENERAL.ORDER_FROM_VENDOR_NOT_ALLOWED)
    }
};

const sendItemsFromArray = async (orderItems, vendorId, errors, request) => {
    for (let item of orderItems) {
        try {
            let res = await request(vendorId, item);
            if (res.status === 200) {
                setNewOrderId(res.data);
            } else {
                sendItemErrorHandler(item, errors, res.response.status);
            }
        } catch (error) {
            sendItemErrorHandler(item, errors, error.response.status);
        }
    }
}

export const duplicateOrder = async (order, getPayload) => {
    sessionStorage.removeItem("newOrderId");
    let errors = [];
    await sendItemsFromArray(order.orderItems, order.vendorId, errors, makeOrderAsync);
    await sendItemsFromArray(order.customOrderItems, order.vendorId, errors, addCustomProduct);

    getPayload({ newId: getNewOrderId(), errors: errors });
};

export const adminAndVendorGetOrderResponseHandler = (responseData, setData, hideButton) => {
    setData(responseData);
    hideButton(responseData.orders.length < DEFAULT_FETCH_ITEMS_COUNT);
};

export const adminAndVendorShowMoreOrdersResponseHandler = (responseData, ordersData, setOrdersData, setIsShowMoreButtonHidden) => {
    let orders = ordersData.orders.concat(responseData.orders);
    ordersData.orders = orders;
    setOrdersData({...ordersData});
    setIsShowMoreButtonHidden(responseData.orders.length < DEFAULT_FETCH_ITEMS_COUNT);
};

export const getOrderTotals = (data) => {
    let totals = {
        totalItemsQuantity: 0,
        totalPriceWithVat:0,
        vat: 0,
        totalPrice: 0,
        customItemsQuantity: 0
    };

    data.orderItems.map(item => {
        totals.totalItemsQuantity += item.quantity;
        totals.totalPriceWithVat += item.sum;
    });

    data.customOrderItems.map(item => {
        totals.customItemsQuantity += item.quantity
    });

    totals.totalPrice = (totals.totalPriceWithVat / 1.2).toFixed(2);
    totals.vat = (totals.totalPriceWithVat - totals.totalPrice).toFixed(2);
    totals.totalPriceWithVat = totals.totalPriceWithVat.toFixed(2);

    return totals
};

export const showDataAccordingToStatus = (status, isAdmin) => {
    if (isAdmin) {
        const isHidden = (initialStatus) => status === initialStatus

        return {
            options: <>
                <option hidden={isHidden(ORDER_STATUSES.ACCEPTED)}
                    value={ORDER_STATUSES.ACCEPTED}> {getStatusInfo(ORDER_STATUSES.ACCEPTED).name} </option>
                <option hidden={isHidden(ORDER_STATUSES.REJECTED)}
                    value={ORDER_STATUSES.REJECTED}> {getStatusInfo(ORDER_STATUSES.REJECTED).name}</option>
                <option hidden={isHidden(ORDER_STATUSES.DELIVERED)}
                    value={ORDER_STATUSES.DELIVERED}> {getStatusInfo(ORDER_STATUSES.DELIVERED).name} </option>
            </>
        };
    } else {
        switch (status) {
            case ORDER_STATUSES.PENDING:
                return {
                    text: texts.VENDOR_PANEL.ORDER_STATUS_PENDING,
                    options: <>
                        <option
                            value={ORDER_STATUSES.ACCEPTED}> {getStatusInfo(ORDER_STATUSES.ACCEPTED).name} </option>
                        <option
                            value={ORDER_STATUSES.REJECTED}> {getStatusInfo(ORDER_STATUSES.REJECTED).name}</option>
                    </>
                };
            case ORDER_STATUSES.ACCEPTED:
                return {
                    text: texts.VENDOR_PANEL.ORDER_STATUS_ACCEPTED,
                    options: <>
                        <option
                            value={ORDER_STATUSES.DELIVERED}> {getStatusInfo(ORDER_STATUSES.DELIVERED).name} </option>
                        <option
                            value={ORDER_STATUSES.REJECTED}> {getStatusInfo(ORDER_STATUSES.REJECTED).name}</option>
                    </>
                };
            case ORDER_STATUSES.REJECTED:
                return {
                    text: texts.VENDOR_PANEL.ORDER_STATUS_REJECTED
                };
            case ORDER_STATUSES.DELIVERED:
                return {
                    text: texts.VENDOR_PANEL.ORDER_STATUS_DELIVERED
                };
            default:
                break;
        }
    }
};

export const addIsEditableAttribute = (data) => {
    return data.map(item => item["isEditable"] = false)
};

export const createOrderBody = (comment, packagingId, quantity, vendorProductId, selectedPackage) => {
    return {
        "comment": comment,
        "packagingId": packagingId,
        "quantity": quantity,
        "vendorProductId": vendorProductId,
        "selectedPackage": selectedPackage
    };
};

export const formatOrderDecimalQuantity = (order) => {
    if  (order.quantity % 1 !== 0) {
        if (order.quantity.toString().split(/[.,]/)[1].length > 3) {
            order.quantity = Number(order.quantity).toFixed(3)
        }
    }
};

export const makeAndUpdateOrderErrorHandler = (error) => {
    switch(error.response.status) {
        case 403:
            notify("error", texts.GENERAL.ORDER_FROM_VENDOR_NOT_ALLOWED);
            break;
        case 404:
            notify("error", texts.VENDOR_PAGE.ENTITY_NOT_FOUND);
            break;
        default:
            break;
    }
};

export const displayMessageAccordingToStatus = (status) => {
    switch (status) {
        case ORDER_STATUSES.PENDING:
            return  texts.VENDOR_PAGE.ORDERS_PAGE.NO_PENDING_ORDERS;
        case ORDER_STATUSES.ACCEPTED:
            return  texts.VENDOR_PAGE.ORDERS_PAGE.ALL_ACCEPTED_ARE_DELIVERED;
        case ORDER_STATUSES.DELIVERED:
            return  texts.VENDOR_PAGE.ORDERS_PAGE.NO_DELIVERED_ORDERS;
        case ORDER_STATUSES.REJECTED:
            return  texts.VENDOR_PAGE.ORDERS_PAGE.NO_REJECTED_ORDERS;
        default:
            return texts.VENDOR_PAGE.ORDERS_PAGE.NO_ORDERS_YET
    }
};

export const shouldDisplayRateSection = (order) => {
    if (order.status === ORDER_STATUSES.ACCEPTED || order.status === ORDER_STATUSES.DELIVERED) {
        return true;
    }
    return false;
};

export const getInitialOrders = async (onSuccess) => {
    try {
        let response = await getOrdersByUserRoleAsync(null, null, ORDER_STATUSES.INITIAL);
        onSuccess(response.data)
    } catch (error) {
        
    }
};

export const showOrderTotalPrice = (order) => {
    if (order.totalPriceVAT) {
        return order.totalPriceVAT.toFixed(2);
    } else {
        return 0;
    }
};

export const getOrdersByRole = async (onSuccess, startDate, endDate, orderStatus, pointOfSaleId, page = 1) => {
    try {
        let response = await getOrdersByUserRoleAsync(startDate, endDate, orderStatus, pointOfSaleId, page);
        onSuccess(response.data)
    } catch (error) {
        
    }
};

export const getPointsOfSaleForOrders = async (startDate, endDate, onSuccess) => {
    try {
        let response = await getPointsOfSaleByUserRoleAsync(startDate, endDate);
        onSuccess(response.data)
    } catch (error) {
        
    }
};

export const printOrder = async (order) => {
    try {
        let response = await printOrderPDF(order);
        if (response.status === 200) {
            let obj = window.open(URL.createObjectURL(response.data));
            setTimeout(() => {
                obj.print();
            }, 1000)
        }

    } catch (error) {

    }
};

export const downloadOrder = async (order) => {
    try {
        let response = await downloadOrderPDF(order);
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `order-${order.code}.pdf`);
            document.body.appendChild(link);
            link.click();
        }
    } catch (error) {
        
    }
};

export const changeOrderStatus = async (orderId, status, onSuccess) => {
    try {
        let response = await changeOrderStatusAsync(orderId, status);
        onSuccess(response)
    } catch (error) {
        
    }
};

export const getOrderById = async (orderId, onSuccess) => {
    try {
        let response = await getOrderByIdAndRoleAsync(orderId);
        onSuccess(response.data)
    } catch (error) {
        
    }
};

export const getOrderStatusChanges = async (orderId, callback) => {
    try {
        let response = await getOrderStatusInfo(orderId);
        callback(response.data)
    } catch (error) {
        
    }
};

export const getStatusChangeTime = (toStatus, orderStatusInfoArray) => {
    const status = orderStatusInfoArray.find(e => e.toStatus === toStatus);
     if (status) {
        return  <> {moment(new Date(status.timestamp)).format("DD.MM.YYYY/ HH:mm")} {texts.VENDOR_PANEL.HOURS_SHORT}</>
     }
 };