import React from 'react';
import PropTypes from 'prop-types';
import {texts} from "../../../../Languages/Language";

const PackagingBox = ({packaging, index}) => {

    return (
        <div className="text-right ml-3">
            <div>
                {texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.PACKAGING_TYPE}:
                <input readOnly className="ml-1 width-100px" type="text" defaultValue={packaging && packaging.packagingType}/>
            </div>
            <div>
                {texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.PACKAGING_UNIT} {index + 1}:
                <input readOnly className="ml-1 width-100px" type="text" defaultValue={packaging && packaging.packagingUnit}/>

            </div>
            <div>
                {texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.UNIT_PRICE}:
                <input readOnly className="ml-1 width-100px" type="text" defaultValue={packaging && packaging.packagingUnitPrice}/>
            </div>
            <div>
                {texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.UNIT_PRICE_VAT}:
                <input readOnly className="ml-1 width-100px" type="text" defaultValue={packaging && packaging.packagingUnitPriceVAT} />
            </div>
        </div>
    );
};

PackagingBox.propTypes = {
    packaging: PropTypes.object,
    index: PropTypes.number
};

export default PackagingBox;