import React from 'react';
import PropTypes from 'prop-types';
import InputRow from "../../InputRow/InputRow";

const EANRow = ({onChange, texts, value}) => {
    return (
        <InputRow testId="gp-ean" name="ean" defaultValue={value} onChange={onChange} label={texts.EAN} className="input-fld ean-input"/>
    );
};

EANRow.propTypes = {
    texts: PropTypes.object,
    onChange: PropTypes.func
};

export default EANRow;