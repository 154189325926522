import React from 'react';
import PropTypes from 'prop-types';
import InputFieldRow from "../../admin/InputFieldRow/InputFieldRow";
import {texts} from '../../../Languages/Language';

const UserProfileForm = ({onChange, user}) => {
    return (
        <form autoComplete="off">
            <InputFieldRow type="text" label={texts.ADMIN_PANEL.USERS.FIRST_NAME} nameAndId="firstName"
                           onChange={onChange}
                           defaultValue={user.firstName}/>
            <InputFieldRow type="text" label={texts.ADMIN_PANEL.USERS.LAST_NAME} nameAndId="lastName"
                           onChange={onChange}
                           defaultValue={user.lastName}/>
            <InputFieldRow type="tel" label={`*${texts.ADMIN_PANEL.USERS.PHONE}`} nameAndId="phone" onChange={onChange}
                           defaultValue={user.phone}/>
            <div className="text-left h4 p-2 w-lg-75 m-auto">
                {texts.ADMIN_PANEL.USERS.USER_NAME}
            </div>
            <InputFieldRow type="email" label={`*${texts.ADMIN_PANEL.USERS.EMAIL}`} nameAndId="email"
                           onChange={(e) => onChange(e, true)}
                           defaultValue={user.email}/>
            <div className="text-left h4 p-2 w-lg-75 m-auto">
                {texts.ADMIN_PANEL.USERS.CHANGE_PASSWORD}
            </div>
            <InputFieldRow wrapperClasses={"pass-fields"} type="password" label={`${ texts.ADMIN_PANEL.USERS.NEW_PASSWORD}`} nameAndId="password"
                           onChange={onChange}/>
            <InputFieldRow wrapperClasses={"pass-fields"} type="password" label={`${texts.ADMIN_PANEL.USERS.CONFIRM_PASSWORD}`}
                           nameAndId="repeat_password" onChange={onChange}/>
        </form>
    );
};

UserProfileForm.propTypes = {
    onChange: PropTypes.func,
    user: PropTypes.object
};

export default UserProfileForm;