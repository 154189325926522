import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from "../../../../assets/times-solid.svg";
import './CustomProductCard.css';

const CustomProductCard = ({item, onClick, hideDelete}) => {
    return (
        <div className="custom-order-container">
                <div className="custom-order-name">
                    {item.itemName}
                </div>
                <div className="custom-order-info">
                    {item.quantity} {item.measureUnit}
                </div>
                <div hidden={hideDelete} className="custom-order-delete-icon"><img className="c-pointer" data-testid="sov_custom_dlt" onClick={onClick} src={DeleteIcon} alt=""/></div>
        </div>
    );
};

CustomProductCard.propTypes = {
    item: PropTypes.object,
    onClick: PropTypes.func,
    hideDelete: PropTypes.bool
};

export default CustomProductCard;