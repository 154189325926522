import React, {useEffect, useState} from 'react';
import {getAllCustomers} from "../../../services/CustomerServices";
import ListCustomersAndVendors from "../ListCustomersAndVendors/ListCustomersAndVendors";

const ListCustomers = props => {
    const [customers, setCustomers] = useState([]);
   
    useEffect(() => {
        getCustomers();
    }, []);

    const getCustomers = async () => {
        try {
            let response = await getAllCustomers();
            setCustomers(response.data)
        } catch (error) {
        }
    };

    return (
        <ListCustomersAndVendors isVendors={false} partners={customers}/>
    );
};

export default ListCustomers;