import {Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import React from "react";
import PopupButtons from "../ButtonsSection/PopupButtons";
import texts from "../../../Languages/bg.json"

const ConfirmOrderStatusModal = ({show, onHide, isExport, isDownload, isPrint, handleDownload, handlePrint, handleExport, order}) => {
    return (
        <Modal show={show}
               onHide={onHide}
               dialogClassName="ov-confirm-dialog">

            <Modal.Header closeButton/>

            <div className="ov-confirm-delivery-modal-text p-4">
                {isExport && <>
                    <div> {texts.VENDOR_PANEL.POPUP_INFO.CONTACT_FOR_EXPORT.FIRST_ROW} </div>
                    <br/>
                    <div> {texts.VENDOR_PANEL.POPUP_INFO.CONTACT_FOR_EXPORT.SECOND_ROW} </div>
                </>}
                {isPrint && <>
                    <div> {texts.VENDOR_PANEL.POPUP_INFO.CONFIRM_DELIVERY_PRINT} </div>
                </>}
                {isDownload && <>
                    <div> {texts.VENDOR_PANEL.POPUP_INFO.CONFIRM_DELIVERY_DOWNLOAD} </div>
                </>}

            </div>
            <div className="text-center p-4">
                <PopupButtons isExport={isExport} isDownload={isDownload} isPrint={isPrint}
                    handleDownload={handleDownload} handlePrint={handlePrint} handleExport={handleExport} order={order}/>
            </div>

        </Modal>
    );
};

ConfirmOrderStatusModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    onClick: PropTypes.func,
    isExport: PropTypes.bool,
    isDownload: PropTypes.bool,
    isPrint: PropTypes.bool,
    handleDownload: PropTypes.func,
    handlePrint: PropTypes.func,
    handleExport: PropTypes.func,
    order: PropTypes.object
};

export default ConfirmOrderStatusModal;