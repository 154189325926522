import React, {useEffect, useState} from 'react';
import './ShoppingCart.css';
import CustomerPageHeader from "../CustomerPageHeader/CustomerPageHeader";
import TitleSection from "../TitleSection/TitleSection";
import GoBackSection from "../GoBackSection/GoBackSection";
import Cart from "./Cart/Cart";
import {VENDOR_PAGE as texts} from '../../../Languages/bg';
import DeliveryTruck from "../../../assets/delivery-truck.png";
import {ROUTES} from "../../../config";
import Spinner from "react-bootstrap/Spinner";
import {getInitialOrders, getItemsPrice, showOrderTotalPrice} from '../../../utils/OrderUtils';

const ShoppingCart = props => {
    const [allCarts, setAllCarts] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
       getInitialOrders(getInitialOrdersFResponseHandler);
    }, []);

    const getInitialOrdersFResponseHandler = async (responseData) => {
        setAllCarts(responseData);
        setIsLoading(false);
    };

    return (
        <div className="sc-body-wrapper">

            <CustomerPageHeader isCartHidden={true} />

            <TitleSection icon={DeliveryTruck} leftText={texts.SHOPPING_CART.ALL_CARTS} rightText={allCarts && getItemsPrice(allCarts)} currency={texts.SHOPPING_CART.CART.CURRENCY}/>

            <GoBackSection  text={texts.SHOPPING_CART.GO_BACK}/>

            <div className="">
                {(() => {
                    if (isLoading) {
                        return <Spinner animation={"border"} variant={"success"} />
                    } else {
                        if (allCarts) {

                            if (allCarts.length > 0) {
                                return allCarts.map((cart, index) =>
                                    (
                                        <div key={index} className="clear-link-style c-pointer" onClick={() => props.history.push({
                                            pathname: `${ROUTES.CUSTOMER.ORDER_WITH_STATUS_INITIAL_VIEW.LINK}/${cart.id}`,
                                            state: {
                                                id: cart.id,
                                                vendorName: cart.vendorName,
                                                itemsCount: cart.itemsCount,
                                                totalPrice: cart.totalPriceVAT,
                                                number: index + 1,
                                                vendorId: cart.vendorId,
                                                cartsCount: allCarts.length
                                            }
                                        })}>
                                            <Cart itemsCount={cart.countOfItems}
                                                  totalPriceVAT={showOrderTotalPrice(cart)}
                                                  vendorName={cart.vendorName}
                                                  number={index + 1}
                                                  id={cart.id}
                                                  vendorId={cart.vendorId}
                                                  texts={texts.SHOPPING_CART}
                                                  cartsCount={allCarts.length}
                                            />
                                        </div>
                                    )
                                )
                            } else {
                                return (
                                    <div className="h2 bg-white text-black-50">
                                        {texts.SHOPPING_CART.EMPTY_CART}
                                    </div>
                                )
                            }
                        }
                    }
                })()}
            </div>
        </div>
    );
};

export default ShoppingCart;