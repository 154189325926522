import React from 'react';
import PropTypes from 'prop-types';
import './OrderTotalSection.css';

const OrderTotalSection = ({texts, totalItems, totalPrice, vat, totalPriceWithVat}) => {
    return (
        <div className="ov-order-total-wrapper">
            <div className="sc-order-total-heading">{texts.ORDER_TOTAL}</div>

            <div className="sc-order-total-items-count">
                <span className=""> {totalItems} </span>
                {texts.CART.ITEMS}
            </div>
            <div className="">
                <div className="sc-order-total-price">
                    <div className="sc-total-label">
                        {texts.TOTAL_PRICE}:
                    </div>
                    <div className="sc-total-value">
                        {totalPrice}  {texts.CART.CURRENCY}
                    </div>
                </div>
                <div className="sc-order-vat">
                    <div className="sc-total-label">
                        {texts.VAT}:
                    </div>
                    <div className="sc-total-value">
                        {vat} {texts.CART.CURRENCY}
                    </div>
                </div>
            </div>

            <div className="sc-order-total-price-vat">
               <div className="sc-total-label">
                   {texts.TOTAL_PRICE_VAT}:
               </div>
                <div className="sc-total-value">
                    {totalPriceWithVat} {texts.CART.CURRENCY}
                </div>
            </div>

            <div className="sc-total-price-info">
                {texts.ORDER_PRICES_INFO}
            </div>
        </div>
    );
};

OrderTotalSection.propTypes = {
    texts: PropTypes.object,
    totalItems: PropTypes.number,
    totalPrice: PropTypes.string,
    vat: PropTypes.string,
    totalPriceWithVat: PropTypes.string
};

export default OrderTotalSection;