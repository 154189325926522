import {backendRequest} from "../utils/backend";
import {DEFAULT_FETCH_ITEMS_COUNT} from "../config";
import { getAuthToken } from "./LocalStorageServices";

export const getVendorById = (vendorId) => {
    return  backendRequest.get(`/vendor/${vendorId}`)
};

export const getVendorProductsAsync = (vendorId, categoryId, productName, page = 1, size = DEFAULT_FETCH_ITEMS_COUNT) => {
    let endpoint = `/vendor/${vendorId}/product`;
    let query = `?page=${page}&size=${size}&`;

    if (categoryId) {
        query += `categoryId=${categoryId}&`
    }

    if (productName) {
        let name = encodeURIComponent(productName)
        query += `name=${name}&`
    }
    endpoint += query;

    return backendRequest.get(endpoint)
};

export const getAllVendorsAsync = (searchQuery, page = 1, size = DEFAULT_FETCH_ITEMS_COUNT) => {
    let endpoint = "/vendor";
    let query = `?page=${page}&size=${size}&`;

    if (searchQuery) {
        let search = encodeURIComponent(searchQuery);
        query += `name=${search}&`
    }
    endpoint += query;
   return backendRequest.get(endpoint)
};

export const getVendorImage = async (vendorId, setFunc) => {
    let res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/vendor/${vendorId}/image`, {
        method: "GET",
        headers: {
            Authorization: getAuthToken()
        }
    });

    let binaryResponse = await res.arrayBuffer()
    let binaryPhoto = `data:image/png;base64,${new Buffer(binaryResponse).toString("base64")}`

    setFunc(binaryPhoto, vendorId)
    return binaryPhoto
};

export const getProductsForVendor = (categoryId, productName, page = 1, size = DEFAULT_FETCH_ITEMS_COUNT) => {
    let endpoint = `/vendor/product`;
    let query = `?page=${page}&size=${size}&`;

    if (categoryId) {
        query += `categoryId=${categoryId}&`
    }

    if (productName) {
        let name = encodeURIComponent(productName);
        query += `name=${name}&`
    }
    endpoint += query;

    return backendRequest.get(endpoint);
};

export const editVendorProductAsync = (product) => {
    return backendRequest.put(`/vendor/product`, product);
};

export const sendVendorPricelist = (data) => {
    return backendRequest.post('/pricelist/upload', data, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
};

export const deleteVendorProductPackaging = (productId, packagingId) => {
    return backendRequest.delete(`/vendor/product/${productId}/packaging/${packagingId}`);
};

export const editVendorAsync = (data) => {
    return backendRequest.put(`/vendor`, data);
};

export const uploadVendorImage = (event, vendorId) => {
    const formData = new FormData();

    formData.append("uploadFile", event.files[0])
    formData.append("fileName", event.files[0].name)

    return backendRequest.put(`/vendor/${vendorId}/image`, formData);
};