import React, {useEffect, useState} from 'react';
import './VendorOrders.css';
import AdminAndVendorPageHeader from "../../common/AdminAndVendorPageHeader/AdminAndVendorPageHeader";
import InfoBox from "../../common/InfoBox/InfoBox";
import PeriodSection from "../../common/PeriodSection/PeriodSection";
import moment from "moment";
import {
    changeOrderStatusAsync
} from "../../../services/OrderServices";
import VendorOrderTable from "./VendorOrderTable";
import ShowMoreButton from "../../customer/ShowMoreButton/ShowMoreButton";
import {texts} from '../../../Languages/Language';
import SelectSearch from "react-select";
import DatePicker from "react-date-picker";
import AdminAndVendorFooter from "../../common/AdmivAndVendorFooter/AdminAndVendorFooter";
import {toggleSidebar} from "../../../utils/SidebarUtils";
import {handleDateChange} from "../../../utils/TimeUtils";
import {ORDER_STATUSES} from "../../../config";
import {adminAndVendorGetOrderResponseHandler, getOrdersByRole, getPointsOfSaleForOrders, printOrder, downloadOrder, adminAndVendorShowMoreOrdersResponseHandler} from "../../../utils/OrderUtils";
import {createSelectOptions} from "../../../utils/SelectUtils";
import {createPointOfSaleOptions, handlePointOfSaleChange, handleStatusChange} from "../../../utils/PointOfSaleUtils";
import ConfirmOrderStatusModal from "../ConfirmOrderStatusModal/ConfirmOrderStatusModal";
import {notify} from "../../../utils/Notifications";
import Spinner from "react-bootstrap/Spinner";

const VendorOrders = props => {

    const [startDate, setStartDate] = useState(moment().subtract(1, "M").format("x"));
    const [endDate, setEndDate] = useState(moment().format("x"));
    const [ordersData, setOrdersData] = useState({
        cancelledOrdersCount: 0,
        orders: [],
        pendingOrdersCount: 0,
        totalOrdersCount: 0
    });

    const [page, setPage] = useState(2);
    const [isShowMoreButtonHidden, setIsShowMoreButtonHidden] = useState(true);
    const [pointsOfSale, setPointsOfSale] = useState([]);
    const [selectedPointsOfSale, setSelectedPointsOfSale] = useState(null);
    const [status, setStatus] = useState(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPrint, setIsPrint] = useState(false);
    const [isDownload, setIsDownload] = useState(false);
    const [isExport, setIsExport] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState();
    const [action, setAction] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let getOrdersInterval;
        if (startDate < endDate) {
            getOrdersByRole(getOrdersResponseHandler, startDate, endDate, status, selectedPointsOfSale);

            getOrdersInterval = setInterval(() => {
                getOrdersByRole(getOrdersResponseHandler, startDate, moment().format("x"), status, selectedPointsOfSale);
                setPage(2);
            }, 10000);
            getPointsOfSaleForOrders(startDate, endDate, setPointsOfSale);
        } else {
            notify("error", texts.VENDOR_PANEL.INVALID_DATE);
        }

        return () => clearInterval(getOrdersInterval)
    }, [startDate, endDate, selectedPointsOfSale, status]);

    const getOrdersResponseHandler = (responseData) => {
        adminAndVendorGetOrderResponseHandler(responseData, setOrdersData, setIsShowMoreButtonHidden);
        setIsEmpty(responseData.orders.length === 0);
        setIsLoading(false);
    };

    const showMoreResponseHandler = (responseData) => {
       adminAndVendorShowMoreOrdersResponseHandler(responseData, ordersData, setOrdersData, setIsShowMoreButtonHidden);
    };

    const showMoreHandler = () => {
        setPage(page + 1);
        getOrdersByRole(showMoreResponseHandler, startDate, endDate, status, selectedPointsOfSale, page)
    };

    const handleActionButtonsClick = (action, order) => {
        setSelectedOrder(order);

        switch (action) {
            case "download":
                setAction("download");
                if (order.status === ORDER_STATUSES.PENDING) {
                    setIsDownload(true);
                    setIsModalVisible(true)
                }

                else {
                    downloadOrder(order)
                }
                break;

            case "print":
                setAction("print");
                if (order.status === ORDER_STATUSES.PENDING) {
                    setIsPrint(true);
                    setIsModalVisible(true)
                }

                else {
                    printOrder(order)
                }
                break;

            case "export":
                setAction("export");
                setIsExport(true);
                setIsModalVisible(true);
                setSelectedOrder(null);
                break;
            default:
                break;
        }
    }

    const handleHideModals = () => {
        setIsExport(false);
        setIsPrint(false);
        setIsDownload(false);
        setIsModalVisible(false);
        setAction("")
    };

    const changeOrderStatus = async (orderId, status) => {
        try {
            let response = await changeOrderStatusAsync(orderId, status);
            changeOrderStatusResponseHandler(response)
        } catch (error) {
            
        }
    };

    const handleOrderStatusChange = () => {
        changeOrderStatus(selectedOrder.id, ORDER_STATUSES.ACCEPTED)
    };

    const changeOrderStatusResponseHandler = (res) => {
        if (res.status === 200) {
            switch (action) {
                case "download":
                    downloadOrder(selectedOrder);
                    break;

                case "print":
                    printOrder(selectedOrder);
                    break;
                default:
                    break;
            }

            getOrdersByRole(getOrdersResponseHandler, startDate, endDate, false, status, selectedPointsOfSale );
            handleHideModals()
        }
    };

    return (
            <div className="vendor-orders-body">
                <AdminAndVendorPageHeader onClick={toggleSidebar} text={texts.VENDOR_PANEL.PAGE_TITLE}/>

                <PeriodSection text={texts.VENDOR_PANEL.ORDERS_FOR_PERIOD} from={startDate} to={endDate}/>

                <div className="w-100 d-md-flex">
                    <InfoBox className="ml-4 text-dark" count={ordersData.totalOrdersCount}
                             status="total" />
                    <InfoBox className="m-3 mt-lg-0" count={ordersData.pendingOrdersCount}
                             status={ORDER_STATUSES.PENDING}/>
                    <InfoBox className="m-3 mt-lg-0" count={ordersData.cancelledOrdersCount}
                             status={ORDER_STATUSES.REJECTED}/>
                </div>

                <div className="w-100 mt-5 pl-2 pr-r">
                    <div className="card shadow">
                        <a href="#data-table" className="card-header text-left" data-toggle="collapse">
                            {texts.VENDOR_PANEL.PAGE_TITLE}
                        </a>
                        <div className="card-body p-2 collapse show" id="data-table">
                            <div className="d-block d-md-flex text-left mt-2 mb-2">
                                <div className="pos-search-wrapper pt-2 pb-2 text-left">
                                    <div className="pt-md-2 pr-1">{texts.VENDOR_PANEL.FOR_POINTOFSALE}: </div>
                                    <SelectSearch
                                        onChange={(e) => handlePointOfSaleChange(e, setSelectedPointsOfSale, setPage)}
                                        options={createPointOfSaleOptions(pointsOfSale, "name", "id")}
                                        className="w-75" />
                                </div>
                                <div className="date-picker-wrapper  pt-2 pb-2">
                                    <div className="pl-2 pr-2 pt-md-2"> {texts.VENDOR_PANEL.DATE_FROM}: </div>
                                    <DatePicker clearIcon={null}
                                               value={new Date(moment.unix(startDate / 1000))}
                                               className="date-picker"
                                               onChange={(e) => handleDateChange(e, "start", setStartDate, setEndDate)}
                                               calendarIcon={<i className="far fa-calendar-alt"></i>}
                                               format="dd-MM-yyyy" data-testid="date-start" />

                                </div>
                                <div className="date-picker-wrapper pt-2 pb-2">
                                    <div className="pl-2 pr-2 pt-md-2"> {texts.VENDOR_PANEL.DATE_TO}: </div>
                                    <DatePicker clearIcon={null}
                                               value={new Date(moment.unix(endDate / 1000))}
                                               className="date-picker"
                                               onChange={(e) => handleDateChange(e, "end", setStartDate, setEndDate)}
                                               calendarIcon={<i className="far fa-calendar-alt"></i>}
                                               format="dd-MM-yyyy" data-testid="date-start" />

                                </div>
                                <div className=" pt-2 pb-2  status-select-wrapper">
                                    <div className="pl-2 pr-1 pt-md-2"> {texts.VENDOR_PANEL.STATUS}: </div>
                                    <SelectSearch 
                                        onChange={(e) => handleStatusChange(e, setStatus, setPage)}
                                        options={createSelectOptions([...Object.values(texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES_PLURAL)], "name", "value")}
                                        className="order-status-select"/>
                                </div>
                            </div>

                        <ConfirmOrderStatusModal order={selectedOrder}
                            show={isModalVisible}
                            isDownload={isDownload}
                            handleDownload={handleOrderStatusChange}
                            isExport={isExport} isPrint={isPrint}
                            handlePrint={handleOrderStatusChange}
                            onHide={handleHideModals}
                            handleExport={handleHideModals} />

                            {((()=>{
                                if (isLoading) {
                                    return <Spinner animation={"border"} variant={"primary"} />
                                } else {
                                    return <VendorOrderTable texts={texts.VENDOR_PANEL} orders={ordersData.orders} actionHandler={handleActionButtonsClick}/>
                                }
                            })())}
                            {isEmpty && <div className="text-center"> {texts.ADMIN_PANEL.NO_ORDERS_FOUND} </div>}
                            <div className="m-4" hidden={isShowMoreButtonHidden}>
                                <ShowMoreButton className="border m-0" text={texts.VENDOR_PAGE.SHOW_MORE_TEXT} onClick={showMoreHandler}/>
                            </div>
                        </div>
                    </div>
                </div>

                <AdminAndVendorFooter texts={texts.VENDOR_PAGE.FOOTER}/>
            </div>
    );
};

export default VendorOrders;