import React from 'react';
import PropTypes from 'prop-types';
import {texts} from '../../../Languages/Language';
import {checkAndAddRedBorder} from "../../../utils/Utils";

const SelectField = ({onChange, nameAndId, options, label, defaultValue, testId}) => {

    return (
        <div className="d-flex align-items-center p-2">
            <div className="pr-1">
                <span>*{label}: </span>
            </div>
            <select data-testid={testId} onBlur={checkAndAddRedBorder}  onChange={onChange} className={`form-control w-75 `} name={nameAndId} id={nameAndId}>
                {!defaultValue &&  <option value={""} selected hidden> {texts.ADMIN_PANEL.USERS.CHOOSE} </option>}
                {options.map(opt => (
                    <option key={opt.value} selected={opt.value === defaultValue} value={opt.value}>{opt.name}</option>
                ))}
            </select>
        </div>
    );
};

SelectField.propTypes = {
    onChange: PropTypes.func,
    nameAndId: PropTypes.string,
    options: PropTypes.array,
    label: PropTypes.string,
    defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
    testId: PropTypes.string
};

export default SelectField;