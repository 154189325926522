import React from 'react';
import PropTypes from 'prop-types';

const InputRow = ({label, onChange, className, name, defaultValue, onBlur, testId}) => {
    return (
        <div className="col-11 d-flex">
            <div className="label pt-2">
                {label}:
            </div>
            <div className="">
                <input data-testid={testId} onChange={onChange} onBlur={onBlur} defaultValue={defaultValue} id={name} name={name} type="text" className={`${className} form-control`}/>
            </div>
        </div>
    );
};

InputRow.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    name: PropTypes.string,
    defaultValue: PropTypes.string,
    onBlur: PropTypes.func,
    testId: PropTypes.string
};

export default InputRow;