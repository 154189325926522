import React from 'react';
import PropTypes from 'prop-types';
import {createColumns} from "../../../utils/TableUtils";
import texts from '../../../Languages/bg'
import {useTable} from "react-table";
import ColumnHideCheckboxes from "../../common/ColumnHideCheckboxes/ColumnHideCheckboxes";
import {getPackagingAttributes, toggleVendorProductEditableFields, optionsCreate} from "../../../utils/VendorProductUtils";
import ProductImageTableCell from "../../admin/ProductImageTableCell/ProductImageTableCell";

const ProductsTable = ({products, onSaveClick, onChange}) => {
    const createRows = (products) => {
        let arr = [];

        products.map((product, productIndex) => {
            let obj = {
                sosku: product.sosku,
                erpid: product.erpid,
                image: <ProductImageTableCell product={product} isVendor={true} />,
                itemName: product.displayName,
                erpItem: product.productName,
                measureUnit: product.measureUnit || texts.VENDOR_PANEL.NONE,
                deliveryUnit1: product.packagingList[0] && <>x {product.packagingList[0].packagingUnit} {product.packagingList[0].packagingType}</>,
                duPrice: toggleVendorProductEditableFields(product,productIndex, 0, onChange),
                deliveryUnit2: getPackagingAttributes(product, 1, productIndex).units,
                duPrice2: getPackagingAttributes(product, 1, productIndex, onChange).price,
                deliveryUnit3: getPackagingAttributes(product, 2, productIndex).units,
                duPrice3: getPackagingAttributes(product, 2, productIndex, onChange).price,
                availability: <>
                    <select disabled={!product.isEditable} id="product-status-change"
                            className={`${!product.isEditable && "bg-none border-0 hideArrow"}`}
                            onChange={(e) => onChange(e, null, product, productIndex)} value={product.productStatus}
                            name="product-status-change" data-testid="vp-status">
                        {optionsCreate(product.productStatus)}
                    </select>
                    <div className="hidden-vp-text ">{!product.isEnabled && texts.ADMIN_PANEL.HIDDEN_PRODUCT}</div>
                </>,
                action: <button onClick={product.isEditable ? () => onSaveClick(product,productIndex, true) : () => onSaveClick(product,productIndex, false)}
                                className="btn btn-primary border-radius-5 p-0 p-md-1">{product.isEditable ? texts.VENDOR_PANEL.SAVE_BTN_TEXT : texts.VENDOR_PANEL.EDIT_BTN_TEXT}</button>
            };

            arr.push(obj)
        });
        return arr
    };

    const columns = React.useMemo(() => createColumns(texts.VENDOR_PANEL.PRODUCTS_TABLE_COLUMNS), []);
    const data = React.useMemo(() => createRows(products), [products]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        allColumns
    } = useTable({
        columns,
        data,
        initialState: {
            hiddenColumns: ["erpid", "erpItem", "deliveryUnit2", "duPrice2", "deliveryUnit3", "duPrice3"]
        }
    });

    return <>
        <ColumnHideCheckboxes columns={allColumns}/>

        <table id="vendor-orders-table" className="table table-bordered table-striped table-sm" {...getTableProps()}>
            <thead>
            {headerGroups.map(headerGroup => (
                <tr  {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th scope="col" {...column.getHeaderProps()}>
                            {column.render('Header')}

                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map(row => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                            return <td data-label={cell.column.Header}
                                       className={`text-dark vertical-align-center`}
                                       {...cell.getCellProps()}>{cell.render('Cell')}
                            </td>
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    </>
};

ProductsTable.propTypes = {
    products: PropTypes.array,
    onSaveClick: PropTypes.func,
    onChange: PropTypes.func
};

export default ProductsTable;