import React from 'react';
import PropTypes from 'prop-types';
import './DeliverySection.css';
import EditIcon from "../../../../assets/edit.svg";

const DeliverySection = ({texts, pointName, pointAddress, onChangePointClick, onTextareaChange, onTextareaBlur, comment}) => {
    return (
        <div>
            <div className="sc-delivery-heading">
                {texts.DELIVERY_HEADING}
            </div>

            <div className="sc-delivery-sale-point-and-address">
                <span> {texts.DELIVERY_POINT}:</span>
                <div data-testid="sov_point_name" className="sc-delivery-point-name-and-address">
                    {pointName}
                </div>
            </div>
            <div className="sc-delivery-sale-point-and-address">
                <span> {texts.DELIVERY_ADDRESS}:</span>
                <div className="sc-delivery-point-name-and-address">
                    {pointAddress}
                </div>
            </div>
            <div onClick={onChangePointClick} className="sc-change-sale-point-wrapper">
                <img className="sc-icon-edit" src={EditIcon} alt=""/>
                <span className="sc-change-sale-point">
                    {texts.DELIVERY_CHANGE_POINT}
                </span>
            </div>

            <div className="sc-order-notes-wrapper">
                <div className="sc-order-note"> {texts.DELIVERY_COMMENT}</div>
                <div>
                    <textarea data-testid="sov_order_comment" onBlur={onTextareaBlur} value={comment} className="sc-order-note-textarea" onChange={onTextareaChange} name="" id="" cols="40" rows="3"  />

                </div>
            </div>
        </div>
    );
};

DeliverySection.propTypes = {
    texts: PropTypes.object,
    pointName: PropTypes.string,
    pointAddress: PropTypes.string,
    onChangePointClick: PropTypes.func,
    onTextareaChange: PropTypes.func,
    onTextareaBlur: PropTypes.func,
    comment: PropTypes.string
};

export default DeliverySection;