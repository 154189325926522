import React, {useEffect, useState} from 'react';
import './AdminImportPriceList.css';
import AdminAndVendorPageHeader from "../../common/AdminAndVendorPageHeader/AdminAndVendorPageHeader";
import {toggleSidebar} from "../../../utils/SidebarUtils";
import SelectSearch from "react-select";
import AdminAndVendorFooter from "../../common/AdmivAndVendorFooter/AdminAndVendorFooter";
import {texts} from '../../../Languages/Language';
import Papa from 'papaparse';
import {createSelectOptions} from "../../../utils/SelectUtils";
import Spinner from "react-bootstrap/Spinner";
import {notify} from "../../../utils/Notifications";
import {uploadProductsCsv} from "../../../services/PriceListServices";
import {ROUTES} from "../../../config";
import {Link} from "react-router-dom";
import { getVendors } from '../../../utils/VendorUtils';
import { redirectWithState } from '../../../utils/Utils';

const AdminImportPriceList = props => {

    const [priceList, setPriceList] = useState();
    const [csvFile, setCsvFile] = useState();
    const [vendors, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState({label: texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.SEARCH_PLACEHOLDER});
    const [isLoading, setIsLoading] = useState(false);
    const [disableImportButton, setDisableImportButton] = useState(false);
   
    useEffect(() => {
        getVendors((response) => setVendors(response.data))
    },[]);

    useEffect(() => {
        if (priceList) {
            Papa.parse(priceList[0], {
                complete: result => setCsvFile([...result.data.filter(el => el.length > 1)])
            });
        }
    }, [priceList]);

    const redirectToImportStepTwo = (data) => {
        if (data.matchingErpId.length > 0) {
            redirectWithState(ROUTES.ADMIN.IMPORT_MATCH_2, data, props)
        } else if (data.matchingErpId.length === 0 && data.noMatches.length > 0) {
            redirectWithState(ROUTES.ADMIN.MATCH_BY_NAME, data, props)
        } else {
            redirectWithState(ROUTES.ADMIN.FINISH_IMPORT, data, props)
        }
    };

    const uploadCsv = async (vendorId, data) => {
        try {
            let response = await uploadProductsCsv(vendorId, data);
            uploadFileResponseHandler(response);
        } catch (error) {
            uploadFileErrorHandler(error)
        }
    };  

    const handlePricelistUpload = (file, vendorId) => {
        setIsLoading(true);
        let formData = new FormData();
        formData.append("uploadFile", file);
        formData.append("fileName", file.name);

        uploadCsv(vendorId, formData);
        setDisableImportButton(true)
    };

    const uploadFileResponseHandler = (response) => {
        setIsLoading(false);
        setDisableImportButton(false);
        if (response.status === 200) {
            notify("success", texts.ADMIN_PANEL.SUCCESSFUL_UPLOAD);
            redirectToImportStepTwo(response.data)
        }
    };

    const uploadFileErrorHandler = (error) => {
        setIsLoading(false);
        setDisableImportButton(false);
        if (error.response) {
            notify("error", texts.ADMIN_PANEL.UNSUCCESSFUL_UPLOAD, error.response.data.message, 5000)
        }
    };

    const handleFileChange = (event) => {
        if (event.target.files[0]) {
            setPriceList([...event.target.files]);
        }
    };

    const checkButtonDisabled = () => {
        return selectedVendor.value == null || priceList == null || disableImportButton
    };

    return (
        <div>
            <div className="aipl-body">
                <AdminAndVendorPageHeader onClick={toggleSidebar} text={texts.ADMIN_PANEL.IMPORT_PAGE_HEADER} />

                <div className="d-flex align-items-center p-5">
                    <div className="h5"> *{texts.ADMIN_PANEL.CHOOSE_VENDOR}: </div>
                        <SelectSearch
                            value={selectedVendor}
                            onChange={(e) => setSelectedVendor(e)}
                            options={createSelectOptions(vendors, "vendorName", "id")}
                            className="width-33 ml-4" />
                    <div className="pl-4">
                        <Link to={ROUTES.ADMIN.NEW_EDIT_VENDOR_ROUTE} className="btn btn-primary border-radius-5">
                            {texts.ADMIN_PANEL.ADD_NEW_VENDOR}
                        </Link>
                    </div>
                </div>
                <div className="text-left p-5">
                    <span className="h5">*{texts.ADMIN_PANEL.PRICE_LIST_FILE}:</span>
                    <label className="btn btn-primary border-radius-5 ml-4 c-pointer" htmlFor="price_list_file">Избери файл</label>
                    <input hidden onChange={handleFileChange} data-testid="aip-file" type="file" name="price_list_file" id="price_list_file"/>
                    {priceList && csvFile && <span>{priceList[0].name} {texts.ADMIN_PANEL.READY_FOR_IMPORT}. {csvFile.length - 1} {texts.ADMIN_PANEL.PRODUCTS_FOUND}</span>}
                </div>

                <div className="p-5 text-right">

                    <button disabled={checkButtonDisabled()}
                            onClick={() => handlePricelistUpload(priceList[0], selectedVendor.value)}
                            className="btn btn-primary btn-icon-split border-radius-5">
                        <span className="icon w-100 h-100 text-white-50">
                              {isLoading ?
                                  <Spinner variant={"light"} size={"sm"} animation={"border"} />
                                  :
                                  <i className="far fa-hand-point-right text-white"></i>}
                        </span>
                        <span className="text font-weight-bold">
                           {texts.ADMIN_PANEL.FORWARD}
                       </span>
                    </button>
                </div>
            </div>

            <div className="fixed-bottom">
                <AdminAndVendorFooter texts={texts.VENDOR_PAGE.FOOTER}/>
            </div>

        </div>
    );
};

export default AdminImportPriceList;