import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {useTable, useSortBy, useRowSelect} from 'react-table'
import {Link} from "react-router-dom";
import moment from "moment";
import {ROUTES} from "../../../config";
import {getStatusInfo, showOrderTotalPrice} from "../../../utils/OrderUtils";
import {texts} from '../../../Languages/Language'
import {checkIsSorted, createColumns} from "../../../utils/TableUtils";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import ColumnHideCheckboxes from "../../common/ColumnHideCheckboxes/ColumnHideCheckboxes";

const AdminOrdersTable = ({orders, setSelectedRows}) => {

     const createRows = (data) => {
        let arr = [];

       data.map((order) => {
            let obj = {
                id: order.id,
                orderId: order.code,
                date: moment.unix(order.lastUpdated / 1000).format("DD.MM.YYYY/ HH:mm"),
                vendor: order.vendorName,
                client:  order.customerName,
                pointOfSale: order.pointOfSaleName,
                address: order.pointOfSaleAddress,
                amount:  showOrderTotalPrice(order) + texts.VENDOR_PAGE.SHOPPING_CART.CART.CURRENCY,
                status: getStatusInfo(order.status).name,
                action: <Link to={`${ROUTES.ADMIN.ADMIN_ORDER.LINK}/${order.id}`}> {texts.VENDOR_PANEL.VIEW} </Link>,
            };

            arr.push(obj)
        });

        return arr

    };

    const data = React.useMemo(() => createRows(orders), [orders]);

    const columns = React.useMemo(() => createColumns(texts.ADMIN_PANEL.TABLE_COLUMNS),[]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        allColumns
    } = useTable({
            columns,
            data,
        },
        useSortBy,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox data-testid="ao-checkbox" {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ])
        });

    useEffect(() => {
        setSelectedRows(selectedFlatRows);
    }, [selectedFlatRows]);

    if (orders) {

        return (
            <React.Fragment>

               <ColumnHideCheckboxes columns={allColumns} />

                <table id="vendor-orders-table" className="table table-bordered table-striped table-sm" {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr  {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => {
                              return  <th className={`${column.id === "selection" && "selection-cell"}`} scope="col" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                            { column.id !== "selection" &&  checkIsSorted(column)}
                            </span>
                                </th>
                            })}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td  data-label={cell.column.Header}
                                               className={`${cell.column.id === "status" ? `${getStatusInfo(cell.value).bg} text-white` : "text-dark"} `}
                                               {...cell.getCellProps()}>{cell.render('Cell')}
                                    </td>
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
};

AdminOrdersTable.propTypes = {
    orders: PropTypes.array,
    setSelectedRows: PropTypes.func
}

export default AdminOrdersTable;