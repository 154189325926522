import React, {useEffect, useState} from 'react';
import './AdminOrderView.css';
import {changePointOfSaleByAdmin} from "../../../services/OrderServices";
import {getCustomerPointsOfSale} from "../../../services/CustomerServices";
import {getCustomerById} from "../../../utils/CustomerUtils";
import AdminAndVendorPageHeader from "../../common/AdminAndVendorPageHeader/AdminAndVendorPageHeader";
import texts from '../../../Languages/bg';
import {toggleSidebar} from "../../../utils/SidebarUtils";
import InfoCard from "../../common/InfoCard/InfoCard";
import {ORDER_STATUSES} from "../../../config";
import {getOrderById, getOrderTotals, getOrderStatusChanges, changeOrderStatus, getStatusChangeTime} from "../../../utils/OrderUtils";
import InfoCardDelivery from "../../common/InfoCardDelivery/InfoCardDelivery";
import InfoCardClient from "../../common/InfoCardClient/InfoCardClient";
import InfoCardStatus from "../../common/InfoCardStatus/InfoCardStatus";
import InfoCardTotals from "../../common/InfoCardTotals/InfoCardTotals";
import AdminOrderTable from "./AdminOrderTable";
import {notify} from "../../../utils/Notifications";
import Spinner from "react-bootstrap/Spinner";
import {getVendorByIdAsync} from '../../../utils/VendorUtils';
 
const AdminOrderView = props => {
    const orderId = props.match.params.id;
    const [order, setOrder] = useState({});
    const [orderStatusInfo, setOrderStatusInfo] = useState([]);
    const [customerInfo, setCustomerInfo] = useState({});
    const [vendorInfo, setVendorInfo] = useState({});
    const [orderTotals, setOrderTotals] = useState({});
    const [pointsOfSale, setPointsOfSale] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getOrderById(orderId, getOrderResponseHandler);
        getOrderStatusChanges(orderId, setOrderStatusInfo);
    }, []);

    const getPointsOfSale = async (customerId, onSuccess) => {
        try {
            let response = await getCustomerPointsOfSale(customerId);
            onSuccess(response.data);
        } catch (error) {
            
        }
    };

    const changePointOfSale = async (orderId, pointOfSale) => {
        try {
            let response = await changePointOfSaleByAdmin(orderId, pointOfSale);
            changePointOfSaleResponseHandler(response);
        } catch (error) {

        }
    };

    const getOrderResponseHandler = (data) => {
        let totals = getOrderTotals(data);
        setOrderTotals(totals);
        setOrder(data);

        if (!customerInfo.id) {
            getCustomerById(data.customerId, setCustomerInfo)
        }

        if (!pointsOfSale) {
            getPointsOfSale(data.customerId, setPointsOfSale);
        }

        if (!vendorInfo.id) {
            getVendorByIdAsync(data.vendorId, setVendorInfo);
        }

        setIsLoading(false)
    };

    const changeOrderStatusResponseHandler = (res) => {
        if (res.status === 200) {
            getOrderById(order.id, getOrderResponseHandler);
            alertForChange(res)
        }
    };

    const handleOrderStatusChange = (status) => {
        changeOrderStatus(order.id, status, changeOrderStatusResponseHandler)
    };

    const handlePointOfSaleChange = (event) => {
        changePointOfSale(orderId, event.target.value)
    };

    const changePointOfSaleResponseHandler = (response) => {
        alertForChange(response);
        getOrderById(order.id, getOrderResponseHandler);
    };

    const alertForChange = (res) => {
        if (res.status === 200) {
           notify("success", texts.ADMIN_PANEL.SUCCESSFUL_CHANGE)
        }
    };

    return (
        <div>
            <AdminAndVendorPageHeader onClick={toggleSidebar}
                                      text={`${texts.VENDOR_PANEL.ORDER_PAGE_TITLE} ${order.code}`}/>

            {((() => {
                if (isLoading) {
                    return <Spinner variant={"primary"} animation={"border"} />
                } else {
                    return  (
                        <>
                            <div className="info-cards-wrapper d-lg-flex flex-lg-wrap">
                                <InfoCard title={texts.VENDOR_PAGE.ORDERS_PAGE.ORDER_LABEL} customBody>
                                    <div className="w-100 text-left">
                                        <div className="">
                                            <span>{texts.ADMIN_PANEL.CREATOR}: </span>
                                            <span className="text-black">{order.userFirstName} {order.userLastName}</span>
                                        </div>

                                        <div className="d-flex justify-content-between text-left">
                                            <div className="">
                                                <div className="">
                                                    <span>{texts.ADMIN_PANEL.DATE_SENT}: </span>
                                                    <span className="text-black"> {getStatusChangeTime(ORDER_STATUSES.PENDING, orderStatusInfo)} </span>
                                                </div>
                                                <div className="">
                                                    <span>{texts.ADMIN_PANEL.DATE_DELIVERED}: </span>
                                                    <span className="text-black"> {getStatusChangeTime(ORDER_STATUSES.DELIVERED, orderStatusInfo)} </span>
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <span>{texts.ADMIN_PANEL.DATE_ACCEPTED}: </span>
                                                    <span className="text-black">  {getStatusChangeTime(ORDER_STATUSES.ACCEPTED, orderStatusInfo)}</span>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <span>{texts.ADMIN_PANEL.DATE_REJECTED}: </span>
                                                    <span className="text-black">  {getStatusChangeTime(ORDER_STATUSES.REJECTED, orderStatusInfo)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pt-2">
                                            {texts.ADMIN_PANEL.EMAIL_TO_VENDOR}: <span className="text-black"> {texts.ADMIN_PANEL.YES} </span>
                                        </div>
                                    </div>
                                </InfoCard>

                                <InfoCardClient customTitle={texts.ADMIN_PANEL.VENDOR} customer={vendorInfo} isVendor/>

                                <InfoCardDelivery order={order} isAdmin pointsOfSale={pointsOfSale} onChange={handlePointOfSaleChange}/>

                                <InfoCardClient customer={customerInfo}/>

                                <InfoCardStatus order={order} customerInfo={customerInfo} onStatusChange={handleOrderStatusChange}/>

                                <InfoCardTotals order={order} orderTotals={orderTotals}/>

                            </div>

                            <div className="table-wrapper m-3">
                                <div className="card shadow">
                                    <a href="#data-table" className="card-header text-uppercase h5 text-left" data-toggle="collapse">
                                        {texts.VENDOR_PANEL.ORDER_TABLE_TITLE}
                                    </a>
                                    <div id="data-table" className="card-body collapse show">
                                        {order.orderItems &&  <AdminOrderTable order={order} />}
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            })())}
        </div>
    );
};

export default AdminOrderView;