import React from 'react';
import PropTypes from 'prop-types';
import './ConfirmDeliveryButton.css';

const ConfirmDeliveryButton = ({text, onClick}) => {
    return (
            <button onClick={onClick} className="ov-confirm-button">
                {text}
            </button>
    );
};

ConfirmDeliveryButton.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
};

export default ConfirmDeliveryButton;