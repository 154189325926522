import React from 'react';
import PropTypes from 'prop-types';
import SOSKURow from './SOSKURow/SOSKURow'
import InputRow from "../InputRow/InputRow";
import BrandNameRow from "./BrandNameRow/BrandNameRow";
import MeasureUnitsRow from "./MeasureUnitsRow/MeasureUnitsRow";
import EANRow from "./EANRow/EANRow";
import GlobalProductContext from "../GlobalProductContext";
import {checkAndAddRedBorder} from "../../../../utils/Utils";

const ProductInformationSection = ({texts, brands, onChange, onBrandsSelectInputChange, onAddBrandButtonClick, units, itemToAdd}) => {
    const product = React.useContext(GlobalProductContext).product;
    return (
        <>
            <div className="ngp-pi-wrapper">
                <div className="h4 font-weight-bold text-left">
                    {texts.HEADING}:
                </div>
            </div>
            <div className="">
                <SOSKURow onChange={onChange} label={texts.SOSKU_LABEL} text={texts.SOSKU}
                          selectLabel={texts.STATUS}
                          options={[
                              { value: "AVAILABLE", label: texts.STATUS_OPTIONS.ENABLED },
                              { value: "NOT_AVAILABLE", label: texts.STATUS_OPTIONS.DISABLED }
                          ]}/>

                <div className="pb-1">
                    <InputRow testId="gp-erpid" name="erpid" defaultValue={product && product.erpid} onChange={onChange} label={"ERP ID"} className="input-fld ean-input"/>
                </div>

                <div className="pb-1">
                    <InputRow testId="gp-brandCode" name="brandCode" defaultValue={product && product.brandCode} onChange={onChange} label={"PBID"} className="input-fld ean-input"/>
                </div>

                <EANRow value={product && product.ean} onChange={onChange} texts={texts}/>

                <InputRow testId="gp-name" onBlur={checkAndAddRedBorder} defaultValue={product && product.productName} name="productName" onChange={onChange} label={`* ${texts.PRODUCT_NAME}`}
                          className={`product-name-input input-fld mt-2 `}/>

                <BrandNameRow
                    label={texts.BRAND_NAME}
                    buttonText={texts.BTN_ADD_BRAND}
                    weightLabel={texts.NET_WEIGHT}
                    netWeightName="netWeight"
                    brands={brands}
                    weightSelectName="netWeightUnit"
                    weightUnitsOptions={units.weightUnits}
                    onChange={onChange}
                    onAddBrandButtonClick={onAddBrandButtonClick}
                    onBrandsSelectInputChange={onBrandsSelectInputChange}
                    brandPlaceholder={texts.BRAND_PLACEHOLDER}
                    weightPlaceholder={texts.WEIGHT_PLACEHOLDER}
                    product={product}
                    itemToAdd={itemToAdd}
                />

                <MeasureUnitsRow onChange={onChange}
                                 name="measureUnit"
                                 label={texts.MEASURE_UNIT}
                                 options={units.measureUnits}
                                 measurePlaceholder={texts.MEASURE_PLACEHOLDER}
                />

            </div>
        </>
    );
};

ProductInformationSection.propTypes = {
    texts: PropTypes.object,
    onChange: PropTypes.func,
    brands: PropTypes.array,
    onAddFieldInputChange: PropTypes.func,
    onAddButtonClick: PropTypes.func,
    units: PropTypes.object,
    itemToAdd: PropTypes.object
};

export default ProductInformationSection;