import React from 'react';
import PropTypes from 'prop-types';
import Spinner from "react-bootstrap/Spinner";
import texts from '../../../Languages/bg';

const LoginForm = ({onChange, onFormSubmit, error, isLoading}) => {

    return (
        <div className="login-form-wrapper">
            <form onSubmit={onFormSubmit}>
                <div className="d-flex flex-column p-3">
                    <label className="text-left text-primary font-weight-bold" htmlFor="email"> {texts.GENERAL.USER_NAME} :</label>
                    <input onChange={onChange} type="email" id="email" name="email"/>
                </div>
                <div className="d-flex flex-column p-3">
                    <label className="text-left text-primary font-weight-bold" htmlFor="password">{texts.GENERAL.PASSWORD} :</label>
                    <input onChange={onChange} type="password" id="password" name="password"/>
                </div>
                <div className="font-09 text-right text-primary font-weight-bold  pr-4">
                    <a href="#">{texts.GENERAL.FORGOT_PASSWORD}?</a>
                </div>
                {error && <div className="alert-danger"> {error} </div>}
                <div className="p-5 text-left font-color-main">
                    <div>
                        {texts.GENERAL.LINK_TERMS_TEXT} <a href="https://shiftorders.com/terms"> {texts.VENDOR_PAGE.FOOTER.TERMS} </a> {texts.GENERAL.AND} <a
                        href="https://shiftorders.com/privacy-policy">{texts.VENDOR_PAGE.FOOTER.PRIVACY}</a>
                    </div>
                </div>

                <div className="p-4">
                    {isLoading ? <Spinner animation={"border"} variant={"primary"} /> : <button type="submit" className="btn btn-primary pl-4 pr-4 font-weight-bold">
                        {texts.GENERAL.LOGIN}
                    </button>}
                </div>
            </form>
        </div>
    );
};

LoginForm.propTypes = {
    onChange: PropTypes.func,
    onFormSubmit: PropTypes.func,
    error: PropTypes.string,
    isLoading: PropTypes.bool
};

export default LoginForm;