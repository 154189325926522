import React from 'react';
import PropTypes from 'prop-types';
import './VendorInfo.css';
import VendorInfoExpandable from "../../VendorInfoExpandable/VendorInfoExpandable";
import {renderPhoneLinks} from "../../../../utils/VendorInfoUtils";

const VendorInfo = ({texts, vendorName, vendorPhones, deliveryRules, workingHours}) => {
    return (
        <div>
            <div className="sc-vendor-info-name">{vendorName}</div>
            <div className="sc-vendor-info-contacts">
                <div>
                    {
                     renderPhoneLinks(vendorPhones)
                    }
                </div>
            </div>
            <div className="accordion sc-order-vendor-info-accordion" id="expandOrderInfo">
                <VendorInfoExpandable id="deliveryTerms"
                                      parentId="expandOrderInfo"
                                      mainText={texts.DELIVERY_CONDITIONS}
                                      content={deliveryRules} />

                <VendorInfoExpandable id="workingHours"
                                      parentId="expandOrderInfo"
                                      mainText={texts.WORKING_TIME}
                                      content={workingHours} />
            </div>
        </div>
    );
};

VendorInfo.propTypes = {
    texts: PropTypes.object,
    vendorName: PropTypes.string,
    vendorPhones: PropTypes.string,
    deliveryRules: PropTypes.string,
    workingHours: PropTypes.string
};

export default VendorInfo;