import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import texts from '../../../Languages/bg'
import InfoCard from "../InfoCard/InfoCard";

const InfoCardDelivery = ({order, isAdmin, onChange, pointsOfSale}) => {
    return (
        <InfoCard id="Delivery" title={texts.VENDOR_PANEL.INFO_DELIVERY}
                  labels={texts.VENDOR_PANEL.DELIVERY_LABELS}>
            <div data-testid="vov-order-time">
                <span className="text-black">
                    {order.code}
                </span>
                <span> {texts.VENDOR_PANEL.DATE_FROM} </span>
                <span className="text-black">
                    {moment(new Date(order.lastUpdated)).format("DD.MM.YYYY / HH:mm")} {texts.VENDOR_PANEL.HOURS_SHORT}
                </span>
            </div>
            <div>
                {isAdmin ?
                     <select
                        // disabled={order.status === ORDER_STATUSES.REJECTED || order.status === ORDER_STATUSES.DELIVERED}
                        onChange={onChange} className="border-0 c-pointer bg-none"
                        name=""
                        id=""
                        data-testid="aov-pos-select"
                        defaultValue={order.pointOfSale && order.pointOfSale.id}>
                        {pointsOfSale && pointsOfSale.map((point, idx) =>
                            <option key={"pos" + idx} hidden={order && order.pointOfSale.id === point.id}
                                    selected={order && order.pointOfSale.id === point.id}
                                    value={point.id}>
                                {point.name}
                            </option>)}
                         <option hidden={pointsOfSale && pointsOfSale.length > 1} disabled value="">{texts.ADMIN_PANEL.NO_POINTS_OF_SALE}</option>
                    </select>
                    :
                    <span className="text-black"> {order.pointOfSale && order.pointOfSale.name} </span>
                }
            </div>
            <div>
                <span className="text-black"> {order.pointOfSale && order.pointOfSale.shippingAddress} </span>
            </div>
            <div>
                <span className="text-black"> {order.pointOfSale && order.pointOfSale.phone} </span>
            </div>
        </InfoCard>
    );
};

InfoCardDelivery.propTypes = {
    order: PropTypes.object,
    isAdmin: PropTypes.bool,
    onChange: PropTypes.func,
    pointsOfSale: PropTypes.array
};

export default InfoCardDelivery;