import React, {useEffect} from 'react';
import SideNavigation from "../../common/SideNavigation/SideNavigation";
import SideNavigationLink from "../../common/SideNavigationLink/SideNavigationLink";
import {Switch} from "react-router-dom";
import VendorOrders from "../VendorOrders/VendorOrders";
import {ROUTES, USER_ROLES} from "../../../config";
import texts from '../../../Languages/bg';
import './VendorMainPage.css';
import VendorOrderView from "../VendorOrderView/VendorOrderView";
import VendorProducts from "../VendorProducts/VendorProducts";
import {PrivateRoute} from "../../../PrivateRoute";
import VendorImportPricelist from "../VendorImportPricelist/VendorImportPricelist";
import AdminAndVendorProfile from "../../common/AdminAndVendorProfile/AdminAndVendorProfile";
import {isLargeScreen, scrollToTop} from "../../../utils/CheckWindowWidth";

const VendorMainPage = props => {

    useEffect(() => {
        if (!isLargeScreen(window.innerWidth)) {
            scrollToTop();
        }
    },[props.location.pathname]);

    return (
        <div className="d-flex " id="wrapper">
              <SideNavigation path={ROUTES.VENDOR.VENDOR_HOME}>
                  <hr className="sidebar-divider" />

                  <SideNavigationLink linkClasses={"nav-link"} className={"nav-item font-weight-bold"} path={ROUTES.VENDOR.VENDOR_ORDERS}
                                      text={texts.VENDOR_PANEL.SIDEBAR_LINKS.ORDERS}
                                      icon={<i className="fas fa-clipboard-list"></i>}/>
                  <SideNavigationLink linkClasses={"nav-link"} className={"nav-item font-weight-bold"} path={ROUTES.VENDOR.VENDOR_PRODUCTS} text={texts.VENDOR_PANEL.SIDEBAR_LINKS.PRODUCTS}
                                      icon={<i className="fas fa-store"></i>} />
                  <SideNavigationLink linkClasses={"nav-link"} className={"nav-item font-weight-bold"} path={ROUTES.VENDOR.VENDOR_IMPORT} text={texts.VENDOR_PANEL.SIDEBAR_LINKS.IMPORT}
                                      icon={<i className="fas fa-file-import"></i>}/>

                  <hr className="sidebar-divider" />

                  <SideNavigationLink linkClasses={"nav-link"} className={"nav-item font-weight-bold"} text={texts.VENDOR_PANEL.SIDEBAR_LINKS.STATISTICS}
                                      icon={<i className="fas fa-chart-line"></i>} />
                  <SideNavigationLink linkClasses={"nav-link"} className={"nav-item font-weight-bold"} text={texts.VENDOR_PANEL.SIDEBAR_LINKS.SETTINGS}
                                      icon={<i className="fas fa-user-cog"></i>}/>
                  <SideNavigationLink linkClasses={"nav-link"} className={"nav-item font-weight-bold"} text={texts.VENDOR_PANEL.SIDEBAR_LINKS.ADVERTISING}
                                      icon={<i className="fas fa-percent"></i>}/>
                  <SideNavigationLink linkClasses={"nav-link"} className={"nav-item font-weight-bold"} text={texts.VENDOR_PANEL.SIDEBAR_LINKS.HELP}
                                      icon={<i className="fas fa-question-circle"></i>}/>
              </SideNavigation>
            <div className="w-100" id="content-wrapper">
                <Switch>
                    <PrivateRoute role={USER_ROLES.VENDOR} exact path={ROUTES.VENDOR.VENDOR_HOME} component={VendorOrders} />
                    <PrivateRoute role={USER_ROLES.VENDOR} exact path={ROUTES.VENDOR.VENDOR_ORDERS} component={VendorOrders} />
                    <PrivateRoute role={USER_ROLES.VENDOR} exact path={ROUTES.VENDOR.VENDOR_ORDER.ROUTE} component={VendorOrderView} />
                    <PrivateRoute role={USER_ROLES.VENDOR} exact path={ROUTES.VENDOR.VENDOR_PRODUCTS} component={VendorProducts} />
                    <PrivateRoute role={USER_ROLES.VENDOR} exact path={ROUTES.VENDOR.VENDOR_IMPORT} component={VendorImportPricelist} />
                    <PrivateRoute role={USER_ROLES.VENDOR} exact path={ROUTES.VENDOR.PROFILE} component={AdminAndVendorProfile} />
                </Switch>
            </div>
        </div>
    );
};

export default VendorMainPage;