import {backendRequest} from "../utils/backend";
import {getLastNotificationsCheckTimestamp, setLastNotificationsCheckTimestamp} from "../utils/NotificationUtils";
import moment from "moment";
import {DEFAULT_FETCH_ITEMS_COUNT} from "../config";

export const getNewNotifications = async () => {

    let timestamp;
    let lastTimestamp = getLastNotificationsCheckTimestamp();
    if (Number(lastTimestamp)) {
        timestamp = lastTimestamp
    } else {
        timestamp = moment(new Date()).subtract(1, "months").format("x");
        setLastNotificationsCheckTimestamp(timestamp)
    }

    return await backendRequest.get(`/notification/new?timestamp=${timestamp}`)
};

export const getAllNotifications = (page = 1, size = DEFAULT_FETCH_ITEMS_COUNT) => {
    return backendRequest.get(`/notification?page=${page}&size=${size}`);
};



