import React, {useEffect, useState} from 'react';
import AdminAndVendorPageHeader from "../../common/AdminAndVendorPageHeader/AdminAndVendorPageHeader";
import {toggleSidebar} from "../../../utils/SidebarUtils";
import {Spinner} from "react-bootstrap";
import {ROUTES, USER_ROLES} from "../../../config";
import AdminAndVendorFooter from "../../common/AdmivAndVendorFooter/AdminAndVendorFooter";
import {Link} from "react-router-dom";
import {texts} from "../../../Languages/Language";
import {sortData} from "../../../utils/Utils";
import { getUsers } from '../../../utils/UserUtils';
import UserRow from './UserRow/UserRow';

const UsersList = props => {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isAscending, setIsAscending] = useState(false);

    useEffect(() => {
        getUsers(getUsersResponseHandler)
    }, []);

    const getUsersResponseHandler = (res) => {
        setIsLoading(false);
        if (res.status === 200) {
            setUsers(res.data)
        }
    };

    const getUserTypeProps = (type) => {
        switch (type) {
            case USER_ROLES.VENDOR:
                return {
                    bg: "bg-primary text-white",
                    role: texts.ADMIN_PANEL.USERS.ROLES.VENDOR
                };
            case USER_ROLES.CUSTOMER:
                return {
                    bg: "bg-success text-white",
                    role: texts.ADMIN_PANEL.USERS.ROLES.CUSTOMER
                };
            case USER_ROLES.ADMIN:
                return {
                    bg: "bg-white",
                    role: texts.ADMIN_PANEL.USERS.ROLES.ADMIN
                };
            default:
                break;
        }
    };

    const handleSortUsers = (keyIndex) => {
        setIsAscending(!isAscending);
        const keys = texts.ADMIN_PANEL.USERS.TABLE_HEADERS;
        sortData(users, isAscending, keys[keyIndex].id);
        setUsers([...users])
    };

    return (
        <div>
            <div style={{minHeight: "82vh"}} className="">
                <AdminAndVendorPageHeader onClick={toggleSidebar}
                                          text={`${ texts.ADMIN_PANEL.USERS.PROFILES} - ${texts.ADMIN_PANEL.USERS.USERS_LIST}`}/>

                <div className="p-3 text-right">
                    <Link to={`${ROUTES.ADMIN.NEW_EDIT_USER_LINK }/new`} className="btn btn-primary">
                        {texts.ADMIN_PANEL.USERS.NEW_USER}
                    </Link>
                </div>

                <div className="pl-3 pr-3">
                    <div className="card shadow">
                        <div className="card-header h4 text-primary text-left">
                            {texts.ADMIN_PANEL.USERS.USERS}
                        </div>
                        <div className="card-body">
                            {(() => {
                                if (isLoading) {
                                    return (
                                        <div className="">
                                            <Spinner variant={"primary"} animation={"border"}/>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <table className="table table-bordered table-striped table-sm">
                                            <thead className="bg-primary text-white">
                                            <tr>
                                                {texts.ADMIN_PANEL.USERS.TABLE_HEADERS.map((header, headerIndex) => (
                                                    <th key={header.id}>
                                                        {header.label}
                                                        {headerIndex < texts.ADMIN_PANEL.USERS.TABLE_HEADERS.length - 1 &&
                                                            <i data-testid="ul-sort" onClick={() => handleSortUsers(headerIndex)} className="fas fa-sort float-right pt-1 pl-2"/>}
                                                    </th>
                                                ))}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {users.map(user => {
                                                const userTypeProps = getUserTypeProps(user.type);
                                                return (
                                                   <UserRow key={user.id} user={user} userTypeProps={userTypeProps} />
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    )
                                }
                            })()}
                        </div>
                    </div>
                </div>
            </div>
            <AdminAndVendorFooter texts={texts.VENDOR_PAGE.FOOTER} />
        </div>
    );
};

export default UsersList;