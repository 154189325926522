import { createBrand, getBrands } from "../services/BrandServices";
import { createSelectOptions } from "./SelectUtils";

export const getAllBrands = async (onSuccess) => {
    try {
        let response = await getBrands();
        let options = createSelectOptions(response.data);
        onSuccess(options);
    } catch (error) { }
};

export const createNewBrand = async (brand, onSuccess, onError) => {
    try {
        let response = await createBrand(brand); 
        onSuccess(response)
    } catch (error) {
        onError(error)
    }
};