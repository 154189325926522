import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getGlobalProductImage} from "../../../services/GlobalProductServices";

const ProductImage = ({globalProductId, className, vendorProductId}) => {
    const [image, setImage] = useState();

    useEffect(() => {
        getImage(globalProductId);
    }, [globalProductId]);

    const getImage = async (id) => {
        let image = await getGlobalProductImage(id, 1);
        setImage(image);
    };

    return (
        <img id={vendorProductId} className={className} src={image} alt=""/>
    );
};

ProductImage.propTypes = {
    globalProductId: PropTypes.number,
    className: PropTypes.string,
    vendorProductId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export default ProductImage;