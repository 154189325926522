import React, {useEffect, useState} from 'react';
import './NotificationsPage.css';
import CustomerPageHeader from "../CustomerPageHeader/CustomerPageHeader";
import TitleSection from "../TitleSection/TitleSection";
import NotificationsIcon from "../../../assets/notifications_icon-white.svg";
import GoBackSection from "../GoBackSection/GoBackSection";
import {getAllNotifications} from "../../../services/NotificationServices";
import {getLastNotificationsCheckTimestamp, setLastNotificationsCheckTimestamp, sortNotificationsByTimestamp} from "../../../utils/NotificationUtils";
import NotificationSection from "./NotificationSection/NotificationSection";
import ShowMoreButton from "../ShowMoreButton/ShowMoreButton";
import Footer from "../Footer/Footer";
import {VENDOR_PAGE as texts} from '../../../Languages/bg';
import BackToTopButton from "../BackToTopButton/BackToTopButton";
import ShoppingCartButtonMobile from "../ShoppingCartButtonMobile/ShoppingCartButtonMobile";
import {getInitialOrders, getItemsCount, getItemsPrice} from "../../../utils/OrderUtils";
import {DEFAULT_FETCH_ITEMS_COUNT} from "../../../config";
import Spinner from "react-bootstrap/Spinner";

const NotificationsPage = props => {

    const lastTimestamp = getLastNotificationsCheckTimestamp();
    const [notifications, setNotifications] = useState({newer: [], older: []});
    const [isShowMoreButtonHidden, setIsShowMoreButtonHidden] = useState(true);
    const [allCarts, setAllCarts] = useState();
    const [page, setPage] = useState(2);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setLastNotificationsCheckTimestamp(new Date().getTime());
        getNotifications(1);
        getInitialOrders(setAllCarts);
    }, []);

 

    const getNotifications = async (page) => {
        try {
            let response = await getAllNotifications(page);
            getAllNotificationResponseHandler(response.data);
        } catch (error) {
            
        }
    };

    const getAllNotificationResponseHandler = (data) => {
        let older = [...notifications.older];
        let newer = [...notifications.newer];

        sortNotificationsByTimestamp(data.ads, newer, older, lastTimestamp);
        sortNotificationsByTimestamp(data.statusChanges, newer, older, lastTimestamp);

        setIsShowMoreButtonHidden(data.ads.length + data.statusChanges.length !== DEFAULT_FETCH_ITEMS_COUNT)
     
        setNotifications({
            older: older.sort((a, b) => a.timestamp + b.timestamp),
            newer: newer.sort((a, b) => a.timestamp + b.timestamp)
        });
        setIsLoading(false)
    };

    const showMoreButtonHandler = () => {
        setPage(page + 1);
        getNotifications(page);
    };

    return (
        <div className="np-container">

            <CustomerPageHeader ordersSum={allCarts && getItemsPrice(allCarts)} inNotificationsPage/>

            <div className="np-title-section-wrapper">
                <TitleSection icon={NotificationsIcon} leftText={texts.NOTIFICATIONS_PAGE.TITLE}/>
            </div>

            <div className="">
                <GoBackSection text={texts.SHOPPING_CART.GO_BACK} />
            </div>

            <div className="np-body-wrapper">

                {((() => {
                    if (isLoading) {
                        return <Spinner animation={"border"} variant={"success"} />
                    } else {
                        return notifications.older.length > 0 || notifications.newer.length > 0 ?
                            <>
                                <div className="np-text-wrapper">
                                    <div className="np-text">{texts.NOTIFICATIONS_PAGE.NEW}:</div>
                                </div>
                                {notifications.newer.length > 0 ?
                                    <div className="">
                                        {notifications.newer
                                            .map(n => {
                                                return <NotificationSection key={n.id} texts={texts.NOTIFICATIONS_PAGE}
                                                                            notification={n}/>
                                            })}
                                    </div>
                                    :
                                    <div className="text-left pl-5">
                                        {texts.NO_NOTIFICATIONS}
                                    </div>
                                }

                                <div className="np-text-wrapper">
                                    <div className="np-text">{texts.NOTIFICATIONS_PAGE.OLD}:</div>
                                </div>
                                {notifications.older.length > 0 ?
                                    notifications.older.map(n => {
                                        return <NotificationSection key={n.id} texts={texts.NOTIFICATIONS_PAGE}
                                                                    notification={n}/>
                                    })
                                    : <div className="text-left pl-5">
                                        {texts.NO_NOTIFICATIONS}
                                    </div>
                                }
                            </>
                            :
                            <h5>
                                {texts.NO_NOTIFICATIONS}
                            </h5>
                    }
                })())}
            </div>

            <div hidden={isShowMoreButtonHidden} className="mb-2">
                <ShowMoreButton onClick={showMoreButtonHandler} className="m-0" text={texts.SHOW_MORE_TEXT}/>
            </div>

            {allCarts && <ShoppingCartButtonMobile cartPrice={getItemsPrice(allCarts)} cartItems={getItemsCount(allCarts)} texts={texts}/>}

            <BackToTopButton texts={texts}/>

            <Footer texts={texts.FOOTER}/>
        </div>
    );
};

NotificationsPage.propTypes = {};

export default NotificationsPage;