import React from 'react';
import PropTypes from 'prop-types';
import './OrderNumberAndDate.css';
import moment from "moment";
const OrderNumberAndDate = ({texts, order}) => {
    return (
        <div className="order-card-row-1">
            <div className="order-number">
                {texts.ORDERS_PAGE.ORDER_LABEL} <span className="">#{order.code} </span>
            </div>
            <div className="order-date-sent">
                {texts.ORDERS_PAGE.DATE_LABEL}: <span className="">{moment(new Date(order.lastUpdated)).format("DD.MM.YYYY/ HH:mm")}</span>
            </div>
        </div>
    );
};

OrderNumberAndDate.propTypes = {
    texts: PropTypes.object,
    order: PropTypes.object,

};

export default OrderNumberAndDate;