import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import DeliveryIcon from "../../../assets/truck-so.png";
import './ShoppingCartButtonMobile.css';
import {ROUTES} from "../../../config";

const ShoppingCartButtonMobile = ({texts, cartItems, cartPrice}) => {
    return (
        <div className="col-12 p-0 d-lg-none  vp-cart-button-wrapper fixed-bottom">
            <Link to={ROUTES.CUSTOMER.SHOPPING_CART}>
                <button className="vp-cart-button">
                    <div className="d-flex col-12 p-0 mt-2">
                        <div className="col-3 pt-2 cart-mobile-image">
                            <img src={DeliveryIcon} alt=""/>
                        </div>
                        <div className="col-5 pt-2 text-left vp-items-in-cart">
                            {cartItems} {texts.SHOPPING_CART.CART.ITEMS}
                        </div>
                        <div className="col-4 pt-2 vp-total-amount">
                            <p className="">{cartPrice} {texts.SHOPPING_CART.CART.CURRENCY}</p>
                        </div>
                    </div>
                </button>
            </Link>
        </div>
    );
};

ShoppingCartButtonMobile.propTypes = {
    texts: PropTypes.object,
    cartItems: PropTypes.number,
    cartPrice: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ])
};

export default ShoppingCartButtonMobile;