import React, {useEffect, useState} from 'react';
import './OrderView.css';
import CustomerPageHeader from "../CustomerPageHeader/CustomerPageHeader";
import TitleSection from "../TitleSection/TitleSection";
import Checklist from "../../../assets/checklist_icon-white.svg";
import {VENDOR_PAGE as texts} from "../../../Languages/bg";
import GoBackSection from "../GoBackSection/GoBackSection";
import {
    getOrderByIdAndRoleAsync, rateOrder
} from "../../../services/OrderServices";
import AddButton from "./AddButton/AddButton";
import OrderInfo from "./OrderInfo/OrderInfo";
import OrderTotalSection from "../OrderTotalSection/OrderTotalSection";
import ProductCard from "./ProductCard/ProductCard";
import CustomProductCard from "../SingleOrderView/CustomProductCard/CustomProductCard";
import Footer from "../Footer/Footer";
import BackToTopButton from "../BackToTopButton/BackToTopButton";
import ShoppingCartButtonMobile from "../ShoppingCartButtonMobile/ShoppingCartButtonMobile";
import {ORDER_STATUSES} from "../../../config";
import CheckIcon from '../../../assets/check.svg';
import ConfirmDeliveryModal from "./ConfirmDeliveryModal/ConfirmDeliveryModal";
import {
    duplicateHandler, getInitialOrders, getItemsCount,
    getItemsPrice, getOrderTotals, shouldDisplayRateSection,
    changeOrderStatus
} from "../../../utils/OrderUtils";
import {scrollToTop} from "../../../utils/CheckWindowWidth";
import Spinner from "react-bootstrap/Spinner";
import OrderRatingsCard from './OrderRatingsCard/OrderRatingsCard';

const OrderView = props => {
    const [order, setOrder] = useState({});
    const [orderTotals, setOrderTotals] = useState({});
    const [isDelivered, setIsDelivered] = useState();
    const [isConfirmDeliveryModalOpen, setIsConfirmDeliveryModalOpen] = useState(false);
    const [isAddButtonLoading, setIsAddButtonLoading] = useState(false);
    const [allCarts, setAllCarts] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        scrollToTop();
        let id = props.match.params.id;
        getOrder(id);
        getInitialOrders(setAllCarts);
    }, []);

    const getOrder = async (odredId, onSuccess = getOrderResponseHandler) => {
        try {
            let response = await getOrderByIdAndRoleAsync(odredId);
            onSuccess(response)
        } catch (error) {
            
        }
    };

    const getOrderResponseHandler = (response) => {
        let totals = getOrderTotals(response.data);

        setOrderTotals(totals);
        setOrder(response.data);
        setIsLoading(false)
    };

    const changeOrderStatusResponseHandler = (data) => {
        if (data.status === 200) {
            setIsDelivered(true)
        }
    };

    const handleOrderConfirm = () => {
        let ord = {...order};
        ord.status = ORDER_STATUSES.DELIVERED;
        setOrder(ord);
        changeOrderStatus(order.id, ORDER_STATUSES.DELIVERED, changeOrderStatusResponseHandler)
    };

    const rateOrderRequest = async (rate) => {
        try {
            await rateOrder(order.id, rate);
            getOrder(order.id, (response) => setOrder(response.data))
        } catch (error) {
            
        }
        
    };

    const handleRateOrder = (event, criteria) => {
        if (order.status === ORDER_STATUSES.DELIVERED) {
            let rate = {
                "rating": event,
                "ratingCriteria": criteria
            };
            rateOrderRequest(rate);
        } else {
            setIsConfirmDeliveryModalOpen(true)
        }
    };

    const handleModalConfirmation = () => {
        handleOrderConfirm();
        setIsConfirmDeliveryModalOpen(false)
    };

    const handleOrderDuplicate = async (order) => {
        setIsAddButtonLoading(true)
        await duplicateHandler(order, props, () => setIsAddButtonLoading(false));
    };

return (
    <div>
        <CustomerPageHeader ordersSum={allCarts && getItemsPrice(allCarts)} />

        <TitleSection icon={Checklist} leftText={texts.ORDERS_PAGE.ORDER_LABEL}/>

        <GoBackSection text={texts.SHOPPING_CART.GO_BACK} />

        <div className="order-body-wrapper">
            {((() => {
                if (isLoading) {
                    return <Spinner variant={"success"} animation={"border"} />
                } else {
                    return (
                        <>
                            <div id="stick">
                                {order.id && <OrderInfo onClick={handleOrderDuplicate} order={order} isLoading={isAddButtonLoading} />}
                            </div>
                            <div className="d-lg-flex">
                                {order.id &&
                                <div className="side-wrapper">
                                    <div className="ov-order-total-section">
                                        <OrderTotalSection texts={texts.SHOPPING_CART}
                                                           totalPriceWithVat={orderTotals.totalPriceWithVat}
                                                           vat={orderTotals.vat}
                                                           totalPrice={orderTotals.totalPrice}
                                                           totalItems={order.orderItems.length + order.customOrderItems.length}/>

                                        <div className="ov-pos-name-wrapper">
                                            <div className="ov-pos-label">
                                                {texts.SHOPPING_CART.DELIVERY_POINT}:
                                            </div>
                                            <div className="ov-pos-name">
                                                {order.pointOfSale.name}
                                            </div>
                                        </div>

                                        <div className="ov-pos-address-wrapper">
                                            <div className="ov-pos-label">
                                                {texts.SHOPPING_CART.DELIVERY_ADDRESS}:
                                            </div>
                                            <div className="ov-pos-name">
                                                {order.pointOfSale.shippingAddress}
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="ov-pos-label">
                                                {texts.SHOPPING_CART.DELIVERY_COMMENT}:
                                            </div>
                                            <div className="">
                                                {order.comment}
                                            </div>
                                        </div>
                                    </div>

                                    <div id="rate-to-stick"  className="d-none d-lg-block">
                                        {order.status === ORDER_STATUSES.DELIVERED &&
                                        <div>
                                            <div className="p-3">
                                                <img style={{"width": "0.9em"}} src={CheckIcon} alt=""/>
                                                <span className="pl-2"> {texts.ORDERS_PAGE.DELIVERED_ORDER} </span>
                                            </div>
                                        </div>
                                        }
                                        {shouldDisplayRateSection(order) &&
                                            <OrderRatingsCard
                                                isDelivered={isDelivered}
                                                order={order}
                                                handleRateOrder={handleRateOrder}
                                                handleOrderConfirm={handleOrderConfirm} />
                                        }
                                    </div>
                                </div>
                                }

                                <div className="w-100">
                                    <div className="ov-products-header-wrapper">
                                        <div className="ov-products-header-name">
                                            {texts.ORDERS_PAGE.CODE_AND_NAME}
                                        </div>
                                        <div className="ov-products-header-cells">
                                            <div className="ov-measure-unit">
                                                <div className="bordered-right">{texts.ORDERS_PAGE.DU}</div>
                                            </div>
                                            <div className="ov-single-vat-price">
                                                <div className=""> {texts.PRODUCT_CARD.PRODUCT_PRICE_VAT} </div>
                                            </div>
                                            <div className="ov-quantity">
                                                <div className="bordered-right bordered-left">{texts.ORDERS_PAGE.QUANTITY}</div>
                                            </div>
                                            <div className="ov-price-vat">
                                                <div className=""> {texts.PRODUCT_CARD.PRODUCT_PRICE} </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ov-products-wrapper">
                                        {order.id && order.orderItems.map(item => {
                                            return <ProductCard key={item.id + item.displayName}
                                                                texts={texts}
                                                                item={item}
                                            />
                                        })}

                                        {order.id && order.customOrderItems.map((item, index) => {
                                            return (
                                                <div key={item.itemName + index} className="ov-custom-product-wrapper">
                                                    <CustomProductCard item={item} hideDelete/>
                                                </div>
                                            )
                                        })}

                                    </div>
                                    <div className="ov-add-btn-bottom-wrapper">
                                        <AddButton isLoading={isAddButtonLoading}  onClick={() => handleOrderDuplicate(order)} text={texts.ORDERS_PAGE.ADD_TO_CART}/>
                                    </div>

                                    <div className="d-lg-none">
                                        {order.status === ORDER_STATUSES.DELIVERED &&
                                        <div>
                                            <div className="p-3">
                                                <img style={{"width": "0.9em"}} src={CheckIcon} alt=""/>
                                                <span className="pl-2">{texts.ORDERS_PAGE.DELIVERED_ORDER}</span>
                                            </div>
                                        </div>
                                        }
                                        {shouldDisplayRateSection(order) &&
                                            <OrderRatingsCard 
                                                isDelivered={isDelivered}
                                                order={order}
                                                handleRateOrder={handleRateOrder}
                                                handleOrderConfirm={handleOrderConfirm} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            })())}

        </div>

        {allCarts && <ShoppingCartButtonMobile cartPrice={getItemsPrice(allCarts)} cartItems={getItemsCount(allCarts)} texts={texts}/>}

        <BackToTopButton texts={texts}/>

        <Footer texts={texts.FOOTER}/>

        <ConfirmDeliveryModal texts={texts}
                              show={isConfirmDeliveryModalOpen}
                              onClick={handleModalConfirmation}
                              onHide={() => setIsConfirmDeliveryModalOpen(false)}/>
    </div>
);
};

export default OrderView;