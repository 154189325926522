import React, {useEffect, useState} from 'react';
import './VendorOrderView.css';
import AdminAndVendorPageHeader from "../../common/AdminAndVendorPageHeader/AdminAndVendorPageHeader";
import {toggleSidebar} from "../../../utils/SidebarUtils";
import texts from "../../../Languages/bg";
import InfoCard from "../../common/InfoCard/InfoCard";
import {getCustomerById} from "../../../utils/CustomerUtils";
import {getOrderTotals, showDataAccordingToStatus, changeOrderStatus, getOrderById} from "../../../utils/OrderUtils";
import {ORDER_STATUSES} from "../../../config";
import OrderTable from "./OrderTable";
import AdminAndVendorFooter from "../../common/AdmivAndVendorFooter/AdminAndVendorFooter";
import ButtonsSection from "../ButtonsSection/ButtonsSection";
import InfoCardDelivery from "../../common/InfoCardDelivery/InfoCardDelivery";
import InfoCardClient from "../../common/InfoCardClient/InfoCardClient";
import InfoCardStatus from "../../common/InfoCardStatus/InfoCardStatus";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

const VendorOrderView = props => {

    const [order, setOrder] = useState({});
    const [customer, setCustomer] = useState({});
    const [orderTotals, setOrderTotals] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const orderId = props.match.params.id;
        getOrderById(orderId, getOrderResponseHandler);
    }, []);

    const getOrderResponseHandler = (data) => {
        let totals = getOrderTotals(data);
        setOrderTotals(totals);
        setOrder(data);
        if (!customer.id) {
            getCustomerById(data.customerId, setCustomer)
        }
        setIsLoading(false)
    };

    const changeOrderStatusResponseHandler = (res) => {
        if (res.status === 200) {
            getOrderById(order.id, getOrderResponseHandler);
        }
    };

    const handleOrderStatusChange = (status) => {
        changeOrderStatus(order.id, status, changeOrderStatusResponseHandler)
    };

    const getSecondButtonType = (status) => {
        if (status === ORDER_STATUSES.ACCEPTED) {
            return {
                text: texts.VENDOR_PANEL.BTN_DELIVER,
                icon: <i className="far fa-thumbs-up"></i>,
                className: "btn-info ",
                onClick: () => handleOrderStatusChange(ORDER_STATUSES.DELIVERED)
            }
        } else if (status === ORDER_STATUSES.PENDING) {
            return {
                text:texts.VENDOR_PANEL.BTN_ACCEPT,
                icon: <i className="fas fa-check"></i>,
                className: "btn-success mt-2 mt-lg-0",
                onClick: () => handleOrderStatusChange(ORDER_STATUSES.ACCEPTED)
            }
        }
    }; 

    return (
        <div>
            <AdminAndVendorPageHeader onClick={toggleSidebar}  text={`${texts.VENDOR_PANEL.ORDER_PAGE_TITLE} ${order.code}`}/>
            {((() => {
                if (isLoading) {
                    return <Spinner animation={"border"} variant={"primary"} />
                } else {
                    return (
                        <>
                            <div className="vov-text-buttons-wrapper">
                                {order.status && <ButtonsSection className="d-md-flex"
                                                                 disableAll={order.status === ORDER_STATUSES.REJECTED}
                                                                 text={order.status && showDataAccordingToStatus(order.status).text}
                                                                 showIcons={order.status !== ORDER_STATUSES.PENDING}
                                                                 hideButtons={order.status !== ORDER_STATUSES.PENDING}
                                                                 firstButtonProperties={{
                                                                     text: texts.VENDOR_PANEL.BTN_REJECT,
                                                                     icon: <i className="fas fa-times"></i>,
                                                                     className: "btn-danger text-left mr-lg-4",
                                                                     onClick: () => handleOrderStatusChange(ORDER_STATUSES.REJECTED)
                                                                 }}
                                                                 secondButtonProperties={{
                                                                     text: texts.VENDOR_PANEL.BTN_ACCEPT,
                                                                     icon: <i className="fas fa-check"></i>,
                                                                     className: "btn-success mt-2 mt-lg-0",
                                                                     onClick: () => handleOrderStatusChange(ORDER_STATUSES.ACCEPTED)
                                                                 }}
                                                                 order={order}
                                                                 onExportClick={() => setShowPopup(true)}

                                />}

                            </div>


                            {orderTotals.vat &&
                            <div className="vov-info-cards-wrapper">

                                <InfoCardDelivery order={order} />

                                {customer.id && <InfoCardClient customer={customer} />}

                                <InfoCardStatus order={order} customerInfo={customer} onStatusChange={handleOrderStatusChange} />

                                <InfoCard customBody id="total" title={texts.VENDOR_PANEL.INFO_TOTAL}>
                                    <div className="w-lg-35 text-left">
                                        <div>
                                            <span className=" font-weight-bold">{order.orderItems && order.orderItems.length + order.customOrderItems.length} </span>
                                            {texts.VENDOR_PAGE.SHOPPING_CART.CART.ITEMS}
                                        </div>
                                        <div>
                                            <span className=" font-weight-bold">{orderTotals.totalItemsQuantity + orderTotals.customItemsQuantity} </span>{texts.VENDOR_PAGE.SHOPPING_CART.UNITS}
                                        </div>
                                    </div>
                                    <div className="w-lg-65 text-left h5">
                                        <div className="d-flex justify-content-between">
                                            <div> {texts.VENDOR_PAGE.SHOPPING_CART.TOTAL_PRICE} </div>
                                            <div className=""> {orderTotals.totalPrice} {texts.VENDOR_PAGE.PRODUCT_CARD.CURRENCY}</div>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <div> {texts.VENDOR_PAGE.SHOPPING_CART.VAT} </div>
                                            <div> {orderTotals.vat} {texts.VENDOR_PAGE.PRODUCT_CARD.CURRENCY}</div>
                                        </div>
                                        <div className="d-flex justify-content-between border-top mt-2 pt-1 font-weight-bold">
                                            <div> {texts.VENDOR_PAGE.SHOPPING_CART.TOTAL_PRICE_VAT} </div>
                                            <div> {orderTotals.totalPriceWithVat} {texts.VENDOR_PAGE.SHOPPING_CART.CART.CURRENCY} </div>
                                        </div>
                                    </div>
                                </InfoCard>

                            </div>}

                            <div className="vov-table-wrapper">
                                <div className="card shadow">
                                    <a href="#data-table" className="card-header text-uppercase h5 text-left" data-toggle="collapse">
                                        {texts.VENDOR_PANEL.ORDER_TABLE_TITLE}
                                    </a>
                                    <div className="card-body p-2 collapse show" id="data-table">
                                        {order.id && <OrderTable order={order} orderTotals={orderTotals}/>}
                                    </div>
                                </div>
                            </div>
                            <div className="text-lg-right m1p">
                                <i>{texts.VENDOR_PANEL.APPROXIMATE_PRICES}.</i>
                            </div>

                            <div className="vov-text-buttons-wrapper mt-4 mb-4">
                                {order.status && <ButtonsSection className="d-md-flex"
                                                                 text={order.status && showDataAccordingToStatus(order.status).text}
                                                                 hideButtons={order.status === ORDER_STATUSES.REJECTED || order.status === ORDER_STATUSES.DELIVERED}
                                                                 firstButtonProperties={{
                                                                     text: texts.VENDOR_PANEL.BTN_REJECT,
                                                                     icon: <i className="fas fa-times"></i>,
                                                                     className: "btn-danger text-left mr-lg-4",
                                                                     onClick: () => handleOrderStatusChange(ORDER_STATUSES.REJECTED)
                                                                 }}
                                                                 secondButtonProperties={getSecondButtonType(order.status)}/>
                                }
                            </div>

                        </>
                    )
                }
            })())}
            <Modal show={showPopup} onHide={() => setShowPopup(false)}
                   dialogClassName="ov-confirm-dialog">
                <Modal.Header closeButton/>

                <div className="ov-confirm-delivery-modal-text p-4">
                    <div> {texts.VENDOR_PANEL.POPUP_INFO.CONTACT_FOR_EXPORT.FIRST_ROW} </div>
                    <br/>
                    <div> {texts.VENDOR_PANEL.POPUP_INFO.CONTACT_FOR_EXPORT.SECOND_ROW} </div>
                </div>

                <div className="text-center p-4">
                    <button onClick={() => setShowPopup(false)} className="btn btn-success">
                        <span className="text">{texts.VENDOR_PANEL.POPUP_INFO.SEND_INQUIRY_BTN}</span>
                    </button>
                </div>
            </Modal>

            <div className="bg-white">
                <AdminAndVendorFooter texts={texts.VENDOR_PAGE.FOOTER}/>
            </div>
        </div>
    );
};

VendorOrderView.propTypes = {};

export default VendorOrderView;