import React from 'react';
import PropTypes from 'prop-types';
import './PackageSelectPill.css'
const PackageSelectPill = ({units, quantity, onClick, isActive}) => {
    return (
        <button disabled={isActive}
             onClick={onClick}
             className={`vp-units-pill bg-none ${isActive && "active"}`}>
            {units} {quantity}
        </button>
    );
};

PackageSelectPill.propTypes = {
    units: PropTypes.number,
    quantity: PropTypes.string,
    onClick: PropTypes.func,
    isActive: PropTypes.bool
};

export default PackageSelectPill;