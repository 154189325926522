import React, {useEffect, useState} from 'react';
import CustomerPageHeader from "../CustomerPageHeader/CustomerPageHeader";
import {getItemsCount, getItemsPrice, getInitialOrders} from "../../../utils/OrderUtils";
import TitleSection from "../TitleSection/TitleSection";
import GoBackSection from "../GoBackSection/GoBackSection";
import UserIcon from '../../../assets/user_icon-white.svg';
import UsernameAndLogoutSection from "../../common/UsernameAndLogoutSection/UsernameAndLogoutSection";
import {getUserData} from "../../../services/LocalStorageServices";
import UserProfileForm from "../../common/UserProfileForm/UserProfileForm";
import ShoppingCartButtonMobile from "../ShoppingCartButtonMobile/ShoppingCartButtonMobile";
import {texts} from '../../../Languages/Language'; 
import {
    editUserProfileErrorHandler, editUserProfileResponseHandler,
    handleErrors,
    handleProfileDataChange, handleProfileSaveClick
} from "../../../utils/UserUtils";
import Footer from "../Footer/Footer";
import {shouldResetPassword, showNotificationFrorPasswordReset} from "../../../utils/LoginUtils";
import {editUserPasswordResponseHandler} from "../../../utils/PasswordUtils";
import {scrollToTop} from "../../../utils/CheckWindowWidth";

const CustomerProfile = props => {
    const [ordersWithStatusInitial, setOrdersWithStatusInitial] = useState([]);
    const [user, setUser] = useState(getUserData());
    const [passwords, setPasswords] = useState({password: "", repeat_password: ""});
    const resetPassword = shouldResetPassword(props);

    useEffect(() => {
        scrollToTop();
        getInitialOrders(setOrdersWithStatusInitial);
        if (resetPassword) {
          showNotificationFrorPasswordReset(texts)
        }
    }, []);


    const handleSaveClick = (userData) => {
        handleProfileSaveClick(userData, passwords, editUserResponseHandler, editUserErrorHandler)
    };

    const editUserResponseHandler = (response, updatePassword) => {
        editUserProfileResponseHandler(response, updatePassword, setUser, passwords, editPasswordResponseHandler, editPasswordErrorHandler, props)
    };

    const editUserErrorHandler = (error) => {
        editUserProfileErrorHandler(error, passwords, user.id, editPasswordResponseHandler, editPasswordErrorHandler)
    };

    const editPasswordResponseHandler = (response) => {
        editUserPasswordResponseHandler(response, resetPassword, props)
    };

    const editPasswordErrorHandler = (err) => {
        handleErrors(err, texts)
    };

    return (
        <div>
            <CustomerPageHeader ordersSum={getItemsPrice(ordersWithStatusInitial)}/>

            <TitleSection icon={UserIcon} leftText={texts.VENDOR_PAGE.PROFILE}/>

            <GoBackSection text={texts.VENDOR_PAGE.SHOPPING_CART.GO_BACK}/>

            <div className="bg-so-primary pb-5">
                <UsernameAndLogoutSection className="customer-profile-username" user={user}/>

                <div className="cp-form-wrapper">
                    <div className="text-left h4 p-2 ">{texts.ADMIN_PANEL.USERS.PROFILE_INFO}</div>
                    <UserProfileForm user={user} onChange={(e, shouldTrim) => handleProfileDataChange(e, user, setUser, passwords, setPasswords, shouldTrim)}/>
                    <div className="p-2 text-right">
                        <button onClick={() => handleSaveClick(user)} className="btn-so-green">
                            {texts.ADMIN_PANEL.USERS.SAVE_BTN}
                        </button>
                    </div>
                </div>

            </div>

            <ShoppingCartButtonMobile texts={texts.VENDOR_PAGE} cartPrice={getItemsPrice(ordersWithStatusInitial)}
                                      cartItems={getItemsCount(ordersWithStatusInitial)}/>
            <Footer texts={texts.VENDOR_PAGE.FOOTER}/>
        </div>
    );
};

export default CustomerProfile;