import React from 'react';
import PropTypes from 'prop-types';
import {checkIsSorted, createColumns} from "../../../utils/TableUtils";
import texts from "../../../Languages/bg";
import {useSortBy, useTable} from "react-table";
import ColumnHideCheckboxes from "../../common/ColumnHideCheckboxes/ColumnHideCheckboxes";
import {getStatusInfo} from "../../../utils/OrderUtils";

const AdminOrderTable = ({order}) => {

    const createRows = (data) => {
        let arr = [];
        data.orderItems.map(orderItem => {
            let deliveryUnitPrice = orderItem.sum / orderItem.quantity;
            let obj = {
                sosku: orderItem.sosku,
                erpId: orderItem.erpId,
                productName: orderItem.displayName,
                erpProductName: orderItem.name,
                deliveryUnit: `x ${orderItem.selectedPackage.packagingUnit} ${orderItem.selectedPackage.packagingType}`,
                deliveryUnitPrice: deliveryUnitPrice.toFixed(2),
                quantity: orderItem.quantity,
                amount: `${orderItem.sum.toFixed(2)} ${texts.VENDOR_PAGE.SHOPPING_CART.CART.CURRENCY}`,
                promotion: "10%",
                comment: orderItem.comment,
            };

            arr.push(obj);
        });

        data.customOrderItems.map(customItem => {
            let obj = {
                sosku: "",
                erpId: "",
                productName: customItem.itemName,
                erpProductName: "",
                deliveryUnit: "",
                deliveryUnitPrice: "",
                quantity: customItem.quantity,
                amount: ``,
                promotion: "",
                comment: customItem.comment,
            };
            arr.push(obj);
        });

        return arr
    };

    const columns = React.useMemo(() => createColumns(texts.ADMIN_PANEL.SINGLE_ORDER_TABLE_COLUMNS), [order]);
    const data = React.useMemo(() => createRows(order), []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        allColumns,
    } = useTable({
            columns,
            data,
            initialState: {
                hiddenColumns: ["erpProductName", "sosku", "erpId", "amount", "promotion", "deliveryUnitPrice"]
            }
        },
        useSortBy);

    return (
        <>
            <ColumnHideCheckboxes columns={allColumns}/>
            <table {...getTableProps()} className="table table-striped table-bordered table-sm">
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr  {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th scope="col" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                            {checkIsSorted(column)}
                            </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td data-label={cell.column.Header}
                                           className={`text-dark ${cell.column.id === "status" ? getStatusInfo(cell.value).bg : null}`}
                                           {...cell.getCellProps()}>{cell.render('Cell')}
                                </td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </>
    );
};

AdminOrderTable.propTypes = {
    order: PropTypes.object
};

export default AdminOrderTable;