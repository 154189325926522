import React, {useEffect} from 'react';
import {Switch} from "react-router-dom";
import {PrivateRoute} from "../../../PrivateRoute";
import {ROUTES, USER_ROLES} from "../../../config";
import CustomerHomePage from "../HomePage/HomePage";
import VendorPage from "../VendorPage/VendorPage";
import ShoppingCart from "../ShoppingCart/ShoppingCart";
import SingleOrderView from "../SingleOrderView/SingleOrderView";
import AllOrdersPage from "../AllOrdersPage/AllOrdersPage";
import OrderView from "../OrderView/OrderView";
import NotificationsPage from "../NotificationsPage/NotificationsPage";
import CustomerProfile from "../CustomerProfile/CustomerProfile";
import {isLargeScreen, scrollToTop} from "../../../utils/CheckWindowWidth";

const CustomerMainPage = props => {

    useEffect(() => {
        if (!isLargeScreen(window.innerWidth)) {
            scrollToTop();
        }
    },[props.location.pathname]);

    return (
        <div>
            <Switch>
                <PrivateRoute role={USER_ROLES.CUSTOMER} exact path={ROUTES.CUSTOMER.CUSTOMER_HOME} component={CustomerHomePage} />

                <PrivateRoute role={USER_ROLES.CUSTOMER}  path={ROUTES.CUSTOMER.VENDOR_PAGE} component={VendorPage}/>

                <PrivateRoute role={USER_ROLES.CUSTOMER}  exact path={ROUTES.CUSTOMER.SHOPPING_CART} component={ShoppingCart}/>

                <PrivateRoute role={USER_ROLES.CUSTOMER}  path={ROUTES.CUSTOMER.ORDER_WITH_STATUS_INITIAL_VIEW.ROUTE} component={SingleOrderView}/>

                <PrivateRoute role={USER_ROLES.CUSTOMER} exact path={ROUTES.CUSTOMER.ALL_ORDERS_PAGE} component={AllOrdersPage}/>

                <PrivateRoute role={USER_ROLES.CUSTOMER}  path={ROUTES.CUSTOMER.SENT_ORDER_VIEW.ROUTE} component={OrderView}/>

                <PrivateRoute role={USER_ROLES.CUSTOMER} path={ROUTES.CUSTOMER.NOTIFICATIONS_PAGE} component={NotificationsPage} />

                <PrivateRoute role={USER_ROLES.CUSTOMER} exact path={ROUTES.CUSTOMER.PROFILE} component={CustomerProfile} />
            </Switch>
        </div>
    );
};

export default CustomerMainPage;