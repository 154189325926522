import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './CustomerPageHeader.css';
import DeliveryIcon from '../../../assets/truck-so.png';
import SOLogo from '../../../assets/logo_shift.svg';
import {Link} from "react-router-dom";
import {getNewNotifications} from "../../../services/NotificationServices";
import {ROUTES} from "../../../config";
import texts from '../../../Languages/bg';
import {withRouter} from 'react-router-dom';
import {redirectToProfilePage} from "../../../utils/UserUtils";
import {getUserData} from "../../../services/LocalStorageServices";
import HeaderSearch from "../HeaderSearch/HeaderSearch";
import {isLargeScreen} from "../../../utils/CheckWindowWidth";

const CustomerPageHeader = ({ordersSum, isCartHidden, showSearch, onSearch, inNotificationsPage, searchRef, onClearClick, handleKeyPress, history, clearIconHidden}) => {

    const [newNotifications, setNewNotifications] = useState(0);

    useEffect(() => {
        getNotifications();

        let getNotificationsWithInterval = setInterval(getNotifications, 10000);

        return () => clearInterval(getNotificationsWithInterval)
    }, []);

    const getNotifications = async () => {
        try {
            let response = await getNewNotifications();
            setNewNotifications(response.data);

            if (inNotificationsPage) {
                setNewNotifications(0)
            }
        } catch (error) { }
    };

    return (
        <div id="customer-header" className={`vp-header-wrapper  `}>
            <div className={`vp-header  d-flex`}>
                <div className="vp-site-logo-wrapper">
                   <Link to={`${ROUTES.CUSTOMER.CUSTOMER_HOME}`}>
                       <img className="vp-site-logo" src={SOLogo} alt=""/>
                   </Link>
                </div>


                <div className="header-search-wrapper">
                    {showSearch && isLargeScreen(window.innerWidth) &&
                        <HeaderSearch wrapperClass={"d-none d-lg-flex justify-content-between"}
                                      handleKeyPress={handleKeyPress}
                                      onClearClick={onClearClick}
                                      searchRef={searchRef}
                                      clearIconHidden={clearIconHidden}
                                      onSearch={onSearch}/>
                    }
                </div>

                <div className="vp-header-icons-wrapper">
                    <div hidden={isCartHidden} className="vp-header-cart">
                        <Link to={ROUTES.CUSTOMER.SHOPPING_CART}>
                            <button className="header-delivery">
                                {ordersSum} {texts.VENDOR_PAGE.PRODUCT_CARD.CURRENCY} <img src={DeliveryIcon} alt=""/>
                            </button>
                        </Link>
                    </div>

                    <div className="orders-wrapper">
                        <Link className="orders-link" to={ROUTES.CUSTOMER.ALL_ORDERS_PAGE}>
                            <div className="past-orders-wrap">
                                <span className="past-orders"> {texts.VENDOR_PAGE.PAST_ORDERS} </span> <i className="fas fa-history nav-image"></i>
                            </div>

                        </Link>
                    </div>

                   <div className="notifications-wrapper">
                      <Link to={ROUTES.CUSTOMER.NOTIFICATIONS_PAGE}>
                          <span hidden={newNotifications === 0} className="red-dot"></span>
                          <div className="image-notification">
                              <i className="fas fa-bell nav-image "></i>
                          </div>
                      </Link>
                   </div>


                    <div className="user-profile-wrapper">
                        <div onClick={() => redirectToProfilePage(getUserData().type, history)} className="image-user c-pointer">
                            <i className="fas fa-user-cog nav-image"></i>
                        </div>
                    </div>
                </div>
            </div>

            {showSearch && !isLargeScreen(window.innerWidth) &&
                <HeaderSearch wrapperClass={"justify-content-between d-flex d-lg-none mt-2"}
                              handleKeyPress={handleKeyPress}
                              onClearClick={onClearClick}
                              searchRef={searchRef}
                              clearIconHidden={clearIconHidden}
                              onSearch={onSearch}/>
            }

        </div>
    );
};

CustomerPageHeader.propTypes = {
    ordersSum: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    isCartHidden: PropTypes.bool,
    showSearch: PropTypes.bool,
    onSearch: PropTypes.func,
    inNotificationsPage: PropTypes.bool,
    searchRef: PropTypes.object,
    onClearClick: PropTypes.func,
    handleKeyPress: PropTypes.func,
    history: PropTypes.object,
    clearIconHidden: PropTypes.bool
};

export default withRouter(CustomerPageHeader);