import React from 'react';
import TableInputField from '../components/common/TableInputField/TableInputField';
import { createGlobalProductRequest, editGlobalProductAsync, getGlobalProductById, getGlobalProductsAsync, matchVendorProductToGlobalProductAsync, uploadGlobalProductImageAsync } from "../services/GlobalProductServices";
import {texts} from '../Languages/Language';

export const getGlobalProducts = async (onSuccess, selectedCategory, searchedProduct, vendorId, page, size) => {
    try {
        let response = await getGlobalProductsAsync(selectedCategory, searchedProduct, vendorId, page, size);
        onSuccess(response);
    } catch (error) {
        
    }
};

export const uploadProductImage = async (productId, imageNumber, event, onSuccess) => {
    try {
        let response = await uploadGlobalProductImageAsync(productId, imageNumber, event, onSuccess);
        onSuccess(response);
    } catch (error) {
        
    }
};

export const editGlobalProduct = async (body, onSuccess, onError) => {
    try {
        let response = await editGlobalProductAsync(body);
        onSuccess(response, body)
    } catch (error) {
        onError(error)
    }
};

export const createBodyForTagsAndCategoriesUpdate = (product, type, categoryToChange, tagsInputRef) => {
    switch (type) {
        case "change_cat":
            return {
                id: product.id,
                categories: [
                    {
                        id: categoryToChange
                    }
                ]
            };
        case "add_cat":
            return {
                id: product.id,
                categories: [
                    ...product.categories,
                    {
                        id: categoryToChange
                    }
                ]
            };
        case "change_tags":
            return {
                id: product.id,
                tags: tagsInputRef.current.value
            };
        case "add_tags":
            return {
                id: product.id,
                tags: product.tags + " " + tagsInputRef.current.value
            };
        default:
            break;
    }
};

export const showSubrow = (name, sosku) => {
    document.getElementById(name + sosku).hidden = !document.getElementById(name + sosku).hidden;
    let isExpanded = document.getElementById("table-expand" + sosku).getAttribute("aria-expanded") === "true";
    let expanded = isExpanded ? "false" : "true";

    document.getElementById("table-expand" + sosku).setAttribute("aria-expanded", expanded)
};

export const displayVendorProductPackages = (packaging, vendorProduct, vendorProductIndex, type, packagingIndex, onChange) => {
    switch (type) {
        case "price":
            if (vendorProduct.isEditable) {
                return <>
                    <TableInputField name="price"
                                     product={vendorProduct}
                                     type="number"
                                     onChange={(e) => onChange(e, packagingIndex, vendorProduct, vendorProductIndex)}
                                     defaultValue={packaging ? packaging.packagingUnitPriceVAT : texts.VENDOR_PANEL.NONE}
                                     isDisabled={packagingIndex > 0 && !vendorProduct.packagingList[packagingIndex -1]}
                                     />
                    {packaging && texts.VENDOR_PAGE.PRODUCT_CARD.CURRENCY}
                </>
            } else {
                if (packaging) {
                    if (packaging.packagingUnitPriceVAT > 0) {
                        return <> {packaging.packagingUnitPriceVAT} {texts.VENDOR_PAGE.PRODUCT_CARD.CURRENCY} </>
                    } else {
                        return texts.VENDOR_PAGE.PRODUCT_CARD.NO_PRICE
                    }
                } else {
                    return texts.VENDOR_PANEL.NONE
                }
            }

        case "units":
            if (vendorProduct.isEditable) {
                return <TableInputField name="units"
                                        product={vendorProduct}
                                        type="number"
                                        onlyIntegers={true}
                                        onChange={(e) => onChange(e, packagingIndex, vendorProduct, vendorProductIndex)}
                                        defaultValue={packaging ? packaging.packagingUnit : texts.VENDOR_PANEL.NONE}
                                        isDisabled={packagingIndex > 0 && !vendorProduct.packagingList[packagingIndex -1]}
                                        />

            } else {
                if (packaging) {
                    return <> {packaging && 'x'} {packaging.packagingUnit} {packaging && packaging.packagingType} </>
                } else {
                    return texts.VENDOR_PANEL.NONE
                }
            }
        default:
            break;
    }
};

export const getGlobalProduct = async (globalProductId, onSuccess, onError) => {
    try {
        let response = await getGlobalProductById(globalProductId);
        onSuccess(response);
    } catch (error) {
        onError(error)
    }
};

export const createGlobalProduct = async (product, onSuccess, onError) => {
    try {
        let response = await createGlobalProductRequest(product);
        onSuccess(response);
    } catch (error) {
        onError(error);
    }
};

export const matchProducts = async (products, onSuccess, onError) => {
    try {
        let response = await matchVendorProductToGlobalProductAsync(products);
        onSuccess(response);
    } catch (error) {
        if (error.response) {
            onError(error)
        }
    }
};

export const calcSinglePrices = (packaging, priceNotAssign) => {
    let price = priceNotAssign;
    let priceWithVAT = priceNotAssign;
    if (Number(packaging.packagingUnitPriceVAT) > 0) {
        price =  packaging.packagingUnitPrice / packaging.packagingUnit;
        priceWithVAT = packaging.singleItemPriceWithVAT
    }

    return {
        price: price,
        priceWithVAT: priceWithVAT
    }
};