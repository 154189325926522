import React from 'react';
import PropTypes from 'prop-types';
import './OrderButton.css';
import { Spinner } from 'react-bootstrap';

const OrderButton = ({texts, isDesktopLast, onClick, shouldWait}) => {
    return (
        <div className={`sc-order-btn-wrapper ${isDesktopLast && "sc-order-button-desktop-wrapper"}`}>
            <button disabled={shouldWait} onClick={onClick} className={`sc-order-btn ${isDesktopLast && "sc-order-button-desktop"}`}>
                {shouldWait ? <Spinner variant="success" animation="border" /> : texts.SEND_ORDER}
            </button>
        </div>
    );
};

OrderButton.propTypes = {
    isDesktopLast: PropTypes.bool,
    onClick: PropTypes.func,
    texts: PropTypes.object,
    shouldWait: PropTypes.bool
};

export default OrderButton;