import React from 'react';
import PropTypes from 'prop-types';
import Modal from "react-bootstrap/Modal";
import texts from "../../../../Languages/bg";

const ChangeCategoriesAndTagsPopup = ({show, onHide, headerText, children, onAccept, isAcceptDisabled}) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                {headerText}
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onHide} className="btn btn-danger">{texts.ADMIN_PANEL.BTN_DECLINE}</button>
                <button disabled={isAcceptDisabled} onClick={onAccept} className="btn btn-primary border-radius-5">{texts.ADMIN_PANEL.BTN_CHANGE}</button>
            </Modal.Footer>
        </Modal>
    );
};

ChangeCategoriesAndTagsPopup.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    headerText: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    onAccept: PropTypes.func
};

export default ChangeCategoriesAndTagsPopup;