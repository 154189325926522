import React from 'react';
import PropTypes from 'prop-types';
import TableRow from "../../MatchByName/TableRow/TableRow";
import {texts} from '../../../../Languages/Language'

const MatchingProductTable = ({globalProduct}) => {

    return (
        <div className="pt-4 pl-5 pr-5">
            <table className="table table-sm table-bordered table-striped">
                <thead className="bg-primary text-white">
                <tr>
                    {Object.values(texts.ADMIN_PANEL.MATCH_STEP_2.TABLE_HEADERS).slice(1)
                        .map(th => (
                            <th className="">{th}</th>
                        ))}
                </tr>
                </thead>
                <tbody className="font-weight-bold">
                <TableRow product={globalProduct}/>
                </tbody>
            </table>
        </div>
    );
};

MatchingProductTable.propTypes = {
    globalProduct: PropTypes.object
};

export default MatchingProductTable;