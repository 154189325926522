import React, {useEffect, useState} from 'react';
import './NewGlobalProductPage.css';
import {texts} from '../../../Languages/Language';
import ProductInformationSection from "./ProductInformationSection/ProductInformationSection";
import ProductCategoriesSection from "./ProductCategoriesSection/ProductCategoriesSection";
import ProductOthersSection from "./ProductOthersSection/ProductOthersSection";
import ProductImagesSection from "./ProductImagesSection/ProductImagesSection";
import ArrowBack from '../../../assets/go_back_icon.svg';
import {createSelectOptionsFromArray} from "../../../utils/SelectUtils";
import {
    getGlobalProductImage
} from "../../../services/GlobalProductServices";
import GlobalProductContext from "./GlobalProductContext";
import {getCategories} from "../../../utils/CategoryUtils";
import {notify} from "../../../utils/Notifications";
import MatchingProductTable from "./МаtchingProductTable/МаtchingProductTable";
import VendorProductAttributesSection from "./VendorProductAttributesSection/VendorProductAttributesSection";
import {PRODUCT_STATUS, ROUTES, MEASURE_UNITS, NET_WEIGHT_UNITS} from "../../../config";
import {handleErrors} from "../../../utils/UserUtils";
import {checkRequiredFields, redirectWithState} from "../../../utils/Utils";
import {createNewOrigin, getAllOrigins } from '../../../utils/OriginUtils';
import {createNewBrand, getAllBrands} from '../../../utils/BrandUtils';
import {createNewQuality, getAllQualities} from '../../../utils/QualityUtils';
import {createNewShelflife, getAllShelflives} from '../../../utils/ShelflifeUtils';
import {getGlobalProduct, editGlobalProduct, uploadProductImage, createGlobalProduct, matchProducts, calcSinglePrices } from '../../../utils/GlobalProductUtils';
import { matchProductErrorHandler } from '../../../utils/MatchUtils';

const NewGlobalProductPage = props => {
    const NEW_GLOBAL_PRODUCT = texts.NEW_GLOBAL_PRODUCT;
    const requiredFields = ['status', 'measureUnit', 'productName'];
    const [product, setProduct] = useState({status: PRODUCT_STATUS.AVAILABLE});
    const [itemToAdd, setItemToAdd] = useState({origin: {}, quality: {}, shelfLife: {}, brand: {}});
    const [brands, setBrands] = useState();
    const [origins, setOrigins] = useState();
    const [qualities, setQualities] = useState();
    const [shelfLives, setShelfLives] = useState();
    const [categories, setCategories] = useState([]);
    const [fromMatch, setFromMatch] = useState(false);
    const [fromEdit, setFromEdit] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [images, setImages] = useState({});

    useEffect(() => {
        const from = props.match.params.from;
        const fromMatchPage = from === "match";
        const fromEditPage = from === "edit";
        if (fromMatchPage) {
            setFromMatch(true);
            let productDTO = props.location.state.data.noMatches[0];
            productDTO.status = productDTO.productStatus;
            setProduct(productDTO)
            if (productDTO.image_1){
                images.image1 = productDTO.image_1;
                setImages({...images})
            }
            if (productDTO.image_2){
                images.image2 = productDTO.image_2;
                setImages({...images})
            }

        } else if (fromEditPage) {
            setFromEdit(true);
            const id = props.location.search.split('=')[1];
            getGlobalProduct(id, (res) => setProduct(res.data));
            getImage(id, 1);
            getImage(id, 2)
        }

        getAllOrigins(setOrigins);
        getAllBrands(setBrands);
        getAllQualities(setQualities);
        getAllShelflives(setShelfLives);
        getCategories(setCategories);

    },[]);

    const getImage = async (id, number) => {
        let res = await getGlobalProductImage(id, number);
        let key = "image" + number;
        images[key] = res;
        setImages({...images})
    };

    const handleInputChange = (event, name) => {
        let gpDTO = {...product};
        if (event.target) {
            let fieldName = event.target.name;
            gpDTO[fieldName] = event.target.value;
        } else {
            gpDTO[name] = event.value;
        }
        setProduct(gpDTO);
    };

    const handleImageChange = (event, imageNumber) => {
        if (event.target) {
            images[imageNumber] = event.target
        } else {
           images[imageNumber] = event
        }
        setImages({...images})
    };

    const handleAddInputsChange = (event) => {
        itemToAdd[event.target.name] = {
            name: event.target.name,
            value: event.target.value
        };

        setItemToAdd({...itemToAdd})
    };

    const saveRequestHandler = () => {
        let data = {...product};
        if (!NET_WEIGHT_UNITS.includes(data.netWeightUnit, 1)) {
            delete data.netWeightUnit
        }
        if (fromEdit) {
            editGlobalProduct(data, editGlobalProductResponseHandler, editGlobalProductErrorHandler)
        } else {
            createGlobalProduct(data, createGlobalProductResponseHandler, createGlobalProductErrorHandler)
        }
    };

    const shouldUpdateImage = (image) => {
        return image && typeof image === "object"
    };

    const uploadImages = (id) => {
        if (shouldUpdateImage(images.image1)) {
            uploadProductImage(id, 1, images.image1, (response) => uploadImageResponseHandler(response, shouldUpdateImage(images.image2), id))
        } else if (shouldUpdateImage(images.image2)) {
            uploadProductImage(id, 2, images.image2, uploadImageResponseHandler)
        }
    };

    const editGlobalProductResponseHandler = async (res) => {
        uploadImages(product.id);
        notify("success", texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.SUCCESSFUL_EDIT);
        props.history.goBack();
    };

    const uploadImageResponseHandler = (response, checkForSecondImage, id) => {
        if (checkForSecondImage) {
            if (shouldUpdateImage(images.image2)) {
                uploadProductImage(id, 2, images.image2, (response) => uploadImageResponseHandler(response, false, id))
            }
        }
        if (response.status !== 200) {
            notify("error", response.statusText, texts.ADMIN_PANEL.NEW_EDIT_VENDOR.UPLOAD_UNSUCCESSFUL)
        }
    };

    const editGlobalProductErrorHandler = (error) => {
        handleErrors(error, texts);
    };

    const createGlobalProductResponseHandler = (res) => {
        let split = res.headers.location.split('/');
        let id = split[split.length - 1];
        if (fromMatch) {
            if (product.vendorProductId) {
                product.id = product.vendorProductId
            }
            let data = [{
                globalProductDTO: {id: Number(id)},
                vendorProductDTO: product
            }];

            matchProducts(data, (response) => matchProductResponseHandler(response, id), () => matchProductErrorHandler(texts.ADMIN_PANEL.MATCH_STEP_2.PRODUCT_NOT_MATCHED));
        } else {
            uploadImages(id);
            props.history.goBack();
        }
        notify("success", texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.SUCCESSFULLY_CREATED_GLOBAL_PRODUCT);
    };

    const matchProductResponseHandler = (res, globalProductId) => {
        if (res.status === 200) {

            uploadImages(globalProductId);

            let dataArray = {...props.location.state.data};
    
            let product = dataArray.noMatches.shift();

            dataArray.totalMatched += 1;

            const notMatchedProducts = res.data.vendorProductIds;
            if (notMatchedProducts.length > 0) {
                if (product.id === notMatchedProducts[0]) {
                    notify("info", `${texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.PRODUCT} ${product.productName} ${texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.WAS_N0T_MATCHED}.`, 6000)
                }
            }

            if (dataArray.noMatches.length > 0) {
                redirectWithState(ROUTES.ADMIN.MATCH_BY_NAME, dataArray, props)
            } else {
                redirectWithState(ROUTES.ADMIN.FINISH_IMPORT, dataArray, props)
            }
        }
    };

    const createGlobalProductErrorHandler = (error) => {
        if (error.response) {
            switch(error.response.status) {
                case 400:
                    let msg;
                    if (error.response.data.message) {
                        msg = error.response.data.message
                    } else {
                        let errObj = error.response.data.parameterViolations[0];
                        msg = errObj.path.split('.')[errObj.path.split('.').length - 1] +  ' ' + errObj.message

                    }
                    notify("error", msg, null, 7000);
                    break;
                case 409:
                    notify("error", texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.PRODUCT_ALREADY_EXIST );
                    break;
                default:
                    break;
            }
        }
    };

    const createOptionResponseHandler = (res, item) => {
        if (res.status === 201) {
            getSelectOptionsByName(item.name);

            notify("success", texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.SUCCESSFUL_CREATED);
            itemToAdd[item.name] = {};
            setItemToAdd({...itemToAdd});
            product[item.name] = item.value;
            setProduct({...product})
        }
    };

    const getSelectOptionsByName = (name) => {
        switch (name) {
            case "brand":
                getAllBrands(setBrands);
                break;
            case "origin":
                getAllOrigins(setOrigins);
                break;
            case "quality":
                getAllQualities(setQualities);
                break;
            case "shelfLife":
                getAllShelflives(setShelfLives);
                break;
            default:
                break;
        }
    }

    const createOptionErrorHandler = (error, element, value) => {
        if (error.response) {
            switch (error.response.status) {
                case 409:
                    notify("error", `${NEW_GLOBAL_PRODUCT.ERROR_CREATING} ${element}`, `${value} ${NEW_GLOBAL_PRODUCT.CREATE_OPTION_409}`);
                    break;
                default:
                    break;
            }
        }
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event)
    };

    const handleAddCategoryClick = () => {
        if (!product.hasOwnProperty("categories")) {
            product.categories = [];
        }
        let category = {
            id: selectedCategory.value,
            name: selectedCategory.label
        };

        product.categories.push(category);
        setProduct({...product});
        setSelectedCategory({})
    };

    const handleRemoveCategoryClick = (category, index) => {
        product.categories.splice(index, 1);
        setProduct({...product})
    };

    return (
        <div>
            <div className="ngp-title-wrapper">
                <p>{fromMatch && `${texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.IMPORT_HEADING} -`} {NEW_GLOBAL_PRODUCT.TITLE}</p>
            </div>
            <div className="w-100 ">
                {fromMatch ?
                    <MatchingProductTable globalProduct={product}/>
                    :
                    <button onClick={() => props.history.goBack()} className="ngp-back-button ml-5 d-flex">
                        <img src={ArrowBack} alt=""/>
                        {NEW_GLOBAL_PRODUCT.BACK_BUTTON}
                    </button>}
            </div>

            <div className="body-container">
                <GlobalProductContext.Provider value={{
                    product: product,
                    requiredFields: requiredFields,
                    requests: {
                        createOrigin: () => createNewOrigin(itemToAdd.origin.value, (response) => createOptionResponseHandler(response, itemToAdd.origin),
                            (error) => createOptionErrorHandler(error, texts.NEW_GLOBAL_PRODUCT.PRODUCT_OTHERS.ORIGIN, itemToAdd.origin.value)),
                        createQuality: () => createNewQuality(itemToAdd.quality.value, (response) => createOptionResponseHandler(response, itemToAdd.quality),
                            (error) => createOptionErrorHandler(error, texts.NEW_GLOBAL_PRODUCT.PRODUCT_OTHERS.QUALITY, itemToAdd.quality.value)),
                        createShelflife: () => createNewShelflife(itemToAdd.shelfLife.value, (response) => createOptionResponseHandler(response, itemToAdd.shelfLife),
                            (error) => createOptionErrorHandler(error, texts.NEW_GLOBAL_PRODUCT.PRODUCT_OTHERS.SHELF_LIFE, itemToAdd.shelfLife.value))
                    }
                    }}>

                    <ProductInformationSection onChange={handleInputChange}
                                               brands={brands}
                                               onBrandsSelectInputChange={handleAddInputsChange}
                                               onAddBrandButtonClick={() => createNewBrand(itemToAdd.brand.value,  (response) => createOptionResponseHandler(response, itemToAdd.brand), (error) => createOptionErrorHandler(error, texts.NEW_GLOBAL_PRODUCT.BRAND,itemToAdd.brand.value))}
                                               texts={NEW_GLOBAL_PRODUCT.PRODUCT_INFORMATION}
                                               units={{
                                                   "measureUnits": createSelectOptionsFromArray(MEASURE_UNITS),
                                                   "weightUnits": createSelectOptionsFromArray(NET_WEIGHT_UNITS)
                                               }}
                                               itemToAdd={itemToAdd}
                    />
                    {fromMatch && <VendorProductAttributesSection product={product} calcSinglePrices={calcSinglePrices}/> }

                    <ProductCategoriesSection onChange={handleInputChange}
                                              categories={categories}
                                              texts={NEW_GLOBAL_PRODUCT.PRODUCT_CATEGORIES}
                                              onAddClick={handleAddCategoryClick}
                                              onCategoryChange={handleCategoryChange}
                                              onRemoveCategoryClick={handleRemoveCategoryClick}
                                              addButtonDisabled={!selectedCategory.hasOwnProperty("value")}
                    />

                    <ProductOthersSection onChange={handleInputChange}
                                          onAddFieldInputChange={handleAddInputsChange}
                                          origins={origins}
                                          qualities={qualities}
                                          shelfLives={shelfLives}
                                          texts={NEW_GLOBAL_PRODUCT.PRODUCT_OTHERS}
                                          itemToAdd={itemToAdd}
                    />


                    <ProductImagesSection onChange={handleImageChange}
                                          texts={NEW_GLOBAL_PRODUCT.PRODUCT_IMAGE}
                                          images={images}
                                          fromMatch={fromMatch}
                                          fromEdit={fromEdit}
                    />

                </GlobalProductContext.Provider>

                <div className="p-5">
                    {fromMatch &&
                    <button onClick={() => props.history.goBack()}  className="btn btn-primary float-left text-uppercase">
                        &lt; {texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.BACK_TO_STEP_3}
                    </button>}

                    <button disabled={!checkRequiredFields(product, requiredFields)} onClick={saveRequestHandler}
                            className="btn btn-primary float-right">
                        {NEW_GLOBAL_PRODUCT.SAVE_BUTTON}
                    </button>
                </div>
            </div>
        </div>
    );
};


export default NewGlobalProductPage;