import { createShelflife, getShelflives } from "../services/ShelflifeServices";
import { createSelectOptions } from "./SelectUtils";

export const getAllShelflives = async (onSuccess) => {
    try {
        let response = await getShelflives();
        let options = createSelectOptions(response.data);
        onSuccess(options);
    } catch (error) { }
};

export const createNewShelflife = async (shelflife, onSuccess, onError) => {
    try {
        let response = await createShelflife(shelflife); 
        onSuccess(response)
    } catch (error) {
        onError(error)
    }
};