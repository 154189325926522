import React, {useEffect, useState} from 'react';
import './VendorImportPricelist.css'
import AdminAndVendorPageHeader from "../../common/AdminAndVendorPageHeader/AdminAndVendorPageHeader";
import {toggleSidebar} from "../../../utils/SidebarUtils";
import texts from "../../../Languages/bg";
import Papa from "papaparse"
import AdminAndVendorFooter from "../../common/AdmivAndVendorFooter/AdminAndVendorFooter";
import {notify} from "../../../utils/Notifications";
import {sendVendorPricelist} from "../../../services/VendorServices";
import {Spinner, Modal} from "react-bootstrap";
import {ROUTES} from "../../../config";

const VendorImportPricelist = props => {

    const [priceList, setPriceList] = useState();
    const [csvFile, setCsvFile] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (priceList) {
            let isCsv = priceList[0].name.endsWith('.csv');
            if (isCsv) {
                Papa.parse(priceList[0], {
                    complete: result => {
                        setCsvFile(result.data.filter(row => row.length > 1))
                    }
                });
            }
        }
    }, [priceList]);

    const handleSendFile = (file) => {
        if (file.name.endsWith(".csv") || file.name.endsWith(".xls")) {
            let formData = new FormData();
            formData.append("fileName", file.name);
            formData.append("uploadFile", file);
            setIsLoading(true);
            sendPricelist(formData);
        } else {
            notify("warning", texts.VENDOR_PANEL.FILE_TYPE_WARNING)
        }
    };

    const sendPricelist = async (data) => {
        try {
            let response = await sendVendorPricelist(data);
            sendPricelistResponseHandler(response);
        } catch (error) {
            sendPricelistErrorHandler(error)
        }
    }

    const sendPricelistResponseHandler = (res) => {
        setPriceList();
        setIsLoading(false);
        if (res.status === 200) {
            setShowModal(true)
        }
    };

    const sendPricelistErrorHandler = (err) => {
        setPriceList();
        setIsLoading(false);
        notify("error", texts.VENDOR_PANEL.UPLOAD_ERROR)
    };

    const handleFileChange = (event) => {
        if (event.target.files[0]) {
            setPriceList(event.target.files);
        };
    };

    return (
        <div>
            <div className="ipl-body">
                <AdminAndVendorPageHeader onClick={toggleSidebar} text={texts.VENDOR_PANEL.IMPORT_PAGE_HEADER}/>

                <div className="p-5 d-md-flex flex-column justify-content-between" style={{height: "60vh"}}>
                    <div className="align-self-start">
                        <span className="h5"> *{texts.VENDOR_PANEL.PRICE_LIST_FILE} :</span>
                        <label htmlFor="add_price_list" className="btn btn-primary c-pointer ml-3 mr-3 border-radius-5">
                            {texts.VENDOR_PANEL.CHOOSE_FILE}
                        </label>
                        <input onChange={handleFileChange} hidden type="file" id="add_price_list"/>
                        {priceList &&
                        <span> {priceList[0].name} {texts.VENDOR_PANEL.READY_TO_IMPORT}. {csvFile && csvFile.length} {texts.VENDOR_PANEL.PRODUCTS_FOUND} </span>}
                    </div>

                    <div className="align-self-end">
                        <button disabled={!priceList} onClick={() => handleSendFile(priceList[0])} className="btn btn-primary border-radius-5">
                            {texts.VENDOR_PANEL.SEND} {isLoading &&
                        <Spinner size={"sm"} variant={"light"} animation={"border"}/>}
                        </button>
                    </div>
                </div>
            </div>

            <Modal show={showModal}  onHide={() => setShowModal(false)}>
                <div className="h5 p-5">
                    {`${texts.VENDOR_PANEL.YOUR} ${csvFile ? csvFile.length - 1 : null} ${texts.VENDOR_PANEL.PRODUCTS_SENT_SUCCESSFULLY}`}
                </div>
                <Modal.Footer>
                    <button onClick={() => props.history.push(ROUTES.VENDOR.VENDOR_HOME)} className="btn btn-primary m-auto border-radius-5">
                        {texts.VENDOR_PANEL.OK}
                    </button>
                </Modal.Footer>
            </Modal>

            <div className="fixed-bottom">
                <AdminAndVendorFooter texts={texts.VENDOR_PAGE.FOOTER}/>
            </div>
        </div>
    );
};

export default VendorImportPricelist;