import React from 'react';
import PropTypes from 'prop-types';

const SideNavigationCollapseLink = ({text, target, children, icon}) => {
    return (
        <li className="nav-item" onBlur={() => setTimeout(() =>  document.getElementById(target).classList.remove("show"), 300)}>
            <a className="nav-link collapsed font-weight-bold" href="#" data-toggle="collapse" data-target={`#${target}`}
               aria-expanded="true">
                {icon}
                <span>{text.toUpperCase()}</span>
            </a>
            <div id={target} className="collapse" data-parent="#accordionSidebar">
                <div className="bg-white text-dark py-2 collapse-inner rounded">
                    {children}
                </div>
            </div>
        </li>
    );
};

SideNavigationCollapseLink.propTypes = {};

export default SideNavigationCollapseLink;