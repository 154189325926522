import React from 'react';
import {Link} from "react-router-dom";
import {ROUTES} from "../../../config";
import {texts} from "../../../Languages/Language";

const SuccessfulImportPage = props => {
    const customMatch = props.location.state.data.customMatch;
 
    return (
        <div>
            <div>
                <h1 className="p-4 border-bottom-secondary">
                    {customMatch ? texts.ADMIN_PANEL.SUCCESSFUL_IMPORT.MATCH_SUCCESS : `${texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.IMPORT_HEADING} - ${texts.ADMIN_PANEL.SUCCESSFUL_IMPORT.HEADING}`}

                </h1>
            </div>
            <div className="h4 p-5" data-testid="matched-and-total">
                {props.location.state.data.totalMatched} {texts.ADMIN_PANEL.SUCCESSFUL_IMPORT.FROM} {props.location.state.data.total} {customMatch ? texts.ADMIN_PANEL.SUCCESSFUL_IMPORT.WERE_MATCHED : texts.ADMIN_PANEL.SUCCESSFUL_IMPORT.SUCCESSFULLY_MATCHED}.
            </div>
            <div className="p-5">
                <Link to={ROUTES.ADMIN.ADMIN_HOME} className="btn btn-primary">
                    {texts.ADMIN_PANEL.SUCCESSFUL_IMPORT.FINISH}
                </Link>
            </div>
        </div>
    );
};

export default SuccessfulImportPage;