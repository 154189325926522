import React from 'react';
import PropTypes from 'prop-types';
import './Drawer.css';

const Drawer = ({children, isOpen ,onMouseDown, isDesktop, onHide}) => {

    return (
        <>
            <div onClick={onHide} className={isOpen ? "mdl-backdrop" : ""}>
            </div>
            <div onMouseDown={onMouseDown} className={`drawer-container ${isOpen ? isDesktop ? "opened-desktop" : "opened" : "closed"} `}>
                {children}
            </div>
        </>

    );
};

Drawer.propTypes = {
    children: PropTypes.object,
    isOpen: PropTypes.bool,
    onMouseDown: PropTypes.func,
    isDesktop: PropTypes.bool,
    onHide: PropTypes.func
};

export default Drawer;