import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import {texts} from "../../../Languages/Language";

const AssignNewUserModal = ({show, onHide, data, onRowSelect, selectedUsers, onAssignClick}) => {
    const assignTexts = texts.ADMIN_PANEL.ASSIGN_USERS;
    return (
        <Modal show={show} onHide={onHide} size={"lg"}>
           <div className="card shadow">
               <div className="card-header h5 text-center">
                   {assignTexts.ASSIGN_NEW}
               </div>
               <div className="card-body">
                   <div className="">
                       <table className="table table-sm table-bordered table-hover">
                           <thead className="bg-primary text-white">
                           <tr>
                               {assignTexts.ASSIGN_NEW_TABLE_HEADERS.map(th => <th key={th}>{th}</th>)}
                           </tr>
                           </thead>
                           <tbody>
                           {data.map(el => (
                               <tr onClick={() => onRowSelect(el)} id={el.email} className={`c-pointer ${selectedUsers.hasOwnProperty(el.email) ? "bg-gray-300" : "bg-none"}`}>
                                   <td>{el.id}</td>
                                   <td>{el.firstName}</td>
                                   <td>{el.lastName}</td>
                                   <td>{el.email}</td>
                               </tr>
                           ))}
                           </tbody>
                       </table>
                   </div>
               </div>
               <div className="text-right pb-3 pr-3">
                   <button onClick={onAssignClick} className="btn btn-primary">
                       {assignTexts.ASSIGN}
                   </button>
               </div>
           </div>
        </Modal>
    );
};

AssignNewUserModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    data: PropTypes.array,
    onRowSelect: PropTypes.func,
    selectedUsers: PropTypes.object,
    onAssignClick: PropTypes.func
};

export default AssignNewUserModal;