import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './ProductCardGrid.css';
import {
    availabilityFormatHandler,
    checkCurrentActivePackage,
    checkCurrentActivePrice, checkIsProductAvailable, checkLastOrderedDate, getFormattedSingleItemPrice,
    packageHasPrice
} from "../../../utils/VendorProductUtils";
import AddToCartButton from "../AddToCartButton/AddToCartButton";
import PromotionPill from "../PromotionPill/PromotionPill";
import PackageSelectPill from "../PackageSelectPill/PackageSelectPill";
import ProductPrices from "../ProductPrices/ProductPrices";
import {PRODUCT_STATUS} from "../../../config";
import {isLargeScreen} from "../../../utils/CheckWindowWidth";
import ProductImage from "../ProductImage/ProductImage";

const ProductCardGrid = ({texts, changeActivePack,  packages,
                             productIndex, itemInCart, onAddButtonClick, updateOrder, product}) => {

    const [rerender, setRerender] = useState(false);

    useEffect(() => {
        setRerender(!rerender)
    }, []);

    const checkIndex = (indx) => {
       return isLargeScreen(window.innerWidth) ? true :  indx % 2 !== 0
    };

    const getHeight = (idx) => {
        if (checkIndex(idx)) {
            let element = document.getElementById(productIndex - 1);
            if (element) {
                let height = element.offsetHeight;
                if (document.getElementById(productIndex)) {
                    document.getElementById(productIndex).style.height = height + 'px';
                }
            }
        }
    };

    return (
        <div id={"product" + product.id} className="pc-container pt-1 p-lg-1">
            <div className="vp-card-grid-wrapper  ml-1 mr-1">
                <div className="vp-card-grid-erpid">{product.sosku}</div>

                <div className="vp-card-grid-image-wrapper">
                    <ProductImage className="vp-card-grid-product-image" vendorProductId={"vp_" + product.id} globalProductId={product.globalProductId} />
                </div>

                <div id={productIndex} className={`vp-card-grid-promo-wrapper`}>
                    {/* Promotions will be displayed here here */}
                    {getHeight(productIndex)}
                    {checkLastOrderedDate(product.lastOrderedTimestamp) && <PromotionPill className="bg-green" text={texts.RECENT_ORDERED} />}
                </div>

                <div className="vp-card-grid-product-name col-12 m-auto">
                    <div className="vp-card-grid-product-name-wrapper">
                        {product.displayName}
                    </div>
                </div>

                {(() => {
                    if (packageHasPrice(packages[product.active])) {
                        return (
                            <div className="vp-card-grid-product-price">
                                {getFormattedSingleItemPrice(packages[product.active])} {texts.CURRENCY}/{product.measureUnit} {texts.VAT_INCLUDED}
                            </div>
                        )
                    } else {
                        return (
                            <div className="vp-card-grid-product-price">
                                {texts.NO_PRICE}
                            </div>
                        )
                    }
                })()}
                <div className="d-flex vp-card-grid-price-pills-wrapper">
                    {packages.map((pack, index) => (
                        <PackageSelectPill key={pack.id}
                                           units={pack.packagingUnit}
                                           quantity={product.measureUnit}
                                           onClick={() => changeActivePack(productIndex, index)}
                                           isActive={checkCurrentActivePackage(itemInCart, packages, pack, product.active)}/>
                    ))}
                </div>
                <div className="vp-card-grid-availability">
                    {availabilityFormatHandler(product.productStatus)}
                </div>
                <div className={`vp-card-grid-price-total ${!checkIsProductAvailable(product.productStatus) && "card-grid-mt"}`}>
                    {packageHasPrice(packages[product.active]) &&
                    <ProductPrices vatPriceLabel={texts.PRODUCT_PRICE_VAT}
                                   noVatPriceLabel={texts.PRODUCT_PRICE}
                                   vatPrice={checkCurrentActivePrice(itemInCart, packages, product.active, true)}
                                   totalPrice={checkCurrentActivePrice(itemInCart, packages, product.active, true, true)}
                                   currency={texts.CURRENCY}
                    />}
                </div>

                <div>
                    <div className="pt-lg-3 pb-lg-3 pb-2 m-1 mt-lg-3">
                        {checkIsProductAvailable(product.productStatus) && packages.length > 0
                            ?
                                <AddToCartButton disabled={product.productStatus === PRODUCT_STATUS.NOT_AVAILABLE}
                                             onAddButtonClick={onAddButtonClick}
                                             packagingId={packages[product.active].id}
                                             id={product.id}
                                             updateOrder={updateOrder}
                                             itemInCart={itemInCart}
                                             selectedPackage={packages[product.active]}
                                             product={product}/>
                            :
                                <div className="font-weight-bold text-red pt-1">
                                    {texts.PRODUCT_AVAILABILITY.NOT_AVAILABLE}
                                </div>
                        }
                    </div>

                </div>
            </div>

        </div>
    );
};

ProductCardGrid.propTypes = {
    texts: PropTypes.object,
    changeActivePack: PropTypes.func,
    packages: PropTypes.array,
    productIndex: PropTypes.number,
    itemInCart: PropTypes.object,
    onAddButtonCLick: PropTypes.func,
    updateOrder: PropTypes.func,
    product: PropTypes.object
};

export default ProductCardGrid;