import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from "../../../assets/search_icon.svg";
import {texts} from "../../../Languages/Language";

const HeaderSearch = ({searchRef, onClearClick, onSearch, handleKeyPress, clearIconHidden, wrapperClass}) => {
    return (
        <div className={`header-search ${wrapperClass}`}>
            <input  ref={searchRef} className="header-search-input" onChange={onSearch} onKeyPress={handleKeyPress} placeholder={texts.VENDOR_PAGE.SEARCH_PRODUCT_OR_VENDOR} type="text"/>
            <i onClick={onClearClick} hidden={clearIconHidden} style={{fontSize: "18px"}} className="fas fa-times c-pointer pt-1"></i>
            <label className="mb-0 ">
                <img src={SearchIcon} alt=""/>
            </label>
        </div>
    );
};

HeaderSearch.propTypes = {
    searchRef: PropTypes.object,
    onClearClick: PropTypes.func,
    onSearch: PropTypes.func,
    handleKeyPress: PropTypes.func,
    clearIconHidden: PropTypes.bool,
    wrapperClass: PropTypes.string
};

export default HeaderSearch;