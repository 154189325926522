import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {ROUTES, USER_ROLES} from "../../../config";
import {notify} from "../../../utils/Notifications";
import AssignNewUserModal from "../AssignNewUserModal/AssignNewUserModal";
import {texts} from "../../../Languages/Language";
import {getUsersByAssignment, assignUserToVendor, unassignUser, getAssignedUsersToPOS, assignUserToPOS} from '../../../utils/UserUtils';

const UsersAssignedCard = ({assignTo, type, customerId, isEdit, setToAssign}) => {
    const assignTexts = texts.ADMIN_PANEL.ASSIGN_USERS;
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [unassignedUsers, setUnassignedUsers] = useState([]);
    const [selectedUsersToAssign, setSelectedUsersToAssign] = useState({});

    useEffect(() => {
        if (assignTo) {
            getAssignedUsers()
        }
    }, []);

    const isVendor = () => {
        return type === "vendor"
    };

    const handeUnassignClick = (client) => {
       if (isEdit) {
           if (isVendor()) {
               unassignUser(client.id, assignTo, "vendor", (response) => unassignResponseHandler(response, client))
           } else {
               unassignUser(client.id, assignTo, "pointofsale", (response) => unassignResponseHandler(response, client))
           }
       } else {
          let index = assignedUsers.indexOf(client);
          assignedUsers.splice(index, 1);
          setAssignedUsers([...assignedUsers])
       }
    };

    const unassignResponseHandler = (response, client) => {
        notify("success", `${client.email} ${assignTexts.USER_UNASSIGNED}`);
        getAssignedUsers();
    };

    const handleAddClick = () => {
        setUnassignedUsers([]);

        //Getting all assigned and unassigned users to filter out only those who are not assigned to the POS
        getUsersByAssignment(customerId, isVendor() ? USER_ROLES.VENDOR : USER_ROLES.CUSTOMER, undefined, allAssigned => {
            getUsersByAssignment(undefined, isVendor() ? USER_ROLES.VENDOR : USER_ROLES.CUSTOMER, true, (unassigned) => getUnassignedUsersResponseHandler(unassigned.data, allAssigned.data))
        });
    };

    const getUnassignedUsersResponseHandler = (unassigned, allAssigned) => {

        //Merging and subtracting assignedUsers to POS will give all the users which have customer but are not assigned to the POS
        Array.prototype.push.apply(unassigned, allAssigned);
        if (assignedUsers.length > 0) {
            unassigned = unassigned.filter(el => !assignedUsers.find(({id}) => el.id === id));
        }

        setUnassignedUsers(unassigned);
        setShowAssignModal(true)
    };

    const handleRowSelect = (el) => {
        if (!selectedUsersToAssign.hasOwnProperty(el.email)) {
            selectedUsersToAssign[el.email] = el;
        } else {
            delete selectedUsersToAssign[el.email];
        }
        setSelectedUsersToAssign({...selectedUsersToAssign});
    };

    const handleAssignClick = async () => {
        for (let user of Object.values(selectedUsersToAssign)) {
           if (isEdit) {
               if (isVendor()) {
                   await assignUserToVendor(user.id, assignTo, (response) => assignUserResponseHandler(response, user), (response) =>  assignUserErrorHandler(response, user))
               } else {
                   await assignUserToPOS(user.id, assignTo, (response) => assignUserResponseHandler(response, user), (response) =>  assignUserErrorHandler(response, user))
               }
           } else {
               setToAssign(selectedUsersToAssign);
               assignedUsers.push(user);
               setAssignedUsers([...assignedUsers]);
               setSelectedUsersToAssign({});
               setShowAssignModal(false);
           }
        }
    };

    const assignUserResponseHandler = (response, user) => {
        notify("success", `${user.email} ${assignTexts.USER_ASSIGNED}`);
        assignedUsers.push(user);
        delete selectedUsersToAssign[user.email];
        if (Object.values(selectedUsersToAssign).length === 0) {
            setShowAssignModal(false)
        }
    };

    const assignUserErrorHandler = (err, user) => {
        notify("error", `${user.email} ${assignTexts.ASSIGN_ERROR}`);
    };

    const getAssignedUsers = () => {
        if (isVendor()) {
            getUsersByAssignment(assignTo, USER_ROLES.VENDOR, undefined, (response) => setAssignedUsers([...response.data]));
        } else {
            getAssignedUsersToPOS(assignTo, (response) => setAssignedUsers(response.data))
        }
    };

    return (
        <div className="card shadow">
            <div className="card-header text-left text-primary h4">
                {assignTexts.ASSIGNED_USERS_HEADER}
            </div>
            <div className="card-body">
                <div className="text-right">
                    <button onClick={handleAddClick} className="btn btn-primary">
                        {assignTexts.ASSIGN_NEW}
                    </button>
                </div>
                <div className="pt-3">
                    <table className="table table-sm table-bordered table-striped">
                        <thead className="bg-primary text-white">
                        <tr>
                            {assignTexts.TABLE_HEADERS.map(th => <th key={th}>{th}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {assignedUsers.map(user => {
                            return (
                                <tr key={user.id + user.firstName}>
                                    <td data-testid="id" className="vertical-align-center">{user.id}</td>
                                    <td data-testid="firstName" className="vertical-align-center">{user.firstName}</td>
                                    <td data-testid="lastName" className="vertical-align-center">{user.lastName}</td>
                                    <td data-testid="user-email" className="vertical-align-center">{user.email}</td>
                                    <td data-testid="user-phone" className="vertical-align-center">{user.phone}</td>
                                    <td data-testid="isActive" className="vertical-align-center">{user.isActive ? texts.ADMIN_PANEL.USERS.ACTIVE : texts.ADMIN_PANEL.USERS.INACTIVE}</td>
                                    <td className="vertical-align-center">
                                        <div>
                                            <Link to={`${ROUTES.ADMIN.NEW_EDIT_USER_LINK + `/edit?user=${user.id}`}`}>
                                               {texts.ADMIN_PANEL.USERS.EDIT}
                                            </Link>
                                        </div>
                                        <div>
                                            <span onClick={() => handeUnassignClick(user)}
                                                  className="btn btn-link c-pointer">{assignTexts.UNASSIGN}</span>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>

            <AssignNewUserModal onAssignClick={handleAssignClick}
                                selectedUsers={selectedUsersToAssign}
                                onRowSelect={handleRowSelect}
                                data={unassignedUsers}
                                show={showAssignModal}
                                onHide={() => setShowAssignModal(false)}/>

        </div>
    );
};

UsersAssignedCard.propTypes = {
    assignTo: PropTypes.number,
    type: PropTypes.string,
    customerId: PropTypes.number,
    isEdit: PropTypes.bool,
    setToAssign: PropTypes.func
};

export default UsersAssignedCard;