import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import {texts} from "../../../../Languages/Language";

const VendorsModal = ({show, onHide, data, onVendorSelect, selectedVendors, onAddClick}) => {
    const assignTexts = texts.ADMIN_PANEL.ASSIGN_VENDORS;
    return (
        <Modal show={show} onHide={onHide} scrollable>
            <Modal.Body>
                {data.map(el => <div className={`c-pointer btn-link ${selectedVendors.hasOwnProperty(el.id) ? "text-primary h4" : "text-secondary h5"}`} onClick={() => onVendorSelect(el)}>{el.vendorName}</div>)}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={onAddClick} className="btn btn-primary">
                    {assignTexts.ADD}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

VendorsModal.propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func,
    data: PropTypes.array,
    onVendorSelect: PropTypes.func,
    selectedVendors: PropTypes.object,
    onAddClick: PropTypes.func
};

export default VendorsModal;