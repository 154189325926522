import React, {useEffect, useState} from 'react';
import TruckImage from '../../../assets/kamion.jpg';

import PropTypes from 'prop-types';
import {
    getGlobalProductImage,
} from "../../../services/GlobalProductServices";
import { uploadProductImage } from '../../../utils/GlobalProductUtils';

const ProductImageTableCell = ({product, uploadImageResHandler, isVendor}) => {
    const [image, setImage] = useState("");

    useEffect( () => {
        let id = isVendor ? product.globalProductId : product.id;
        if  (isVendor || product.image1) {
            getProductImage(id);
        } else {
            setImage(TruckImage);
        }
    }, [product]);

    const getProductImage = async (productId) => {
        let image = await getGlobalProductImage(productId, 1);
        setImage(image)
    };

    const uploadResponseHandler = async (response, productName) => {
        uploadImageResHandler(response, null, null, productName)
        await getProductImage(product.id);
    };

    return (
        <div className="position-relative">
            <img className="img-max-75" src={image} alt=""/>

            {uploadImageResHandler &&   <i onClick={() => document.getElementById(product.sosku + "img").click()}
                                           className="add-image-icon position-absolute c-pointer fas fa-plus-circle">
            </i>}
            <input hidden
                   id={product.sosku + "img"}
                   type="file"
                   onChange={(e) => uploadProductImage(product.id, 1, e.target, (response) => uploadResponseHandler(response, product.productName))}/>
        </div>
    );
};

ProductImageTableCell.propTypes = {
    product: PropTypes.object,
    uploadImageResHandler: PropTypes.func
};

export default ProductImageTableCell;