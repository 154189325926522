import React from 'react';
import { notify } from './Notifications';

export const getMatchedProp = (tuple) => {
    let gp = tuple.globalProductDTO;
    let vp = tuple.vendorProductDTO;

    if (gp.ean) {
        return <span>
           EAN: {gp.ean}
      </span>
    } else if (gp.pbid) {
        return <span>
          PBID: {gp.pbid}
      </span>
    } else {
        return <span>
          ERPID: {vp.erpid}
      </span>
    }
};

export const removeFromArray = (array, vp, fromMatchedArray) => {
    if (fromMatchedArray) {
        array.map((el, index) => {
            if (el.vendorProductDTO === vp) {
                array.splice(index, 1)
            }
        })
    } else {
        let idx = array.indexOf(vp);
        if (idx > -1) {
            array.splice(idx, 1)
        }
    }
};

export const matchProductErrorHandler = (text) => {
   notify('error', text);
};
