import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import ShiftLogo from '../../../assets/logo-shift-white.svg';
import {toggleSidebar} from "../../../utils/SidebarUtils";

const SideNavigation = props => {

    return (
            <ul className={`navbar-nav bg-gradient-primary sidebar sidebar-dark toggled  accordion text-uppercase`} id="accordionSidebar">
                <Link to={props.path}  className="sidebar-brand text-center">
                    <img src={ShiftLogo} alt=""/>
                </Link>

                {props.children}

               <div className="text-center">
                   <button onClick={toggleSidebar} id="sidebarToggle"  className="btn  rounded-circle">
                   </button>
               </div>
            </ul>
    );
};

SideNavigation.propTypes = {
    path: PropTypes.string
};

export default SideNavigation;