import React from 'react';
import PropTypes from 'prop-types';
import './AddButton.css';
import {Spinner} from "react-bootstrap";

const AddButton = ({text, onClick, isLoading}) => {
    return (
        <button onClick={onClick} disabled={isLoading} className="ov-add-to-cart-btn">
            {isLoading && <Spinner size="sm" animation="border" variant="light"  role="status"/>} {text}
        </button>
    );
};

AddButton.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    isLoading: PropTypes.bool
};

export default AddButton;