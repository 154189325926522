import React from 'react';
import PropTypes from 'prop-types';
import PackagingBox from "../PackagingBox/PackagingBox";
import {texts} from "../../../../Languages/Language";

const VendorProductAttributesSection = ({product, calcSinglePrices}) => {

    return (
        <>
            <div className="p-4" style={{fontSize: "0.825em"}} >
                <div className="text-left h5 font-weight-bold">
                    {texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.VENDOR_PRICE_LIST_ATTRIBUTES}
                </div>
                <div className="pt-4 pb-4 pl-2 pr-2 d-flex bg-grey text-white">
                    <div className="text-right">
                        <div>
                            {texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.SINGLE_PRICE}
                            <input readOnly className="ml-1 width-100px" type="text" defaultValue={calcSinglePrices(product.packagingList[0], texts.VENDOR_PAGE.PRODUCT_CARD.NO_PRICE).price} />
                        </div>
                        <div>
                            {texts.ADMIN_PANEL.CREATE_NEW_GLOBAL_PRODUCT.SINGLE_PRICE_VAT}
                            <input readOnly className="ml-1 width-100px" type="text" value={calcSinglePrices(product.packagingList[0]).priceWithVAT} />
                        </div>
                    </div>
                    {product.packagingList.map((p, i) => {
                        return (
                            <PackagingBox packaging={p} index={i} />
                        )
                    })}
                </div>
                <div className="bg-grey  text-left pl-4">
                    <select disabled>
                        <option hidden selected value="">
                            {product.productStatus}
                        </option>
                    </select>
                </div>
            </div>
        </>
    );
};

VendorProductAttributesSection.propTypes = {
    product: PropTypes.object,
    calcSinglePrices: PropTypes.func
};

export default VendorProductAttributesSection;