import React from 'react';
import './App.css';
import './assets/sb-admin-2.min.css';
import {Switch, Route} from 'react-router-dom';
import LoginPage from "./components/LoginPage/LoginPage";
import {ROUTES, USER_ROLES} from "./config";
import VendorMainPage from "./components/vendor/VendorMainPage/VendorMainPage";
import AdminMainPage from "./components/admin/AdminMainPage/AdminMainPage";
import {PrivateRoute} from "./PrivateRoute";
import ForbiddenPage from "./components/common/ForbiddenPage/ForbiddenPage";
import {NotificationContainer} from "react-notifications";
import CustomerMainPage from "./components/customer/CustomerMainPage/CustomerMainPage";

function App() {

    return (
        <div className="App">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-12 p-0 text-center">
                        <Switch>
                            <Route exact path={ROUTES.HOME} component={LoginPage}/>
                            <PrivateRoute role={USER_ROLES.ADMIN} path={ROUTES.ADMIN.ADMIN_HOME}  component={AdminMainPage}/>
                            <PrivateRoute role={USER_ROLES.CUSTOMER} path={ROUTES.CUSTOMER.CUSTOMER_HOME} component={CustomerMainPage} />
                            <PrivateRoute role={USER_ROLES.VENDOR} path={ROUTES.VENDOR.VENDOR_HOME} component={VendorMainPage} />
                            <Route to="/forbidden" component={ForbiddenPage}/>
                        </Switch>
                    </div>
                </div>
            </div>
            <NotificationContainer/>
        </div>
    );
}

export default App;
