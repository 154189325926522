import React from 'react';
import PropTypes from 'prop-types';
import {texts} from "../../../Languages/Language";
import {logout} from "../../../utils/Logout";

const UsernameAndLogoutSection = ({user, className}) => {
    return (
        <div className={`pl-3 pr-3 pl-md-5 pr-lg-5 d-flex justify-content-between ${className}`}>
            <div className="">
                <span className="ual-user-name">{texts.ADMIN_PANEL.USERS.USER}: </span>
                <span className="ual-email">{user.email}</span>
            </div>
            <div onClick={logout} className="text-primary ual-logout c-pointer">
                {texts.ADMIN_PANEL.USERS.LOGOUT}
            </div>
        </div>
    );
};

UsernameAndLogoutSection.propTypes = {
    user: PropTypes.object,
    className: PropTypes.string
};

export default UsernameAndLogoutSection;