import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {getUserData} from "../../../services/LocalStorageServices";
import {redirectToProfilePage} from "../../../utils/UserUtils";

const AdminAndVendorPageHeader = ({text, icon, onClick, history}) => {

    return (
        <div className="w-100">
            <div className="navbar navbar-expand navbar-light bg-white  mb-4 shadow d-flex">
                <button onClick={onClick} id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"></i>
                </button>
                <h3 className="heading-text text-primary text-left w-50">{text}</h3>
                <div className="text-primary text-right w-50">
                    <h3 className="">{icon} </h3>
                    <div>
                        <i onClick={() => redirectToProfilePage(getUserData().type, history)} style={{fontSize:"2em", marginRight:"3%"}} className="fas fa-users-cog c-pointer"></i>
                      <div>my account</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

AdminAndVendorPageHeader.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    history: PropTypes.object
};

export default withRouter(AdminAndVendorPageHeader);