import React from 'react';
import PropTypes from 'prop-types';
import './AddProductsText.css';

const AddProductsText = ({texts, visibleInMobile}) => {
    return (
        <div className={`${visibleInMobile ? "sc-add-product-text" : "d-none d-lg-block sc-add-product-text"}`}>
            {texts.ADD_PRODUCTS_TEXT}
        </div>
    );
};

AddProductsText.propTypes = {
    texts: PropTypes.object,
    visibleInMobile: PropTypes.bool
};

export default AddProductsText;