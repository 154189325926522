import React, {useCallback, useEffect, useState} from 'react';
import './AdminProducts.css';
import {getGlobalProducts, uploadProductImage, editGlobalProduct, createBodyForTagsAndCategoriesUpdate} from "../../../utils/GlobalProductUtils";
import AdminAndVendorPageHeader from "../../common/AdminAndVendorPageHeader/AdminAndVendorPageHeader";
import {getCategories} from "../../../utils/CategoryUtils";
import {
    deletePackaging,
    editVendorProduct,
    productDoesntHavePackageWithUnits,
} from "../../../utils/VendorProductUtils";
import AdminProductsTable from "./AdminProductsTable";
import SelectSearch from "react-select";
import {createNestedOptions, createSelectOptions} from "../../../utils/SelectUtils";
import texts from '../../../Languages/bg';
import AdminAndVendorFooter from "../../common/AdmivAndVendorFooter/AdminAndVendorFooter";
import ShowMoreButton from "../../customer/ShowMoreButton/ShowMoreButton";
import {DEFAULT_FETCH_ITEMS_COUNT, ROUTES} from "../../../config";
import {addIsEditableAttribute} from "../../../utils/OrderUtils";
import {Prompt, Link} from "react-router-dom";
import ChangeCategoriesAndTagsPopup from "./ChangeCategoriesAndTagsPopup/ChangeCategoriesAndTagsPopup";
import ChangeCategoryPopupBody from "./ChangeCategoryPopupBody/ChangeCategoryPopupBody";
import ChangeTagsPopupBody from "./ChangeTagsPopupBody/ChangeTagsPopupBody";
import {notify} from "../../../utils/Notifications";
import {Spinner} from "react-bootstrap";
import _ from 'lodash';
import { getVendors } from '../../../utils/VendorUtils';

const AdminProducts = props => {
    const SHOW_HIDE_OPERATION = "SHOW_OR_HIDE";
    const MATCH_OPERATION = "MATCH";
    const initialAction = {label: texts.ADMIN_PANEL.ACTIONS_SELECT_PLACEHOLDER, value: null};
    const initialErrorObject = {show:false, message:""};

    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchedProduct, setSearchedProduct] = useState("");
    const [selectedCategory, setSelectedCategory] = useState({});
    const [isShowMoreButtonHidden, setIsShowMoreButtonHidden] = useState(true);
    const [page, setPage] = useState(2);
    const [vendors, setVendors] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState({});
    const [productsInEdit, setProductsInEdit] = useState(0);
    const [vendorProductOps, setVendorProductOps] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedAction, setSelectedAction] = useState(initialAction);
    const [selectedImage, setSelectedImage] = useState();
    const [error, setError] = useState(initialErrorObject);
    const [showCategoriesPopup, setShowCategoriesPopup] = useState(false);
    const [categoryToChange, setCategoryToChange] = useState();
    const [showTagsPopup, setShowTagsPopup] = useState(false);
    const [showImagePopup, setShowImagePopup] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedVendorOperation, setSelectedVendorOperation] = useState();

    const searchRef = React.useRef();
    const tagsInputRef = React.useRef();

    useEffect(() => {
        getCategories(setCategories);
        getVendors((response) => setVendors(response.data));
    }, []);

    useEffect(() => {
        if (selectedCategory.label) {
            resetPageForShowMoreButton();
            setLoadingToTrue();
            let vendorId = selectedVendor.value === "All" ? null : selectedVendor.value;
            getGlobalProducts(getGlobalProductsResponseHandler, selectedCategory.value, searchedProduct, vendorId)
        }
    }, [selectedCategory]);

    useEffect(() => {
        resetPageForShowMoreButton();
        setLoadingToTrue();
        
        debouncedSearchRequest(getGlobalProductsResponseHandler, selectedCategory.value, searchedProduct, selectedVendor.value)
    }, [searchedProduct]);

    useEffect(() => {
        if (selectedVendor.label) {
            resetPageForShowMoreButton();
            getGlobalProducts(getGlobalProductsResponseHandler, selectedCategory.value, searchedProduct, selectedVendor.value)
        }
        setLoadingToTrue();
    }, [selectedVendor]);

    useEffect(() => {
        if (productsInEdit > 0  || vendorProductOps.length > 0) {
            window.onbeforeunload = (e) => {
                e.returnValue = ""
            }
        } else {
            window.onbeforeunload = null
        }
    }, [productsInEdit, vendorProductOps]);

    const debouncedSearchRequest = useCallback(_.debounce(getGlobalProducts, 800), []);

    const setLoadingToTrue = () => {
        if (!isLoading) {
            setIsLoading(true)
        }
    };

    const resetPageForShowMoreButton = () => {
      setPage(2)
    };

    const getGlobalProductsResponseHandler = (res) => {
        if (res.data.length > 0) {
            setError(initialErrorObject);
            res.data.map(e => addIsEditableAttribute(e.vendorProducts));
        } else {
            displayErrorMessagesAccordingToFilters()
        }
        setProducts([...res.data]);
        setIsLoading(false);
        setIsShowMoreButtonHidden(res.data.length < DEFAULT_FETCH_ITEMS_COUNT);
    };

    const displayErrorMessagesAccordingToFilters = () => {
        if (selectedVendor.value && selectedCategory.value) {
            setError({show: true, message: texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.NO_PRODUCTS_FOR_VENDOR_IN_CATEGORY})
        } else if(selectedVendor.value) {
            setError({show: true, message: texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.NO_PRODUCTS_FOR_VENDOR})
        } else if (selectedCategory.value) {
            setError({show: true, message: texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.NO_PRODUCTS_IN_CATEGORY})
        }
    };

    const handleFilterChange = (event, filterBy) => {
        switch (filterBy) {
            case "search":
                setSearchedProduct(event.target.value.trim());
                break;
            case "category":
                setSelectedCategory(event);
                break;
            case "vendor":
                setSelectedVendor(event);
                break;
            default:
                break;
        }
    };

    const handleClearSearch = () => {
        setSearchedProduct("");
        searchRef.current.value = ""
    };

    const handleShowMoreClick = (e) => {
        setPage(page + 1);
        getGlobalProducts(showMoreResponseHandler, selectedCategory.value, searchedProduct, selectedVendor.value, page);
    };

    const showMoreResponseHandler = (res) => {
        setProducts(products.concat(res.data));
        setIsShowMoreButtonHidden(res.data.length < DEFAULT_FETCH_ITEMS_COUNT)
    };

    const editVendorProductResponseHandler = (res) => {
        notify('success',  texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.SUCCESSFUL_CHANGE + '!');
        let globalProduct = products.find(e => e.id === res.data.globalProductId);
        let globalProductIndex = products.indexOf(globalProduct)
        let vendorProduct = globalProduct.vendorProducts.find(e => e.id === res.data.id)
        let vendorProductIndex = globalProduct.vendorProducts.indexOf(vendorProduct)
        products[globalProductIndex].vendorProducts[vendorProductIndex] = res.data
        setProducts([...products]);
        setProductsInEdit(productsInEdit - 1);
    };

    const editVendorProductErrorHandler = (err) => {
        setProductsInEdit(productsInEdit - 1);
    };

    const handleVendorProductChange = (event, packIndex, product, index) => {
        let gp = products.find(e => e.id === product.globalProductId);
        let idx = products.indexOf(gp);
        let obj = {
            packagingType: products[idx].vendorProducts[index].packagingList[0].packagingType,
            packagingUnit: 0,
            packagingUnitPriceVAT: 0
        };

        switch (event.target.name) {
            case "product-status-change":
                products[idx].vendorProducts[index].productStatus = event.target.value;
                break;
            case "price":
                if (products[idx].vendorProducts[index].packagingList[packIndex]) {
                    products[idx].vendorProducts[index].packagingList[packIndex].packagingUnitPriceVAT = event.target.value;
                } else {
                    obj.packagingUnitPriceVAT = event.target.value;
                    products[idx].vendorProducts[index].packagingList.splice(packIndex, 0, obj)
                }
                break;
            case "units":
                if (products[idx].vendorProducts[index].packagingList[packIndex]) {
                    products[idx].vendorProducts[index].packagingList[packIndex].packagingUnit = event.target.value;
                    if (productDoesntHavePackageWithUnits(product)) {
                        notify("info", texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.DU_MUST_BE_GRATER_THAN_ZERO, null, 10000)
                    }
                } else {
                    obj.packagingUnit = event.target.value;
                    products[idx].vendorProducts[index].packagingList.splice(packIndex, 0, obj)
                }
                break;
            default:
                break;
        }
        setProducts([...products])
    };

    const handleEditAndSaveClick = async (product, index, isEdit) => {
        let gp = products.find(e => e.id === product.globalProductId);
        let idx = products.indexOf(gp);
    
        if (isEdit) {
            products[idx].vendorProducts[index].isEditable = false;
    
            for (let pack of product.packagingList) {
                if (+pack.packagingUnit === 0 && pack.hasOwnProperty('id')) {
                    await deletePackaging(product.id, pack.id);
                }
            }
    
            product.packagingList = [...product.packagingList.filter(packaging => +packaging.packagingUnit > 0)];
            await editVendorProduct(product, editVendorProductResponseHandler, editVendorProductErrorHandler)
    
        } else {
            setProductsInEdit(productsInEdit + 1);
    
            products[idx].vendorProducts[index].isEditable = true;
            setTimeout(() => {
                let element = document.getElementById(product.id);
                if (element) {
                    element.focus();
                    element.select()
                }
            }, 100)
        }
        setProducts([...products]);
    };

    const handleRunOperationForVendorProducts = () => {
        switch (selectedVendorOperation) {
            case SHOW_HIDE_OPERATION:
                vendorProductOps.map(e => {
                    editVendorProduct(e, vendorProductsBulkOperationResponseHandler)
                });
                break;
            case MATCH_OPERATION:
                props.history.push({
                    pathname: ROUTES.ADMIN.MATCH_BY_NAME,
                    state: {
                        data: {
                            noMatches: vendorProductOps,
                            total: vendorProductOps.length,
                            totalMatched: 0,
                            customMatch: true
                        }
                    }
                });
                break;
            default:
                break;
        }
    };

    const vendorProductsBulkOperationResponseHandler = (res) => {
        if (res.status === 200) {
            notify("success", texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.SUCCESSFUL_CHANGE);
            let pr = vendorProductOps.find(e => e.id === res.data.id);
            let idx = vendorProductOps.indexOf(pr);
            vendorProductOps.splice(idx, 1);
            if (vendorProductOps.length === 0) {
                setSelectedVendorOperation();
            }
            setVendorProductOps([...vendorProductOps])
        }
    };

    const getVendorOperationType = (eventValue) => {
        switch (eventValue) {
            case "enable":
                return SHOW_HIDE_OPERATION;
            case "disable":
                return SHOW_HIDE_OPERATION;
            case "match":
                return MATCH_OPERATION
            default:
                break;
        }
    };

    const handleVendorOperationChange = (event, vp) => {
        let value = event.target.value;
        let operationType = getVendorOperationType(value);

        if (!selectedVendorOperation || selectedVendorOperation === operationType) {
            setSelectedVendorOperation(operationType);
            switch (event.target.value) {
                case "enable":
                    vp.isEnabled = true;
                    break;
                case "disable":
                    vp.isEnabled = false;
                    break;
                default:
                    break;
            }

            let el = vendorProductOps.find(e => e.id === vp.id);

            if (el) {
                let idx = vendorProductOps.indexOf(el);
                vendorProductOps[idx] = vp
            } else {
                vendorProductOps.push(vp)
            }
            setVendorProductOps([...vendorProductOps])
        } else {
            notify("warning", texts.ADMIN_PANEL.DIFFERENT_ACTION, texts.ADMIN_PANEL.DIFFERENT_ACTION_MESSAGE)
        }
    };

    const handleRunOperationForGlobalProducts = () => {
        switch (selectedAction.value) {
            case texts.ADMIN_PANEL.ACTIONS_DROPDOWN.ADD_IMAGES.value:
                setShowImagePopup(true);
                break;
            case texts.ADMIN_PANEL.ACTIONS_DROPDOWN.CHANGE_ASSIGN_CAT.value:
                setShowCategoriesPopup(true);
                break;
            case texts.ADMIN_PANEL.ACTIONS_DROPDOWN.ADD_CATEGORY.value:
                setShowCategoriesPopup(true);
                break;
            case texts.ADMIN_PANEL.ACTIONS_DROPDOWN.CHANGE_TAGS.value:
                setShowTagsPopup(true);
                break;
            case texts.ADMIN_PANEL.ACTIONS_DROPDOWN.ADD_TAGS.value:
                setShowTagsPopup(true);
                break;
            default:
                break;
        }
    };

    const uploadImageResponseHandler = async (res, row, shouldFetchProducts, productName) => {
        if (res.status === 200) {
            setShowImagePopup(false);
            let name = productName ? productName : row.product.productName;
            notify("success", texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.SUCCESSFUL_IMAGE_UPLOAD, name);
            let idx = selectedRows.indexOf(row);
            selectedRows.splice(idx, 1);
            setSelectedRows([...selectedRows]);
            if (selectedRows.length === 0 && shouldFetchProducts) {
                getGlobalProducts(getGlobalProductsResponseHandler, selectedCategory.value, searchedProduct, selectedVendor.value)
            }
        }
    };

    const changeProductRequestHandler = (property) => {
        selectedRows.map(async row => {
            await editGlobalProduct(
                createBodyForTagsAndCategoriesUpdate(row.product, property, categoryToChange, tagsInputRef),
                editGlobalProductResponseHandler,
                editGlobalProductErrorHandler
            )
        });

        setShowCategoriesPopup(false)
        setSelectedAction(initialAction)
    };

    const uploadProductImages = async () => {
        selectedRows.map(async (row) => {
            await uploadProductImage(row.product.id, 1, selectedImage, (response) => uploadImageResponseHandler(response, row, true))
        });
        setSelectedAction(initialAction)
    };

    const editGlobalProductResponseHandler = (res, body) => {
        if (res.status === 200) {
            setShowTagsPopup(false);
            setShowCategoriesPopup(false);
            notify("success", texts.ADMIN_PANEL.SUCCESSFUL_CHANGE);
            let product = selectedRows.find(e => e.product.id === body.id);
            selectedRows.splice(selectedRows.indexOf(product), 1);
            setSelectedRows([...selectedRows]);
            if (selectedRows.length === 0) {
                setSearchedProduct("");
                searchRef.current.value = "";
                getGlobalProducts(getGlobalProductsResponseHandler)
            }
        }
    };

    const editGlobalProductErrorHandler = (err) => {
        notify("error", texts.GENERAL.ERROR_OCCURRED, null, 0)
    };

    const categorySelectHandler = (id) => {
        setCategoryToChange(id);
        let prevSelected = document.querySelector(".current-selected-cat");
        if (prevSelected) {
            prevSelected.classList.remove("current-selected-cat")
        }

        let element = document.getElementById("cat" + id);
        element.classList.add("current-selected-cat")
    };

    const handleOpsChange = (event) => {
        if (event) {
            if (selectedRows.length > 0) {
                setSelectedAction(event)
            } else {
                notify("info", texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.SELECT_PRODUCTS);
            }
        } else {
            setSelectedAction(initialAction)
        }
    };

    const getOperationText = (operation) => {
        switch (operation) {
            case SHOW_HIDE_OPERATION:
                return texts.ADMIN_PANEL.SHOW_HIDE;
            case MATCH_OPERATION:
                return texts.ADMIN_PANEL.MATCH;
            default:
                break;
        }
    };

    return (
        <div>
            <AdminAndVendorPageHeader text={texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.TITLE}/>

            <div className="d-md-flex m-2">
                <div className="input-group w-100 w-lg-55 mb-3 pr-md-5">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1"><i className="fas fa-search"></i></span>
                    </div>
                    <input type="text" className="form-control no-box-shadow"
                           ref={searchRef}
                           onChange={(e) => handleFilterChange(e, "search")}
                           placeholder={texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.SEARCH_PLACEHOLDER}
                           aria-describedby="basic-addon1"/>
                    <div className="input-group-append c-pointer" data-testid="ap-clear-search" onClick={handleClearSearch}>
                        <span className="input-group-text"><i className="fas fa-times-circle"></i> </span>
                    </div>
                </div>
                <div className="mr-4">
                    <Link to={ROUTES.ADMIN.IMPORT} className="btn btn-primary border-radius-5"> {texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.ADD_VENDOR_PL} </Link>
                </div>
                <div className="">
                    <Link to={ROUTES.ADMIN.NEW_GLOBAL_PRODUCT_LINK + `/new`} className="btn btn-primary border-radius-5"> {texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.ADD_NEW_GLOBAL_PRODUCT}</Link>
                </div>
            </div>

            <div className="d-md-flex m-2 w-100">
                <div className="d-flex w-25">
                    <SelectSearch isClearable value={selectedAction} className="w-75" onChange={handleOpsChange}
                                  options={Object.values(texts.ADMIN_PANEL.ACTIONS_DROPDOWN)}  />
                    <button disabled={selectedRows.length === 0} onClick={handleRunOperationForGlobalProducts} className="btn btn-primary border-radius-5 ml-2">
                        {texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.RUN}
                    </button>
                </div>
                <div className="w-25 d-flex align-items-center justify-content-center">
                    <button disabled={vendorProductOps.length === 0} className="btn btn-primary border-radius-5 ml-2 " onClick={handleRunOperationForVendorProducts}>
                        {texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.RUN_FOR_VENDOR}
                    </button>
                    {selectedVendorOperation &&
                    <div className="text-primary pl-2">
                        {getOperationText(selectedVendorOperation)}
                        <i hidden={!selectedVendorOperation} data-testid="vendor-op-clear" onClick={() => {
                            setSelectedVendorOperation();
                            setVendorProductOps([])
                        }} className="fas fa-times-circle c-pointer pl-1"></i>
                    </div>
                    }
                </div>
                <div className="d-md-flex justify-content-end w-50 pr-3">
                    <div className="width-33 pl-2">
                        <SelectSearch placeholder={texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.CATEGORY_PLACEHOLDER} onChange={(e) => handleFilterChange(e, "category")}
                                      options={createNestedOptions(categories)}/>
                    </div>
                    <div className="width-33 pl-2">
                        <SelectSearch placeholder={texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.VENDORS_PLACEHOLDER} onChange={(e) => handleFilterChange(e, "vendor")}
                                      options={[{label: texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.ALL, value: null}, ...createSelectOptions(vendors, "vendorName", "id")]}/>
                    </div>
                    <div className="width-33 pl-2">
                        <SelectSearch placeholder={texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.PRICE_LIST_DATE}/>
                    </div>
                </div>
            </div>

            <div className="">
                <div className="card shadow m-2">
                    <a href="#table-products" className="card-header h5 text-left" data-toggle="collapse"> {texts.VENDOR_PANEL.PRODUCTS_PAGE_HEADING} </a>
                    <div id="table-products" className="card-body collapse show">
                        {((() => {
                            if (isLoading) {
                                return (
                                    <div className="">
                                        <Spinner animation={"border"} variant={"primary"} />
                                    </div>
                                )
                            } else {
                                return (
                                    <>
                                        {error.show && <div>{error.message}</div>}
                                        {products.length > 0 && <AdminProductsTable
                                            onEditOrSaveClick={handleEditAndSaveClick}
                                            products={products}
                                            onVendorProductChange={handleVendorProductChange}
                                            onVendorOpsChange={handleVendorOperationChange}
                                            setSelectedRows={setSelectedRows}
                                            uploadImageResHandler={uploadImageResponseHandler}
                                            vendorProductOps={vendorProductOps}
                                        />
                                        }
                                        <div className="text-left">
                                            {texts.GENERAL.PRODUCTS_SHOWN}: {products.length}
                                        </div>
                                    </>
                                )
                            }
                        })())
                        }
                        <div className="m-2" hidden={isShowMoreButtonHidden}>
                            <ShowMoreButton className={"border m-0"} text={texts.VENDOR_PAGE.SHOW_MORE_TEXT} onClick={handleShowMoreClick}/>
                        </div>
                    </div>
                </div>
            </div>



            <ChangeCategoriesAndTagsPopup show={showImagePopup}
                                          onHide={() => setShowImagePopup(false)}
                                          headerText={texts.ADMIN_PANEL.CHOOSE_IMAGE}
                                          onAccept={uploadProductImages}
                                          isAcceptDisabled={!selectedImage}>

                <input type="file" data-testid="bulk_add_image"
                       onChange={(e) => setSelectedImage(e.target)}
                       id="add_image"/>
            </ChangeCategoriesAndTagsPopup>


            <ChangeCategoriesAndTagsPopup show={showTagsPopup}
                                          onHide={() => setShowTagsPopup(false)}
                                          headerText={texts.ADMIN_PANEL.CHOOSE_TAGS}
                                          onAccept={() =>  changeProductRequestHandler(selectedAction.value)}>

                <ChangeTagsPopupBody onClear={() => tagsInputRef.current.value = ""} inputRef={tagsInputRef} />
            </ChangeCategoriesAndTagsPopup>

            <ChangeCategoriesAndTagsPopup show={showCategoriesPopup}
                                          onHide={() => setShowCategoriesPopup(false)}
                                          headerText={texts.ADMIN_PANEL.CHOOSE_CATEGORY}
                                          onAccept={() => changeProductRequestHandler(selectedAction.value)}
                                          isAcceptDisabled={!categoryToChange}>

              <ChangeCategoryPopupBody categories={categories} categorySelectHandler={categorySelectHandler} />
            </ChangeCategoriesAndTagsPopup>

            <Prompt when={productsInEdit > 0 || vendorProductOps.length > 0} message={texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.ROUTER_PROMPT_MESSAGE}/>
            <AdminAndVendorFooter texts={texts.VENDOR_PAGE.FOOTER}/>
        </div>
    );
};

export default AdminProducts;