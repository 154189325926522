import React from 'react';
import {useSortBy, useTable} from 'react-table';
import {checkIsSorted, createColumns} from "../../../utils/TableUtils";
import texts from '../../../Languages/bg'
import {getStatusInfo} from "../../../utils/OrderUtils";
import ColumnHideCheckboxes from "../../common/ColumnHideCheckboxes/ColumnHideCheckboxes";
import ProductImageTableCell from "../../admin/ProductImageTableCell/ProductImageTableCell";
import PropTypes from "prop-types";

const OrderTable = ({order}) => {

    const createRows = (data) => {
        let arr = [];
        data.orderItems.map(item => {
            let obj = {
                sosku: item.sosku,
                erpId: item.erpId,
                image: <ProductImageTableCell product={item} isVendor={true}/>,
                productName:  item.displayName,
                quantity: item.quantity,
                measureUnit: `x ${item.selectedPackage.packagingUnit} ${item.selectedPackage.packagingType}`,
                amount: `${item.sum.toFixed(2)} ${texts.VENDOR_PAGE.SHOPPING_CART.CART.CURRENCY}`,
                promotion: "6 + 1",
                comment:  item.comment  ??  "-"
            };

            arr.push(obj)
        });

        data.customOrderItems.map(item => {
            let obj = {
                sosku: "",
                erpId: "",
                image: "-",
                productName:  item.itemName,
                quantity: item.quantity,
                measureUnit: item.measureUnit,
                amount: "",
                promotion: "",
                comment: item.comment ?? "-"
            };

            arr.push(obj)
        });

        return arr

    };

    const columns = React.useMemo(() => createColumns(texts.VENDOR_PANEL.SINGLE_ORDER_TABLE_COLUMNS), []);
    const data = React.useMemo(() => createRows(order),[order]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        allColumns,
    } = useTable({
            columns,
            data,
            initialState: {
                hiddenColumns: ["sosku", "erpId", "amount", "promotion"]
            }
        },
        useSortBy);

    return (
        <>
            <ColumnHideCheckboxes columns={allColumns} />
            <table id="vendor-orders-table" className="table table-bordered table-striped table-sm" {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr  {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th scope="col" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                            {checkIsSorted(column)}
                            </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td
                                           className={`text-center text-dark ${cell.column.id === "status" ? getStatusInfo(cell.value).bg : null}`}
                                           {...cell.getCellProps()}>{cell.render('Cell')}
                                </td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </>
    );
};

OrderTable.propTypes = {
    order: PropTypes.object
};

export default OrderTable;