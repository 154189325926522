import React from 'react';
import PropTypes from 'prop-types';

const TableInputField = ({product, defaultValue, onChange, name="", type="number", className, isDisabled, onlyIntegers}) => {
    const handleChange = (event, shouldBeInt) => {
        if (shouldBeInt) {
            if (!Number.isInteger(+event.target.value)) {
                return
            }
        }
        onChange(event)
    };

    return (
        <input name={name}
               id={product.id}
               type={type}
               className={`${className} input-num-hide-arrows width-100px p-0 text-right ${!product.isEditable && "bg-none border-0"}`}
               onChange={(event) => handleChange(event, onlyIntegers)}
               defaultValue={defaultValue}
               data-testid="du-price"
               disabled={isDisabled} />
    );
};

TableInputField.propTypes = {
    product: PropTypes.object,
    productIndex: PropTypes.number,
    priceWithVat: PropTypes.number,
    packIndex: PropTypes.number,
    onChange: PropTypes.func,
    name: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    onlyIntegers: PropTypes.bool
};

export default TableInputField;