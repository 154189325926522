import React from 'react';
import PropTypes from 'prop-types';

const ColumnHideCheckboxes = ({columns}) => {
    return (
        <div className="d-md-flex">
            {columns.map(column => {
                if (column.id !== "selection") {
                    return (
                        <div className="d-inline-block pl-2 pr-2" key={column.id}>
                            <label>
                                <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                                {column.Header}
                            </label>
                        </div>
                    )
                }
            })}
        </div>
    );
};

ColumnHideCheckboxes.propTypes = {
    columns: PropTypes.array
};

export default ColumnHideCheckboxes;