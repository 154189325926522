import React from 'react';
import PropTypes from 'prop-types';
import ProductImageTableCell from "../../ProductImageTableCell/ProductImageTableCell";

const TableRow = ({product, showImage}) => {
    return (
        <tr className="text-break">
            {showImage &&
            <td style={{width:"10%"}}>
                <ProductImageTableCell product={product} />
            </td>}
            <td style={{width:"18%"}} >{product.productName}</td>
            <td style={{width:"4%"}}>{product.netWeight}</td>
            <td style={{width:"4%"}}>{product.measureUnit}</td>
            <td style={{width:"5%"}}>{product.brand}</td>
            <td style={{width:"10%"}}>{product.categories && product.categories[0] && product.categories[0].name}</td>
            <td style={{width:"10%"}}>{product.categories && product.categories[1] && product.categories[1].name}</td>
            <td style={{width:"10%"}}>{product.categories && product.categories[2] && product.categories[2].name}</td>
            <td style={{width:"10%"}}>{product.origin}</td>
            <td style={{width:"10%"}}>{product.quality}</td>
        </tr>
    );
};

TableRow.propTypes = {
    product: PropTypes.object
};

export default TableRow;