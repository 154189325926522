import React from 'react';
import PropTypes from 'prop-types';
import './OrderInfo.css';
import OrderNumberAndDate from "../../AllOrdersPage/OrderNumberAndDate/OrderNumberAndDate";
import {VENDOR_PAGE as texts} from "../../../../Languages/bg";
import OrderStatus from "../../AllOrdersPage/OrderStatus/OrderStatus";
import {getStatusInfo} from "../../../../utils/OrderUtils";
import AddButton from "../AddButton/AddButton";
import {Link} from "react-router-dom";
import {ROUTES} from "../../../../config";

const OrderInfo = ({order, onClick, isLoading}) => {
    return (
        <div className="ov-order-info">
            <div className="ov-order-number-and-date-wrapper">
                <OrderNumberAndDate texts={texts} order={order}/>
            </div>
            <div className="ov-name-status-wrapper">
                <div className="ov-vendor-name">
                <Link className="clear-link-style text-grey" to={{
                    pathname: ROUTES.CUSTOMER.VENDOR_PAGE,
                    state: {
                        id: order.vendorId
                    }

                }}>
                    {order.vendorName}
                </Link></div>

                <div className="ov-status-wrapper">
                    <OrderStatus status={order.status && getStatusInfo(order.status).name}
                                 statusColor={order.status && getStatusInfo(order.status).color}/>
                </div>

                <div className="ov-add-to-cart-btn-wrapper d-none d-lg-block">
                    <AddButton isLoading={isLoading} onClick={() => onClick(order)} text={texts.ORDERS_PAGE.ADD_TO_CART}/>
                </div>
            </div>

            <div className="ov-add-to-cart-btn-wrapper d-lg-none">
                <AddButton isLoading={isLoading}  onClick={() => onClick(order)} text={texts.ORDERS_PAGE.ADD_TO_CART}/>
            </div>
        </div>
    );
};

OrderInfo.propTypes = {
    onClick: PropTypes.func,
    order: PropTypes.object,
    isLoading: PropTypes.bool
};

export default OrderInfo;