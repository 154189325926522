import { texts } from "../Languages/Language";

export const createSelectOptions = (data, label = "name", value = "name") => {
    return data.map(el => {
        return {
            label: el[label],
            value: el[value]
        }
    });
};

export const createSelectOptionsFromArray = (data) => {
   return data.map(el => {
        return {
            label: el,
            value: el
        }
    })
};

export const createNestedOptions = (data) => {
    let arr = [{label: texts.ADMIN_PANEL.GLOBAL_PRODUCTS_PAGE.ALL, value: null}];

    data.map(category => {
        arr.push({
            label: category.name,
            value: category.id
        });
        if (category.children) {
            category.children.map(secondCategory => {
                arr.push({
                    label: `${category.name}/${secondCategory.name}`,
                    value: secondCategory.id
                });
                if (secondCategory.children) {
                    secondCategory.children.map(thirdCategory => {
                        arr.push({
                            label: `${category.name}/${secondCategory.name}/${thirdCategory.name}`,
                            value: thirdCategory.id
                        })
                    })
                }
            })
        }
    });
    return arr
};