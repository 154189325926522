import React, {useEffect, useState} from 'react';
import './LoginPage.css';
import LoginForm from "./LoginForm/LoginForm";
import {loginUserRequest} from "../../services/UserServices";
import {getAuthToken} from "../../services/LocalStorageServices";
import jwt from "jwt-decode";
import {loginUserErrorHandler, loginUserResponseHandler, redirect} from "../../utils/LoginUtils";

const LoginPage = props => {
    const [user, setUser] = useState({email: "", password: ""});
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let token = getAuthToken();
        if (token) {
            redirect(jwt(token).groups[0], props)
        }
    },[]);

    const handleInputChange = (event) => {
        user[event.target.name] = event.target.value;
        setUser({...user})
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setIsLoading(true);
        try {
            let res = await loginUserRequest(user);
            loginUserResponseHandler(res, setIsLoading, props)
        } catch (error) {
            loginUserErrorHandler(error, setIsLoading, setError)
        }
    };


    return (
        <div>
            <LoginForm error={error} isLoading={isLoading} onChange={handleInputChange} onFormSubmit={handleFormSubmit}/>
        </div>
    );
};

export default LoginPage;