import React from 'react';
import PropTypes from 'prop-types';
import './CategoriesToggler.css';
import CategoriesIcon from "../../../../assets/bars-solid.svg";

const CategoriesToggler = ({onClick}) => {
    return (
        <div className="sc-categories-toggler">
            <div className="col-2 categories-toggler"
                 onClick={onClick} data-testid="sov-cat-toggle">
                <img style={{width: "1.6em", color: "white"}}
                     src={CategoriesIcon} alt=""/>
            </div>
        </div>
    );
};

CategoriesToggler.propTypes = {
    onClick: PropTypes.func
};

export default CategoriesToggler;