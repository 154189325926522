import React, {useEffect, useState} from 'react';
import './AllOrdersPage.css';
import CustomerPageHeader from "../CustomerPageHeader/CustomerPageHeader";
import TitleSection from "../TitleSection/TitleSection";
import Checklist from '../../../assets/checklist_icon-white.svg';
import GoBackSection from "../GoBackSection/GoBackSection";
import {texts as language} from '../../../Languages/Language';
import Footer from "../Footer/Footer";
import ShoppingCartButtonMobile from "../ShoppingCartButtonMobile/ShoppingCartButtonMobile";
import {DEFAULT_FETCH_ITEMS_COUNT} from "../../../config";
import OrderCard from "./OrderCard/OrderCard";
import { getOrderByIdAndRoleAsync, getOrdersByUserRoleAsync } from "../../../services/OrderServices";
import ShowMoreButton from "../ShowMoreButton/ShowMoreButton";
import StatusDropdown from "./StatusDropdown/StatusDropdown";
import {duplicateHandler, getInitialOrders, getItemsCount, getItemsPrice, getStatusInfo} from "../../../utils/OrderUtils";
import {scrollToTop} from "../../../utils/CheckWindowWidth";
import BackToTopButton from "../BackToTopButton/BackToTopButton";
import Spinner from "react-bootstrap/Spinner";
import {displayMessageAccordingToStatus} from '../../../utils/OrderUtils';

const AllOrdersPage = props => {
    const texts = language.VENDOR_PAGE;
    const [currentSelectedStatus, setCurrentSelectedStatus] = useState();
    const [orders, setOrders] = useState([]);
    const [isShowMoreButtonHidden, setIsShowMoreButtonHidden] = useState(true);
    const [page, setPage] = useState(2);
    const [isLoading, setIsLoading] = useState(true);
    const [initialOrdersInfo, setInitialOrdersInfo] = useState({price: 0, itemsCount: 0});

    useEffect(() => {
        scrollToTop();
        getOrdersByStatus(null);
        getInitialOrders(getInitialOrdersResponseHandler);
    }, []);

    const getOrdersByStatus = async (orderStatus, onSuccess = getOrdersResponseHandler, onError = () => {}, page) => {
        try {
            let response = await getOrdersByUserRoleAsync(null, null, orderStatus, null, page);
            onSuccess(response.data);
            setIsLoading(false);
        } catch (error) {
            onError(error)   
        }
    };

    const getInitialOrdersResponseHandler = (responseData) => {
        setInitialOrdersInfo({price: getItemsPrice(responseData), itemsCount: getItemsCount(responseData)});
    };

    const getOrderToDuplicate = async (orderId, onSuccess) => {
        try {
            let response = await getOrderByIdAndRoleAsync(orderId);
            onSuccess(response.data);
        } catch (error) {

        }
    };

    const handleStatusSelect = (event) => {
        setCurrentSelectedStatus(event.target.id)
        if (event.target.id === texts.ORDERS_PAGE.STATUSES_PLURAL.ALL.value) {
            getOrdersByStatus(null)
        } else {
            getOrdersByStatus(event.target.id, setOrders)
        }
        setIsLoading(true)
    };

    const getOrdersResponseHandler = (data) => {
        let ordersArray = orders.concat(data)
        ordersArray.map(order => order.isLoading = false);
        setOrders(ordersArray);
        setIsShowMoreButtonHidden(data.length !== DEFAULT_FETCH_ITEMS_COUNT)
        setIsLoading(false)
    };

    const handleShowMoreClick = () => {
        setPage(page + 1);
        getOrdersByStatus(currentSelectedStatus, getOrdersResponseHandler, null, page)
    };

    const handleAddToCartClick = async (orderToAdd) => {
        let index = orders.indexOf(orderToAdd);
        orders[index].isLoading = true;
        setOrders([...orders]);
        getOrderToDuplicate(orderToAdd.id, (response) => addToCartResponseHandler(response, orderToAdd) );
    };

    const addToCartResponseHandler = async (order, orderToAdd) => {
        await duplicateHandler(order, props, () => stopSpinnerInButton(orders.indexOf(orderToAdd)))
    };

    const stopSpinnerInButton = (orderIndex) => {
       if (orderIndex > 0) {
        orders[orderIndex].isLoading = false;
        setOrders([...orders]);
       }
    }

    return (
        <div className="all-orders-container">
            <CustomerPageHeader ordersSum={initialOrdersInfo.price} />

            <div className="all-orders-body">

                <TitleSection icon={Checklist} leftText={texts.ORDERS_PAGE.PAGE_TITLE}/>

                <GoBackSection text={texts.SHOPPING_CART.GO_BACK} />

                {!isLoading && (
                    <StatusDropdown texts={texts} onStatusSelect={handleStatusSelect}
                        selectedStatus={currentSelectedStatus} />
                )}
                <div className="op-orders-wrapper">
                    {(() => {
                        if (isLoading) {
                            return <Spinner animation={"border"} variant={"success"} />
                        } else {
                           if (orders.length > 0) {
                                return orders.map(order => {
                                    return (
                                        <OrderCard key={order.id}
                                                   texts={texts}
                                                   order={order}
                                                   status={getStatusInfo(order.status).name}
                                                   statusColor={getStatusInfo(order.status).color}
                                                   onClick={handleAddToCartClick}
                                        />
                                    )
                                })
                            } else {
                               return (
                                   <div className="h4">
                                       {displayMessageAccordingToStatus(currentSelectedStatus)}
                                   </div>
                               )
                           }
                        }
                    })()}
                </div>
            </div>

            <div hidden={isShowMoreButtonHidden} className="p-4">
                <ShowMoreButton className={"m-0"} onClick={handleShowMoreClick} text={texts.SHOW_MORE_TEXT}/>
            </div>

            <BackToTopButton texts={texts} />

            <ShoppingCartButtonMobile texts={texts} cartPrice={initialOrdersInfo.price} cartItems={initialOrdersInfo.itemsCount} />

            <Footer texts={texts.FOOTER}/>

        </div>
    );
};

export default AllOrdersPage;