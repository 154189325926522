import React from 'react';
import PropTypes from 'prop-types';
import {getStatusInfo} from "../../../utils/OrderUtils";

const InfoBox = ({count, status, className}) => {

    const statusInfo = getStatusInfo(status);

    return (
        <div className={`${className} pl-4 pr-4 text-white ${statusInfo.bg}`}>
            {statusInfo.namePlural}: <span className="font-weight-bold ">{count}</span>
        </div>
    );
};

InfoBox.propTypes = {
    count: PropTypes.number,
    background: PropTypes.string,
    className: PropTypes.string
};

export default InfoBox;