import React from 'react';
import CustomButton from "../CustomButton/CustomButton";
import texts from '../../../Languages/bg';
import {downloadOrder, printOrder} from "../../../utils/OrderUtils";
import PropTypes from "prop-types";

const ButtonsSection = ({text, firstButtonProperties, secondButtonProperties, hideButtons, showIcons, order, className, disableAll, onExportClick}) => {
    return (
        <div className={`${className} w-100`}>
            <div className="text-lg-left vov-text m-lg-auto pt-lg-2 pl-lg-5 h5">
                {text}
            </div>
            {showIcons && <div className="text-right vov-icons-wrapper">
                <button disabled={disableAll} data-testid="vov-download" className="border-0 bg-none text-center icon-btn mr-5" onClick={(e) => {
                    e.preventDefault();
                    downloadOrder(order);}}>
                    <div className="d-inline-block text-center">
                        <i className="far fa-file-pdf vov-icon"></i>
                        <div className="icon-colored-text">
                            {texts.VENDOR_PANEL.DOWNLOAD}
                        </div>
                    </div>
                </button>
                <button disabled={disableAll} data-testid="vov-print" className="border-0 bg-none text-center icon-btn mr-5" onClick={(e) => {
                    e.preventDefault();
                    printOrder(order);}}>
                    <div className="d-inline-block text-center">
                        <i className="fas fa-print vov-icon"></i>
                        <div className="icon-colored-text">
                            {texts.VENDOR_PANEL.PRINT}
                        </div>
                    </div>
                </button>
                <button disabled={disableAll} onClick={onExportClick} data-testid="vov-export" className="border-0 bg-none text-center icon-btn mr-2">
                <div className="d-inline-block text-center">
                    <i className="fas fa-file-export vov-icon"></i>
                    <div className="icon-colored-text pr-3">
                        {texts.VENDOR_PANEL.EXPORT}
                    </div>
                </div>
                </button>
            </div>}
            <div hidden={hideButtons} className="vov-buttons">
                {firstButtonProperties &&  <CustomButton testid={"vov-first"} className={firstButtonProperties.className} text={firstButtonProperties.text}
                              icon={firstButtonProperties.icon} onClick={firstButtonProperties.onClick}/> }

                {secondButtonProperties &&  <CustomButton testid={"vov-second"} className={secondButtonProperties.className} text={secondButtonProperties.text}
                              icon={secondButtonProperties.icon} onClick={secondButtonProperties.onClick}/> }
            </div>
        </div>
    );
};

ButtonsSection.propTypes = {
    text: PropTypes.string,
    firstButtonProperties: PropTypes.object,
    secondButtonProperties: PropTypes.object,
    hideButtons: PropTypes.bool,
    showIcons: PropTypes.bool,
    order: PropTypes.object,
    className: PropTypes.string,
    disableAll: PropTypes.bool,
    onExportClick: PropTypes.func
};

export default ButtonsSection;