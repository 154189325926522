import React from 'react';
import PropTypes from 'prop-types';
import './Cart.css';
import OpenCartButton from "../OpenCartButton/OpenCartButton";
import {Link} from 'react-router-dom';
import {ROUTES} from "../../../../config";
import {roundTo} from "../../../../utils/Utils";

const Cart = ({texts, vendorName, itemsCount, totalPriceVAT, number, id, vendorId, hideButton, cartsCount}) => {

    return (
        <div className="sc-cart-wrapper">
            <div className="sc-cart-first-row">
                <div className="sc-cart-number">
                    {number}
                </div>
                <div className="sc-cart-vendor-name">
                    <Link to={{pathname: ROUTES.CUSTOMER.VENDOR_PAGE, state: {id: vendorId}}}>
                        {vendorName}
                    </Link>
                </div>
                <div className="sc-cart-items-and-price">
                    <div>
                        <span className="sc-items-count">{itemsCount}</span> {texts.CART.ITEMS}
                    </div>
                    <div>
                        <span className="sc-cart-price">{totalPriceVAT && roundTo(totalPriceVAT, 2)} {texts.CART.CURRENCY}</span> {texts.CART.WITH_VAT}
                        </div>
                </div>
            </div>
            <Link hidden={hideButton} to={{
                pathname: `${ROUTES.CUSTOMER.ORDER_WITH_STATUS_INITIAL_VIEW.LINK}/${id}`,
                state: {
                    id: id,
                    vendorName: vendorName,
                    itemsCount: itemsCount,
                    totalPrice: totalPriceVAT,
                    number: number,
                    vendorId: vendorId,
                    cartsCount: cartsCount
                }
            }}
                  className={`sc-show-items-button-wrapper`}>
                <OpenCartButton texts={texts}/>

            </Link>
        </div>
    );
};

Cart.propTypes = {
    texts: PropTypes.object,
    vendorName: PropTypes.string,
    itemsCount: PropTypes.number,
    totalPriceVAT: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    number: PropTypes.number,
    id: PropTypes.number,
    vendorId: PropTypes.number,
    hideButton: PropTypes.bool,
    cartsCount: PropTypes.number
};

export default Cart;