import React from 'react';
import PropTypes from 'prop-types';
import UploadImageButton from "../UploadImageButton/UploadImageButton";

const UploadSection = ({texts, number, info, onChange, name, image, id, testId}) => {

    return (
        <div>
            <div className="h4 font-weight-bold mt-2 text-left">
                {texts.FIRST_HEADING} {number}:
            </div>

            <div className="mt-1 d-flex">
                <div className="ngp-image-preview">
                    {!image ?
                        <div>
                            {info.map(item => (
                                <h1 key={info.indexOf(item)}>{item}</h1>
                            ))}
                        </div>
                        :
                        <img className="ngp-image-show" src={image} alt=""/>}
                </div>
                <UploadImageButton testId={testId} name={name} id={id} onChange={onChange} buttonText={texts.BTN_UPLOAD_IMAGE}/>
            </div>
        </div>
    );
};

UploadSection.propTypes = {
    texts: PropTypes.object,
    number: PropTypes.number,
    info: PropTypes.array,
    onChange: PropTypes.func,
    name: PropTypes.string,
    image: PropTypes.string,
    id: PropTypes.string,
    testId: PropTypes.string
};

export default UploadSection;