import React from 'react';
import PropTypes from 'prop-types';
import './AdminAndVendorFooter.css';

const AdminAndVendorFooter = ({texts}) => {
    return (
        <div className="w-100 mt-5">
            <div className="d-flex pl-4 pr-4 justify-content-center">
                    <div className="float-left text-right">
                        <div>
                            <a className="text-grey" href="https://shiftorders.com/terms">
                                {texts.TERMS}
                            </a>
                        </div>
                        <div>
                            <a className="text-grey" href="https://shiftorders.com/privacy-policy">
                                {texts.PRIVACY}
                            </a>
                        </div>
                    </div>
                    <div className="border ml-4 mr-4">

                    </div>
                    <div className="float-right text-left">
                        <div>
                            {texts.CONTACTS}:
                        </div>
                        <div>{texts.PHONE}</div>
                        <div> {texts.EMAIL}</div>
                    </div>
            </div>

            <div className="w-100 mt-2 d-flex text-center pr-4">
                <p className="w-75 footer-cr mr-4 text-right">{texts.COPYRIGHT}</p>
                <div className="d-flex w-25 footer-icons justify-content-end">
                    <i style={{fontSize: "2em", marginRight:"1%"}} className="fab fa-facebook-square"></i>
                    <i style={{fontSize: "2em"}} className="fab fa-linkedin"></i>
                </div>
            </div>

        </div>
    );
};

AdminAndVendorFooter.propTypes = {
    texts: PropTypes.object
};

export default AdminAndVendorFooter;