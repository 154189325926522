import React from 'react';
import PropTypes from 'prop-types';
import './ConfirmDeliveryModal.css';
import {Modal} from "react-bootstrap";

const ConfirmDeliveryModal = ({texts, show, onHide, onClick}) => {
    return (
        <Modal show={show}
               onHide={onHide}
               dialogClassName="ov-confirm-dialog"
        >
            <div className="ov-confirm-delivery-modal-text">
                {texts.ORDERS_PAGE.MODAL_TEXT}
            </div>
            <div className="ov-delivery-modal-btn-wrapper">
                <button onClick={onHide}
                        className="ov-delivery-modal-btn-not-delivered">
                    {texts.ORDERS_PAGE.MODAL_CANCEL_BTN}
                </button>

                <button onClick={onClick} className="ov-delivery-modal-btn-delivered">
                    {texts.ORDERS_PAGE.MODAL_CONFIRM_BTN}
                </button>
            </div>

        </Modal>
    );
};

ConfirmDeliveryModal.propTypes = {
    texts: PropTypes.object,
    show: PropTypes.bool,
    onHide: PropTypes.func,
    onClick: PropTypes.func
};

export default ConfirmDeliveryModal;