import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Select from "react-select";
import GlobalProductContext from "../../GlobalProductContext";
import {texts} from '../../../../../Languages/Language';

const SOSKURow = ({label, text, selectLabel, options, onChange}) => {
    const product = useContext(GlobalProductContext);
    return (
        <div className="col-11 d-flex justify-content-between mt-2">
            <div className="d-flex">
                <div className="label pr-2 pt-2">
                    * {label}:
                </div>
                <div className="sosku-number">
                    {text}
                </div>
            </div>
            <div className="d-flex">
                <div className="label pt-1">
                    * {selectLabel}:
                </div>
                <div className="ml-1">
                    <Select onChange={(e) => onChange(e, "status")}
                            isSearchable={false}
                            options={options}
                            id={"status"}
                            defaultValue={{value: product.status, label: texts.NEW_GLOBAL_PRODUCT.PRODUCT_INFORMATION.STATUS_OPTIONS.ENABLED}}
                            className="input-fld ngp-select status-select"
                            classNamePrefix={"select_search"} />

                </div>
            </div>
        </div>
    );
};

SOSKURow.propTypes = {
    label: PropTypes.string,
    text: PropTypes.string,
    selectLabel: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
};

export default SOSKURow;