import moment from "moment";

export const convertTimestampToDate = (stamp, separator=".") => {
    return moment.unix(Number(stamp / 1000)).format(`DD${separator}MM${separator}YYYY`)
};

export const handleDateChange = (event, dateType, setStartDate, setEndDate) => {
    switch (dateType) {
        case  "start":
            setStartDate(moment(event).format('x'));
            break;
        case "end":
            setEndDate(moment(event).add(1, 'd').subtract(1, 's').format('x'));
            break;
        default:
            break;
    }
};