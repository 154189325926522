import React from 'react';
import PropTypes from 'prop-types';
import {useSortBy, useTable} from 'react-table';
import {Link} from "react-router-dom";
import moment from "moment";
import {getStatusInfo, showOrderTotalPrice} from "../../../utils/OrderUtils";
import texts from '../../../Languages/bg'
import {checkIsSorted, createColumns} from "../../../utils/TableUtils";
import ColumnHideCheckboxes from "../../common/ColumnHideCheckboxes/ColumnHideCheckboxes";

const VendorOrderTable = ({orders, actionHandler}) => {

    const createRows = (data) => {

        let arr = [];
        data.map(order => {
            let obj = {
                orderId: order.code,
                date: moment.unix(order.lastUpdated / 1000).format("DD.MM.YYYY/ HH:mm"),
                client: order.customerName,
                pointOfSale: order.pointOfSaleName,
                address: order.pointOfSaleAddress,
                amount: showOrderTotalPrice(order) + texts.VENDOR_PAGE.SHOPPING_CART.CART.CURRENCY,
                status: getStatusInfo(order.status).name,
                action: [
                   <div className="p-1">
                       <Link to={`/vendor/order/${order.id}`}> {texts.VENDOR_PANEL.VIEW} </Link>
                   </div>,
                    <div className="vo-actions">
                        <button data-testid="tb-download" className="order-table-button" onClick={() => actionHandler("download", order)}>
                            <div className="d-inline-block text-center">
                                <i className="far fa-file-pdf vov-icon"></i>
                            </div>
                        </button>
                        <button data-testid="tb-print" className="order-table-button" onClick={() => actionHandler("print", order)}>
                            <div className="d-inline-block text-center">
                                <i className="fas fa-print vov-icon"></i>
                            </div>
                        </button>
                        <button data-testid="tb-export" className="order-table-button" onClick={() => actionHandler("export")}>
                            <div className="d-inline-block text-center">
                                <i className="fas fa-file-export vov-icon"></i>
                            </div>
                        </button>
                    </div>
                ],
            };

            arr.push(obj)
        });

        return arr

    };

    const data = React.useMemo(() => createRows(orders), [orders]);

    const columns = React.useMemo(() => createColumns(texts.VENDOR_PANEL.TABLE_COLUMNS),[]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        allColumns
    } = useTable({
            columns,
            data
        },
        useSortBy);

    if (orders) {
        return (
            <React.Fragment>

                <ColumnHideCheckboxes columns={allColumns} />
                <table id="vendor-orders-table" className="table table-bordered table-striped table-sm" {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr  {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th scope="col" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                            {checkIsSorted(column)}
                            </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td data-label={cell.column.Header}
                                        className={`${cell.column.id === "status" ? `${getStatusInfo(cell.value).bg} text-white ` : "text-dark "}`}
                                        {...cell.getCellProps()}>{cell.render('Cell')}
                                    </td>
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
};

VendorOrderTable.propTypes = {
    orders: PropTypes.array,
    actionHandler: PropTypes.func
};

export default VendorOrderTable;