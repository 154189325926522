import texts from "../Languages/bg";
import {createSelectOptions} from "./SelectUtils";

export const handlePointOfSaleChange = (event, setSelectedPointsOfSale, setPage) => {
    if (event.value === texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES_PLURAL.ALL.value) {
        setSelectedPointsOfSale(null);
        setPage(2)
    } else {
        setSelectedPointsOfSale(event.value)
    }
};

export const createPointOfSaleOptions = (data, label, value) => {
    let arr = [{
        label: texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES_PLURAL.ALL.name,
        value: texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES_PLURAL.ALL.value
    }];

    arr.push(...createSelectOptions(data, label, value));
    return arr
};

export const handleStatusChange = (event, setStatus, setPage) => {
    if (event.value === texts.VENDOR_PAGE.ORDERS_PAGE.STATUSES_PLURAL.ALL.value) {
        setStatus(null);
        setPage(2)
    } else {
        setStatus(event.value)
    }
};