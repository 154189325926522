import React from 'react';
import PropTypes from 'prop-types';
import InputFieldRow from "../InputFieldRow/InputFieldRow";
import {checkAndAddRedBorder} from "../../../utils/Utils";
import {texts} from '../../../Languages/Language';

const CompanyInfoCard = ({onChange, partner}) => {
    const text = texts.ADMIN_PANEL.NEW_EDIT_VENDOR;

    return (
        <div className="card shadow">
            <div className="card-header h5 text-primary text-left">
                {texts.COMPANY_INFO}
            </div>
            <div className="card-body">
                <InputFieldRow testId="companyName" onBlur={checkAndAddRedBorder} onChange={onChange} nameAndId={"companyName"} label={'*' + text.COMPANY_NAME} defaultValue={partner.companyName}/>
                <InputFieldRow testId="companyAddress" onBlur={checkAndAddRedBorder} onChange={onChange} nameAndId={"companyAddress"} label={'*' + text.COMPANY_ADDRESS} defaultValue={partner.companyAddress}/>
                <InputFieldRow testId="vatNumber" onBlur={checkAndAddRedBorder} onChange={onChange} nameAndId={"vatNumber"} label={'*'+ text.COMPANY_VAT_NUMBER} defaultValue={partner.vatNumber}/>
                <InputFieldRow testId="manager" onBlur={checkAndAddRedBorder} onChange={onChange} nameAndId={"manager"} label={'*'+ text.COMPANY_MANAGER} defaultValue={partner.manager}/>
                <InputFieldRow testId="companyEmail" onChange={(e) => onChange(e, true)} nameAndId={"companyEmail"} label={text.COMPANY_EMAIL} defaultValue={partner.companyEmail}/>
                <InputFieldRow testId="companyPhone" onChange={onChange} nameAndId={"companyPhone"} label={text.COMPANY_PHONE} defaultValue={partner.companyPhone}/>
            </div>
        </div>
    );
};

CompanyInfoCard.propTypes = {
    onChange: PropTypes.func,
    partner: PropTypes.object
};

export default CompanyInfoCard;