import { addOrEditCustomer, addOrEditPintOfSaleAsync, getCustomerByIdAsync } from "../services/CustomerServices";
import { assignVendorToCustomerAsync, unassignVendorFromCustomerAsync } from "../services/CustomerServices";
import {notify} from "./Notifications";

export  const assignVendorErrorHandler = (err, vendor, texts) => {
    notify("error", `${vendor.vendorName} ${texts.ADMIN_PANEL.ASSIGN_USERS.ASSIGN_ERROR}`)
};

export const getCustomerById = async (customerId, onSuccess) => {
    try {
        let response = await getCustomerByIdAsync(customerId);
        onSuccess(response.data)
    } catch (error) {
        
    }
};

export const createOrUpdateCustomer = async (data, onSuccess, onError = () => {}) => {
    try {
        let response = await addOrEditCustomer(data);
        onSuccess(response);
    } catch (error) {
        onError(error)
    }
};

export const assignVendorToCustomer = async (customerId, vendorId, onSuccess, onError = () => {}) => {
    try {
        let response = await assignVendorToCustomerAsync(customerId, vendorId);
        onSuccess(response);
    } catch (error) {
        onError(error)
    }
};

export const unassignVendorFromCustomer = async (customerId, vendorId, onSuccess, onError = () => { }) => {
    try {
        let response = await unassignVendorFromCustomerAsync(customerId, vendorId);
        onSuccess(response);
    } catch (error) {
        onError(error)
    }
};

export const addOrEditPintOfSale = async (id, data, isEdit, onSuccess, onError = () => {}) => {
    try {
        let response = await addOrEditPintOfSaleAsync(id, data, isEdit);
        onSuccess(response);
    } catch (error) {
        onError(error);
    }
};