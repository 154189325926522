import React from 'react';
import PropTypes from 'prop-types';
import SectionField from "../../SectionField/SectionField";
import Select from "react-select";

const BrandNameRow = ({label, onChange, onClick, buttonText,
                          weightLabel,weightSelectName, onBrandsSelectInputChange, onAddBrandButtonClick,
                          brands, weightUnitsOptions, brandPlaceholder, weightPlaceholder, netWeightName, product, itemToAdd}) => {

    const handleNetWeightUnitChange = (e) => {
        onChange(e, weightSelectName)
    };

    return (
        <div className=" d-flex ngp-brand-row">
            <SectionField label={label}
                          onSelectChange={onChange}
                          onAddFieldInputChange={onBrandsSelectInputChange}
                          onAddButtonClick={onAddBrandButtonClick}
                          onClick={onClick}
                          buttonText={buttonText}
                          placeholder={brandPlaceholder}
                          name={"brand"}
                          options={brands}
                          value={itemToAdd.brand.value}
                          defaultValue={product.brand && {value: product.brand, label: product.brand}} 
                          testId="gp-create-brand">

            </SectionField>
            <div className="ml-2 mt-1 d-flex">
                <div className="pt-1">{weightLabel}:</div>
                <input data-testid="gp-netWeight" defaultValue={product && product.netWeight} onChange={onChange} name={netWeightName} type="text" className="input-fld form-control weight-input ml-2"/>
            </div>
            <div className="ml-2 mt-1 select-wrapper weight-select">
                <Select onChange={handleNetWeightUnitChange} placeholder={product ? product.netWeightUnit : weightPlaceholder} name={weightSelectName} options={weightUnitsOptions}
                        className="ngp-unit-select input-fld" classNamePrefix={"select_search"}/>
            </div>
        </div>
    );
};

BrandNameRow.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
    weightLabel: PropTypes.string,
    weightSelectName: PropTypes.string,
    options: PropTypes.array,
    onAddFieldInputChange: PropTypes.func,
    onAddButtonClick: PropTypes.func,
    weightUnitsOptions: PropTypes.array,
    brandPlaceholder: PropTypes.string,
    weightPlaceholder: PropTypes.string,
    netWeightName: PropTypes.string,
    product: PropTypes.object, 
    itemToAdd: PropTypes.object
};

export default BrandNameRow;