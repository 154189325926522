import React from "react";
import {PRODUCT_STATUS} from "../config";
import moment from "moment";
import texts from "../Languages/bg";
import TableInputField from "../components/common/TableInputField/TableInputField";
import { deleteVendorProductPackaging, editVendorProductAsync } from "../services/VendorServices";
import { notify } from "./Notifications";

export const packageHasPrice = (pack) => {
    if (pack) {
        if (pack.packagingUnitPrice !== null) {
            if (pack.packagingUnitPrice !== "0.00" && pack.packagingUnitPrice !== "0") {
                return true
            }
        }
    }
    return false
};

export const attachActiveAttribute = (data) => {
        data.map(item => item.active = 0);
        return data
};

export const availabilityFormatHandler = (status) => {
    return <span className="vp-available">{texts.VENDOR_PAGE.PRODUCT_CARD.PRODUCT_AVAILABILITY[status]}</span>;
};

export const checkCurrentActivePackage = (data, packages, selectedPack, activePackage) => {
    if (data) {
        return data.selectedPackage.id === selectedPack.id
    } else {
        return packages.indexOf(selectedPack) === activePackage
    }
};

export const checkCurrentActivePrice = (data, packages, activePackage, isWithVat, isTotal) => {
    if (data) {
        if (isWithVat) {
           if (isTotal) {
               return (data.selectedPackage.packagingUnitPriceVAT * data.quantity).toFixed(2)
           } else  {
               return data.selectedPackage.packagingUnitPriceVAT
           }
        } else {
            return data.selectedPackage.packagingUnitPrice
        }
    } else {
        if (isWithVat) {
            return packages[activePackage].packagingUnitPriceVAT
        } else {
            return packages[activePackage].packagingUnitPrice
        }
    }
};

export const checkIsProductAvailable = (status) => {
    return status === PRODUCT_STATUS.AVAILABLE || status === PRODUCT_STATUS.ON_REQUEST
};

export const checkLastOrderedDate = (date) => {
    if (date) {
       return moment().diff(moment(date)) >= 3
    }

};

export  const getProductOrderedCount = (data) => {
    let sum = 0;

    data.map(p => sum += p.orderCount);

    return sum
};

export const getMinAndMaxPrice = (data) => {
   if (data.length > 0) {
       let min = 0;
       let max = 0;

       if (Number(data[0].packagingList[0])) {
           min = Number(data[0].packagingList[0].singleItemPriceWithVAT);
           max = Number(data[0].packagingList[0].singleItemPriceWithVAT);
       }
       let currency = texts.VENDOR_PAGE.PRODUCT_CARD.CURRENCY;

       data.map((p, i) => {
           p.packagingList.map(el => {
               let price = Number(el.singleItemPriceWithVAT);

               if (price > max) {
                   max = price
               }
               if (price < min) {
                   min = price
               }
           })
       });

       if ((max === 0 && min === 0 )|| min === max) {
           max = ""
       }

       if (min === 0) {
           min = texts.VENDOR_PAGE.PRODUCT_CARD.NO_PRICE;
           currency = ""
       }

       if (max === 0) {
           max = texts.VENDOR_PAGE.PRODUCT_CARD.NO_PRICE;
           currency = ""
       }

       return {
           min: min,
           max: max,
           currency: currency
       }
   }
};

export const getPackagingAttributes = (product, packagingIndex, productIndex, onChange) => {
    if (product.packagingList[packagingIndex]) {
        return {
            units: <>x {product.packagingList[packagingIndex].packagingUnit} {product.packagingList[packagingIndex].packagingType}</>,
            price: toggleVendorProductEditableFields(product, productIndex, packagingIndex, onChange)
        }
    } else {
        return {
            units: texts.VENDOR_PANEL.NONE,
            price: texts.VENDOR_PANEL.NONE
        }
    }
};

export const toggleVendorProductEditableFields = (product, productIndex, pacagingIndex, onChange) => {
  if (product.packagingList.length > 0) {
      let priceWithVat = Number(product.packagingList[pacagingIndex].packagingUnitPriceVAT);

      if (product.isEditable) {
          return <> <TableInputField product={product} productIndex={productIndex} defaultValue={priceWithVat} packIndex={pacagingIndex} onChange={(e) => onChange(e, pacagingIndex, product, productIndex)} /> {texts.VENDOR_PAGE.PRODUCT_CARD.CURRENCY} </>
      } else {
          if (priceWithVat > 0)  {
              return <span>{priceWithVat} {texts.VENDOR_PAGE.PRODUCT_CARD.CURRENCY}</span>
          } else {
              return <span>{texts.VENDOR_PAGE.PRODUCT_CARD.NO_PRICE}</span>
          }
      }
  }
};

export const optionsCreate = (status) => {
    let options = [];
    Object.entries(texts.VENDOR_PANEL.STATUSES).map(entry =>
        options.push(
            <option key={entry[0]} value={entry[0]} hidden={status === entry[0]} disabled={status === entry[0]}>
                {entry[1]}
            </option>
        )
    );
    return options
};

export const getFormattedSingleItemPrice = (pack) => {
    let price = Number(pack.singleItemPriceWithVAT);
    let split = pack.singleItemPriceWithVAT.split('.')
    let digits = split[1].split('');
    if (digits.length >= 2 && digits[2] > 0) {
        price = price.toFixed(3)
    } else {
        price = price.toFixed(2)
    }
    return price
};

export const editVendorProduct = async (product, onSuccess, onError) => {
    try {
        let response = await editVendorProductAsync(product);
        onSuccess(response)
    } catch (error) {
        if (error.response) {
            onError(error)
            notify("error", error.response.data.parameterViolations[0].message);
        }
    }
};

export const deletePackaging = async (productId, packagingId) => {
    try {
        await deleteVendorProductPackaging(productId, packagingId);
    } catch (error) {
        
    }
};

export const productDoesntHavePackageWithUnits = (product) => {
    return product.packagingList.reduce((sum, {packagingUnit}) => sum + +packagingUnit, 0) === 0
};