import {PASSWORD_MINIMAL_LENGTH} from "../config";
import {notify} from "./Notifications";
import {logoutAfterDelay} from "./LoginUtils";
import {goToPreviousPage} from "./Utils";
import {texts} from "../Languages/Language";

export const checkPasswordsExist = (passwords) => {
    return passwords.password.length > 0 || passwords.repeat_password.length > 0
};

export const checkPasswordsEqual = (passwords) => {
    return passwords.password === passwords.repeat_password
};

export const checkPasswordMinimalLength = (password) => {
    return password ? password.length >= PASSWORD_MINIMAL_LENGTH  : false;
};

export const editUserPasswordResponseHandler = (response, resetPassword, props) => {
    if (response.status === 204) {
        notify("success", texts.ADMIN_PANEL.USERS.SUCCESSFUL_EDIT);
        if  (resetPassword) {
            logoutAfterDelay(1500);
        } else {
            goToPreviousPage(props)
        }
    }
};