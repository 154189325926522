import React, {useEffect, useState} from 'react';
import AdminAndVendorPageHeader from "../../common/AdminAndVendorPageHeader/AdminAndVendorPageHeader";
import {toggleSidebar} from "../../../utils/SidebarUtils";
import CompanyInfoCard from "../CompanyInfoCard/CompanyInfoCard";
import {Link} from "react-router-dom";
import {handleProfileDataChange} from "../../../utils/UserUtils";
import AdminAndVendorFooter from "../../common/AdmivAndVendorFooter/AdminAndVendorFooter";
import {notify} from "../../../utils/Notifications";
import {ROUTES} from "../../../config";
import VendorsAssignedCard from "../VendorsAssignedCard/VendorsAssignedCard";
import {assignVendorErrorHandler, getCustomerById, assignVendorToCustomer, createOrUpdateCustomer} from "../../../utils/CustomerUtils";
import {texts} from "../../../Languages/Language";
import {checkRequiredFields} from "../../../utils/Utils";

const NewAndEditCustomer = props => {
    const requiredFields = ["companyName", "companyAddress", "vatNumber", "manager"];
    const newAndEditCustomerTexts = texts.ADMIN_PANEL.NEW_EDIT_CUSTOMER;
    const customerId = Number(props.location.search.split('=')[1]);
    const isEdit = customerId > 0;
    const [customer, setCustomer] = useState({});
    const [vendors, setVendors] = useState({});

    useEffect(() => {
        if (isEdit) {
            getCustomerById(customerId, setCustomer);
        }
    }, []);

    const handleSaveClick = (customerData, isAddPOSClicked) => {
        createOrUpdateCustomer(customerData, (response) => createOrUpdateResponseHandler(response, isAddPOSClicked), createOrUpdateErrorHandler)
    };

    const redirectToNewPOSPage = (clientId) => {
        props.history.push({
            pathname: ROUTES.ADMIN.NEW_EDIT_POS,
            state: {
                customerId: clientId
            }
        })
    };

    const createOrUpdateResponseHandler = async (response, isAddPOSClicked) => {
        let message = isEdit ? newAndEditCustomerTexts.EDIT_RESPONSE_MESSAGE : newAndEditCustomerTexts.NEW_RESPONSE_MESSAGE;
        let customerId = response.data.id;
        
        if (isAddPOSClicked) {
            if (!isEdit) {
                notify("success", message);
            }
            return redirectToNewPOSPage(customerId)
        } else {
            notify("success", message);
            if (!isEdit) {
                let vendorsArr = Object.values(vendors);
                if (vendorsArr.length > 0 ) {
                    for (let v of vendorsArr) {
                        await assignVendorToCustomer(customerId, v.id, (res) => assignVendorResponseHandler(res, v), (err) => assignVendorErrorHandler(err, v, texts))
                    }
                }
            }
            setTimeout(() => {
                props.history.goBack();
            }, 500)
        }

    };

    const assignVendorResponseHandler = (response, vendor) => {
        notify("success", `${vendor.vendorName}  ${texts.ADMIN_PANEL.ASSIGN_USERS.USER_ASSIGNED}`)
    };

    const setVendorsToAssign = (vendors) => {
        setVendors(vendors);
        customer.assignedVendors = Object.values(vendors)
        setCustomer({...customer})
    };

    const createOrUpdateErrorHandler = (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    notify("error", texts.ADMIN_PANEL.USERS.MISSING_FIELD);
                    break;
                case 409:
                    notify("error", newAndEditCustomerTexts.ERROR_MESSAGE);
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <div>
            <AdminAndVendorPageHeader onClick={toggleSidebar}
                                      text={`${isEdit ? newAndEditCustomerTexts.EDIT_HEADER : newAndEditCustomerTexts.NEW_HEADER}`}/>

            <div className="p-2">
                <CompanyInfoCard partner={customer}
                                 onChange={(e, shouldTrim) => handleProfileDataChange(e, customer, setCustomer, null, null, shouldTrim)}/>
            </div>

            <div className="p-2">
                <div className="card shadow">
                    <div className="card-header h5 text-primary text-left">
                        {newAndEditCustomerTexts.POINTS_ASSIGNED}
                    </div>
                    <div className="card-body">
                        <div className="text-right pb-3">
                            <button disabled={!checkRequiredFields(customer, requiredFields)} onClick={() => handleSaveClick(customer, true)}
                             className="btn btn-primary pr-3 pl-3">
                                {newAndEditCustomerTexts.ADD_POINT}
                            </button>
                        </div>
                        <div className="">
                            <table className="table table-sm table-bordered table-stripped">
                                <thead className="bg-primary text-white">
                                <tr>
                                    {newAndEditCustomerTexts.POS_TABLE_HEADERS.map(th => <th key={th}>{th}</th>)}
                                </tr>
                                </thead>
                                <tbody>
                                {customer.pointsOfSale && customer.pointsOfSale.map(point => {
                                    return (
                                        <tr key={point.shippingAddress}>
                                            <td>{point.id}</td>
                                            <td>{point.name}</td>
                                            <td>{point.shippingAddress}</td>
                                            <td>{point.email}</td>
                                            <td>{point.phone}</td>
                                            <td></td>
                                            <td></td>
                                            <td><Link to={{
                                                pathname: `${ROUTES.ADMIN.NEW_EDIT_POS}` ,
                                                state: {
                                                    data: point,
                                                    posId: point.id,
                                                    customerId: customerId
                                                }
                                            }}>
                                                {newAndEditCustomerTexts.EDIT}
                                            </Link>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-2">
                <VendorsAssignedCard data={customer} customerId={customerId} setVendorsToAssign={setVendorsToAssign} />
            </div>

            <div className="p-5 text-right ">
                <button disabled={!checkRequiredFields(customer, requiredFields)} onClick={() => handleSaveClick(customer, false)} className="btn btn-primary">
                    {texts.ADMIN_PANEL.USERS.SAVE_BTN}
                </button>
            </div>

            <AdminAndVendorFooter texts={texts.VENDOR_PAGE.FOOTER}/>
        </div>
    );
};

export default NewAndEditCustomer;