export const isLargeScreen = (width) => {
    return width > 992
};

export const isWideScreen = (width) => {
  return width > 1500
};


export const scrollToTop = () => {
    window.scrollTo(0,0)
};