import React, {useEffect, useState} from 'react';
import {getUserById} from "../../../services/UserServices";
import {notify} from "../../../utils/Notifications";
import AdminAndVendorPageHeader from "../../common/AdminAndVendorPageHeader/AdminAndVendorPageHeader";
import {toggleSidebar} from "../../../utils/SidebarUtils";
import InputFieldRow from "../InputFieldRow/InputFieldRow";
import SelectField from "../SelectField/SelectField";
import {editUserPasswordResponseHandler} from "../../../utils/PasswordUtils";
import {
    editUserProfileErrorHandler, editUserProfileResponseHandler,
    handleErrors, registerUser,
    handleProfileDataChange, handleProfileSaveClick
} from "../../../utils/UserUtils";
import {checkAndAddRedBorder, checkRequiredFields, goToPreviousPage} from "../../../utils/Utils";
import {texts} from "../../../Languages/Language";

const NewAndEditUser = props => {
    const requiredFields = ["phone", "email", "type", "isActive",];
    const isEdit = props.match.params.action === "edit";
    const [userData, setUserData] = useState({});
    const [passwords, setPasswords] = useState({password: "", repeat_password: ""});

    useEffect(() => {
        if (isEdit) {
            const id = Number(props.location.search.split('=')[1]);
            getUser(id);
        }
    }, []);

    const getUser = async (userId) => {
        try {
            let response = await getUserById(userId);
            getUserResponseHandler(response);
        } catch (error) {
            getUserErrorHandler(error);
        }
    };

    const getUserResponseHandler = (res) => {
        setUserData(res.data);
    };

    const getUserErrorHandler = (err) => {
        notify("error", texts.ADMIN_PANEL.USERS.ERROR_WITH_GETTING_THE_USER)
    };

    const handleSave = (data, passwords) => {
        if (isEdit) {
            handleProfileSaveClick(data, passwords, editUserResponseHandler, editUserErrorHandler)
        } else {
            let data = {...userData};
            data.isActive = data.isActive ?? true;
            registerUser(data, registerUserResponseHandler, registerUserErrorHandler)
        }
    };

    const registerUserResponseHandler = (res) => {
        if (res.status === 204) {
            notify("success", texts.ADMIN_PANEL.USERS.SUCCESSFUL_REGISTRATION)
            goToPreviousPage(props);
        }
    };

    const registerUserErrorHandler = (err) => {
        handleErrors(err, texts)
    };

    const editUserResponseHandler = (response, updatePassword) => {
        editUserProfileResponseHandler(response, updatePassword, null, passwords, editPasswordResponseHandler, editPasswordErrorHandler, props)
    };

    const editUserErrorHandler = (error) => {
        editUserProfileErrorHandler(error, passwords, userData.id, editPasswordResponseHandler, editPasswordErrorHandler)
    };

    const editPasswordResponseHandler = (response) => {
        editUserPasswordResponseHandler(response, null, props)
    };

    const editPasswordErrorHandler = (err) => {
        handleErrors(err, texts)
    };

    return (
        <div>
            <div>
                <AdminAndVendorPageHeader onClick={toggleSidebar}
                                          text={`${texts.ADMIN_PANEL.USERS.PROFILES} - ${isEdit ? texts.ADMIN_PANEL.USERS.EDIT_USER : texts.ADMIN_PANEL.USERS.NEW_USER}`}/>

                <div className="p-4">
                    <div className="card shadow">
                        <div className="card-header h4 text-primary text-left">
                            {texts.ADMIN_PANEL.USERS.PROFILE_INFO}
                        </div>
                        <div className="card-body">
                            <form autoComplete="off">
                                <InputFieldRow type="text" label={texts.ADMIN_PANEL.USERS.FIRST_NAME}
                                               nameAndId="firstName"
                                               onChange={(e) => handleProfileDataChange(e, userData, setUserData, passwords, setPasswords)}
                                               defaultValue={isEdit ? userData.firstName : null}
                                               testId="firstName"
                                               />
                                <InputFieldRow type="text" label={texts.ADMIN_PANEL.USERS.LAST_NAME}
                                               nameAndId="lastName"
                                               onChange={(e) => handleProfileDataChange(e, userData, setUserData, passwords, setPasswords)}
                                               defaultValue={isEdit ? userData.lastName : null}
                                               testId="lastName"
                                               />
                                <InputFieldRow onBlur={checkAndAddRedBorder}
                                               type="tel"
                                               label={`*${texts.ADMIN_PANEL.USERS.PHONE}`}
                                               nameAndId="phone"
                                               onChange={(e) => handleProfileDataChange(e, userData, setUserData, passwords, setPasswords)}
                                               defaultValue={isEdit ? userData.phone : null}
                                               testId="phone"
                                               />
                                <div className="text-left h4 w-75 m-auto">
                                    {texts.ADMIN_PANEL.USERS.USER_NAME}
                                </div>
                                <InputFieldRow type="email"
                                               label={`*${texts.ADMIN_PANEL.USERS.EMAIL}`}
                                               nameAndId="email"
                                               onChange={(e) => handleProfileDataChange(e, userData, setUserData, passwords, setPasswords, true)}
                                               defaultValue={isEdit ? userData.email : null}
                                               onBlur={checkAndAddRedBorder}
                                               testId="email"
                                               />
                                <div className="d-flex w-75 justify-content-end">
                                    {!isEdit && (
                                        <SelectField label={texts.ADMIN_PANEL.USERS.TYPE}
                                        onChange={(e) => handleProfileDataChange(e, userData, setUserData, passwords, setPasswords)}
                                        nameAndId={"type"}
                                        options={texts.ADMIN_PANEL.USERS.TYPE_OPTIONS}
                                        defaultValue={userData.type}
                                        testId="type"
                                        />
                                    )}
                                    <SelectField label={texts.ADMIN_PANEL.USERS.STATUS}
                                                 onChange={(e) => handleProfileDataChange(e, userData, setUserData, passwords, setPasswords)}
                                                 nameAndId={"isActive"}
                                                 defaultValue={userData.isActive}
                                                 options={texts.ADMIN_PANEL.USERS.STATUS_OPTIONS}
                                                 testId="isActive"
                                                 />
                                </div>
                                {isEdit && (
                                        <div>
                                            <InputFieldRow type="password"
                                                           label={`${!isEdit ? "*" : ''}${texts.ADMIN_PANEL.USERS.NEW_PASSWORD}`}
                                                           nameAndId="password"
                                                           onChange={(e) => handleProfileDataChange(e, userData, setUserData, passwords, setPasswords)}
                                                           testId="password"
                                                           />
                                            <InputFieldRow type="password"
                                                           label={`${!isEdit ? "*" : ''}${texts.ADMIN_PANEL.USERS.CONFIRM_PASSWORD}`}
                                                           nameAndId="repeat_password"
                                                           onChange={(e) => handleProfileDataChange(e, userData, setUserData, passwords, setPasswords)}
                                                           testId="repeat-password"
                                                           />
                                        </div>
                                )}
                            </form>
                            <div className="p-5 text-right">
                                <button disabled={!checkRequiredFields(userData, requiredFields)} onClick={() => handleSave(userData, passwords)} className="btn btn-primary">
                                    {texts.ADMIN_PANEL.USERS.SAVE_BTN}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewAndEditUser;