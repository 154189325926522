import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import SectionField from "../SectionField/SectionField";
import SectionTextarea from "./SectionTextarea";
import GlobalProductContext from "../GlobalProductContext";

const ProductOthersSection = ({texts, origins, qualities, shelfLives, onChange, onAddFieldInputChange, itemToAdd}) => {

    const product = useContext(GlobalProductContext).product;
    const createRequests = useContext(GlobalProductContext).requests;

    return (
        <>
            <div className="h4 font-weight-bold text-left">
                Product Others:
            </div>

            <SectionField onSelectChange={onChange}
                          onAddFieldInputChange={onAddFieldInputChange}
                          onAddButtonClick={() => createRequests.createOrigin()}
                          label={texts.ORIGIN}
                          buttonText={texts.BTN_ADD_ORIGIN}
                          name="origin"
                          placeholder={product ? product.origin : texts.ORIGIN_PLACEHOLDER}
                          options={origins}
                          value={itemToAdd.origin.value}
                          defaultValue={product.origin && {label: product.origin, value: product.origin}}
                          testId="gp-create-origin" />

            <SectionField onSelectChange={onChange}
                          onAddFieldInputChange={onAddFieldInputChange}
                          onAddButtonClick={() => createRequests.createQuality()}
                          label={texts.QUALITY}
                          buttonText={texts.BTN_ADD_QUALITY}
                          name="quality"
                          placeholder={product ? product.quality : texts.QUALITY_PLACEHOLDER}
                          options={qualities}
                          value={itemToAdd.quality.value}
                          defaultValue={product.quality && {label: product.quality, value: product.quality}}
                          testId="gp-create-quality" />

            <SectionField onSelectChange={onChange}
                          onAddFieldInputChange={onAddFieldInputChange}
                          onAddButtonClick={() => createRequests.createShelflife()}
                          label={texts.SHELF_LIFE}
                          buttonText={texts.BTN_ADD_SHELF_LIFE}
                          name="shelfLife"
                          placeholder={product ? product.shelfLife : texts.SHELF_LIFE_PLACEHOLDER}
                          options={shelfLives}
                          value={itemToAdd.shelfLife.value}
                          defaultValue={product.shelfLife && {label: product.shelfLife, value: product.shelfLife}}
                          testId="gp-create-shelflife" />

            <SectionTextarea testId="gp-description" onChange={onChange} defaultValue={product ? product.description : null} name="description" label={texts.DESCRIPTION} cols={110}
                             rows={5}/>
            <SectionTextarea testId="gp-tags" onChange={onChange} defaultValue={product ? product.tags : null} name="tags" label={texts.TAGS} cols={40} rows={3}/>
        </>
    );
};

ProductOthersSection.propTypes = {
    texts: PropTypes.object,
    onChange: PropTypes.func,
    origins: PropTypes.array,
    qualities: PropTypes.array,
    shelfLives: PropTypes.array,
    onAddFieldInputChange: PropTypes.func,
    itemToAdd: PropTypes.object,
};

export default ProductOthersSection;