import { createOrigin, getOrigins } from "../services/OriginServices";
import { createSelectOptions } from "./SelectUtils";


export const getAllOrigins = async (onSuccess) => {
    try {
        let response = await getOrigins();
        let options = createSelectOptions(response.data);
        onSuccess(options);
    } catch (error) { }
};

export const createNewOrigin = async (origin, onSuccess, onError) => {
    try {
        let response = await createOrigin(origin); 
        onSuccess(response)
    } catch (error) {
        onError(error)
    }
};